import axiosSession from "../config/axiosSession";

const getDictionary = id => {
  return axiosSession.get(`/api/dicts/${id}/`);
};

const getTimeLimitedAttributes = () => {
  return axiosSession.get("/api/dicts/time-limited-attributes/select-data/");
};

const getSources = () => {
  return axiosSession.get("/api/dicts/sources/select-data/");
};

export { getDictionary, getSources, getTimeLimitedAttributes };
