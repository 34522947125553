import "./DirectoriesSearch.scss";
import "@components/Modal/GenericModal.scss";

import React, { useEffect, useState } from "react";
import {
  faArchive,
  faCopy,
  faDownload,
  faEye,
  faFile,
  faFolder,
  faLayerGroup,
  faLock,
  faPen,
  faPlus,
  faShareSquare,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import {
  faMinusSquare,
  faPlusSquare
} from "@fortawesome/free-regular-svg-icons";

import Checkbox from "@app/components/Checkbox/Checkbox";
import DirectoriesDigitalItemExport from "./DirectoriesDigitalItemExport";
import DirectoriesGroupOperations from "./DirectoriesGroupOperiations";
import DirectoriesObjectTransfer from "./DirectoriesObjectTransfer";
import DropdownButton from "@app/components/DropdownButton/DropdownButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericModal from "@app/components/Modal/GenericModal";
import GenericSelect from "@app/components/Select/GenericSelect";
import GenericTable from "@app/components/Table/GenericTable";
import Modal from "react-responsive-modal";
import SearchModal from "@app/plugins/components/search/AdvancedSearch/SearchModal";
import TruncateMarkup from "react-truncate-markup";
import axiosSession from "@app/config/axiosSession";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

const DirectoriesSearch = ({
  hasCreatePermission,
  hasMassOperationPermission,
  hasSipPermission
}) => {
  const { handleSubmit, setValue, control, errors } = useForm();
  const { notifySuccess, notifyError } = useGenericToastify();
  const [libraries, setLibraries] = useState([]);
  const [library, setLibrary] = useState({});
  const [modalAction, setModalAction] = useState({});
  const [messages, setMessages] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [itemData, setItemData] = useState({});
  const [shouldTableRefresh, setShouldTableRefresh] = useState(false);
  const [menuVisible, setMenuVisible] = useState(null);
  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false);
  const [expandedRowData, setExpandedRowData] = useState([]);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState([]);
  const [byFilter, setByFilter] = useState(false);
  const [searchType, setSearchType] = useState("all");
  const [objectsToTransfer, setObjectsToTransfer] = useState({});
  const [isModalObjectTransferOpen, setIsModalObjectTransferOpen] = useState(
    false
  );
  const [isDigitalItemExportOpen, setIsDigitalItemExportOpen] = useState(false);
  const [changeStatusModalIsOpen, setChangeStatusModalIsOpen] = useState(false);
  const [groupChangeStatusAction, setGroupChangeStatusAction] = useState("");
  const [groupData, setGroupData] = useState({});
  const [itemsForGroupChangeStatus, setItemsForGroupChangeStatus] = useState(
    {}
  );
  const [operationType, setOperationType] = useState("");
  const [isSubmitVisible, setIsSubmitVisible] = useState(true);
  const [groupOperationBtnsActive, setGroupOperationBtnsActive] = useState(
    true
  );
  const [searchModalIsOpen, setSearchModalIsOpen] = useState(false);
  const [queryId, setQueryId] = useState("");

  const urls = {
    get: queryId
      ? "/api/search/backoffice/advanced-search/results/"
      : "/api/search/backoffice/",
    add: "/directories/",
    edit: "/directories/",
    delete: "/api/directories/directory/",
    deleteItem: "/api/digital_items/",
    downloadSip: "/api/archives/request_sip/"
  };

  const collectionsUrls = {
    get: `/api/library/${library.id}/collections/?modify_items=true`
  };

  const changeStatusUrl = "/api/mass-operations/change-status/";

  const directoryCreate = "directory-create";
  const groupObjectCreate = "group-object-create";
  const digitalItemCreate = "digital-item-create";

  const groupActions = {
    mainOption: {
      title: "Przypisz do kolekcji",
      action: groupAssign
    },
    options: [
      {
        title: "Przypisz wszystkie do kolekcji",
        action: groupAssignAll
      },
      {
        title: "Zmiana schematu opisowego",
        action: groupSchemaChange,
        massOperation: true
      },
      {
        title: "Zmiana schematu opisowego dla wszystkich",
        action: groupSchemaChangeAll,
        massOperation: true
      },
      {
        title: "Zmiana typu obiektu",
        action: groupTypeChange,
        massOperation: true
      },
      {
        title: "Zmiana typu obiektu dla wszystkich",
        action: groupTypeChangeAll,
        massOperation: true
      },
      {
        title: "Zmiana statusu",
        action: groupChangeStatus,
        massOperation: true
      },
      {
        title: "Zmiana statusu dla wszystkich",
        action: groupChangeStatusAll,
        massOperation: true
      },
      {
        title: "Przenoszenie zasobów",
        action: objectTransfer,
        massOperation: true
      },
      {
        title: "Przenoszenie zasobów dla wszystkich",
        action: objectTransferAll,
        massOperation: true
      },
      {
        title: "Zmiana wartości atrybutu",
        action: groupAttributesChange,
        massOperation: true
      },
      {
        title: "Zmiana wartości atrybutu dla wszystkich",
        action: groupAttributesChangeAll,
        massOperation: true
      },
      {
        title: "Wysyłka SIP",
        action: digitalItemExport,
        sipOperation: true
      },
      {
        title: "Wysyłka SIP dla wszystkich",
        action: digitalItemExportAll,
        sipOperation: true
      }
    ]
  };

  const deleteMessages = {
    headerTitle: "Potwierdzenie usunięcia elementu",
    bodyContent: "Czy na pewno chcesz usunąć element?",
    deleteSuccess: "Element został poprawnie usunięty",
    deleteError: "Wystąpił problem podczas usuwania elementu",
    btnDeleteNo: "Anuluj",
    btnDeleteYes: "Usuń"
  };

  const archiveMessages = {
    headerTitle: "Potwierdzenie zmiany statusu",
    bodyContent:
      "Uwaga, spowoduje to zmianę statusu na archiwalny, czy na pewno chcesz kontynuować?",
    deleteSuccess: "Element został poprawnie zarchiwizowany",
    deleteError: "Wystąpił problem podczas archiwizowania elementu",
    btnDeleteNo: "Nie, nie archiwizuj",
    btnDeleteYes: "Tak, archiwizuj"
  };

  const publishMessages = {
    headerTitle: "Potwierdzenie zmiany statusu",
    bodyContent:
      "Uwaga, spowoduje to zmianę statusu na opublikowany, czy na pewno chcesz kontynuować?",
    deleteSuccess: "Element został poprawnie opublikowany",
    deleteError: "Wystąpił problem podczas publikowania elementu",
    btnDeleteNo: "Nie, nie publikuj",
    btnDeleteYes: "Tak, opublikuj"
  };

  const groupStatusChangeMessages = {
    headerTitle: "Zmiana statusu",
    bodyContent:
      "Dokonując zmiany, zmieniasz status dla wszystkich zaznaczonych zasobów",
    deleteSuccess: "Status elementów został poprawnie zmieniony",
    deleteError: "Wystąpił problem podczas zmiany statusu",
    btnDeleteNo: "Anuluj",
    btnDeleteYes: "Zapisz"
  };

  const permissonMessages = {
    headerTitle: "Przypisanie zasobów do kolekcji",
    bodyContent: " Czy mimo to chcesz kontynuować dla pozostałych zasobów?",
    btnDeleteNo: "Nie, przerwij akcję",
    btnDeleteYes: "Tak, kontynuuj"
  };

  const noPermissionMessagesStatus = {
    headerTitle: "Zmiana statusu",
    bodyContent:
      "Nie można zmienić statusu dla wybranych zasobów, ponieważ mają niewłaściwy status lub nie posiadasz wystarczających uprawnień.",
    btnDeleteNo: "Rozumiem"
  };

  const noPermissionMessagesTransfer = {
    headerTitle: "Przenoszenie zasobów",
    bodyContent:
      "Nie można przenieść wybranych zasobów, ponieważ są zarchiwizowane lub nie posiadasz wystarczających uprawnień.",
    btnDeleteNo: "Rozumiem"
  };

  const noPermissionMessagesSchema = {
    headerTitle: "Zmiana schematu opisowego",
    bodyContent:
      "Nie można zmienić schematu opisowego dla obiektów pojedynczych.",
    btnDeleteNo: "Anuluj"
  };

  const noPermissionMessagesType = {
    headerTitle: "Zmiana typu obiektu",
    bodyContent:
      "Zmiana typu obiektu jest możliwa tylko dla obiektów pojedynczych.",
    btnDeleteNo: "Anuluj"
  };

  const icons = {
    directory: faFolder,
    digital_item: faFile,
    group_object: faCopy,
    archive: faArchive
  };

  const statuses = {
    published: "published",
    draft: "draft",
    archived: "archived"
  };

  const groupStatusChangeSelect = [
    { id: 1, name: "Publikuj", action: "published" },
    { id: 3, name: "Zarchiwizuj", action: "archived" }
  ];

  const tableStructure = [
    {
      header: "Nazwa",
      accessor: "name",
      className: "w-4",
      sort: true
    },
    {
      header: "Ścieżka",
      accessor: "path",
      className: "w-3",
      sort: true
    },
    {
      header: "Status",
      accessor: "status",
      className: "w-2"
    },
    {
      header: "Akcje",
      className: "w-2"
    }
  ];

  const collectionsTableStructure = [
    {
      header: "Nazwa",
      accessor: "internal_name",
      className: "w-6",
      sort: true
    },
    {
      header: "Status",
      accessor: "status",
      className: "w-5",
      sort: true
    }
  ];

  useEffect(() => {
    axiosSession
      .get("/api/library/select-data/")
      .then(({ data }) => {
        setLibraries(data);
        if (data.length > 0) {
          setLibrary(data[0]);
          setValue("library", data[0]);
        }
      })
      .catch(err => console.error(err));
  }, [setValue]);

  useEffect(() => {
    if (shouldTableRefresh) {
      setShouldTableRefresh(false);
    }
  }, [shouldTableRefresh]);

  useEffect(() => {
    if (modalIsOpen === false) setIsSubmitVisible(true);
  }, [modalIsOpen]);

  const expandRow = (row, index) => {
    if (row.expanded) {
      setExpandedRowData({
        index: index,
        expanded: false,
        rows: []
      });
    } else {
      let level = row.level ? row.level : 0;
      row.level = level;
      let ancestors = row.ancestors ? row.ancestors : [];
      row.ancestors = ancestors;

      axiosSession
        .get(collectionsUrls.get, { params: { parent: row.id } })
        .then(res => {
          let ancestor = [res.data.results[0].parent];
          let data = res.data.results.map(di => ({
            ...di,
            level: level + 1,
            ancestors: ancestors + ancestor
          }));
          setExpandedRowData({
            index: index,
            expanded: true,
            rows: data
          });
        });
    }
  };

  const showMenu = item => {
    if (item.id != menuVisible) {
      setMenuVisible(item.id);
    } else {
      setMenuVisible(null);
    }
  };

  const handleChange = data => {
    setLibrary(data[0]);
  };

  const changeStatusQuery = (url, objects) => {
    setGroupOperationBtnsActive(false);
    axiosSession
      .post(url, objects)
      .then(res => {
        if (
          res.data.digital_items_ids.length === 0 &&
          res.data.group_objects_ids.length === 0
        ) {
          setChangeStatusModalIsOpen(false);
          setMessages(noPermissionMessagesStatus);
          setIsSubmitVisible(false);
          setModalIsOpen(true);
          return;
        }

        setItemsForGroupChangeStatus({
          digital_items_ids: res.data.digital_items_ids,
          group_objects_ids: res.data.group_objects_ids
        });

        if (
          res.data.message === "" ||
          res.data.message === undefined ||
          res.data.message === null
        ) {
          return setChangeStatusModalIsOpen(true);
        }

        setMessages({
          headerTitle: "Zmiana statusu",
          bodyContent: res.data.message,
          btnDeleteNo: "Nie, przerwij akcję",
          btnDeleteYes: "Tak, kontynuuj"
        });
        setModalAction({
          type: "groupChangeStatusPermission"
        });
        setModalIsOpen(true);
      })
      .catch(err => {
        console.error(err);
        setChangeStatusModalIsOpen(false);
        setMessages(noPermissionMessagesStatus);
        setIsSubmitVisible(false);
        setModalIsOpen(true);
      })
      .finally(() => setGroupOperationBtnsActive(true));
  };

  function groupChangeStatus() {
    const url = `${changeStatusUrl}check/`;

    if (selectedObjects.find(item => item.object_class == "directory")) {
      notifyError("Nie można zmienić statusu dla katalogu");
      return;
    }
    if (isEmpty(selectedObjects)) {
      notifyError("Zaznacz obiekty aby zmienić status");
      return;
    }

    const digital_items_ids = [];
    const group_objects_ids = [];

    selectedObjects.map(item =>
      item.object_class === "group_object"
        ? group_objects_ids.push(item.id)
        : item.object_class === "digital_item" &&
          digital_items_ids.push(item.id)
    );

    const objects = {
      digital_items_ids,
      group_objects_ids
    };

    changeStatusQuery(url, objects);
  }

  function groupChangeStatusAll(_, filter, selectedFilters) {
    if (filter.length === 0)
      return notifyError("Wyszukaj obiekty aby wykonać operację");

    const url = `${changeStatusUrl}check-by-filter/`;

    const object_class = Array.from(selectedFilters);
    object_class.splice(object_class.indexOf("directory"), 1);

    const objects = {
      query: filter,
      object_class,
      filters: [],
      library_context: library.id,
      type: searchType
    };

    changeStatusQuery(url, objects);
  }

  const handleChangeGroupStatus = data => {
    setGroupChangeStatusAction(data[0]);
  };

  const onConfirmGroupStatusChange = () => {
    if (groupChangeStatusAction === "")
      return notifyError("Wybierz akcję aby kontynuować");
    const url = `${changeStatusUrl}operation/`;
    axiosSession
      .post(url, {
        digital_items_ids: itemsForGroupChangeStatus.digital_items_ids,
        group_objects_ids: itemsForGroupChangeStatus.group_objects_ids,
        status: groupChangeStatusAction.action
      })
      .then(res => {
        if (
          res.data.digital_items_ids.length === 0 &&
          res.data.group_objects_ids.length === 0
        ) {
          setChangeStatusModalIsOpen(false);
          setMessages(noPermissionMessagesStatus);
          setIsSubmitVisible(false);
          setModalIsOpen(true);
          return;
        }
        if (
          res.data.message === "" ||
          res.data.message === undefined ||
          res.data.message === null
        ) {
          notifySuccess("Zmieniono status wybranych obiektów");
          setChangeStatusModalIsOpen(false);
          setGroupChangeStatusAction("");
          setSelectedObjects([]);
          //TODO: usunąć setTimeout po rozwiązaniu MBC-1633 - indekser dla operacji masowych
          setTimeout(() => {
            setShouldTableRefresh(true);
          }, 2500);
        } else {
          setItemsForGroupChangeStatus({
            digital_items_ids: res.data.digital_items_ids,
            group_objects_ids: res.data.group_objects_ids
          });
          setMessages({
            headerTitle: "Zmiana statusu",
            bodyContent: res.data.message,
            btnDeleteNo: "Nie",
            btnDeleteYes: "Tak"
          });
          setModalAction({
            type: "groupChangeStatusAvailability"
          });
          setChangeStatusModalIsOpen(false);
          setModalIsOpen(true);
        }
      })
      .catch(err => {
        console.error(err);
        setChangeStatusModalIsOpen(false);
        setMessages(noPermissionMessagesStatus);
        setIsSubmitVisible(false);
        setModalIsOpen(true);
      })
      .finally(() => {
        setShouldTableRefresh(false);
      });
  };

  function groupAttributesChange() {
    if (isEmpty(selectedObjects)) {
      notifyError("Zaznacz obiekty aby zmienić wartości atrybutów ");
      return;
    }
    setOperationType("attributes");
  }

  function groupAttributesChangeAll(_, filter, selectedFilters) {
    if (filter.length === 0)
      return notifyError("Wyszukaj obiekty aby wykonać operację");
    setGroupData({
      query: filter,
      object_class: selectedFilters,
      filters: [],
      library_context: library.id,
      type: searchType
    });
    setOperationType("attributesAll");
  }

  function digitalItemExport() {
    if (isEmpty(selectedObjects)) {
      notifyError("Nie zaznaczono danych przeniesienia do archiwum");
      return;
    }
    setIsDigitalItemExportOpen(true);
  }

  function digitalItemExportAll(_, filter, selectedFilters) {
    if (filter.length === 0) {
      notifyError("Wyszukaj obiekty aby przeniesieść do archiwum");
      return;
    }
    setGroupData({
      query: filter,
      object_class: selectedFilters,
      filters: [],
      library_context: library.id,
      type: searchType
    });
    setIsDigitalItemExportOpen(true);
  }

  const onConfirmAssign = (digitalItems, groupObjects) => {
    axiosSession
      .post("/api/collections/objects/", {
        collections: selectedCollections.map(elem => elem.id),
        group_objects: groupObjects,
        digital_items: digitalItems
      })
      .then(() => {
        notifySuccess("Pomyślnie przypisano obiekty do kolekcji");
        setSelectedCollections([]);
        setShouldTableRefresh(true);
        setModalIsOpen(false);
      })
      .catch(err => {
        console.error(err);
        notifyError("Nie udało się przypisać obiektów do kolekcji");
      })
      .finally(() => {
        setShouldTableRefresh(false);
      });
  };

  const onAssign = () => {
    if (byFilter) {
      axiosSession
        .post("/api/collections/objects-by-filter/", {
          query: query,
          object_class: filters,
          type: searchType,
          library_context: library.id,
          filters: [],
          collections: selectedCollections.map(elem => elem.id)
        })
        .then(({ data }) => {
          if (isEmpty(data.digital_items) && isEmpty(data.group_objects)) {
            notifyError("Nie masz uprawnień, by wykonać tę czynność.");
            setSelectedCollections([]);
            return;
          }

          setCollectionsModalIsOpen(false);
          setExpandedRowData([]);

          if (data.message == "") {
            onConfirmAssign(data.digital_items, data.group_objects);
          } else {
            setMessages({
              ...permissonMessages,
              bodyContent: data.message
            });
            setModalAction({
              type: "assign",
              digital_items: data.digital_items,
              group_objects: data.group_objects
            });
            setItemData({});
            setModalIsOpen(true);
          }
        })
        .catch(err => {
          if (err.response.data) {
            notifyError(err.response.data.detail);
          } else {
            notifyError("Przypisanie obiektów nie powiodło się");
          }
          console.error(err);
        })
        .finally(() => setShouldTableRefresh(false));
    } else {
      const digital_items = [];
      const group_objects = [];

      selectedObjects.forEach(elem => {
        if (elem.object_class == "digital_item") {
          digital_items.push(elem.id);
        } else if (elem.object_class == "group_object") {
          group_objects.push(elem.id);
        }
      });

      axiosSession
        .post("/api/collections/objects/", {
          collections: selectedCollections.map(elem => elem.id),
          digital_items: digital_items,
          group_objects: group_objects
        })
        .then(() => {
          notifySuccess("Pomyślnie przypisano obiekty do kolekcji");
          setCollectionsModalIsOpen(false);
          setSelectedCollections([]);
          setSelectedObjects([]);
          setShouldTableRefresh(true);
          setExpandedRowData([]);
        })
        .catch(err => {
          if (err.response.data) {
            notifyError(err.response.data.detail);
          } else {
            notifyError("Przypisanie obiektu nie powiodło się");
          }
          console.error(err);
        })
        .finally(() => setShouldTableRefresh(false));
    }
  };

  function groupAssign() {
    if (selectedObjects.find(elem => elem.object_class == "directory")) {
      notifyError("Nie można przypisać katalogu do kolekcji");
      return;
    }
    if (isEmpty(selectedObjects)) {
      notifyError("Zaznacz obiekty do przypisania");
      return;
    }
    setSelectedCollections([]);
    setCollectionsModalIsOpen(true);
    setByFilter(false);
  }

  function groupAssignAll(_, filter, selectedFilters) {
    if (filter.length === 0)
      return notifyError("Wyszukaj obiekty aby wykonać operację");
    setQuery(filter);
    setFilters(selectedFilters);
    setSelectedCollections([]);
    setCollectionsModalIsOpen(true);
    setByFilter(true);
  }

  const onAssignSingleObject = item => {
    setSelectedObjects([item]);
    setSelectedCollections([]);
    setCollectionsModalIsOpen(true);
  };

  function groupSchemaChange() {
    if (selectedObjects.find(elem => elem.object_class === "digital_item")) {
      setMessages(noPermissionMessagesSchema);
      setIsSubmitVisible(false);
      setModalIsOpen(true);
      return;
    }
    if (isEmpty(selectedObjects)) {
      notifyError(
        "Zaznacz katalogi lub obiekty grupowe, aby zmienić schemat opisowy"
      );
      return;
    }
    setOperationType("schema");
  }

  function groupSchemaChangeAll(_, filter, selectedFilters) {
    if (filter.length === 0)
      return notifyError("Wyszukaj obiekty aby wykonać operację");
    setGroupData({
      query: filter,
      object_class: selectedFilters.filter(elem => elem != "digital_item"),
      filters: [],
      library_context: library.id,
      type: searchType
    });
    setOperationType("schemaAll");
  }

  function groupTypeChange() {
    if (selectedObjects.find(elem => elem.object_class != "digital_item")) {
      setMessages(noPermissionMessagesType);
      setIsSubmitVisible(false);
      setModalIsOpen(true);
      return;
    }
    if (isEmpty(selectedObjects)) {
      notifyError("Zaznacz obiekty pojedyncze, aby zmienić ich typ");
      return;
    }
    setOperationType("type");
  }

  function groupTypeChangeAll(_, filter, selectedFilters) {
    if (filter.length === 0)
      return notifyError("Wyszukaj obiekty aby wykonać operację");
    setGroupData({
      query: filter,
      object_class: selectedFilters.filter(elem => elem == "digital_item"),
      filters: [],
      library_context: library.id,
      type: searchType
    });
    setOperationType("typeAll");
  }

  const onDelete = item => {
    setItemData({ id: item.id, name: item.name });
    setMessages({
      ...deleteMessages,
      bodyContent: item.has_children
        ? "Uwaga, ten katalog ma przypisane obiekty, czy na pewno chcesz go usunąć?"
        : "Czy na pewno chcesz usunąć element?"
    });
    setModalAction({
      type: "delete",
      id: item.id,
      object_class: item.object_class
    });
    setModalIsOpen(true);
  };

  const onDownloadSip = item => {
    axiosSession
      .post(urls.downloadSip, { digital_items: [item.id] })
      .then(() =>
        notifySuccess("Paczka SIP z obiektem zostanie wysłana na e-mail")
      )
      .catch(err => console.error(err));
  };

  const onConfirmDelete = (id, object_class) => {
    const deleteUrl = `${
      object_class === "digital_item" ? urls.deleteItem : urls.delete
    }${id}/`;

    axiosSession
      .delete(deleteUrl)
      .then(() => {
        notifySuccess(messages.deleteSuccess);
        setShouldTableRefresh(true);
      })
      .catch(error => {
        if (error.response.data.detail) {
          notifyError(error.response.data.detail);
        } else {
          notifyError(messages.deleteError);
        }
        console.error(error);
      })
      .finally(() => {
        setModalIsOpen(false);
        setShouldTableRefresh(false);
      });
  };

  const onPublish = item => {
    setItemData({ id: item.id, name: item.name });
    setMessages(publishMessages);
    setModalAction({
      type: "publish",
      id: item.id,
      object_class: item.object_class
    });
    setModalIsOpen(true);
  };

  const onConfirmPublish = (id, object_class) =>
    onSetStatus(statuses.published, id, object_class);

  const onArchive = item => {
    setItemData({ id: item.id, name: item.name });
    setMessages(archiveMessages);
    setModalAction({
      type: "archive",
      id: item.id,
      object_class: item.object_class
    });
    setModalIsOpen(true);
  };

  const onConfirmGroupChangeStatusPermission = () => {
    setModalIsOpen(false);
    setChangeStatusModalIsOpen(true);
  };

  const onConfirmGroupChangeStatusAvailability = () => {
    const url = `${changeStatusUrl}operation/`;
    axiosSession
      .post(url, {
        digital_items_ids: itemsForGroupChangeStatus.digital_items_ids,
        group_objects_ids: itemsForGroupChangeStatus.group_objects_ids,
        status: groupChangeStatusAction.action
      })
      .then(() => {
        setModalIsOpen(false);
        setSelectedObjects([]);
        //TODO: usunąć setTimeout po rozwiązaniu MBC-1633 - indekser dla operacji masowych
        setTimeout(() => {
          setShouldTableRefresh(true);
        }, 2500);
        return notifySuccess("Zmieniono status dla wybranych obiektów");
      })
      .catch(err => {
        console.error(err);
        return notifyError("Zmieniona statusu nie powiodła się.");
      })
      .finally(() => {
        setShouldTableRefresh(false);
      });
  };

  const onConfirmArchive = (id, object_class) =>
    onSetStatus(statuses.archived, id, object_class);

  const onSetStatus = (status, id, object_class) => {
    const patchUrl =
      object_class != "digital_item"
        ? `/api/directories/${id}/status/`
        : `/api/digital_items/${id}/status/`;

    axiosSession
      .put(patchUrl, { status: status })
      .then(() => {
        notifySuccess(messages.deleteSuccess);
        setShouldTableRefresh(true);
      })
      .catch(error => {
        if (error.response.data.detail) {
          notifyError(error.response.data.detail);
        } else {
          notifyError(messages.deleteError);
        }
        console.error(error);
      })
      .finally(() => {
        setModalIsOpen(false);
        setShouldTableRefresh(false);
      });
  };

  const onModalAction = () => {
    switch (modalAction.type) {
      case "delete":
        onConfirmDelete(modalAction.id, modalAction.object_class);
        break;
      case "archive":
        onConfirmArchive(modalAction.id, modalAction.object_class);
        break;
      case "publish":
        onConfirmPublish(modalAction.id, modalAction.object_class);
        break;
      case "assign":
        onConfirmAssign(modalAction.digital_items, modalAction.group_objects);
        break;
      case "transfer":
        onConfirmTransfer();
        break;
      case "groupChangeStatusPermission":
        onConfirmGroupChangeStatusPermission();
        break;
      case "groupChangeStatusAvailability":
        onConfirmGroupChangeStatusAvailability();
        break;
    }
  };

  const onConfirmTransfer = () => {
    setModalIsOpen(false);
    setIsModalObjectTransferOpen(true);
  };

  const customOnCheckboxChangeCollections = (data, setData, item) => {
    const position = item.target.name;

    if (item.target.checked) {
      setSelectedCollections(selectedCollections.concat(data[position]));
    } else {
      setSelectedCollections(
        selectedCollections.filter(elem => elem.id != data[position].id)
      );
    }

    setData(
      data.map(elem => {
        if (elem.id == data[position].id) {
          return { ...elem, isChecked: !elem.isChecked };
        }
        return elem;
      })
    );
  };

  const customOnSelectAllCollections = (
    data,
    setData,
    selectAll,
    setSelectAll
  ) => {
    const mappedData = data.map(elem => ({ ...elem, isChecked: !selectAll }));

    const toAdd = [];
    let selected = selectedCollections;

    mappedData.forEach(elem => {
      if (elem.isChecked && !selectedCollections.find(e => e.id == elem.id)) {
        toAdd.push(elem);
      }
      if (!elem.isChecked) {
        selected = selected.filter(e => e.id != elem.id);
      }
    });

    setSelectedCollections(selected.concat(toAdd));
    setData(mappedData);
    setSelectAll(!selectAll);
  };

  const checkboxResetCollections = () => {
    setSelectedCollections([]);
  };

  const onRetrieveCollections = (data, setData, setSelectAll) => {
    data.forEach(e => {
      e.ancestors = [];

      e.isChecked = selectedCollections.find(elem => elem.id == e.id)
        ? true
        : false;
    });

    setData(data);

    if (
      data.filter(elem => elem.isChecked === true).length === data.length &&
      data.length > 0
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const collectionsCustomRow = (item, index, checkboxHandler) => {
    return (
      <React.Fragment key={index}>
        <tr id={`row-${item.id}`}>
          <td>
            <Checkbox
              disabled={!item.actions.can_assign_objects}
              name={`${index}`}
              onChange={checkboxHandler}
              checked={item.isChecked}
              darkTheme={false}
            />
          </td>
          <td className={item.has_children ? "has-children" : ""}>
            {item.has_children && (
              <span
                className={`generic-table__table__link ${item.level &&
                  `indent-with-extend-${item.level}`}`}
                onClick={() => expandRow(item, index)}
                onKeyPress={() => expandRow(item, index)}
                title="Rozwiń wiersz"
                role="button"
                tabIndex="0"
              >
                <FontAwesomeIcon
                  icon={item.expanded ? faMinusSquare : faPlusSquare}
                />
              </span>
            )}
            &nbsp;
            <TruncateMarkup>
              <span
                title={item.internal_name}
                className={
                  (item.has_children
                    ? ""
                    : item.level
                    ? `indent-${item.level}`
                    : "") + " collection"
                }
              >
                {item.internal_name}
              </span>
            </TruncateMarkup>
          </td>
          <td>{item.status}</td>
        </tr>
      </React.Fragment>
    );
  };

  const customOnCheckboxChange = (data, setData, item) => {
    const position = item.target.name;

    if (item.target.checked) {
      setSelectedObjects(selectedObjects.concat(data[position]));
    } else {
      setSelectedObjects(
        selectedObjects.filter(
          elem =>
            elem.id != data[position].id ||
            elem.object_class != data[position].object_class
        )
      );
    }

    setData(
      data.map(elem => {
        if (
          elem.id == data[position].id &&
          elem.object_class == data[position].object_class
        ) {
          return { ...elem, isChecked: !elem.isChecked };
        }
        return elem;
      })
    );
  };

  const customOnSelectAll = (data, setData, selectAll, setSelectAll) => {
    const mappedData = data.map(elem => ({ ...elem, isChecked: !selectAll }));

    const toAdd = [];
    let selected = selectedObjects;

    mappedData.forEach(elem => {
      if (
        elem.isChecked &&
        !selectedObjects.find(
          e => e.id == elem.id && e.object_class == elem.object_class
        )
      ) {
        toAdd.push(elem);
      }
      if (!elem.isChecked) {
        selected = selected.filter(
          e => e.id != elem.id || e.object_class != elem.object_class
        );
      }
    });

    setSelectedObjects(selected.concat(toAdd));
    setData(mappedData);
    setSelectAll(!selectAll);
  };

  const checkboxReset = () => {
    setSelectedObjects([]);
  };

  const onRetrieve = (data, setData, setSelectAll) => {
    data.forEach(e => {
      e.ancestors = [];

      e.isChecked = selectedObjects.find(
        elem => elem.id == e.id && elem.object_class == e.object_class
      )
        ? true
        : false;
    });

    setData(data);

    if (
      data.filter(elem => elem.isChecked === true).length === data.length &&
      data.length > 0
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const actions = item => {
    return (
      <td className="has-text-right">
        {item.object_class != "directory" &&
          (item.permissions[0].permissions.includes(
            "manage_digitalitem_collection"
          ) ||
            item.permissions[0].permissions.includes(
              "manage_directory_collection"
            )) && (
            <button
              onClick={() => onAssignSingleObject(item)}
              className="generic-table__table__link"
              title="Przypisz do kolekcji"
            >
              <FontAwesomeIcon icon={faLayerGroup} />
            </button>
          )}
        {item.actions.to_publish && (
          <button
            onClick={() => onPublish(item)}
            className="generic-table__table__link"
            title="Opublikuj"
          >
            <FontAwesomeIcon icon={faShareSquare} />
          </button>
        )}
        {item.actions.to_archive && (
          <button
            onClick={() => onArchive(item)}
            className="generic-table__table__link"
            title="Archiwizuj"
          >
            <FontAwesomeIcon icon={faLock} />
          </button>
        )}
        {item.actions.viewable && (
          <a
            href={
              item.object_class === "digital_item"
                ? `/digital_items/${item.id}/digital-item-preview/`
                : `/directories/${item.id}/${item.object_class}-preview/`
            }
            className="generic-table__table__link"
            title="Podgląd"
          >
            <FontAwesomeIcon icon={faEye} />
          </a>
        )}
        {item.actions.editable && (
          <a
            href={`${urls.edit}${item.id}/${item.object_class.replace(
              "_",
              "-"
            )}-edit/`}
            className="generic-table__table__link"
            title="Edycja"
          >
            <FontAwesomeIcon icon={faPen} />
          </a>
        )}
        {item.actions.removable && (
          <button
            onClick={() => onDelete(item)}
            className="generic-table__table__link"
            title="Usuń"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        )}
        {item.object_class === "digital_item" &&
          hasSipPermission &&
          item.actions.to_sip && (
            <button
              onClick={() => onDownloadSip(item)}
              className="generic-table__table__link"
              title="Pobierz paczkę SIP"
            >
              <FontAwesomeIcon icon={faDownload} />
            </button>
          )}
        {hasCreatePermission &&
          (item.object_class == "directory" ||
            item.object_class == "group_object") && (
            <React.Fragment>
              <button
                onMouseUp={() => showMenu(item)}
                onKeyPress={() => showMenu(item)}
                className="generic-table__table__link"
                title="Dodaj"
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
              {item.id == menuVisible && (
                <div onMouseLeave={() => setMenuVisible(null)}>
                  {item.object_class != "group_object" && (
                    <div>
                      <a
                        href={`${urls.edit}${directoryCreate}/?directory_id=${item.id}`}
                        className="generic-table__table__link"
                        title="Dodaj katalog"
                      >
                        <FontAwesomeIcon icon={faFolder} />
                      </a>
                      <br />
                    </div>
                  )}

                  <div>
                    <a
                      href={`${urls.edit}${groupObjectCreate}/?directory_id=${item.id}`}
                      className="generic-table__table__link"
                      title="Dodaj obiekt grupowy"
                    >
                      <FontAwesomeIcon icon={faCopy} />
                    </a>
                    <br />
                  </div>

                  <div>
                    <a
                      href={`${urls.edit}${digitalItemCreate}/?directory_id=${item.id}`}
                      className="generic-table__table__link"
                      title="Dodaj obiekt"
                    >
                      <FontAwesomeIcon icon={faFile} />
                    </a>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
      </td>
    );
  };

  const customRow = (item, index, checkboxHandler) => {
    return (
      <React.Fragment key={index}>
        <tr id={`row-${item.id}-${item.object_class}`}>
          <td>
            <div
              className={item.in_archive ? "d-flex d-flex--align-items" : ""}
            >
              <Checkbox
                name={`${index}`}
                onChange={checkboxHandler}
                checked={item.isChecked}
                darkTheme={false}
              />
              {item.in_archive && <FontAwesomeIcon icon={icons.archive} />}
            </div>
          </td>
          <td>
            <span
              className="directories-search__truncate-name"
              title={item.name}
            >
              <FontAwesomeIcon icon={icons[item.object_class]} />
              &nbsp;
              {item.name}
            </span>
          </td>
          <td>
            <TruncateMarkup>
              <span className="directories-search__truncate" title={item.path}>
                {item.path}
              </span>
            </TruncateMarkup>
          </td>
          <td>{item.status}</td>
          {actions(item, index)}
        </tr>
      </React.Fragment>
    );
  };

  const transferURL = "/api/directories/group-assigment-";

  const handleTransfer = (url, objects) => {
    axiosSession
      .post(url, objects)
      .then(({ data }) => {
        if (isEmpty(data.digital_items) && isEmpty(data.directories)) {
          setMessages(noPermissionMessagesTransfer);
          setIsSubmitVisible(false);
          setModalIsOpen(true);
        } else {
          setObjectsToTransfer({
            digital_items: data.digital_items,
            directories: data.directories
          });
          if (data.message === "") {
            return setIsModalObjectTransferOpen(true);
          }
          setMessages({
            ...permissonMessages,
            headerTitle: "Przenoszenie zasobów",
            bodyContent: data.message + permissonMessages.bodyContent
          });
          setModalAction({
            type: "transfer"
          });
          setModalIsOpen(true);
        }
      })
      .catch(error => {
        console.error(error);
        return notifyError("Wystąpił problem podczas wykonywania operacji");
      });
  };

  function objectTransfer() {
    if (isEmpty(selectedObjects)) {
      notifyError("Nie zaznaczono żadnego elementu.");
      return;
    }
    const url = `${transferURL}check/`;

    const digital_items = [];
    const directories = [];

    selectedObjects.map(item =>
      item.object_class === "digital_item"
        ? digital_items.push(item.id)
        : directories.push(item.id)
    );

    const objects = {
      digital_items,
      directories
    };

    handleTransfer(url, objects);
  }

  function objectTransferAll(_, filter, selectedFilters) {
    if (filter.length === 0)
      return notifyError("Wyszukaj obiekty aby wykonać operację");

    const url = `${transferURL}filter-check/`;

    const objects = {
      library_context: library.id,
      query: filter,
      object_class: selectedFilters,
      filters: [],
      type: searchType
    };

    handleTransfer(url, objects);
  }

  return (
    <div className="mbc-backoffice">
      <div className="container">
        {isModalObjectTransferOpen && (
          <DirectoriesObjectTransfer
            libraryId={library.id}
            objectsToTransfer={objectsToTransfer}
            setObjectsToTransfer={setObjectsToTransfer}
            isModalObjectTransferOpen={isModalObjectTransferOpen}
            setIsModalObjectTransferOpen={setIsModalObjectTransferOpen}
          />
        )}

        {isDigitalItemExportOpen && (
          <DirectoriesDigitalItemExport
            selectedObjects={selectedObjects}
            setSelectedObjects={setSelectedObjects}
            isDigitalItemExportOpen={isDigitalItemExportOpen}
            setIsDigitalItemExportOpen={setIsDigitalItemExportOpen}
            groupData={groupData}
            setGroupData={setGroupData}
          />
        )}

        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Wyszukiwanie zasobów</strong>
        </div>

        <GenericModal
          itemData={itemData}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          labels={messages}
          actionSubmit={onModalAction}
          buttonSection={isSubmitVisible}
        />

        <SearchModal
          modalIsOpen={searchModalIsOpen}
          setModalIsOpen={setSearchModalIsOpen}
          frontoffice={false}
          setQueryId={setQueryId}
        />

        <Modal
          open={collectionsModalIsOpen}
          onClose={() => {
            setCollectionsModalIsOpen(false);
            setExpandedRowData([]);
          }}
          classNames={{
            modal: "GenericModal directories-search__collections-modal"
          }}
        >
          <div className="GenericModal__Header--with-border">
            <p className="GenericModal__Header__Title">Przypisz do kolekcji</p>
          </div>
          <div className="GenericModal__Body">
            <GenericTable
              structure={collectionsTableStructure}
              customRowWithActions={collectionsCustomRow}
              hasSearch={false}
              urls={collectionsUrls}
              hasHeaderActions={false}
              isSelectable={true}
              customActions={() => {}}
              expandedRowData={expandedRowData}
              customCheckboxHandler={{
                handler: customOnCheckboxChangeCollections,
                onSelectAll: customOnSelectAllCollections,
                reset: checkboxResetCollections,
                onRetrieve: onRetrieveCollections
              }}
            />
          </div>
          <div className="GenericModal__Footer">
            <p className="GenericModal__Footer__Buttons buttons is-right is-fullwidth">
              <button
                className="button"
                onClick={() => {
                  setCollectionsModalIsOpen(false);
                  setExpandedRowData([]);
                }}
              >
                Anuluj
              </button>
              <button className="button button--is-orange" onClick={onAssign}>
                Przypisz do kolekcji
              </button>
            </p>
          </div>
        </Modal>

        <Modal
          open={changeStatusModalIsOpen}
          onClose={() => setChangeStatusModalIsOpen(false)}
          center
          showCloseIcon={false}
          classNames={{ modal: "GenericModal" }}
        >
          <div className="GenericModal__Header--with-border">
            <p className="GenericModal__Header__Title">
              {groupStatusChangeMessages.headerTitle}
            </p>
          </div>
          <div className="GenericModal__Body">
            {groupStatusChangeMessages.bodyContent}
          </div>
          <div className="GenericModal__Select-in-modal">
            <GenericSelect
              options={groupStatusChangeSelect}
              label="Status"
              name="groupStatusChangeSelect"
              control={control}
              errors={errors}
              handleSelectChange={value => handleChangeGroupStatus(value)}
            />
          </div>
          <div className="GenericModal__Footer">
            <p className="GenericModal__Footer__Buttons buttons is-right is-fullwidth">
              <button
                className="button GenericModal__Footer__Button"
                onClick={() => {
                  setChangeStatusModalIsOpen(false);
                  setGroupChangeStatusAction("");
                  setItemsForGroupChangeStatus({});
                }}
              >
                {groupStatusChangeMessages.btnDeleteNo}
              </button>
              <button
                className="button button--is-orange GenericModal__Footer__Button"
                onClick={() => onConfirmGroupStatusChange()}
              >
                {groupStatusChangeMessages.btnDeleteYes}
              </button>
            </p>
          </div>
        </Modal>

        <DirectoriesGroupOperations
          libraryId={library.id}
          selectedObjects={selectedObjects}
          setSelectedObjects={setSelectedObjects}
          operationType={operationType}
          setOperationType={setOperationType}
          setShouldTableRefresh={setShouldTableRefresh}
          groupData={groupData}
          setGroupOperationBtnsActive={setGroupOperationBtnsActive}
        />

        <div className="generic-table__controls">
          <form onSubmit={handleSubmit(() => {})}>
            <GenericSelect
              options={libraries}
              label="Biblioteka"
              name="library"
              control={control}
              errors={errors}
              isDisabled={libraries.length === 1}
              handleSelectChange={value => handleChange(value)}
            />
          </form>

          {hasCreatePermission && (
            <DropdownButton
              mainOption={{
                title: "Dodaj obiekt",
                href: `${urls.add}${digitalItemCreate}/`
              }}
              options={[
                {
                  id: 1,
                  title: "Dodaj obiekt grupowy",
                  href: `${urls.add}${groupObjectCreate}/`
                },
                {
                  id: 2,
                  title: "Dodaj katalog",
                  href: `${urls.add}${directoryCreate}/`
                }
              ]}
            />
          )}
        </div>

        {!isEmpty(library) && typeof library != undefined && (
          <div className="directories-search__generic-table" key={queryId}>
            <GenericTable
              customRowWithActions={customRow}
              structure={tableStructure}
              urls={urls}
              hasHeaderActions={false}
              isSelectable={true}
              customCheckboxHandler={{
                handler: customOnCheckboxChange,
                onSelectAll: customOnSelectAll,
                reset: checkboxReset,
                onRetrieve: onRetrieve
              }}
              libraryId={`${library.id}`}
              searchField="query"
              hasFilters={true}
              filtersValues="object_class"
              filtersCopyrightValues="copyright_id"
              filtersOptions={[
                { id: "directory", name: "Katalogi" },
                { id: "group_object", name: "Obiekty grupowe" },
                { id: "digital_item", name: "Obiekty pojedyncze" },
                { id: 1, name: "Dostępne w domenie publicznej" },
                {
                  id: 2,
                  name:
                    "Dostępne z ograniczeniami licencyjnymi do udostępniania/pobierania"
                },
                { id: 3, name: "Aktywna ochrona praw autorskich" },
                { id: 4, name: "Nieokreślone prawa autorskie" }
              ]}
              isCopyrightFilter
              isArchiveFilter
              groupActions={groupActions}
              groupOperationBtnsActive={groupOperationBtnsActive}
              advancedSearch={() =>
                setSearchModalIsOpen(searchModalIsOpen => !searchModalIsOpen)
              }
              shouldRefresh={shouldTableRefresh}
              setShouldRefresh={setShouldTableRefresh}
              searchType={searchType}
              setSearchType={setSearchType}
              defaultTableFilters={[
                "directory",
                "group_object",
                "digital_item"
              ]}
              massOperationPerm={hasMassOperationPermission}
              sipOperationPerm={hasSipPermission}
              additionalParameters={
                queryId ? { id: queryId } : { view: "basic" }
              }
              setQueryId={setQueryId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DirectoriesSearch;
