import "./Radio.scss";

import PropTypes from "prop-types";
import React from "react";

const Radio = ({
  name,
  value,
  radioText,
  register,
  checked,
  onChange,
  disabled
}) => {
  return (
    <>
      <label className="radio">
        <input
          type="radio"
          name={name}
          value={value}
          ref={register}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <span className="mbc-radio__label_text">{radioText}</span>
      </label>
    </>
  );
};

Radio.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
  register: PropTypes.func,
  disabled: PropTypes.bool
};

Radio.defaultProps = {
  disabled: false
};

export default Radio;
