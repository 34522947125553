import "./DirectoriesSearch.scss";
import "react-tabs/style/react-tabs.css";

import { FormContext, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import GenericModal from "@app/components/Modal/GenericModal";
import GenericSelect from "@app/components/Select/GenericSelect";
import Modal from "react-responsive-modal";
import { NewFormBuilder } from "@app/components/FormBuilder/NewFormBuilder";
import RSwitch from "react-switch";
import axiosSession from "@app/config/axiosSession";
import convertFormDataToValue from "@app/helpers/convertFormDataToValue";
import find from "lodash/find";
import handleErrors from "@app/helpers/handleErrors";
import isEmpty from "lodash/isEmpty";
import updateAction from "@app/actions/updateAction";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useStateMachine } from "little-state-machine";

const DirectoriesGroupOperations = ({
  selectedObjects,
  setSelectedObjects,
  libraryId,
  operationType,
  setOperationType,
  setShouldTableRefresh,
  groupData,
  setGroupOperationBtnsActive
}) => {
  const { handleSubmit, reset, ...formMethods } = useForm();
  const { action, state } = useStateMachine(updateAction);
  const { notifySuccess, notifyError } = useGenericToastify();
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertMessages, setAlertMessages] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabsError, setTabsError] = useState({});
  const [formBuilderData, setFormBuilderData] = useState([]);
  const [overwriteAttribute, setOverwriteAttribute] = useState(true);
  const [libraryLanguages, setLibraryLanguages] = useState([]);
  const [modalAction, setModalAction] = useState({});
  const [filteredIds, setFilteredIds] = useState([]);
  const [selectLabel, setSelectLabel] = useState("");
  const [selectData, setSelectData] = useState([]);
  const [selectDisabled, setSelectDisabled] = useState(false);
  const [schemaMessage, setSchemaMessage] = useState("");
  const [messages, setMessages] = useState({});
  const [selectedSchema, setSelectedSchema] = useState({});
  const [type, setType] = useState("");
  const [buttonSection, setButtonSection] = useState(true);
  const [activeLanguageFieldsLength, setActiveLanguageFieldsLength] = useState(
    {}
  );
  const [attributesChangeData, setAttributesChangeData] = useState({});

  const urls = {
    schemaPermissions: "/api/directories/change-schema/",
    schemaSubmit: "/api/directories/change-schema/operation/",
    schemaAttributes: "/api/directories/change-schema/attributes/",
    schemaSelect: "/api/attributes/schema/select-data/",
    schemaAll: "/api/directories/change-schema-by-filter/",
    typePermissions: "/api/digital_items/change-type/",
    typeSubmit: "/api/digital_items/change-type/operation/",
    typeAttributes: "/api/digital_items/change-type/attributes/",
    typeSelect: "/api/digital_items/digital-item-types/select-data/",
    typeAll: "/api/digital_items/change-type-by-filter/",
    attributesPermission:
      "/api/mass-operations/change-attributes-values/check/",
    attributesOperation:
      "/api/mass-operations/change-attributes-values/operation/",
    attributesAll:
      "/api/mass-operations/change-attributes-values/check-by-filter/"
  };

  const attributesMessages = {
    headerTitle: "Zmiana wartości atrybutu",
    deleteSuccess: "Wartość atrybutu została poprawnie zmieniona",
    deleteError: "Wystąpił problem podczas zmiany wartości atrybutu",
    btnDeleteNo: "Anuluj",
    btnDeleteYes: "Zapisz"
  };

  const schemaMessages = {
    headerTitle: "Zmiana schematu opisowego",
    deleteSuccess: "Schemat opisowy został poprawnie zmieniony",
    deleteError: "Wystąpił problem podczas zmiany schematu opisowego",
    btnDeleteNo: "Anuluj",
    btnDeleteYes: "Zapisz"
  };

  const typeMessages = {
    headerTitle: "Zmiana typu obiektu",
    deleteSuccess: "Typ obiektu został poprawnie zmieniony",
    deleteError: "Wystąpił problem podczas zmiany typu obiektu",
    btnDeleteNo: "Anuluj",
    btnDeleteYes: "Zapisz"
  };

  const permissionMessages = {
    bodyContent: " Czy mimo to chcesz kontynuować dla pozostałych zasobów?",
    btnDeleteNo: "Nie, przerwij akcję",
    btnDeleteYes: "Tak, kontynuuj"
  };

  const noPermissionMessages = {
    headerTitle: "Zmiana wartości atrybutu",
    bodyContent: "Nie można wykonać operacji dla zaznaczonych obiektów ponieważ są one zarchiwizowane lub nie posiadają odpowiednich uprawnień",
    btnDeleteNo: "Anuluj"
  };

  const mbc_light = "#f6f6f6";
  const mbc_white = "#000000";

  useEffect(() => {
    switch (operationType) {
      case "attributes":
        setType("attributes");
        setSelectLabel("Atrybut");
        handleAttributeChange("attributes");
        break;
      case "attributesAll":
        setType("attributes");
        setSelectLabel("Atrybut");
        checkSchemaAllPermissions("attributes");
        break;
      case "schema":
        setType("schema");
        setSelectLabel("Schemat opisowy");
        checkSchemaPermissions("schema");
        break;
      case "schemaAll":
        setType("schema");
        setSelectLabel("Schemat opisowy");
        checkSchemaAllPermissions("schema");
        break;
      case "type":
        setType("type");
        setSelectLabel("Typ obiektu");
        checkSchemaPermissions("type");
        break;
      case "typeAll":
        setType("type");
        setSelectLabel("Typ obiektu");
        checkSchemaAllPermissions("type");
        break;
      default:
        break;
    }
  }, [operationType]);

  useEffect(() => {
    if (!alertIsOpen) setButtonSection(true);
  }, [alertIsOpen])

  useEffect(() => {
    return () => {
      setOperationType("");
      window.sessionStorage.clear();
    };
  });

  useEffect(() => {
    if (type == "schema" || type == "type") {
      const url = type == "schema" ? urls.schemaSelect : urls.typeSelect;

      axiosSession
        .get(url)
        .then(({ data }) => {
          setSelectData(data);
          if (data.length > 0) {
            setSelectedSchema(data[0]);
          }
        })
        .catch(err => console.error(err));
    }
  }, [type]);

  const clearData = () => {
    setSelectData([]);
    setSelectDisabled(false);
    setFormBuilderData([]);
    setLibraryLanguages([]);
    setSelectedTab(0);
    setSchemaMessage("");
    setType("");
    setButtonSection(true);
    setAttributesChangeData({});
  };

  const onModalAction = () => {
    switch (modalAction.type) {
      case "attributes":
        confirmAttributesChange();
        break;
      case "schema":
        onSchemaChange(modalAction.type);
        break;
      case "type":
        onSchemaChange(modalAction.type);
        break;
    }
  };

  function confirmAttributesChange() {
    setAlertIsOpen(false);
    setMessages(attributesMessages);
    const attributesForSelect = attributesChangeData.attributes.map(
      (item, index) => ({ id: index, name: item.label, label: item.name })
    );
    setSelectData(attributesForSelect);
    setLibraryLanguages(attributesChangeData.language);
    setIsOpen(true);
  }

  const handleAttributeChange = () => {
    setGroupOperationBtnsActive(false)
    const digital_items_ids = [];
    const group_objects_ids = [];
    selectedObjects.map(item => {
      if (item.object_class === "digital_item") {
        digital_items_ids.push(item.id);
      } else {
        group_objects_ids.push(item.id);
      }
    });
    const request = {
      library_id: libraryId,
      digital_items_ids,
      group_objects_ids
    };
    axiosSession
      .post(urls.attributesPermission, request)
      .then(({ data }) => {
        setAttributesChangeData({
          library_id: data.library_id,
          digital_items_ids: data.digital_items_ids,
          group_objects_ids: data.group_objects_ids,
          attributes: data.attributes,
          language: data.language
        });
        if (data.message !== null) {
          if (data.attributes.length === 0) {
            setAlertMessages({
              ...noPermissionMessages,
              bodyContent: data.message
            });
            setButtonSection(false);
          } else {
            setAlertMessages({
              ...permissionMessages,
              headerTitle: "Zmiana wartości atrybutu",
              bodyContent: `${data.message}. Czy mimo to chcesz kontynuować dla pozostałych zasobów?`
            });
            setModalAction({ type: "attributes" });
          }
          setAlertIsOpen(true);
          return;
        }
        setMessages(attributesMessages);
        const attributesForSelect = data.attributes.map((item, index) => ({
          id: index,
          name: item.label,
          label: item.name
        }));
        setSelectData(attributesForSelect);
        setLibraryLanguages(data.language);
        setIsOpen(true);
      })
      .catch(err => {
        console.error(err);
        setAlertMessages({
          ...noPermissionMessages
        });
        setButtonSection(false);
        setAlertIsOpen(true);
      })
      .finally(() => setGroupOperationBtnsActive(true));
  };

  const switchActiveText = isActive => {
    return isActive ? "dictionaries-form__switch--active-text" : "";
  };

  const handleGroupChangeAttributesValue = data => {
    setFormBuilderData([
      attributesChangeData.attributes.find(item => item.name === data[0].label)
    ]);
  };

  const checkSchemaPermissions = type => {
    setGroupOperationBtnsActive(false)
    const ids =
      type == "schema"
        ? selectedObjects.filter(elem => elem.object_class != "digital_item")
        : selectedObjects.filter(elem => elem.object_class == "digital_item");

    const url =
      type == "schema" ? urls.schemaPermissions : urls.typePermissions;

    const title =
      type == "schema" ? "Zmiana schematu opisowego" : "Zmiana typu obiektu";

    axiosSession
      .post(url, {
        library_id: libraryId,
        ids: ids.map(elem => elem.id)
      })
      .then(({ data }) => {
        setFilteredIds(data.ids);

        if (data.message != "" || data.ids.length == 0) {
          if (data.ids.length > 0) {
            setAlertMessages({
              ...permissionMessages,
              headerTitle: title,
              bodyContent: `${data.message} ${permissionMessages.bodyContent}`
            });
          } else {
            setAlertMessages({ ...noPermissionMessages, headerTitle: title });
            setButtonSection(false);
          }
          setModalAction({ type });
          setAlertIsOpen(true);
        } else {
          onSchemaChange(type);
        }
      })
      .catch(err => console.error(err))
      .finally(() => setGroupOperationBtnsActive(true));
  };

  const checkSchemaAllPermissions = type => {
    setGroupOperationBtnsActive(false)
    let url = "";
    let title = "";
    let body = "";
    switch (type) {
      case "schema":
        url = urls.schemaAll;
        title = "Zmiana schematu opisowego";
        body = "Nie można zmienić schematu opisowego dla wybranych zasobów, ponieważ są zarchiwizowane, są obiektami pojedynczymi lub nie posiadasz odpowiednich uprawnień.";
        break;
      case "type":
        url = urls.typeAll;
        title = "Zmiana typu obiektu";
        body = "Nie można zmienić typu obiektu dla wybranych zasobów, ponieważ są zarchiwizowane, nie są obiektami pojedynczymi lub nie posiadasz odpowiednich uprawnień.";
        break;
      case "attributes":
        url = urls.attributesAll;
        title = "Zmiana typu wartości atrybutu";
        break;
    }

    axiosSession
      .post(url, groupData)
      .then(({ data }) => {
        if (type === "schema" || type === "type") {
          setFilteredIds(data.ids);
          if (data.message != "" || data.ids.length == 0) {
            if (data.ids.length > 0) {
              setAlertMessages({
                ...permissionMessages,
                headerTitle: title,
                bodyContent: `${data.message} ${permissionMessages.bodyContent}`
              });
            } else {
              setAlertMessages({
                ...noPermissionMessages,
                headerTitle: title,
                bodyContent: body
              });
              setButtonSection(false);
            }
            setModalAction({ type });
            setAlertIsOpen(true);
          } else {
            onSchemaChange(type);
          }
        } else if (type === "attributes") {
          setAttributesChangeData({
            library_id: data.library_id,
            digital_items_ids: data.digital_items_ids,
            group_objects_ids: data.group_objects_ids,
            attributes: data.attributes,
            language: data.language
          });
          if (data.message !== "") {
            if (data.attributes.length === 0) {
              setAlertMessages({
                ...noPermissionMessages,
                headerTitle: "Zmiana wartości atrybutu",
                bodyContent: data.message
              });
              setButtonSection(false);
            } else {
              setAlertMessages({
                ...permissionMessages,
                headerTitle: "Zmiana wartości atrybutu",
                bodyContent: data.message
              });
              setModalAction({ type: "attributes" });
            }
            setAlertIsOpen(true);
            return;
          }
          setMessages(attributesMessages);
          const attributesForSelect = data.attributes.map((item, index) => ({
            id: index,
            name: item.label,
            label: item.name
          }));
          setSelectData(attributesForSelect);
          setLibraryLanguages(data.language);
          setIsOpen(true);
        }
      })
      .catch(err => {
        console.error(err);
        if (type === "schema" || type === "type") return;
        setAlertMessages({
          ...noPermissionMessages,
          headerTitle: "Zmiana wartości atrybutu"
        });
        setButtonSection(false);
        setAlertIsOpen(true);
      })
      .finally(() => setGroupOperationBtnsActive(true));
  };

  const handleSchemaSelectChange = value => {
    setSelectedSchema(value[0]);
  };

  const submitSelectedSchema = () => {
    const url = type == "schema" ? urls.schemaAttributes : urls.typeAttributes;
    const msg = `Aby dokończyć zmianę ${
      type == "schema" ? "schematu opisowego" : "typu obiektu"
    } naciśnij przycisk "Zapisz".`;

    const formData = {
      ids: filteredIds,
      library_id: libraryId
    };

    if (type == "schema") {
      formData.schema_id = selectedSchema.id;
    } else {
      formData.type_id = selectedSchema.id;
    }

    axiosSession
      .post(url, formData)
      .then(({ data }) => {
        setLibraryLanguages(data.languages);
        setFormBuilderData(
          data.attributes.map(elem => ({
            ...elem,
            label: elem.label + ` [${elem.counter}]`
          }))
        );
        if (data.attributes.length == 0) {
          setSchemaMessage(msg);
        }
        setSelectDisabled(true);
      })
      .catch(err => console.error(err));
  };

  const onSchemaChange = type => {
    const msgs = type == "schema" ? schemaMessages : typeMessages;

    setAlertIsOpen(false);
    setMessages(msgs);
    setIsOpen(true);
  };

  const onSchemaChangeSubmit = async data => {
    if ((type === "attributes" && formBuilderData.length < 1) || (type !== "attributes" && !selectDisabled)) {
      let operationType = ""
      switch (type) {
        case "schema":
          operationType = "schemat opisowy";
          break;
        case "type":
          operationType = "typ obiektu";
          break;
        case "attributes":
          operationType = "atrybut";
          break;
      }
      return notifyError(`Wybierz ${operationType} aby kontynuować`)
    }

    let url = "";
    let successString = "";
    let errorMessage = "";

    switch (type) {
      case "schema":
        url = urls.schemaSubmit;
        successString = "Pomyślnie zmieniono schemat opisowy";
        errorMessage = "Nie udało się zmienić schematu opisowego";
        break;
      case "type":
        url = urls.typeSubmit;
        successString = "Pomyślnie zmieniono typ obiektu";
        errorMessage = "Nie udało się zmienić typu obiektu";
        break;
      case "attributes":
        url = urls.attributesOperation;
        successString = "Pomyślnie zmieniono wartość atrubutu";
        errorMessage = "Nie udało się zmienić wartości atrybutu";
        break;
    }

    const errors = {};
    let isError = false;

    const activeLanguageCode = find(libraryLanguages, [
      "id",
      `${selectedTab + 1}`
    ]).code;
    delete data.select;
    const activeLanguageFormData = {
      [activeLanguageCode]: data
    };
    const dataValues = {
      ...state.data,
      ...activeLanguageFormData
    };
    action(dataValues);

    for (let [key, value] of Object.entries(dataValues)) {
      const language = find(libraryLanguages, ["code", key]).id;
      const attributesValue = await convertFormDataToValue(
        value,
        formBuilderData
      );

      let formData = {};

      switch (type) {
        case "schema":
          formData = {
            attributes: attributesValue,
            language_id: parseInt(language),
            validate_field: true,
            ids: filteredIds,
            schema_id: selectedSchema.id,
            library_id: libraryId
          };
          break;
        case "type":
          formData = {
            attributes: attributesValue,
            language_id: parseInt(language),
            validate_field: true,
            ids: filteredIds,
            type_id: selectedSchema.id,
            library_id: libraryId
          };
          break;
        case "attributes":
          formData = {
            attributes: attributesValue,
            digital_items_ids: attributesChangeData.digital_items_ids,
            group_objects_ids: attributesChangeData.group_objects_ids,
            language_id: parseInt(language),
            library_id: libraryId,
            overwriteAttribute
          };
          break;
      }

      await axiosSession.post(url, formData).catch(error => {
        isError = true;
        console.error(error);
        notifyError(errorMessage);

        const languageErrors = error?.response?.data?.attributes ?? "";
        if (languageErrors) {
          errors[key] = { ...languageErrors };
        }
        if (key === activeLanguageCode && languageErrors) {
          handleErrors(languageErrors, formMethods.setError);
        }
      });
    }

    if (isEmpty(errors) && !isError) {
      notifySuccess(successString);
      setIsOpen(false);
      clearData();
      setSelectedObjects([]);
      setShouldTableRefresh(true);
    } else if (!isEmpty(errors)) {
      setTabsError(errors);
    }
  };

  return (
    <>
      <GenericModal
        modalIsOpen={alertIsOpen}
        setModalIsOpen={setAlertIsOpen}
        labels={alertMessages}
        actionSubmit={onModalAction}
        buttonSection={buttonSection}
      />

      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          clearData();
        }}
        center
        showCloseIcon={false}
        onOverlayClick={() => clearData()}
        classNames={{
          modal: "GenericModal directories-search__operation-modal"
        }}
      >
        <div className="GenericModal__Header--with-border">
          <p className="GenericModal__Header__Title">{messages.headerTitle}</p>
        </div>

        <div className="GenericModal__Body">
          <div className="directories-search__operation-modal__select-container">
            {selectData.length > 0 && (
              <>
                <div className="directories-search__operation-modal__select">
                  <GenericSelect
                    options={selectData}
                    defaultValue={type === "attributes" ? "" : selectData[0]}
                    label={selectLabel}
                    name="select"
                    control={formMethods.control}
                    errors={formMethods.errors}
                    isDisabled={selectDisabled}
                    handleSelectChange={value => {
                      if (type == "attributes") {
                        handleGroupChangeAttributesValue(value);
                      } else {
                        handleSchemaSelectChange(value);
                      }
                    }}
                  />
                </div>
                {(type == "schema" || type == "type") && (
                  <button className="button" onClick={submitSelectedSchema}>
                    Wybierz
                  </button>
                )}
              </>
            )}
          </div>

          {type == "schema" && (
            <div className="directories-search__operation-modal__message">
              Zmiana schematu nastąpi tylko dla katalogów i obiektów grupowych
            </div>
          )}

          {type == "type" && (
            <div className="directories-search__operation-modal__message">
              Zmiana typu obiektu nastąpi tylko dla obiektów pojedynczych
            </div>
          )}

          {schemaMessage != "" && (
            <span className="directories-search--message-padding">
              {schemaMessage}
            </span>
          )}

          <div className="directories-search__operation-modal--font-size">
            {formBuilderData.length > 0 && formBuilderData[0].frequent && (
              <div>
                <label className="columns" htmlFor="isOpen">
                  <span
                    className={`column ${switchActiveText(
                      !overwriteAttribute
                    )} has-text-black`}
                  >
                    Dodaj nowe wartości
                  </span>
                  <RSwitch
                    checked={overwriteAttribute}
                    disabled={false}
                    onChange={() => setOverwriteAttribute(!overwriteAttribute)}
                    onColor={mbc_light}
                    offColor={mbc_light}
                    offHandleColor={mbc_white}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onHandleColor={mbc_white}
                    handleDiameter={22}
                    height={15}
                    width={34}
                    className="column dictionaries-form__switch--border react-switch is-one-fifth is-paddingless"
                    id="isOpen"
                    name="isOpen"
                  />
                  <span
                    className={`column ${switchActiveText(
                      overwriteAttribute
                    )} has-text-black`}
                  >
                    Nadpisz wartości
                  </span>
                </label>
              </div>
            )}

            {formBuilderData.length > 0 && libraryLanguages && (
              <Tabs
                selectedIndex={selectedTab}
                onSelect={tabIndex => {
                  const oldLanguageCode = find(libraryLanguages, [
                    "id",
                    `${selectedTab + 1}`
                  ]).code;
                  const formData = formMethods.getValues({ nest: true });
                  delete formData.select;
                  const data = {
                    [oldLanguageCode]: formData
                  };
                  action({ ...state.data, ...data });
                  const languageCode = find(libraryLanguages, [
                    "id",
                    `${tabIndex + 1}`
                  ]).code;
                  setActiveLanguageFieldsLength({});
                  reset(state.data[languageCode]);
                  setSelectedTab(tabIndex);
                  if (!isEmpty(tabsError[languageCode])) {
                    handleErrors(tabsError[languageCode], formMethods.setError);
                  }
                }}
              >
                <TabList>
                  {libraryLanguages.map(language => (
                    <Tab key={language.id}>Język {language.name}</Tab>
                  ))}
                </TabList>
                {libraryLanguages.map(language => (
                  <TabPanel key={language.id}>
                    <FormContext {...formMethods}>
                      <form onSubmit={handleSubmit(() => {})}>
                        <div className="columns is-variable is-10 is-multiline">
                          <div className="column is-8">
                            {formBuilderData && (
                              <NewFormBuilder
                                key={`mfb-${selectedTab}-${language.id}`}
                                data={formBuilderData}
                                fieldsLength={activeLanguageFieldsLength}
                              />
                            )}
                          </div>
                        </div>
                      </form>
                    </FormContext>
                  </TabPanel>
                ))}
              </Tabs>
            )}
          </div>
        </div>

        <div className="GenericModal__Footer">
          <p className="GenericModal__Footer__Buttons buttons is-right is-fullwidth">
            <button
              className="button GenericModal__Footer__Button"
              onClick={() => {
                setFormBuilderData([]);
                clearData();
                setIsOpen(false);
              }}
            >
              {messages.btnDeleteNo}
            </button>
            <button
              className="button button--is-orange GenericModal__Footer__Button"
              onClick={() => handleSubmit(onSchemaChangeSubmit)()}
            >
              {messages.btnDeleteYes}
            </button>
          </p>
        </div>
      </Modal>
    </>
  );
};

export default DirectoriesGroupOperations;
