import "./WorkerForm.scss";

import React, { useEffect, useState } from "react";

import CancelButton from "@components/CancelButton/CancelButton";
import GenericSelect from "@app/components/Select/GenericSelect";
import Input from "@app/components/Input/Input";
import axiosSession from "@app/config/axiosSession";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

const WorkerForm = ({ id }) => {
  const {
    handleSubmit,
    register,
    errors,
    setValue,
    reset,
    control,
    setError
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [inputError, setInputError] = useState("");
  const { notifyError } = useGenericToastify();
  const [libraries, setLibraries] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedLibrary, setSelectedLibrary] = useState("");

  const returnLocation = "/cancel?url=/users/workers/";

  const resetErrors = () => {
    setInputError(false);
  };

  useEffect(() => {
    !isEmpty(errors) ? notifyError("Nie udało się zapisać użytkownika.") : null;
  }, [errors, notifyError]);

  useEffect(() => {
    if (id) {
      axiosSession
        .get(`/api/users/workers/${id}/`)
        .then(res => {
          reset(res.data.user);
          setSelectedLibrary(res.data.library.id);
          setValue("library", res.data.library);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [id, reset, setValue]);

  useEffect(() => {
    axiosSession
      .get("/api/library/select-data/")
      .then(res => {
        setLibraries(res.data);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    let url = `/api/users/groups/select-data/`;
    if (selectedLibrary !== "") {
      url = `${url}?library=${selectedLibrary}`;
    }
    axiosSession
      .get(url)
      .then(res => {
        setGroups(res.data);
      })
      .catch(err => {
        console.error(err);
      });
  }, [selectedLibrary]);

  const handleLibraryChange = ([selectedOption]) => {
    setSelectedLibrary(selectedOption.id);
    return selectedOption;
  };

  const onSubmit = data => {
    setIsLoading(true);

    let method = "";
    let url = "";
    let groups_list = !isEmpty(data.groups) ? data.groups.map(f => f.id) : [];
    data.groups = groups_list;
    let formData = {
      user: data,
      library: data.library.id
    };

    resetErrors();

    if (id) {
      method = "PUT";
      url = `/api/users/workers/${id}/`;
    } else {
      method = "POST";
      url = "/api/users/workers/";
    }

    axiosSession({ method: method, url: url, data: formData })
      .then(response => {
        setIsLoading(false);
        window.location.href = response.data.url;
      })
      .catch(error => {
        if (error.response.data.user) {
          if (error.response.data.user.username) {
            setError("username", "", error.response.data.user.username);
          }

          if (error.response.data.user.first_name) {
            setError("first_name", "", error.response.data.user.first_name);
          }

          if (error.response.data.user.last_name) {
            setError("last_name", "", error.response.data.user.last_name);
          }

          if (error.response.data.user.email) {
            setError("email", "", error.response.data.user.email);
          }

          if (error.response.data.user.groups) {
            setError("groups", "", error.response.data.user.groups);
          }
        }

        if (error.response.data.library) {
          setError("library", "", error.response.data.library);
        }

        if (error.response.data.non_field_errors) {
          setInputError(error.response.data.non_field_errors[0]);
        }
        notifyError("Nie udało się zapisać użytkownika.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section className="workers-form">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/users/workers/"
            title="Użytkownicy wewnętrzni"
          >
            Użytkownicy wewnętrzni
          </a>
          &nbsp;/&nbsp;
          <strong>{id ? "Edycja" : "Dodaj"} użytkownika</strong>
        </div>

        <div className="workers-form__header">
          <h2 className="workers-form__header__title">
            {id ? "Edycja" : "Dodawanie"} użytkownika
          </h2>
          <hr className="workers-form__header__hr" />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="columns is-centered is-variable is-8">
            <div className="column">
              <Input
                disabled={!!id}
                name={"username"}
                label={"Nazwa użytkownika (LOGIN)"}
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
              <Input
                name={"first_name"}
                label={"Imię"}
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
              <Input
                name={"last_name"}
                label={"Nazwisko"}
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
              <Input
                name={"email"}
                label={"Adres E-MAIL"}
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
            </div>
            <div className="column">
              <GenericSelect
                options={libraries}
                placeholder="Wybierz bibliotekę"
                control={control}
                required={{ required: "To pole jest wymagane!" }}
                handleSelectChange={handleLibraryChange}
                name="library"
                isDisabled={!!id}
                errors={errors}
                label="Biblioteka"
                isRequire
              />
              <GenericSelect
                options={groups}
                placeholder="Wybierz grupy uprawnień"
                control={control}
                required={{ required: false }}
                name="groups"
                errors={errors}
                label="Grupy uprawnień"
                isMulti
              />
            </div>
          </div>
          <div>
            {inputError ? (
              <div className="level is-danger is-size-6">
                <div className="level-left section-danger has-text-danger">
                  <span className="level-item icon">
                    <i className="fas fa-exclamation-triangle" />
                  </span>
                  {inputError}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="buttons">
              <CancelButton
                returnLocation={returnLocation}
                bodyContentSufix={`${
                  id ? "edycję" : "dodanie nowego"
                } użytkownika?`}
              />
              <button
                className={`button workers-form__btn ${
                  isLoading ? "is-loading" : ""
                }`}
                type="submit"
                title="Zapisz"
              >
                Zapisz
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default WorkerForm;
