import {
  faEye,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericTable from "../../components/Table/GenericTable";
import React from "react";
import TruncateMarkup from "react-truncate-markup";

const tableStructure = [
  {
    header: "Ostatnia modyfikacja",
    accessor: "modified",
    className: "w-3",
    sort: false
  },
  {
    header: "Nazwa",
    accessor: "short_content",
    className: "w-4",
    sort: true
  },
  {
    header: "Status",
    accessor: "status",
    className: "w-2"
  },
  {
    header: "Wersje oczekujące",
    accessor: "waiting",
    className: "w-2"
  },
  {
    header: "Akcje",
    className: "w-1 has-text-right"
  }
];

const urls = {
  get: "/api/rules/agreements/",
  add: "/rules/agreements/create/",
  edit: "/rules/agreements/", //appends /${id}/update
  delete: "/api/rules/destroy/" //appends /${id}/
};
const messages = {
  deleteSuccess: "Zgoda została poprawnie usunięta",
  deleteError: "Wystąpił problem podczas usuwania zgody",
  deleteConfirm: "Czy na pewno chcesz usunąć zgodę?",
  btnAdd: "Dodaj zgodę",
  btnDeleteNo: "Nie",
  btnDeleteYes: "Tak"
};

const onEdit = id => event => {
  window.location = `${urls.edit}${id}/edit/`;
};

const onPreview = id => event => {
  window.location = `${urls.edit}${id}/preview/`;
}

const customRow = item => {
  return (
    <React.Fragment>
      <td>
        <TruncateMarkup>
          <div title={item.modified}>{item.modified}</div>
        </TruncateMarkup>
      </td>
      <td>
        <TruncateMarkup>
          <div title={item.short_content}>{item.short_content}</div>
        </TruncateMarkup>
      </td>
      <td>
        <TruncateMarkup>
          <div title={item.child_active}>{item.child_active}</div>
        </TruncateMarkup>
      </td>
      <td>
        <TruncateMarkup>
          <div title={item.waiting}>{item.waiting}</div>
        </TruncateMarkup>
      </td>
    </React.Fragment>
  );
};

const customActions = item => {
  return (
    <React.Fragment>
      <td className="has-text-right">
        <span
          onClick={onPreview(item.edit_id)}
          onKeyPress={onPreview(item.edit_id)}
          role="button"
          tabIndex="0"
          className="generic-table__table__link"
          title="Podgląd"
        >
          <FontAwesomeIcon icon={faEye} />
        </span>
        <span
          onClick={onEdit(item.edit_id)}
          onKeyPress={onEdit(item.edit_id)}
          role="button"
          tabIndex="0"
          className="generic-table__table__link"
          title="Edycja"
        >
          <FontAwesomeIcon icon={faPen} />
        </span>
      </td>
    </React.Fragment>
  );
};

const Agreements = ({ message, hasCreatePermission }) => {
  return (
    <section className="rules">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Zgody</strong>
        </div>

        <GenericTable
          customRow={customRow}
          customActions={customActions}
          structure={tableStructure}
          notification={message}
          messages={messages}
          urls={urls}
          hasHeaderActions={hasCreatePermission}
        />
      </div>
    </section>
  );
};

export default Agreements;
