import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import remove from "lodash/remove";
import CKEditor from "ckeditor4-react";

const Preview = ({ rows, displayFalseValues }) => {
  const [modifiedRows, setModifiedRows] = useState([]);

  useEffect(() => {
    if (!displayFalseValues) {
      remove(rows, o => {
        return o.value.length == 0 || (o.value.length == 1 && o.value[0] == "");
      });
    }
    setModifiedRows(rows);
  }, [rows]);

  return (
    <>
      {modifiedRows.map((row, idx) => (
        <div className="columns is-1" key={idx + Date.now()}>
          <div className="is-uppercase column is-3" key={idx}>
            {row.title}:
          </div>
          <div className="has-text-weight-bold column ">
            {row.value.map((v, idxV) => (
              !row.isHTML ?
                <p key={idxV} className="has-text-black">
                  {v}
                </p>
                :
                <CKEditor
                  key={idxV}
                  readOnly={true}
                  data={v}
                />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

Preview.propTypes = {
  rows: PropTypes.array.isRequired
};

Preview.defaultProps = {
  rows: [],
  displayFalseValues: false
};

export default Preview;
