import "./GroupForm.scss";

import React, { useEffect, useState } from "react";

import CancelButton from "@components/CancelButton/CancelButton";
import CheckboxColumn from "@components/CheckboxColumn/CheckboxColumn";
import ClipLoader from "react-spinners/ClipLoader";
import GenericSelect from "@components/Select/GenericSelect";
import Input from "@components/Input/Input";
import axiosSession from "@app/config/axiosSession";
import chunk from "lodash/chunk";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import propTypes from "prop-types";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

const GroupForm = ({ id }) => {
  const {
    handleSubmit,
    register,
    errors,
    setValue,
    control,
    setError
  } = useForm();
  const { notifyError } = useGenericToastify();
  const [selectedPermissionFields, setSelectedPermissionFields] = useState([]);
  const [permissionFields, setPermissionFields] = useState([]);
  const [chunkedPermissionFields, setChunkedPermissionFields] = useState([]);
  const [libraries, setLibraries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputError, setInputError] = useState("");

  const [dataLoad, setDataLoad] = useState(false);
  const [permissionFieldLoad, setPermissionFieldLoad] = useState(false);
  const [
    chunkedPermissionFieldsLoad,
    setChunkedPermissionFieldsLoad
  ] = useState(false);

  const [name, setName] = useState("");
  const [library, setLibrary] = useState("");

  const returnLocation = "/cancel?url=/users/groups/";

  const resetErrors = () => {
    setInputError(false);
  };

  useEffect(() => {
    !isEmpty(errors) ? notifyError("Nie udało się zapisać grupy.") : null;
  }, [errors, notifyError]);

  const onSubmit = data => {
    let method = "";
    let url = "";
    const libraries_list = data.library.id;

    setIsLoading(true);
    resetErrors();
    data.library = libraries_list;
    data.permissions = permissionFields
      .filter(field => field.isChecked)
      .map(field => field.permId);

    if (id) {
      method = "PUT";
      url = `/api/users/groups/${id}/`;
    } else {
      method = "POST";
      url = "/api/users/groups/";
    }
    axiosSession({ method: method, url: url, data: data })
      .then(function(response) {
        window.location.href = response.data.url;
      })
      .catch(function(error) {
        if (error.response.data.name) {
          setError("name", "", error.response.data.name);
        }

        if (error.response.data.library) {
          setError("library", "", error.response.data.library);
        }

        if (error.response.data.non_field_errors) {
          setInputError(error.response.data.non_field_errors[0]);
        }
        notifyError("Nie udało się zapisać grupy.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      axiosSession
        .get(`/api/users/groups/${id}/`)
        .then(res => {
          setSelectedPermissionFields(res.data.permissions);
          setName(res.data.short_name);
          setLibrary(res.data.library);
          setDataLoad(true);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [id, setValue]);

  useEffect(() => {
    axiosSession
      .get("/api/users/permissions/")
      .then(res => {
        const permissionFieldsList = res.data.map(field => ({
          name: field.name,
          searchName: field.name.toLowerCase(),
          isChecked: selectedPermissionFields.includes(field.id),
          show: true,
          permId: field.id
        }));
        setPermissionFields(permissionFieldsList);
        setPermissionFieldLoad(true);
      })
      .catch(err => {
        console.error(err);
      });
  }, [selectedPermissionFields]);

  useEffect(() => {
    const showPermissionFieldsList = permissionFields.filter(f => f.show);
    const columnCount = showPermissionFieldsList.length > 10 ? 3 : 1;
    const groupSize = Math.ceil(showPermissionFieldsList.length / columnCount);
    const sets = chunk(showPermissionFieldsList, groupSize);
    setChunkedPermissionFields(sets);
    permissionFieldLoad ? setChunkedPermissionFieldsLoad(true) : null;
  }, [permissionFields]);

  useEffect(() => {
    axiosSession
      .get("/api/library/select-data/")
      .then(res => {
        setLibraries(res.data);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  return (
    <section className="group-form">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a className="breadcrumbs__link" href="/users/groups/" title="Grupy">
            Grupy
          </a>
          &nbsp;/&nbsp;
          {id ? <strong>Edycja grupy</strong> : <strong>Dodaj grupę</strong>}
        </div>

        <div className="group-form__header">
          <h2 className="group-form__header__title">
            {id ? "Edycja" : "Dodawanie"} grupy
          </h2>
          <hr className="group-form__header__hr" />
        </div>
        {!permissionFieldLoad ||
        (id ? !dataLoad || !chunkedPermissionFieldsLoad : false) ? (
          <div className="step-form-loader">
            <ClipLoader size={120} color={"black"} loading={true} />
            <br />
            <p>Trwa ładowanie formularza.</p>
            <p>Proszę czekać.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="columns is-variable">
              <div className="column is-half-desktop is-full-mobile">
                <Input
                  name="name"
                  label="Nazwa"
                  darkTheme={false}
                  register={register({ required: "To pole jest wymagane!" })}
                  errors={errors}
                  isRequired
                  defaultValue={name}
                />

                <GenericSelect
                  options={libraries}
                  placeholder="Wybierz bibliotekę"
                  control={control}
                  required={{ required: "To pole jest wymagane!" }}
                  name="library"
                  isDisabled={!!id}
                  errors={errors}
                  label="Biblioteka"
                  isRequire
                  defaultValue={library}
                />
                <Input
                  name="permissions_search"
                  label="Uprawnienia"
                  placeholder="Wyszukaj uprawnienia"
                  darkTheme={false}
                  onChange={e => {
                    const permissionFieldsList = permissionFields.map(
                      field => ({
                        ...field,
                        show: includes(
                          field.searchName,
                          e.target.value.toLowerCase()
                        )
                      })
                    );
                    setPermissionFields(permissionFieldsList);
                  }}
                  onKeyPress={e => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                />
              </div>
            </div>
            <div className="column">
              <p className="checkbox-count">
                Liczba zaznaczonych uprawnień:{" "}
                {permissionFields.filter(field => field.isChecked).length}
              </p>

              <div className="columns is-centered checkbox-column">
                {!isEmpty(chunkedPermissionFields) ? (
                  chunkedPermissionFields.map((perms, index) => (
                    <CheckboxColumn
                      key={`column-${index}`}
                      items={perms}
                      name="permissions"
                      setPermissionFields={setPermissionFields}
                      permissionFields={permissionFields}
                    />
                  ))
                ) : (
                  <span>Brak danych</span>
                )}
              </div>
            </div>
            <div>
              {inputError ? (
                <div className="level is-danger is-size-6">
                  <div className="level-left section-danger has-text-danger">
                    <span className="level-item icon">
                      <i className="fas fa-exclamation-triangle" />
                    </span>
                    {inputError}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="buttons">
                <CancelButton
                  returnLocation={returnLocation}
                  bodyContentSufix={`${id ? "edycję" : "dodanie nowej"} grupy?`}
                />
                <button
                  className={`button workers-form__btn ${
                    isLoading ? "is-loading" : ""
                  }`}
                  type="submit"
                >
                  Zapisz
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </section>
  );
};

GroupForm.propTypes = {
  id: propTypes.string
};

export default GroupForm;
