import GenericTable from "../../components/Table/GenericTable";
import React from "react";

const tableStructure = [
  {
    header: "Nazwa",
    field: "name",
    accessor: "name",
    className: "w-2",
    sort: true
  },
  {
    header: "Źródło danych",
    accessor: "source__name",
    field: "source",
    className: "w-2",
    sort: true
  },
  {
    header: "Dostęp",
    field: "is_open",
    accessor: "is_open_display",
    className: "w-2",
    sort: true
  },
  {
    header: "Układ",
    field: "is_flat",
    accessor: "is_flat_display",
    className: "w-2",
    sort: true
  },
  {
    header: "Kontekst czasu",
    field: "is_time_limited",
    accessor: "is_time_limited_display",
    className: "w-2",
    sort: true
  },
  {
    header: "Akcje",
    className: "w-2 has-text-right"
  }
];

const urls = {
  get: "/api/dicts/",
  add: "/dictionaries/create/",
  edit: "/dictionaries/", //appends /${id}/edit
  delete: "/api/dicts/" //appends /${id}/
};

const messages = {
  deleteSuccess: "Słownik został poprawnie usunięty",
  deleteError: "Wystąpił problem podczas usuwania słownika",
  btnAdd: "Dodaj słownik",
  btnDeleteNo: "Anuluj",
  btnDeleteYes: "Usuń",
  headerTitle: "Potwierdzenie usunięcia",
  bodyContent: "Czy na pewno chcesz usunąć słownik?",
  contentLabel: "Potwierdzenie usunięcia słownika"
};

const Dictionaries = ({ message, hasCreatePermission }) => {
  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Słowniki</strong>
        </div>
        <GenericTable
          structure={tableStructure}
          messages={messages}
          notification={message}
          urls={urls}
          disablePreview={false}
          hasHeaderActions={hasCreatePermission}
        />
      </div>
    </section>
  );
};

export default Dictionaries;
