import "./MapComponent.scss";

import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import React, { useEffect, useState } from "react";

import L from "leaflet";

const icon = "/static/images/marker-icon.png";
const iconShadow = "/static/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

const MapComponent = ({ addPoint, initialMarkers, preview = false }) => {
  const [markers, setMarkers] = useState(initialMarkers);
  const [centerMapPosition, setCenterMapPosition] = useState(null);

  const LocationMarkers = () => {
    const map = useMapEvents({
      click(e) {
        if (!preview) {
          setMarkers([e.latlng]);
          addPoint(e.latlng);
        }
      }
    });

    return (
      <React.Fragment>
        {markers.map(marker => (
          <Marker key={new Date()} position={marker}></Marker>
        ))}
      </React.Fragment>
    );
  };
  useEffect(() => {
    if (markers.length > 0) {
      setCenterMapPosition({ lat: markers[0]?.lat, lng: markers[0]?.lng });
    } else {
      setCenterMapPosition({
        lat: 50.065,
        lng: 19.93
      });
    }
  }, [initialMarkers]);

  return (
    <div className="map-container" style={{ width: "800px", height: "500px" }}>
      {!preview && (
        <span className="map-container__label">
          Wybierz położenie obiektu na mapie:
        </span>
      )}
      { centerMapPosition && (
        <MapContainer
          center={centerMapPosition}
          zoom={18}
          scrollWheelZoom={false}
          style={{
            height: "400px",
            backgroundColor: "#f2efe9",
            marginTop: "30px",
            marginBottom: "50px"
          }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <LocationMarkers />
        </MapContainer>
      )}
    </div>
  );
};

export default MapComponent;
