import React from "react";

export const Preview = ({ fields, data }) => {
  const renderedFields = [];
  for (const field of fields) {
    switch (field.type) {
      case "array": {
        if (field.subType === "group") {

          for (const [index, datad] of data[field.name]?.entries() ?? []) {
            const fieldsData = [
              {
                ...field,
                name: `${field.name}`,
                type: field.subType
              }
            ];
            renderedFields.push(
              <Preview
                key={`${field.name}-${index}`}
                fields={fieldsData}
                data={{ [field.name]: datad }}
              />
            );
          }
        } else {
          const values = data?.[field.name]?.values;
          if (values) {
            renderedFields.push(
              <li
                className="attributes-preview__list"
                key={field?.subName ?? field.name}
              >
                <p className="attributes-preview__list-item__label">
                  {field.label}
                </p>
                <p className="attributes-preview__list-item__name">
                  {values ? values.join(", ") : ""}
                </p>
              </li>
            );
          }
        }
        break;
      }
      case "group": {

        const fields = field.fields.map(item => ({
          ...item,
          subName: `${field.name}.${item.name}`,
          name: `${item.name}`
        }));

        if(data?.[field.name] ?? false) {
        renderedFields.push(
          <li key={field.name} className="attributes-preview__list">
            <ul
              key={field.name}
              style={{ marginBottom: "15px", width: "100%" }}
            >
              <p className="attributes-preview__list-item__label">
                {field.label} (AZ):
              </p>
              <div
                style={{
                  paddingLeft: "25px",
                  marginLeft: "25px",
                  borderLeft: "1px dashed black"
                }}
              >
                <Preview
                  key={field.name}
                  fields={fields}
                  data={data[field.name]}
                />
              </div>
            </ul>
          </li>
        );
        };
        break;
      }
      default:
        if (data?.[field.name]?.values) {
          renderedFields.push(
            <li
              className="attributes-preview__list"
              key={field?.subName ?? field.name}
            >
              <p className="attributes-preview__list-item__label">
                {field.label}
              </p>
              <p className="attributes-preview__list-item__name">
                {data?.[field.name]?.values}
              </p>
            </li>
          );
        }
        break;
    }
  }
  return renderedFields;
};

export default function AttributesPreviewBuilder({ schema, data }) {
  return <Preview fields={schema} data={data} />;
}
