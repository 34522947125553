import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericTable from "@app/components/Table/GenericTable";
import React from "react";
import TruncateMarkup from "react-truncate-markup";

const tableStructure = [
  {
    header: "Ostatnia modyfikacja",
    accessor: "modified",
    className: "w-5",
    sort: false
  },
  {
    header: "Status",
    accessor: "status",
    className: "w-5",
    sort: true
  },
  {
    header: "Akcje",
    className: "w-2 has-text-right"
  }
];
const urls = {
  get: "/api/rules/terms/active/",
  edit: "/rules/terms/" //appends /${id}/update
};

const customRow = item => {
  return (
    <React.Fragment>
      <td>
        <TruncateMarkup>
          <div title={item.modified}>{item.modified}</div>
        </TruncateMarkup>
      </td>
      <td>
        <TruncateMarkup>
          <div title={item.active ? "opublikowany" : "nieopublikowany"}>
            {item.active ? "opublikowany" : "nieopublikowany"}
          </div>
        </TruncateMarkup>
      </td>
    </React.Fragment>
  );
};

const onEdit = id => event => {
  window.location = `${urls.edit}${id}/edit/`;
};

const onPreview = id => event => {
  window.location = `${urls.edit}${id}/preview/`;
};

const customActions = item => {
  return (
    <React.Fragment>
      <td className="has-text-right">
        <span
          onClick={onPreview(item.id)}
          onKeyPress={onPreview(item.id)}
          role="button"
          tabIndex="0"
          className="generic-table__table__link"
          title="Podgląd"
        >
          <FontAwesomeIcon icon={faEye} />
        </span>
        <span
          onClick={onEdit(item.id)}
          onKeyPress={onEdit(item.id)}
          role="button"
          tabIndex="0"
          className="generic-table__table__link"
          title="Edycja"
        >
          <FontAwesomeIcon icon={faPen} />
        </span>
      </td>
    </React.Fragment>
  );
};

const Agreements = ({ message }) => {
  return (
    <section className="rules">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Regulaminy</strong>
        </div>
        <GenericTable
          customRow={customRow}
          customActions={customActions}
          structure={tableStructure}
          notification={message}
          urls={urls}
          hasSearch={false}
          hasHeaderActions={false}
        />
      </div>
    </section>
  );
};

export default Agreements;
