import GenericTable from "../../components/Table/GenericTable";
import React from "react";

const tableStructure = [
  {
    header: "Ostatnia modyfikacja",
    field: "modified",
    accessor: "modified",
    className: "w-4",
    sort: true
  },
  {
    header: "Nazwa",
    field: "name",
    accessor: "name",
    className: "w-6",
    sort: true
  },
  {
    header: "Akcje",
    className: "w-2 has-text-right"
  }
];

const urls = {
  get: "/api/users/groups/",
  add: "/users/groups/create/",
  edit: "/users/groups/", //appends /${id}/update
  delete: "/api/users/groups/" //appends /${id}/
};

const messages = {
  deleteSuccess: "Grupa została poprawnie usunięta",
  deleteError: "Wystąpił problem podczas usuwania grupy",
  btnAdd: "Dodaj grupę",
  btnDeleteNo: "Anuluj",
  btnDeleteYes: "Usuń",
  headerTitle: "Potwierdzenie usunięcia",
  bodyContent: "Czy na pewno chcesz usunąć grupę?",
  contentLabel: "Potwierdzenie usunięcia grupy"
};

const Groups = ({ message, hasCreatePermission }) => {
  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Grupy</strong>
        </div>

        <GenericTable
          structure={tableStructure}
          notification={message}
          messages={messages}
          urls={urls}
          disablePreview={false}
          hasHeaderActions={hasCreatePermission}
        />
      </div>
    </section>
  );
};

export default Groups;
