import "./AdminPanel.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const AdminPanel = ({ userMenuPermissions }) => {
  const Tile = ({ title, icon, link }) => {
    return (
      <a href={link} className={`admin-panel__tile`}>
        <FontAwesomeIcon icon={icon} />
        <span>{title}</span>
      </a>
    );
  };

  const noChildrenElements = [];
  const childrenElements = [];

  userMenuPermissions.forEach(item => {
    if (item.children.length === 0) {
      noChildrenElements.push(
        <Tile
          key={item.label}
          title={item.label}
          icon={item.icon}
          link={item.url}
        />
      );
    } else {
      childrenElements.push(
        <React.Fragment key={item.label}>
          <h2 className="admin-panel__title">{item.label}</h2>
          <div className="admin-panel__row">
            {item.children.map(child_item => (
              <Tile
                key={child_item.label}
                title={child_item.label}
                icon={child_item.icon}
                link={child_item.url}
              />
            ))}
          </div>
        </React.Fragment>
      );
    }
  });

  return (
    <section className="admin-panel">
      {userMenuPermissions.length > 0 ? (
        <div className="container">
          {noChildrenElements.length > 0 && (
            <div className="admin-panel__row">{noChildrenElements}</div>
          )}
          {childrenElements}
        </div>
      ) : (
        <div className="mbc-backoffice">
          <div className="container">
            <div className="mbc-backoffice__header">
              <h2 className="mbc-backoffice__header__title">
                Brak dostępnych modułów
              </h2>
              <hr className="mbc-backoffice__header__hr" />
            </div>
            <div className="buttons">
              <a className="button button--is-orange" href="/">
                Powrót na stronę główną
              </a>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default AdminPanel;
