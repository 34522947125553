import "@trendmicro/react-dropdown/dist/react-dropdown.css";
import "./DropdownButton.scss";

import React, { useCallback, useState } from "react";

import Dropdown from "@trendmicro/react-dropdown";
import PropTypes from "prop-types";

const DropdownButton = ({
  mainOption,
  options,
  isLoading,
  darkTheme,
  buttonSize,
  disabled
}) => {
  const buttonStyle = darkTheme ? "button--is-gray" : "button--is-orange";
  const loadingButton = isLoading ? "is-loading" : "";
  const dropdownStyle = darkTheme ? "" : "dropdown-content--is-orange";
  const [currentFocus, setFocus] = useState(1);
  const [isOpen, setIsOpen] = useState(false);

  const handleKeyDown = useCallback(
    e => {
      if (e.keyCode === 9) {
        e.preventDefault();
        setFocus(currentFocus === options.length ? 1 : currentFocus + 1);
        document.getElementById(currentFocus).focus();
      } else if (e.keyCode === 27) {
        setIsOpen(false);
      }
    },
    [currentFocus, options]
  );

  const menuHandler = useCallback(
    e => {
      if (e.keyCode === 9 && isOpen) {
        return (e.keyCode = 40);
      } else if (e.keyCode === 13) {
        setIsOpen(true);
      } else if (e.keyCode === 27) {
        setIsOpen(false);
      }
    },
    [isOpen]
  );

  return (
    <Dropdown
      className={`button ${buttonStyle} pull-right is-paddingless mbc-dropdown`}
      disabled={disabled}
    >
      {mainOption.onClick ? (
        <button
          className={`button button--is-borderless pull-right ${buttonStyle} ${loadingButton}`}
          title={mainOption.title}
          type={mainOption.type}
          disabled={disabled}
          onClick={mainOption.onClick}
        >
          {mainOption.title}
        </button>
      ) : (
        <a
          className={`button button--is-borderless pull-right ${buttonStyle} ${loadingButton}`}
          title={mainOption.title}
          disabled={disabled}
          href={mainOption.href}
        >
          {mainOption.title}
        </a>
      )}

      <Dropdown.Toggle
        noCaret
        btnSize={buttonSize}
        className={`button ${buttonStyle} dropdown-trigger`}
        aria-haspopup="true"
        aria_hidden="true"
        aria-expanded="false"
        onClick={menuHandler}
        onKeyDown={menuHandler}
      >
        <i className="fas fa-angle-down" />
      </Dropdown.Toggle>
      {options.length > 0 && (
        <Dropdown.Menu className={`dropdown-content ${dropdownStyle}`}>
          {options.map(item => !item.disabled && (
            item.onClick ? (
              <button
                className={`dropdown-item ${buttonStyle}`}
                type={item.id}
                id={item.id}
                title={item.title}
                key={item.id}
                tabIndex="-1"
                onKeyDown={handleKeyDown}
                onClick={item.onClick}
              >
                {item.title}
              </button>
            ) : (
              <a
                id={item.id}
                key={item.id}
                className={`dropdown-item ${buttonStyle}`}
                tabIndex="-1"
                href={item.href}
                title={item.title}
                onKeyDown={handleKeyDown}
              >
                {item.title}
              </a>
            ))
          )}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

DropdownButton.propTypes = {
  options: PropTypes.array,
  mainOption: PropTypes.object,
  darkTheme: PropTypes.bool,
  buttonSize: PropTypes.string,
  disabled: PropTypes.bool
};

DropdownButton.defaultProps = {
  options: [],
  darkTheme: false,
  buttonSize: "lg",
  disabled: false
};

export default DropdownButton;
