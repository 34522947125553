import "./CollectionsPreview.scss";

import React, { useEffect, useRef, useState } from "react";
import {
  getCollectionAttributesValue,
  getCollectionDetail,
  getCollectionObject,
  getCollectionPermissions,
  getCollectionSchema,
  getCollectionThumbnail
} from "@app/services/collectionServices";

import AttributesPreview from "@app/pages/Directories/AttributesPreview";
import CollectionsTable from "@app/components/Table/CollectionsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Preview from "@app/components/Preview/Preview";
import PropTypes from "prop-types";
import axiosSession from "@app/config/axiosSession";
import isEmpty from "lodash/isEmpty";

const CollectionsPreview = ({ id }) => {
  const [rows, setRows] = useState([]);
  const [files, setFiles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const isMounted = useRef(null);
  const [collections, setCollections] = useState([]);

  const [languagesSchema, setLanguagesSchema] = useState([]);
  const [attributesSchema, setAttributesSchema] = useState([]);
  const [attributes, setAttributes] = useState({});
  const [isAttributesVisible, setIsAttributesVisible] = useState(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchCollectionData();
      await fetchThumbnailData();
      await fetchPermissionData();
      await fetchCollectionObject();
    };
    if (isMounted.current && id) {
      fetchData();
    }
  }, [id]);

  const fetchCollectionData = async () => {
    const [collection] = await axiosSession.all([getCollectionDetail(id)]);
    const data = collection.data;
    setRows([
      { title: "Biblioteka", value: [data.library_name] },
      { title: "Kolekcja nadrzędna", value: [data.parent_name] },
      { title: "Nazwa wewnętrzna kolekcji", value: [data.internal_name] },
      {
        title: "Tłumaczenie",
        value: data.translations_names.map(
          el => `${el.language} - ${el.content}`
        )
      },
      { title: "Schemat opisowy", value: [data.schema_name] },
      { title: "Identyfikator OAI-PMH", value: [data.oai_pmh_identifier] },
      { title: "Opis OAI-PMH", value: [data.oai_pmh_description] }
    ]);
    setCanEdit(data.can_edit);
  };
  const fetchThumbnailData = async () => {
    const [thumbnail] = await axiosSession.all([getCollectionThumbnail(id)]);
    setFiles(thumbnail.data.results);
  };
  useEffect(() => {
    const fetchData = async () => {
      const [schema, values] = await axiosSession.all([
        getCollectionSchema(id),
        getCollectionAttributesValue(id)
      ]);
      setLanguagesSchema(schema.data.languages);
      setAttributesSchema(schema.data.attributes);
      setAttributes(values.data);
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  const fetchPermissionData = async () => {
    const [permissions] = await axiosSession.all([
      getCollectionPermissions(id)
    ]);
    setPermissions(permissions.data);
  };

  const fetchCollectionObject = async () => {
    const collection = await getCollectionObject(id);
    setCollections(collection.data.objects);
  };

  const onPreview = path => {
    window.open(path, "_blank").focus();
  };

  useEffect(() => {
    if (!isEmpty(attributes)) {
      for (let key in attributes) {
        if (!isEmpty(attributes[key])) {
          setIsAttributesVisible(true);
        }
      }
    }
  }, [attributes]);

  return (
    <section className="collections-preview">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/collections/"
            title="Kolekcje"
          >
            Kolekcje
          </a>
          &nbsp;/&nbsp;<strong>Podgląd</strong>
        </div>

        <div className="collections-preview__header">
          <h2 className="collections-preview__header__title">
            Podgląd kolekcji
          </h2>
          <hr className="collections-preview__header__hr" />
        </div>

        {/*key for Safari render problem MBC-941*/}
        {rows && <Preview key={new Date()} rows={rows} />}

        {files.length > 0 && (
          <>
            <h2 className="collections-preview__header__title collections-preview__padding">
              Pliki
            </h2>
            <div className="container">
              <div className="columns is-1">
                <div className="column is-3 is-uppercase">Pliki:</div>
                <div className="column has-text-weight-bold">
                  {files.map((file, idx) => (
                    <p key={idx}>{file.name}</p>
                  ))}
                </div>
                <div className="column has-text-weight-bold">
                  {files.map((file, idx) => (
                    <button
                      key={idx}
                      onClick={() => onPreview(file.path)}
                      className="collections-preview__button"
                    >
                      Zobacz
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}

        {isAttributesVisible && (
          <AttributesPreview
            languagesSchema={languagesSchema}
            attributesSchema={attributesSchema}
            attributes={attributes}
          />
        )}

        {collections.length > 0 && (
          <CollectionsTable table={collections} id={id} />
        )}

        {permissions.length > 0 && (
          <>
            <h2 className="collections-preview__header__title collections-preview__padding">
              Uprawnienia
            </h2>
            <div className="columns">
              <div className="column is-three-fifths">
                <table className="collections-preview__table">
                  <thead>
                    <tr className="collections-preview--has-white-background">
                      <th className="collections-preview--has-right-border">
                        Grupy
                      </th>
                      <th className="collections-preview--has-text-centered">
                        Odczyt
                      </th>
                      <th className="collections-preview--has-text-centered">
                        Modyfikacja
                      </th>
                      <th className="collections-preview--has-text-centered">
                        Obiekty
                      </th>
                      <th className="collections-preview--has-text-centered">
                        Publikacja
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {permissions.map(group => (
                      <tr key={group.id}>
                        <td className="collections-preview--has-right-border collections-preview--has-padding-left">
                          {group.group_name}
                        </td>
                        <td className="collections-preview--has-text-centered">
                          {group.permissions.find(
                            e => e.name == "view_content_collection"
                          ).value && <FontAwesomeIcon icon="check" />}
                        </td>
                        <td className="collections-preview--has-text-centered">
                          {group.permissions.find(
                            e => e.name == "change_collection"
                          ).value && <FontAwesomeIcon icon="check" />}
                        </td>
                        <td className="collections-preview--has-text-centered">
                          {group.permissions.find(
                            e => e.name == "manage_objects_collection"
                          ).value && <FontAwesomeIcon icon="check" />}
                        </td>
                        <td className="collections-preview--has-text-centered">
                          {group.permissions.find(
                            e => e.name == "publish_collection"
                          ).value && <FontAwesomeIcon icon="check" />}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        <div className="collections-preview__bottom">
          <a
            className="collections-preview__controls is-uppercase has-text-weight-bold"
            href="/collections/"
          >
            &lt; Wróć do listy kolekcji
          </a>
          {canEdit && (
            <a
              href={`/collections/${id}/edit`}
              className="collections-preview__controls is-uppercase has-text-weight-bold"
            >
              Edytuj
            </a>
          )}
        </div>
      </div>
    </section>
  );
};

CollectionsPreview.propTypes = {
  id: PropTypes.number
};

export default CollectionsPreview;
