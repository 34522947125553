import React, { useState } from "react";
import {
  faEye,
  faMinusCircle,
  faPen,
  faUndoAlt
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericModal from "@app/components/Modal/GenericModal";
import GenericTable from "@app/components/Table/GenericTable";
import axiosSession from "@app/config/axiosSession";
import useGenericToastify from "@app/hooks/useGenericToastify";

const tableStructure = [
  {
    header: "Imię",
    field: "first_name",
    accessor: "first_name",
    className: "w-2",
    sort: true
  },
  {
    header: "Nazwisko",
    field: "last_name",
    accessor: "last_name",
    className: "w-2",
    sort: true
  },
  {
    header: "Login",
    field: "username",
    accessor: "username",
    className: "w-2",
    sort: true
  },
  {
    header: "Typ rejestracji",
    field: "log_by",
    accessor: "log_by",
    className: "w-2"
  },
  {
    header: "Status",
    field: "status",
    accessor: "status",
    className: "w-2",
    sort: true
  },
  {
    header: "Akcje",
    className: "w-2 has-text-right"
  }
];

const urls = {
  get: "/api/users/external-users/",
  home: "/admin-panel/home/",
  edit: "/users/external-users/"
};

const ExternalUsers = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [externalUserId, setExternalUserId] = useState(null);
  const [shouldTableRefresh, setShouldTableRefresh] = useState(false);
  const [operationType, setOperationType] = useState(null);
  const { notifySuccess, notifyError } = useGenericToastify();

  const successMessage = `Użytkownik został poprawnie ${
    operationType ? "aktywowany" : "zablokowany"
  }.`;
  const errorMessage = `Wystąpił problem podczas próby ${
    operationType ? "aktywowania" : "zablokowania"
  } użytkownika.`;

  const handleOpenModal = (id, operationType) => {
    setExternalUserId(id);
    setOperationType(operationType);
    setModalMessage({
      headerTitle: `${
        operationType ? "Aktywowanie" : "Zablokowania"
      } użytkownika`,
      bodyContent: `Uwaga, spowoduje to ${
        operationType ? "aktywowanie" : "zablokowania"
      } wybranego użytkownika zawnętrznego. Czy na pewno chcesz kontynuować?`,
      btnDeleteNo: "Nie",
      btnDeleteYes: "Tak"
    });
    setModalIsOpen(true);
  };

  const blockUser = id => {
    axiosSession
      .put(`${urls.get}${id}/active/`, { is_active: operationType })
      .then(() => {
        notifySuccess(successMessage);
        setExternalUserId(null);
        setOperationType(null);
        setModalIsOpen(false);
        setShouldTableRefresh(true);
      })
      .catch(err => {
        notifyError(errorMessage);
        console.error(err);
      })
      .finally(() => setShouldTableRefresh(false));
  };

  const onEdit = id => {
    window.location = `${urls.edit}${id}/edit/`;
  };

  const customActions = item => {
    return (
      <React.Fragment>
        <td className="has-text-right">
          {item.actions.viewable && (
          <a
            href={`${item.id}/preview/`}
            role="button"
            tabIndex="0"
            className="generic-table__table__link"
            title="Podgląd"
          >
            <FontAwesomeIcon icon={faEye} />
          </a>
          )}
          {item.actions.editable && (item.actions.blockable ? (
            <span
              onClick={() => handleOpenModal(item.id, false)}
              onKeyPress={() => handleOpenModal(item.id, false)}
              role="button"
              tabIndex="0"
              className="generic-table__table__link"
              title="Zablokuj"
            >
              <FontAwesomeIcon icon={faMinusCircle} />
            </span>
          ) : (
            <span
              onClick={() => handleOpenModal(item.id, true)}
              onKeyPress={() => handleOpenModal(item.id, true)}
              role="button"
              tabIndex="0"
              className="generic-table__table__link"
              title="Aktywuj"
            >
              <FontAwesomeIcon icon={faUndoAlt} />
            </span>
          ))}
          {item.actions.editable && (
            <span
              onClick={() => onEdit(item.id)}
              onKeyPress={() => onEdit(item.id)}
              role="button"
              tabIndex="0"
              className="generic-table__table__link"
              title="Edycja"
            >
              <FontAwesomeIcon icon={faPen} />
            </span>
          )}
        </td>
      </React.Fragment>
    );
  };

  return (
    <section className="mbc-backoffice">
      <GenericModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        labels={modalMessage}
        actionSubmit={() => blockUser(externalUserId)}
        editable
      />
      <div className="container">
        <div className="breadcrumbs">
          <a
            href={urls.home}
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Użytkownicy zewnętrzni</strong>
        </div>

        <GenericTable
          customActions={customActions}
          notification={messages}
          structure={tableStructure}
          urls={urls}
          hasHeaderActions={false}
          searchField="search_field"
          shouldRefresh={shouldTableRefresh}
          setShouldRefresh={setShouldTableRefresh}
        />
      </div>
    </section>
  );
};

export default ExternalUsers;
