import "./Rules.scss";

import React, { useEffect, useState } from 'react';

import Preview from "@app/components/Preview/Preview";
import axiosSession from "@app/config/axiosSession";
import useGenericToastify from "@app/hooks/useGenericToastify";

const AgreementsPreview = ({ id }) => {
  const [rows, setRows] = useState([]);
  const { notifyError } = useGenericToastify();

  useEffect(() => {
    const fetchData = async () => {
      await axiosSession(`/api/rules/agreements/${id}/`)
        .then(({ data }) => {
          const {
            short_content,
            status,
            created,
            modified,
            content,
          } = data;
          setRows([
            { title: "Nazwa", value: [short_content] },
            { title: "Status", value: [status] },
            { title: "Data utworzenia", value: [created] },
            { title: "Data modyfikacji", value: [modified] },
            { title: "Treść", value: [content], isHTML: true },
          ]);
        })
        .catch(() => {
          notifyError("Spróbuj ponownie");
        })
    }
    fetchData()
  }, [id]);

  return (
    <section className="directories-preview">
      <div className="container">
        <div className="breadcrumbs">
          <a
            className="breadcrumbs__link"
            title="Strona główna"
            href="/admin-panel/home/"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/rules/agreements/"
            title="Regulaminy"
          >
            Regulaminy
          </a>
          &nbsp;/&nbsp;
            <strong>Zgody</strong>
        </div>

        <div className="directories-preview__header">
          <h2 className="directories-preview__header__title">Podgląd zgody</h2>
          <hr className="directories-preview__header__hr"></hr>
        </div>

        <div className="step">{rows && <Preview rows={rows} />}</div>

        <div className="directories-preview--bottom">
          <a
            className="directories-preview--goback is-uppercase has-text-weight-bold"
            href="/rules/agreements/"
          >
            &lt; Wróć do listy
          </a>
        </div>

      </div>
    </section>
  );
};

export default AgreementsPreview;
