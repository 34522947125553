import "./Rules.scss";

import React, { useState } from "react";

import CKEditor from "ckeditor4-react";
import CancelButton from "@components/CancelButton/CancelButton";
import Input from "@components/Input/Input";
import axiosSession from "@app/config/axiosSession";
import ckEditorConfig from "@app/config/ckEditor";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

const AgreementsCreate = () => {
  const { handleSubmit, register, errors } = useForm();
  const { notifyError } = useGenericToastify();
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [submitType, setSubmitType] = useState("");
  const [contentError, setContentError] = useState(null);

  const returnLocation = "/cancel?url=/rules/agreements/";

  const onSubmit = data => {
    setIsLoading(true);

    let errorMessage = "";

    if (submitType === "preview") {
      data.active = false;
      errorMessage = "Wystąpił błąd podczas zapisywania wersji roboczej zgody";
    } else {
      data.active = true;
      errorMessage = "Wystąpił błąd podczas publikowania zgody";
    }
    data.content = content;
    axiosSession
      .post("/api/rules/agreements/", data)
      .then(response => {
        window.location.href = response.data.url;
      })
      .catch(error => {
        notifyError(errorMessage);
        if (error.response.data.content) {
          setContentError(error.response.data.content);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section className="rules-form">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/rules/agreements/"
            title="Zgody"
          >
            Zgody
          </a>
          &nbsp;/&nbsp;
          <strong>Dodawanie zgody</strong>
        </div>

        <div>
          <p className={"titleHeader is-size-4 has-text-black"}>
            Dodawanie zgody
          </p>
          <hr className="hr hr--orange hr--margin-top" />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="columns is-variable">
            <div className="column is-5">
              <Input
                name={"short_content"}
                label={"Skrócona treść zgody"}
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
              <div className="column"></div>
            </div>
          </div>
          <div className="columns is-centered is-variable is-12">
            <div className="column">
              <label htmlFor="content" className="label">
                Treść *
              </label>
              <CKEditor
                config={ckEditorConfig}
                onBeforeLoad={CKEDITOR => {
                  CKEDITOR.disableAutoInline = true;
                }}
                label="Treść"
                name="content"
                data={content}
                onChange={evt => setContent(evt.editor.getData())}
              />
              {contentError ? (
                <p className="help is-danger">{contentError}</p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div>
            <div className="buttons is-right">
              <CancelButton
                returnLocation={returnLocation}
                bodyContentSufix="dodanie nowej zgody?"
              />
              <button
                onClick={() => {
                  setSubmitType("preview");
                  return true;
                }}
                className={`button ${isLoading ? "is-loading" : ""}`}
                type="submit"
              >
                Zapisz szkic
              </button>
              <button
                onClick={() => {
                  setSubmitType("publish");
                  return true;
                }}
                className={`button button--is-orange ${
                  isLoading ? "is-loading" : ""
                }`}
                type="submit"
              >
                Opublikuj
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default AgreementsCreate;
