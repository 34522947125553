import "./OcrPanel.scss";

import React, { useState } from "react";
import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericInput from "@app/components/Input/GenericInput";
import GenericTable from "@components/Table/GenericTable";
import { Input } from "antd";
import Modal from "react-responsive-modal";
import TruncateMarkup from "react-truncate-markup";
import axiosSession from "@app/config/axiosSession";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

const { TextArea } = Input;

const OcrPanel = () => {
  const { errors, control, handleSubmit } = useForm();
  const { notifySuccess, notifyError } = useGenericToastify();
  const [displayModal, setDisplayModal] = useState(false);
  const [editableItem, setEditableItem] = useState(null);
  const [originalCorrection, setOriginalCorrection] = useState("");
  const [editAction, setEditAction] = useState(true);
  const [shouldTableRefresh, setShouldTableRefresh] = useState(false);
  const [rejectModalIsOpen, setRejectModalIsOpen] = useState(false);

  const filters = [
    {
      id: 1,
      name: "Oczekująca"
    },
    {
      id: 2,
      name: "Zaakceptowana"
    },
    {
      id: 3,
      name: "Poprawiona przez administratora"
    },
    {
      id: 4,
      name: "Odrzucona"
    }
  ];

  const urls = {
    get: "/api/digital_items/annotation-correction/"
  };

  const tableStructure = [
    {
      header: "Data",
      accessor: "created",
      sort: true,
      className: "w-2"
    },
    {
      header: "Zasób",
      accessor: "digital_item_name",
      className: "w-2"
    },
    {
      header: "Email",
      accessor: "user_email",
      className: "w-2"
    },
    {
      header: "Aktualna treść",
      accessor: "text_before",
      className: "w-2"
    },
    {
      header: "Treść po korekcie",
      accessor: "correction",
      className: "w-2"
    },
    {
      header: "Status",
      accessor: "status_name",
      className: "w-1"
    },
    {
      header: "Akcje",
      className: "w-1 has-text-right"
    }
  ];

  const submit = (status, content) => {
    setEditableItem(editableItem => ({
      ...editableItem,
      status
    }));

    axiosSession
      .put(`${urls.get}${editableItem.id}/`, {
        status,
        content,
        item: editableItem
      })
      .then(({ data }) => {
        setRejectModalIsOpen(false);
        setDisplayModal(false);
        notifySuccess(data);
        setShouldTableRefresh(true);
      })
      .catch(error => {
        console.error(error);
        notifyError("Wystąpił błąd podczas edycji OCR");
      })
      .finally(() => {
        setShouldTableRefresh(false);
      });
  };

  const onAction = (item, edit) => {
    setEditAction(edit);
    setEditableItem(item);
    setOriginalCorrection(item.correction);
    setDisplayModal(true);
  };

  const onInputChange = ({ target: { value } }) => {
    setEditableItem(editableItem => ({
      ...editableItem,
      correction: value
    }));
  };

  const customRow = item => {
    return (
      <React.Fragment key={item.id}>
        <tr id={`row-${item.id}`}>
          <td>
            <TruncateMarkup>
              <span title={item.created}>{item.created} &nbsp;</span>
            </TruncateMarkup>
          </td>
          <td>
            <TruncateMarkup>
              <span title={item.digital_item_name}>
                {item.digital_item_name} &nbsp;
              </span>
            </TruncateMarkup>
          </td>
          <td>
            <TruncateMarkup>
              <span title={item.user_email}>{item.user_email} &nbsp;</span>
            </TruncateMarkup>
          </td>
          <td>
            <TruncateMarkup>
              <span title={item.text_before}>{item.text_before} &nbsp;</span>
            </TruncateMarkup>
          </td>
          <td>
            <TruncateMarkup>
              <span title={item.correction}>{item.correction} &nbsp;</span>
            </TruncateMarkup>
          </td>
          <td>
            <TruncateMarkup>
              <span title={item.status_name}>{item.status_name}</span>
            </TruncateMarkup>
          </td>
          <td className="has-text-right">
            {item.actions.editable && (
              <span
                onClick={() => onAction(item, true)}
                onKeyPress={() => onAction(item, true)}
                role="button"
                tabIndex="0"
                className="generic-table__table__link"
                title="Edycja"
              >
                <FontAwesomeIcon icon={faPen} />
              </span>
            )}
            {item.actions.viewable && (
              <span
                onClick={() => onAction(item, false)}
                onKeyPress={() => onAction(item, false)}
                role="button"
                tabIndex="0"
                className="generic-table__table__link"
                title="Podgląd"
              >
                <FontAwesomeIcon icon={faEye} />
              </span>
            )}
          </td>
        </tr>
      </React.Fragment>
    );
  };

  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Zgłoszenia OCR</strong>
        </div>

        <div className="mbc-backoffice__header">
          <h2 className="mbc-backoffice__header__title">Zgłoszenia OCR</h2>
          <hr className="mbc-backoffice__header__hr" />
        </div>

        <Modal
          open={displayModal}
          onClose={() => setDisplayModal(false)}
          classNames={{
            modal: "GenericModal "
          }}
        >
          <div className="GenericModal__Header--with-border">
            <p className="GenericModal__Header__Title">
              {editAction ? "Edycja" : "Podgląd"}
            </p>
          </div>

          <div className="GenericModal__Body">
            {editableItem && (
              <div className="ocr-panel__GenericModal__Body__container">
                <table className="ocr-panel__GenericModal__Body__Table">
                  <tbody>
                    <tr>
                      <td className="is-uppercase ocr-panel__GenericModal__Body__Table--header">
                        Data, godzina:
                      </td>
                      <td>{editableItem.created}</td>
                    </tr>
                    <tr>
                      <td className="is-uppercase ocr-panel__GenericModal__Body__Table--header">
                        Zasób
                      </td>
                      <td>{editableItem.digital_item_name}</td>
                    </tr>
                    <tr>
                      <td className="is-uppercase ocr-panel__GenericModal__Body__Table--header">
                        E-mail
                      </td>
                      <td>{editableItem.user_email}</td>
                    </tr>
                    <tr>
                      <td className="is-uppercase ocr-panel__GenericModal__Body__Table--header">
                        Obszar przed korektą
                      </td>
                      <td>
                        <img
                          alt={`obszar-przed-korektą-${editableItem.digital_item_name}`}
                          className="ocr-panel__GenericModal__Body__Table--photo"
                          src={editableItem.image_before}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="is-uppercase ocr-panel__GenericModal__Body__Table--header">
                        Obszar po korekcie
                      </td>
                      <td>
                        <img
                          alt={`obszar-po-korekcie-${editableItem.digital_item_name}`}
                          className="ocr-panel__GenericModal__Body__Table--photo"
                          src={editableItem.image_after}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="is-uppercase ocr-panel__GenericModal__Body__Table--header">
                        Treść przed korektą
                      </td>
                      <td>{editableItem.old_content}</td>
                    </tr>
                    <tr>
                      <td
                        className={`is-uppercase ocr-panel__GenericModal__Body__Table--header ${
                          editAction ? "" : "ocr-panel--padding"
                        }`}
                      >
                        Treść po korekcie
                      </td>
                      <td
                        className={`${editAction ? "" : "ocr-panel--padding"}`}
                      >
                        {editAction ? (
                          <TextArea
                            autoSize={true}
                            defaultValue={editableItem.correction}
                            onChange={onInputChange}
                          />
                        ) : (
                          editableItem.correction
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <div className="GenericModal__Footer__Buttons buttons is-right is-fullwidth">
            {editAction ? (
              <>
                <button
                  className="button button--left GenericModal__Footer__Button "
                  onClick={() => setDisplayModal(false)}
                >
                  Anuluj
                </button>
                <button
                  className="button button--is-orange GenericModal__Footer__Button "
                  onClick={() => setRejectModalIsOpen(true)}
                >
                  Odrzuć
                </button>
                <button
                  className="button button--is-orange GenericModal__Footer__Button "
                  onClick={() => {
                    submit(
                      editableItem.correction === originalCorrection
                        ? "accepted"
                        : "corrected"
                    );
                  }}
                >
                  Zatwierdź
                </button>
              </>
            ) : (
              <button
                className="button  GenericModal__Footer__Button "
                onClick={() => setDisplayModal(false)}
              >
                Zamknij
              </button>
            )}
          </div>
        </Modal>

        <Modal
          open={rejectModalIsOpen}
          onClose={() => setRejectModalIsOpen(false)}
          center
          classNames={{
            modal: "GenericModal"
          }}
          focusTrapped={true}
          showCloseIcon={false}
        >
          <div className="GenericModal__Header">
            <p className="GenericModal__Header__Title">
              Odrzucenie zgłoszenia OCR
            </p>
          </div>
          <div className="GenericModal__Body">
            <GenericInput
              label="Powód odrzucenia"
              placeholder=""
              name="content"
              darkTheme={false}
              control={control}
              tag="textarea"
              required={{ required: "To pole jest wymagane" }}
              errors={errors}
            />
          </div>
          <div className="GenericModal__Footer">
            <p className="GenericModal__Footer__Buttons buttons is-right is-fullwidth">
              <button
                className="button GenericModal__Footer__Button"
                onClick={() => setRejectModalIsOpen(false)}
              >
                Anuluj
              </button>
              <button
                className="button button--is-orange GenericModal__Footer__Button"
                onClick={handleSubmit(({ content }) => {
                  submit("rejected", content);
                })}
              >
                Odrzuć
              </button>
            </p>
          </div>
        </Modal>

        <GenericTable
          structure={tableStructure}
          urls={urls}
          hasHeaderActions={false}
          customRowWithActions={customRow}
          styleClassNames="generic-table__table table"
          filtersOptions={filters}
          filterLabel="Status"
          inlineFilters={true}
          filtersValues="filter"
          defaultTableFilters={[1, 2, 3, 4]}
          hasFilters={true}
          shouldRefresh={shouldTableRefresh}
        />
      </div>
    </section>
  );
};

export default OcrPanel;
