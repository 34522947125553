import React, { useEffect, useState } from "react";

import CancelButton from "@components/CancelButton/CancelButton";
import Input from "@components/Input/Input";
import axiosSession from "@app/config/axiosSession";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

const AdminForm = ({ id }) => {
  const { handleSubmit, register, errors, reset, setError } = useForm();
  const { notifyError } = useGenericToastify();
  const [isLoading, setIsLoading] = useState(false);
  const [inputError, setInputError] = useState("");

  const returnLocation = "/cancel?url=/users/global_admins/";

  const resetErrors = () => {
    setInputError(false);
  };

  useEffect(() => {
    if (id) {
      axiosSession
        .get(`/api/users/global_admins/${id}/`)
        .then(res => {
          reset(res.data);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [id, reset]);

  useEffect(() => {
    !isEmpty(errors)
      ? notifyError("Nie udało się zapisać administratora.")
      : null;
  }, [errors, notifyError]);

  const onSubmit = data => {
    setIsLoading(true);

    let method = "";
    let url = "";

    resetErrors();

    if (id) {
      method = "PUT";
      url = `/api/users/global_admins/${id}/`;
    } else {
      method = "POST";
      url = "/api/users/global_admins/create/";
    }

    axiosSession({ method: method, url: url, data: data })
      .then(response => {
        window.location.href = response.data.url;
      })
      .catch(error => {
        notifyError();
        if (error.response.data) {
          if (error.response.data.username) {
            setError("username", "", error.response.data.username);
          }

          if (error.response.data.first_name) {
            setError("first_name", "", error.response.data.first_name);
          }

          if (error.response.data.last_name) {
            setError("last_name", "", error.response.data.last_name);
          }

          if (error.response.data.email) {
            setError("email", "", error.response.data.email);
          }
        }

        if (error.response.data.non_field_errors) {
          setInputError(error.response.data.non_field_errors[0]);
        }
        notifyError("Nie udało się zapisać administratora.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/users/global_admins"
            title="Administratorzy"
          >
            Administratorzy globalni
          </a>
          &nbsp;/&nbsp;
          <strong>{id ? "Edytuj" : "Dodaj"} administratora</strong>
        </div>

        <div className="mbc-backoffice__header">
          <h2 className="mbc-backoffice__header__title">
            {id ? "Edycja" : "Dodawanie"} administratora
          </h2>
          <hr className="mbc-backoffice__header__hr" />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="columns is-centered is-variable is-8">
            <div className="column">
              <Input
                disabled={!!id}
                name="username"
                label="Nazwa użytkownika (LOGIN)"
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
              <Input
                name="first_name"
                label="Imię"
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
              <Input
                name="last_name"
                label="Nazwisko"
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
              <Input
                name="email"
                label="Adres E-MAIL"
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
            </div>
            <div className="column"></div>
          </div>
          <div>
            {inputError ? (
              <div className="level is-danger is-size-6">
                <div className="level-left section-danger has-text-danger">
                  <span className="level-item icon">
                    <i className="fas fa-exclamation-triangle" />
                  </span>
                  {inputError}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="buttons">
              <CancelButton
                returnLocation={returnLocation}
                bodyContentSufix={`${
                  id ? "edycję" : "dodanie nowego"
                } administratora globalnego?`}
              />
              <button
                className={`button button--is-orange ${
                  isLoading ? "is-loading" : ""
                }`}
                type="submit"
              >
                Zapisz
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default AdminForm;
