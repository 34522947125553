import "@app/scss/variables.scss";

import React, { useState } from "react";

import GenericModal from "@components/Modal/GenericModal";

const CancelButton = ({
  returnLocation,
  bodyContentSufix
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const messages = {
    headerTitle: "Anuluj",
    bodyContent: `Klikając przycisk anuluj sprawiasz, że dane nie zostaną zapisane. Czy na pewno chcesz anulować ${bodyContentSufix}`,
    btnDeleteNo: "Nie",
    btnDeleteYes: "Tak"
  };

  const onConfirm = () => {
    window.location = returnLocation;
    setModalIsOpen(false);
  };

  return (
    <>
      <GenericModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        labels={messages}
        actionSubmit={onConfirm}
        actionCancel={() => setModalIsOpen(false)}
      />
      <button
        className="button"
        onClick={() => setModalIsOpen(true)}
        title="Anuluj i wróć do listy"
        type="button"
      >
        Anuluj
      </button>
    </>
  );
};

export default CancelButton;
