import "./Radio.scss";

import PropTypes from "prop-types";
import Radio from "./Radio";
import React from "react";

const GenericRadioGroup = ({
  register,
  name,
  groupLabel,
  radiosText,
  handleRadioChange,
  valueSelected,
  disabled
}) => {
  return (
    <div className="field">
      <label htmlFor={name} className="label has-text-weight-bold">
        {groupLabel}
      </label>
      <div className="control mbc-radio">
        <Radio
          name={name}
          value={true}
          register={register}
          radioText={radiosText.yes}
          onChange={handleRadioChange}
          checked={valueSelected}
          disabled={disabled}
        />
        <Radio
          name={name}
          value={false}
          register={register}
          radioText={radiosText.no}
          onChange={handleRadioChange}
          checked={!valueSelected}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

GenericRadioGroup.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
  register: PropTypes.func,
  groupLabel: PropTypes.string,
  disabled: PropTypes.bool
};

GenericRadioGroup.defaultProps = {
  radiosText: {
    yes: "TAK",
    no: "NIE"
  },
  disabled: false
};

export default GenericRadioGroup;
