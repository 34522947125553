import React, { useEffect, useState } from "react";

import { format, isFuture, parse } from "date-fns";
import CKEditor from "ckeditor4-react";
import CancelButton from "@components/CancelButton/CancelButton";
import GenericDatePicker from "@components/GenericDatePicker/GenericDatePicker";
import GenericInput from "@app/components/Input/GenericInput";
import GenericSelect from "@components/Select/GenericSelect";
import Input from "@components/Input/Input";
import axiosSession from "@app/config/axiosSession";
import ckEditorConfig from "@app/config/ckEditor";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { zonedTimeToUtc } from "date-fns-tz";

const minDate = new Date();

const MessagesAdd = ({ id }) => {
  const {
    handleSubmit,
    register,
    control,
    errors,
    setValue,
    getValues
  } = useForm();
  const { notifyError } = useGenericToastify();
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState("");
  const [groups, setGroups] = useState([]);
  const [libraries, setLibraries] = useState([]);

  const isEdit = id !== "";

  const urls = {
    getLibraries: "/api/library/",
    getGroups: "/api/users/groups/libraries/",
    getDefaultData: `/api/notifications/${id}/`,
    postMessage: `/api/notifications/${isEdit ? `${id}/` : ""}`,
    home: "/admin-panel/home/",
    messagesList: "/messages/",
    cancel: "/cancel?url=/messages/"
  };

  useEffect(() => {
    getGroups([]);
  }, []);

  useEffect(() => {
    if (isEdit) {
      axiosSession
        .get(urls.getDefaultData)
        .then(({ data }) => {
          const date = data.sending_datetime.split(", ")[0];
          const time = data.sending_datetime.split(", ")[1];
          const libraries = data.libraries.map(item => {
            return { id: item.library__id, name: item.library__name };
          });
          handleGetGroups([libraries]);
          setValue("title", data.title);
          setValue("libraries", libraries);
          setValue("groups", data.groups);
          setValue("date", parse(date, "dd.MM.yyyy", new Date()));
          setValue("time", time);
          setContent(data.content);
        })
        .catch(error => {
          notifyError("Pobieranie danych nie powiodło się");
          console.error(error);
        });
    }
    axiosSession
      .get(urls.getLibraries)
      .then(({ data: { results } }) => {
        setLibraries(results);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const getGroups = libraries => {
    axiosSession
      .post(urls.getGroups, {
        libraries
      })
      .then(({ data }) => {
        setGroups(data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleGetGroups = ([selectedLibraries]) => {
    if (selectedLibraries === null) {
      return setGroups([]);
    }

    const librariesIds = selectedLibraries.map(item => item.id);

    const currentGroups = getValues("groups");

    if (currentGroups !== null) {
      const adminId = groups.find(item => item.name === "Admin")?.id;
      const userId = groups.find(item => item.name === "User")?.id;

      const allowedGroups = selectedLibraries
        .reduce((acc, item) => acc.concat(item.groups), [])
        .concat(adminId, userId);

      const filteredGroups = currentGroups.filter(group =>
        allowedGroups.includes(group.id)
      );

      setValue("groups", filteredGroups);
    }

    getGroups(librariesIds);

    return selectedLibraries;
  };

  const foldDate = (date, time) => {
    const foldedDate = new Date(date);
    const splitTime = time.split(":");
    foldedDate.setHours(parseInt(splitTime[0]));
    foldedDate.setMinutes(parseInt(splitTime[1]));
    return foldedDate;
  }

  const onSubmit = data => {
    setIsLoading(true);

    const convertedData = { ...data };

    const sending_datetime = foldDate(data.date, data.time)
    if(!isFuture(sending_datetime)){
      setIsLoading(false);
      return notifyError("Godzina wysyłki komunikatu nie może być ustawiona na przeszłość.");
    }
    convertedData.sending_datetime = zonedTimeToUtc(sending_datetime, "time-zone");

    convertedData.groups = convertedData.groups.map(item => item.id);

    convertedData.content = content;

    axiosSession({
      method: isEdit ? "PUT" : "POST",
      url: urls.postMessage,
      data: convertedData
    })
      .then(() => {
        window.location = "/messages/";
      })
      .catch(error => {
        notifyError("Nie udało się zapisać komunikatu.");
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href={urls.home}
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href={urls.messagesList}
            title="Komunikaty"
          >
            Komunikaty
          </a>
          &nbsp;/&nbsp;
          <strong>
            {isEdit ? "Edycja komunikatu" : "Dodawanie komunikatu"}
          </strong>
        </div>

        <div className="mbc-backoffice__header">
          <p className="mbc-backoffice__header__title">
            {isEdit ? "Edycja komunikatu" : "Dodawanie komunikatu"}
          </p>
          <hr className="mbc-backoffice__header__hr" />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="columns is-variable">
            <div className="column is-5">
              <Input
                name="title"
                label="Tytuł"
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
              />
              <GenericSelect
                options={libraries}
                placeholder="Wybierz biblioteki"
                control={control}
                required={{ required: false }}
                name="libraries"
                errors={errors}
                label="Biblioteki"
                isMulti
                handleSelectChange={handleGetGroups}
                isClearable={false}
                closeMenuOnSelect={false}
              />
              <GenericSelect
                options={groups}
                placeholder="Wybierz grupy użytkowników"
                control={control}
                required={{ required: "To pole jest wymagane!" }}
                name="groups"
                errors={errors}
                label="Grupy użytkowników"
                isMulti
                isClearable={false}
                closeMenuOnSelect={false}
              />
              <GenericDatePicker
                name="date"
                dateFormat="dd.MM.yyyy"
                label="Data wysłania"
                errors={errors}
                control={control}
                onBlur
                required={{ required: "To pole jest wymagane!" }}
                minDate={minDate}
              />
              <GenericInput
                name="time"
                label="Godzina wysłania"
                type="time"
                errors={errors}
                control={control}
                required={{ required: "To pole jest wymagane!" }}
                defaultValue={format(new Date(), 'HH:mm')}
              />
              <div className="column"></div>
            </div>
          </div>
          <div className="columns is-centered is-variable is-12">
            <div className="column">
              <label htmlFor="content" className="label">
                Treść
              </label>
              <CKEditor
                config={ckEditorConfig}
                onBeforeLoad={CKEDITOR => {
                  CKEDITOR.disableAutoInline = true;
                }}
                label="Treść"
                name="content"
                data={content}
                onChange={evt => setContent(evt.editor.getData())}
              />
            </div>
          </div>
          <div>
            <div className="buttons is-right">
              <CancelButton
                returnLocation={urls.cancel}
                bodyContentSufix={`${
                  id ? "edycję" : "dodanie nowego"
                } komunikatu?`}
              />
              <button
                className={`button button--is-orange ${
                  isLoading ? "is-loading" : ""
                }`}
                type="submit"
              >
                Zapisz
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default MessagesAdd;
