import "./DirectoryForm.scss";

import FileUpload from "../../components/FileUpload/FileUpload";
import React from "react";

const DirectoriesFileUpload = ({ id, fileServer, formType }) => {
  const endpoint =
    formType === "digital-item"
      ? `/api/digital_items/${id}/files/`
      : `/api/directories/${id}/thumbnails/`;

  return (
    <section className="directory-form">
      <div className="container">
        <div className="directory-form__header">
          <h2 className="directory-form__header__title">
            Trwa dodawanie plików
          </h2>
          <hr className="directory-form__header__hr" />
          <span>
            W tym oknie występuje wysyłanie plików na serwer. Pozostaw to okno
            otwarte na czas zakończenia wysyłania. Celem dalszej pracy możesz
            powrócić do wcześniejszego okna.
          </span>
        </div>

        <div className={"container"}>
          <FileUpload
            id={id}
            fileServer={fileServer}
            formType={formType}
            fileUploadURL={endpoint}
          />
        </div>
      </div>
    </section>
  );
};

export default DirectoriesFileUpload;
