import "./GenericDatePicker.scss";

import { Controller, ErrorMessage, useForm } from "react-hook-form";

import DatePicker from "react-date-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";

const GenericDatePicker = ({
  name,
  dateFormat,
  label,
  errors,
  required,
  control,
  defaultValue,
  onBlur,
  maxDate,
  minDate,
  checkDate,
  disabled
}) => {
  const { watch, setValue } = useForm();
  return (
    <div className="field">
      <label htmlFor={`${name}`} className="label">
        {label}
      </label>
      <div className="control mbc-input">
        <Controller
          as={
            <DatePicker
              locale="pl"
              format={dateFormat}
              disabled={disabled}
              calendarIcon={
                <FontAwesomeIcon
                  icon={["far", "calendar-alt"]}
                  size="2x"
                  color={`${disabled} ? #363636 : #7a7a7a`}
                />
              }
              onBlur={onBlur}
            />
          }
          minDate={minDate}
          maxDate={maxDate}
          defaultValue={defaultValue}
          value={watch(`${name}`)}
          onChange={date => {
            setValue(`${name}`, date);
            checkDate();
            return date;
          }}
          clearIcon={null}
          rules={required}
          name={`${name}`}
          className={
            !isEmpty(errors) && has(errors, name) ? "input is-danger" : "input"
          }
          control={control}
        />
      </div>
      <ErrorMessage
        as={<p className="help is-danger" />}
        errors={errors}
        name={name}
      />
    </div>
  );
};

GenericDatePicker.propTypes = {
  name: PropTypes.string,
  dateFormat: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.object,
  required: PropTypes.object,
  control: PropTypes.object,
  disabled: PropTypes.bool
};

GenericDatePicker.defaultProps = {
  dateFormat: "dd.MM.yyyy",
  defaultValue: null,
  maxDate: null,
  minDate: new Date("1299-01-01"),
  checkDate: () => null,
  disabled: false
};

export default GenericDatePicker;
