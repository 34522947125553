import "./DictionariesForm.scss";

import React, { useEffect, useState } from "react";
import {
  getDictionary,
  getSources,
  getTimeLimitedAttributes
} from "@app/services/dictionaryServices";

import CancelButton from "@components/CancelButton/CancelButton";
import DictionariesEntries from "./DictionariesEntries";
import DropdownButton from "@app/components/DropdownButton/DropdownButton";
import GenericInput from "@app/components/Input/GenericInput";
import GenericSelect from "@app/components/Select/GenericSelect";
import RSwitch from "react-switch";
import axiosSession from "@app/config/axiosSession";
import findIndex from "lodash/findIndex";
import handleErrors from "@app/helpers/handleErrors";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

const mbc_light = "#f6f6f6";
const mbc_white = "#000000";
const hiddenUrlNames = ["NUKAT", "LOC"];

const DictionaryForm = ({ id, message, hasCreatePermission }) => {
  const {
    getValues,
    handleSubmit,
    setError,
    errors,
    setValue,
    reset,
    control
  } = useForm();

  const [isOpen, setIsOpen] = useState(false);
  const [isFlat, setIsFlat] = useState(false);
  const [isTimeLimited, setIsTimeLimited] = useState(false);
  const [isExternal, setIsExternal] = useState(false);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [inputErrorIsOpen, setInputErrorIsOpen] = useState(false);
  const [inputErrorIsFlat, setInputErrorIsFlat] = useState(false);
  const [inputErrorIsTimeLimited, setInputErrorIsTimeLimited] = useState(false);
  const [inputErrorAttributes, setInputErrorAttributes] = useState(false);
  const [switchDisabled, setSwitchDisabled] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [goToAddEntry, setGoToAddEntry] = useState(false);
  const { notifySuccess, notifyError } = useGenericToastify();

  const [sources, setSources] = useState([]);
  const [source, setSource] = useState();
  const [searchKey, setSearchKey] = useState([]);
  const [selectedSearchKey, setSelectedSearchKey] = useState();
  const [searchKeyFieldDisable, setSearchKeyFieldDisable] = useState(true);
  const [urlFieldVisible, setUrlFieldVisible] = useState(true);
  const [selectedSourceUrl, setSelectedSourceUrl] = useState("");
  const [dataAttributes, setDataAttributes] = useState([]);

  const returnLocation = "/cancel?url=/dictionaries/";

  useEffect(() => {
    if (!isEmpty(message.messages)) {
      notifySuccess(message.messages[0]);
    }
  }, [message, notifySuccess]);

  useEffect(() => {
    if (urlFieldVisible) {
      const selectedSource = getValues()["source"];
      setValue("source_url", selectedSource?.source_url ?? "");
    }
  }, [urlFieldVisible]);

  const resetErrors = () => {
    setIsError(false);
    setInputErrorAttributes(false);
  };

  const getSearchKeys = async dictionary_source_id => {
    const searchKeys = await axiosSession.get(
      "/api/dicts/sources/keys/select-data/",
      { params: { dictionary_source: dictionary_source_id } }
    );
    setSearchKey(searchKeys.data);
  };

  const callback = data => {
    if (data && data.count) {
      setIsEditable(false);
    } else {
      setIsEditable(true);
    }
  };

  const handleSourceChange = ([selectedOption]) => {
    if (hiddenUrlNames.includes(selectedOption.name)) {
      setUrlFieldVisible(false);
    } else {
      urlFieldVisible
        ? setValue("source_url", selectedOption?.source_url ?? "")
        : setUrlFieldVisible(true);
    }
    setSelectedSourceUrl(selectedOption?.source_url ?? "");
    setValue("search_key", "");
    getSearchKeys(selectedOption.id);
    setSearchKeyFieldDisable(false);
    return selectedOption;
  };

  const buttonIsSelectStyle = isSelect => {
    const style = "button dictionaries-form__button";
    return isSelect
      ? style + " dictionaries-form__button--is-select"
      : style + " dictionaries-form__button--is-not-select";
  };

  const switchActiveText = isActive => {
    return isActive ? "dictionaries-form__switch--active-text" : "";
  };

  const prepareData = data => {
    if (isExternal) {
      return {
        name: data.name,
        is_open: null,
        is_flat: null,
        is_time_limited: null,
        time_limited_attribute: null,
        source: data.source ? data.source.id : null,
        source_url: selectedSourceUrl,
        front_external: isExternal,
        search_key: data.search_key ? data.search_key.id : null
      };
    } else {
      return {
        name: data.name,
        is_open: isOpen,
        is_flat: isFlat,
        is_time_limited: isTimeLimited,
        time_limited_attribute: data.time_limited_attribute
          ? data.time_limited_attribute.id
          : null,
        source: null,
        source_url: null,
        front_external: isExternal,
        search_key: null
      };
    }
  };

  useEffect(() => {
    !isEmpty(errors) ? notifyError("Nie udało się zapisać słownika.") : null;
  }, [errors, notifyError]);

  const onSubmit = data => {
    setIsLoading(true);

    let method = "";
    let url = "";

    let formData = prepareData(data);

    resetErrors();

    if (id) {
      method = "PUT";
      url = `/api/dicts/${id}/`;
    } else {
      method = "POST";
      url = "/api/dicts/";
    }
    axiosSession({ method: method, url: url, data: formData })
      .then(response => {
        setIsLoading(false);
        if (goToAddEntry) {
          location.href = `/dictionaries/${response.data.id}/entries/create/`;
        } else {
          location.href = "/dictionaries/";
        }
      })
      .catch(error => {
        handleErrors(error.response.data, setError);
        notifyError();
        if (error.response.data.is_open) {
          setInputErrorIsOpen(error.response.data.is_open);
        }
        if (error.response.data.is_flat) {
          setInputErrorIsFlat(error.response.data.is_flat);
        }
        if (error.response.data.is_time_limited) {
          setInputErrorIsTimeLimited(error.response.data.is_time_limited);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const fetch = async () => {
      getSources()
        .then(res => {
          setSources(res.data.results);
        })
        .catch(err => {
          console.error(err);
        });

      const timeLimitedAttributes = await getTimeLimitedAttributes();

      setDataAttributes(timeLimitedAttributes.data?.results ?? []);

      if (id) {
        getDictionary(id)
          .then(({ data }) => {
            reset(data);
            if (data.source !== null) {
              setIsExternal(true);
              setSource(data.source);
              getSearchKeys(data.source);
              setSelectedSearchKey(data.search_key);
            } else {
              setIsExternal(false);
              setIsOpen(data.is_open);
              setIsFlat(data.is_flat);
              setIsTimeLimited(data.is_time_limited);
              setSwitchDisabled(true);
            }

            if (data.time_limited_attribute !== null) {
              setValue(
                "time_limited_attribute",
                timeLimitedAttributes.data?.results?.find(
                  x => x.id === data.time_limited_attribute
                )
              );
            }
          })
          .catch(err => {
            console.error(err);
          });
      } else {
        setIsEditable(true);
      }
    };

    fetch();
  }, [id, reset]);

  useEffect(() => {
    if (sources !== undefined) {
      const selection = sources[findIndex(sources, ({ id }) => id === source)];
      if (selection !== undefined) {
        setValue("source", selection);
        if (hiddenUrlNames.includes(selection.name)) {
          setUrlFieldVisible(false);
        }
      }
    }
  }, [setValue, source, sources]);

  useEffect(() => {
    if (searchKey !== undefined) {
      const selection =
        searchKey[findIndex(searchKey, ({ id }) => id === selectedSearchKey)];
      if (selection !== undefined) {
        setValue("search_key", selection);
      }
    }
  }, [setValue, selectedSearchKey, searchKey]);

  return (
    <section className="dictionaries-form">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/dictionaries/"
            title="Słowniki"
          >
            Słowniki
          </a>
          &nbsp;/&nbsp;
          <strong>{id ? "Edytuj" : "Dodaj"} słownik</strong>
        </div>

        <div className="dictionaries-form__header">
          <h2 className="dictionaries-form__header__title">
            {id ? "Edycja" : "Dodawanie"} słownika
          </h2>
          <hr className="dictionaries-form__header__hr" />
        </div>

        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="columns is-centered is-variable is-8">
              <div className="column">
                <GenericInput
                  control={control}
                  name="name"
                  label="Nazwa"
                  errors={errors}
                  required={{ required: "Pole Nazwa jest wymagane" }}
                />
                <button
                  className={
                    "dictionaries-form__button--margin-tb dictionaries-form__button--margin-r " +
                    buttonIsSelectStyle(!isExternal)
                  }
                  onClick={() => setIsExternal(false)}
                  disabled={!isEditable}
                  type="button"
                >
                  WEWNĘTRZNY
                </button>
                <button
                  className={
                    "dictionaries-form__button--margin-tb " +
                    buttonIsSelectStyle(isExternal)
                  }
                  onClick={() => setIsExternal(true)}
                  disabled={!isEditable}
                  type="button"
                >
                  ZEWNĘTRZNY
                </button>
                {isExternal ? (
                  <div>
                    <GenericSelect
                      options={sources}
                      control={control}
                      handleSelectChange={handleSourceChange}
                      required={{
                        required: "Pole Wybór Źródła Danych jest wymagane"
                      }}
                      name="source"
                      errors={errors}
                      label="Wybór źródła danych"
                    />
                    {urlFieldVisible && (
                      <GenericInput
                        control={control}
                        disabled
                        name="source_url"
                        label="Adres URL"
                        errors={errors}
                        required={{
                          required: "Pole adres url jest wymagane",
                          pattern: {
                            value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
                            message: "Podaj poprawny adres url"
                          }
                        }}
                        placeholder="https://"
                      />
                    )}
                    <GenericSelect
                      options={searchKey}
                      control={control}
                      required={{
                        required: "Pole Szukaj po wartości jest wymagane"
                      }}
                      name="search_key"
                      errors={errors}
                      label="Szukaj po wartości"
                      isDisabled={searchKeyFieldDisable}
                    />
                  </div>
                ) : (
                  <>
                    <div>
                      <label className="columns" htmlFor="isOpen">
                        <span className={"column " + switchActiveText(isOpen)}>
                          otwarty
                        </span>
                        <RSwitch
                          checked={!isOpen}
                          disabled={!isEditable}
                          onChange={() => setIsOpen(!isOpen)}
                          onColor={mbc_light}
                          offColor={mbc_light}
                          offHandleColor={mbc_white}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          onHandleColor={mbc_white}
                          handleDiameter={22}
                          height={15}
                          width={34}
                          className="column dictionaries-form__switch--border react-switch is-one-fifth is-paddingless"
                          id="isOpen"
                          name="isOpen"
                        />
                        <span className={"column " + switchActiveText(!isOpen)}>
                          zamknięty
                        </span>
                        {inputErrorIsOpen && (
                          <div className="level is-danger is-size-6">
                            <div className="level-left section-danger has-text-danger">
                              {inputErrorIsOpen}
                            </div>
                          </div>
                        )}
                      </label>
                    </div>
                    <div>
                      <label className="columns" htmlFor="isFlat">
                        <span className={"column " + switchActiveText(isFlat)}>
                          płaski
                        </span>
                        <RSwitch
                          checked={!isFlat}
                          disabled={!isEditable}
                          onChange={() => setIsFlat(!isFlat)}
                          onColor={mbc_light}
                          offColor={mbc_light}
                          offHandleColor={mbc_white}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          onHandleColor={mbc_white}
                          handleDiameter={22}
                          height={15}
                          width={34}
                          className="column dictionaries-form__switch--border react-switch is-one-fifth is-paddingless"
                          id="isFlat"
                        />
                        <span className={"column " + switchActiveText(!isFlat)}>
                          hierarchiczny
                        </span>
                        {inputErrorIsFlat && (
                          <div className="level is-danger is-size-6">
                            <div className="level-left section-danger has-text-danger">
                              {inputErrorIsFlat}
                            </div>
                          </div>
                        )}
                      </label>
                    </div>

                    <div>
                      <label className="columns" htmlFor="isTimeLimited">
                        <span
                          className={
                            "column " + switchActiveText(isTimeLimited)
                          }
                        >
                          z kontekstem czasu
                        </span>
                        <RSwitch
                          checked={!isTimeLimited}
                          disabled={!isEditable}
                          onChange={() => setIsTimeLimited(!isTimeLimited)}
                          onColor={mbc_light}
                          offColor={mbc_light}
                          offHandleColor={mbc_white}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          onHandleColor={mbc_white}
                          handleDiameter={22}
                          height={15}
                          width={34}
                          className="column dictionaries-form__switch--border react-switch is-one-fifth is-paddingless"
                          id="isTimeLimited"
                        />
                        <span
                          className={
                            "column " + switchActiveText(!isTimeLimited)
                          }
                        >
                          bez kontekstu czasu
                        </span>
                        {inputErrorIsTimeLimited ? (
                          <div className="level is-danger is-size-6">
                            <div className="level-left section-danger has-text-danger">
                              {inputErrorIsTimeLimited}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>

                    {isTimeLimited ? (
                      <div className="dictionaries-form__margin-top-20">
                        <GenericSelect
                          options={dataAttributes}
                          control={control}
                          required={{
                            required: false
                          }}
                          placeholder=""
                          name="time_limited_attribute"
                          errors={errors}
                          isDisabled={!isEditable}
                          label="Atrybut dostarczający domyślny kontekst czasu"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
              <div className="column"></div>
            </div>
            <div className="dictionaries-form__right">
              <CancelButton
                returnLocation={returnLocation}
                bodyContentSufix={`${
                  id ? "edycję" : "dodanie nowego"
                } słownika?`}
              />
              {id ? (
                <button
                  className={`button dictionaries-form__button dictionaries-form__button--orange ${
                    isLoading ? "is-loading" : ""
                  }`}
                  type="submit"
                >
                  Zapisz
                </button>
              ) : (
                <DropdownButton
                  mainOption={{
                    title: "Zapisz",
                    type: "submit",
                    onClick: () => setGoToAddEntry(false)
                  }}
                  options={[
                    {
                      id: 1,
                      title: "Zapisz i dodaj wartości",
                      type: "submit",
                      onClick: () => setGoToAddEntry(true)
                    }
                  ]}
                  isLoading={false}
                />
              )}
            </div>
          </form>
          {id ? (
            <React.Fragment>
              <hr className="dictionaries-form__hr" />
              <DictionariesEntries
                message=""
                dictionary={id}
                dataCallback={callback}
                hasCreatePermission={hasCreatePermission}
                isTimeLimited={isTimeLimited}
              />
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
        <div className="dictionaries-form__bottom">
          <a
            className="dictionaries-form__goback is-uppercase has-text-weight-bold"
            href="/dictionaries/"
          >
            &lt; Wróć do listy słowników
          </a>
        </div>
      </div>
    </section>
  );
};

export default DictionaryForm;
