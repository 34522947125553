import "./SchemasForm.scss";
import "@app/scss/Collapsible.scss";

import React, { useEffect, useState } from "react";

import CancelButton from "@components/CancelButton/CancelButton";
import Collapsible from "react-collapsible";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericRadioGroup from "@components/Radio/GenericRadioGroup";
import GenericSelect from "@components/Select/GenericSelect";
import Input from "@components/Input/Input";
import { ReactSortable } from "react-sortablejs";
import SchemasFormMarc from "./SchemasFormMarc";
import axiosSession from "@app/config/axiosSession";
import differenceBy from "lodash/differenceBy";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

const dcOptions = [
  { id: 1, title: "Sklej wartości" },
  { id: 2, title: "Sklej odpowiadające wartości" },
  { id: 3, title: "Dodaj jako nową wartość" },
  { id: 4, title: "Wartość atrybutu jest wskaźnikiem 1" },
  { id: 5, title: "Wartość atrybutu jest wskaźnikiem 2" }
];

const attribute_complex_type = 9;

const SchemasForm = ({ id }) => {
  const { notifySuccess, notifyError } = useGenericToastify();
  const {
    handleSubmit,
    register,
    errors,
    control,
    watch,
    setValue,
    getValues,
    setError
  } = useForm();
  const [inputError, setInputError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [schemes, setSchemes] = useState([]);
  const [selectedScheme, setSelectedScheme] = useState(null);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [attributes, setAttributes] = useState([]);
  const [attributesList, setAttributesList] = useState([]);
  const [marcFields, setMarcFields] = useState([]);
  const [isWithMarc, setIsWithMarc] = useState(false);
  const [tempValues, setTempValues] = useState({});
  const [topPlace, setTopPlace] = useState(0);
  const [complexAttributes, setComplexAttributes] = useState({});
  const [refreshForm, setRefreshForm] = useState(false);

  const endpointURL = "/api/attributes/schema";
  const returnLocation = "/cancel?url=/attributes/schemas/";

  const requiredMessage = "To pole jest wymagane!";

  const resetErrors = () => {
    setInputError(false);
  };

  const onAttrSelectChange = ([selectedOption]) => {
    setSelectedAttribute(selectedOption);
    return selectedOption;
  };

  const onSchemeSelectChange = ([selectedOption]) => {
    setSelectedScheme(selectedOption);
    return selectedOption;
  };

  const setMarcValues = inData => {
    const complexArray = [];
    inData.attributes.forEach((attr, idx) => {
      if (attr.attribute_type === attribute_complex_type) {
        complexArray.push({ attribute: attr, idx: idx });
      } else if (inData.marc21.length > 0) {
        setIsWithMarc(true);
        const marc = inData.marc21.find(
          x =>
            x.attribute === attr.id &&
            (x.parent_attribute_path === null ||
              x.parent_attribute_path.length == 1)
        );
        if (marc !== undefined) {
          setValue(`marc21[${idx}].attribute`, marc.attribute);
          setValue(
            `marc21[${idx}].marc21`,
            marcFields.find(x => x.id === marc.marc21)
          );
          setValue(`marc21[${idx}].separator`, marc.separator);
          setValue(`marc21[${idx}].place`, marc.place);
          setValue(
            `marc21[${idx}].indicator1`,
            marc.indicator1 !== null ? { id: marc.indicator1 } : null
          );
          setValue(
            `marc21[${idx}].indicator2`,
            marc.indicator2 !== null ? { id: marc.indicator2 } : null
          );
          setValue(
            `marc21[${idx}].option`,
            dcOptions.find(x => x.id === marc.option)
          );
          setValue(`marc21[${idx}].marc21_subfield`, marc.marc21_subfield);
        }
      }
    });

    const apiRequestLoop = array => {
      let promiseArray = [];
      array.forEach(attr => {
        promiseArray.push(
          axiosSession
            .get(`/api/attributes/${attr.attribute.id}/children/`)
            .then(({ data }) => {
              return { data: data, attribute: attr.attribute, idx: attr.idx };
            })
        );
      });
      return axiosSession.all(promiseArray);
    };

    apiRequestLoop(complexArray).then(results => {
      let tempObj = Object.assign({}, complexAttributes);
      results.forEach(result => {
        const tempComplexArray = result.data.map(node => {
          return {
            id: node.id,
            name: node.name,
            path: node.path
          };
        });
        tempObj[`${result.attribute.id}`] = tempComplexArray;
      });
      setComplexAttributes(tempObj);
      results.forEach(result => {
        const { data, attribute, idx } = result;
        if (inData.marc21.length > 0) {
          if (idx === 0) {
            setIsWithMarc(true);
          }
          const tempArray = inData.marc21.filter(
            x =>
              x.parent_attribute_path &&
              x.parent_attribute_path.length > 1 &&
              x.parent_attribute_path[0] === attribute.id
          );
          tempArray.forEach((node, idx2) => {
            setValue(
              `marc21[${idx}].attribute[${idx2}].marc21`,
              marcFields.find(x => x.id === node.marc21)
            );
            setValue(
              `marc21[${idx}].attribute[${idx2}].separator`,
              node.separator
            );
            setValue(`marc21[${idx}].attribute[${idx2}].place`, node.place);
            setValue(
              `marc21[${idx}].attribute[${idx2}].indicator1`,
              node.indicator1 !== null ? { id: node.indicator1 } : null
            );
            setValue(
              `marc21[${idx}].attribute[${idx2}].indicator2`,
              node.indicator2 !== null ? { id: node.indicator2 } : null
            );
            setValue(
              `marc21[${idx}].attribute[${idx2}].option`,
              dcOptions.find(x => x.id === node.option)
            );
            setValue(
              `marc21[${idx}].attribute[${idx2}].marc21_subfield`,
              node.marc21_subfield
            );
          });
        }
      });
    });
  };

  const onLoadSchema = () => {
    if (selectedScheme.id && marcFields.length > 0) {
      setIsLoading(true);
      setRefreshForm(origin => !origin);
      axiosSession
        .get(`${endpointURL}/${selectedScheme.id}/`)
        .then(({ data }) => {
          setAttributesList(data.attributes);
          setTopPlace(data.attributes.length);
          setMarcValues(data);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const onSubmit = data => {
    let method;
    let url;
    setIsLoading(true);
    resetErrors();
    let isError = false;
    if (isWithMarc) {
      const tempMarc = data.marc21.map((marc, idx) => {
        if (marc !== null && marc !== undefined && marc.marc21 !== null) {
          const attr = attributesList.find(x => x.place === idx + 1);
          if (attr.attribute_type === attribute_complex_type) {
            const attributes = complexAttributes[`${attr.id}`];
            return marc.attribute.map((item, idx2) => {
              if (item.marc21 !== null && item.place === "") {
                setError(
                  `marc21[${idx}].attribute[${idx2}].place`,
                  "",
                  requiredMessage
                );
                isError = true;
              }
              if (
                item.marc21 !== null &&
                item.marc21.id > 6 &&
                item.marc21_subfield === null
              ) {
                setError(
                  `marc21[${idx}].attribute[${idx2}].marc21_subfield`,
                  "",
                  requiredMessage
                );
                isError = true;
              }
              return item.marc21
                ? {
                    marc21: item.marc21 ? item.marc21.id : null,
                    attribute:
                      attributes[idx2].path[attributes[idx2].path.length - 1],
                    separator: item.separator,
                    place: item.place,
                    marc21_subfield:
                      item.marc21_subfield !== undefined &&
                      item.marc21_subfield !== null
                        ? item.marc21_subfield.id
                        : "",
                    indicator1:
                      item.indicator1 !== null && item.indicator1 !== undefined
                        ? item.indicator1.id
                        : null,
                    indicator2:
                      item.indicator2 !== null && item.indicator2 !== undefined
                        ? item.indicator2.id
                        : null,
                    option: item.option.id,
                    parent_attribute_path: attributes[idx2].path
                  }
                : null;
            });
          } else {
            if (
              marc.marc21.id > 6 &&
              (marc.marc21_subfield === null ||
                marc.marc21_subfield === undefined)
            ) {
              setError(`marc21[${idx}].marc21_subfield`, "", requiredMessage);
            }
            if (marc.place === null || marc.place === "") {
              setError(`marc21[${idx}].place`, "", requiredMessage);
            }
            return marc.marc21
              ? {
                  marc21: marc.marc21.id,
                  attribute: attr.id,
                  separator: marc.separator,
                  place: marc.place,
                  marc21_subfield:
                    marc.marc21_subfield !== undefined &&
                    marc.marc21_subfield !== null
                      ? marc.marc21_subfield.id
                      : "",
                  indicator1:
                    marc.indicator1 !== null && marc.indicator1 !== undefined
                      ? marc.indicator1.id
                      : null,
                  indicator2:
                    marc.indicator2 !== null && marc.indicator2 !== undefined
                      ? marc.indicator2.id
                      : null,
                  option: marc.option.id
                }
              : null;
          }
        }
      });
      data.marc21 = tempMarc
        .flat()
        .filter(el => el !== null && el !== undefined);
    }
    data.attributes = attributesList.map((attr, index) => {
      return { place: index + 1, attribute: attr.id };
    });

    if (id) {
      method = "PUT";
      url = `${endpointURL}/${id}/`;
    } else {
      method = "POST";
      url = `${endpointURL}/`;
    }
    setIsLoading(false);
    if (!isError) {
      axiosSession({ method: method, url: url, data: data })
        .then(res => {
          notifySuccess("Zapisano schemat opisowy");
          window.location.href = res.data.url;
        })
        .catch(error => {
          if (error.response.data.name) {
            setError("name", "", error.response.data.name);
          }

          if (error.response.data.description) {
            setError("description", "", error.response.data.description);
          }

          if (error.response.data.marc21) {
            error.response.data.marc21.forEach((err, idx) => {
              if (err.marc21) {
                setError(`marc21[${idx}.marc21]`, "", err.marc21[0]);
              }
              if (err.marc21_subfield) {
                setError(
                  `marc21[${idx}.marc21_subfield]`,
                  "",
                  err.marc21_subfield[0]
                );
              }
              if (err.separator) {
                setError(`marc21[${idx}.separator]`, "", err.separator[0]);
              }
              if (err.place) {
                setError(`marc21[${idx}.place]`, "", err.place[0]);
              }
            });
          }
          notifyError("Nie udało się zapisać formularza.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onAddAttribute = () => {
    if (selectedAttribute) {
      if (selectedAttribute.attribute_type === attribute_complex_type) {
        axiosSession
          .get(`/api/attributes/${selectedAttribute.id}/children`)
          .then(({ data }) => {
            let tempObj = Object.assign({}, complexAttributes);
            tempObj[`${selectedAttribute.id}`] = data;
            setComplexAttributes(tempObj);
            setAttributesList([
              ...attributesList,
              { ...selectedAttribute, place: topPlace + 1 }
            ]);
            setTopPlace(topPlace + 1);
            setAttributes(
              attributes.filter(i => i.id !== selectedAttribute.id)
            );
            setValue("attribute", []);
            setValue(`marc21[${attributesList.length}]`, []);
            setSelectedAttribute(null);
          })
          .catch(error => console.error(error));
      } else {
        setAttributesList([
          ...attributesList,
          { ...selectedAttribute, place: topPlace + 1 }
        ]);
        setTopPlace(topPlace + 1);
        setAttributes(attributes.filter(i => i.id !== selectedAttribute.id));
        setValue("attribute", []);
        setValue(`marc21[${attributesList.length}]`, []);
        setSelectedAttribute(null);
      }
    }
  };

  const onRemoveAttribute = item => {
    setAttributes([...attributes, item]);
    setAttributesList(attributesList.filter(i => i.id !== item.id));
    let tempObj = Object.assign({}, complexAttributes);
    tempObj[`${item.id}`] = undefined;
    tempObj = JSON.parse(JSON.stringify(tempObj));
    setComplexAttributes(tempObj);
    let tempValues = getValues();
    delete tempValues[`marc21[${item.place - 1}]`];
    Object.entries(tempValues).forEach(([key, value]) => {
      setValue(key, value);
    });
  };

  useEffect(() => {
    !isEmpty(errors) ? notifyError("Nie udało się zapisać formularza.") : null;
  }, [errors, notifyError]);

  useEffect(() => {
    axiosSession
      .get("/api/attributes/schema/select-data/")
      .then(({ data }) => {
        let schemes = data.map(s => {
          return { id: s.id, name: `${s.name} - wersja ${s.version}` };
        });
        setSchemes(schemes);
      })
      .catch(error => {
        setSchemes([]);
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axiosSession
      .get("/api/attributes/select-data/")
      .then(({ data }) => setAttributes(data))
      .catch(error => {
        setAttributes([]);
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axiosSession
      .get(" /api/attributes/marc21_field/")
      .then(({ data }) => {
        setMarcFields(data.results);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (id && marcFields.length > 0) {
      setIsLoading(true);
      axiosSession
        .get(`${endpointURL}/${id}/`)
        .then(({ data }) => {
          setValue("name", data.name);
          setValue("description", data.description);
          setAttributesList(data.attributes);
          setTopPlace(data.attributes.length);
          setMarcValues(data);
        })
        .finally(() => setIsLoading(false));
    }
  }, [id, marcFields]);

  useEffect(() => {
    let diff = differenceBy(attributes, attributesList, "id");
    setAttributes(diff);
    Object.entries(tempValues).forEach(([key, value]) => {
      setValue(key, value);
    });
  }, [attributesList]);

  const removeAttribute = (item, index) => {
    event.preventDefault();
    onRemoveAttribute(item, index);
  };

  return (
    <section className="mbc-backoffice schemas-form">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/attributes/schemas/"
            title="Schematy opisowe"
          >
            Schematy opisowe
          </a>
          &nbsp;/&nbsp;
          <strong>{id ? "Edycja" : "Dodawanie"} schematu opisowego</strong>
        </div>

        <div className="mbc-backoffice__header">
          <h2 className="mbc-backoffice__header__title">
            {id ? "Edycja" : "Dodawanie"} schematu opisowego
          </h2>
          <hr className="mbc-backoffice__header__hr" />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          {!id ? (
            <div className="columns is-variable">
              <div className="column">
                <GenericSelect
                  options={schemes}
                  placeholder="Wybierz schemat"
                  control={control}
                  errors={errors}
                  handleSelectChange={onSchemeSelectChange}
                  name="schemes"
                  label="Duplikuj schemat"
                />
              </div>
              <div className="column">
                <button
                  type="button"
                  onClick={() => {
                    event.preventDefault();
                    onLoadSchema();
                  }}
                  className={`has-mt-1 button button--is-black ${
                    isLoading ? "is-loading" : ""
                  }`}
                >
                  Uzupełnij schemat
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="columns is-variable">
            <div className="column is-half-desktop is-full-mobile">
              <Input
                name="name"
                label="Nazwa"
                register={register({
                  required: requiredMessage
                })}
                errors={errors}
                isRequired
              />
              <Input
                name="description"
                label="Opis schematu"
                register={register({
                  required: requiredMessage
                })}
                errors={errors}
                isRequired
              />
            </div>
          </div>

          <div className="columns is-variable">
            <div className="column">
              <GenericSelect
                options={attributes}
                placeholder="Wybierz atrybut"
                label="Wybierz atrybut"
                control={control}
                errors={errors}
                value={selectedAttribute}
                handleSelectChange={onAttrSelectChange}
                hideSelectedOptions={true}
                name="attribute"
                isClearable={true}
              />
            </div>
            <div className="column">
              <button
                type="button"
                onClick={() => {
                  event.preventDefault();
                  onAddAttribute();
                }}
                className={`has-mt-1 button button--is-black ${
                  isLoading ? "is-loading" : ""
                }`}
              >
                Dodaj atrybut
              </button>
            </div>
          </div>

          <div className="columns is-variable ">
            <div className="column is-half-desktop is-full-mobile">
              <h4 className="has-text-black">
                Ustaw kolejność wyświetlania (przeciągając daną sekcję)
              </h4>
              {attributesList.length ? (
                <ReactSortable
                  className="schemas-form__attributes__list"
                  list={attributesList}
                  setList={newList => {
                    setTempValues(getValues());
                    setAttributesList(newList);
                  }}
                >
                  {attributesList.map((item, index) => (
                    <div
                      className="schemas-form__attributes__list__item cursor__grab"
                      key={item.id}
                    >
                      <div className="">
                        <FontAwesomeIcon icon="bars" title="Zmień kolejność" />{" "}
                        &nbsp;
                        {item.name}
                      </div>
                      <div className="">
                        <span
                          role="button"
                          tabIndex="0"
                          onClick={() => removeAttribute(item, index)}
                          onKeyPress={() => removeAttribute(item, index)}
                          className="schemas-form__attributes__list__button"
                          title="Usuń"
                        >
                          <FontAwesomeIcon icon="times" />
                        </span>
                      </div>
                    </div>
                  ))}
                </ReactSortable>
              ) : (
                <div className="schemas-form__attributes__list__item">
                  <div className="">Nie dodano aturybutów</div>
                </div>
              )}
            </div>
          </div>

          <div>
            {inputError ? (
              <div className="level is-danger is-size-6">
                <div className="level-left section-danger has-text-danger">
                  <span className="level-item icon">
                    <i className="fas fa-exclamation-triangle" />
                  </span>
                  {inputError}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="columns is-variable">
              <div className="column is-full">
                <GenericRadioGroup
                  name="marc"
                  groupLabel={"połącz - marc21"}
                  radiosText={{
                    yes: "TAK (rozwinie się więcej opcji)",
                    no: "NIE"
                  }}
                  handleRadioChange={e =>
                    setIsWithMarc(JSON.parse(e.target.value))
                  }
                  valueSelected={isWithMarc}
                />
              </div>
            </div>
            {isWithMarc ? (
              <div key={refreshForm}>
                {attributesList.map(attr => {
                  return (
                    <React.Fragment key={attr.id}>
                      <Collapsible
                        transitionTime={200}
                        trigger={`${attr.name} ${
                          attr.attribute_type === attribute_complex_type
                            ? "- atrybut złożony"
                            : ""
                        }`}
                      >
                        {attr.attribute_type === attribute_complex_type &&
                        Object.keys(complexAttributes).length > 0 ? (
                          <>
                            {Object.keys(complexAttributes).includes(
                              `${attr.id}`
                            ) && (
                              <>
                                {complexAttributes[`${attr.id}`].map(
                                  (item, idx) => {
                                    return (
                                      <div key={item.name}>
                                        {Object.keys(
                                          complexAttributes
                                        ).includes(`${attr.id}`) && (
                                          <div>
                                            <h3 className="schemas-form__complex-header has-text-weight-bold">
                                              Atrybut: {item.name}
                                            </h3>
                                            <SchemasFormMarc
                                              getValues={getValues}
                                              marc21={watch(
                                                `marc21[${attr.place -
                                                  1}].attribute[${idx}].marc21`
                                              )}
                                              attribute={attr}
                                              control={control}
                                              errors={errors}
                                              marcFields={marcFields}
                                              dcOptions={dcOptions}
                                              setValue={setValue}
                                              setTempValues={setTempValues}
                                              isComplex={true}
                                              idx={idx}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <SchemasFormMarc
                            getValues={getValues}
                            marc21={
                              getValues()[`marc21[${attr.place - 1}].marc21`]
                            }
                            attribute={attr}
                            control={control}
                            errors={errors}
                            marcFields={marcFields}
                            dcOptions={dcOptions}
                            setValue={setValue}
                            setTempValues={setTempValues}
                          />
                        )}
                      </Collapsible>
                    </React.Fragment>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            <div className="buttons">
              <CancelButton
                returnLocation={returnLocation}
                bodyContentSufix={`${
                  id ? "edycję" : "dodanie nowego"
                } schematu opisowego?`}
              />
              <button
                className={`button button--is-orange ${
                  isLoading ? "is-loading" : ""
                }`}
                type="submit"
              >
                Zapisz
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default SchemasForm;
