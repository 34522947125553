import "./ExternalUsersEdit.scss";

import React, { useEffect, useState } from "react";

import CancelButton from "@components/CancelButton/CancelButton";
import Input from "@app/components/Input/Input";
import RSwitch from "react-switch";
import axiosSession from "@app/config/axiosSession";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

const ExternalUsersEdit = ({ id }) => {
  const { handleSubmit, register, errors, setError, reset } = useForm();
  const { notifySuccess, notifyError } = useGenericToastify();

  const [isBlocked, setIsBlocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const urls = {
    home: "/admin-panel/home/",
    list: "/users/external-users/"
  };

  const url = `/api/users/external-users/${id}/`;

  const mbc_light = "#f6f6f6";
  const mbc_white = "#000000";

  useEffect(() => {
    const fetchData = async () => {
      const result = axiosSession({ method: "get", url })
        .then(({ data }) => {
          const { blockable } = data.actions;
          setIsBlocked(blockable);
          reset(data);
        })
        .catch(err => {
          console.error(err);
        });
    };
    fetchData();
  }, []);

  const handleUserActivity = () => {
    axiosSession
      .put(`${url}active/`, { is_active: !isBlocked })
      .then(({ data }) => {
        const { is_active } = data;
        setIsBlocked(is_active);
        notifySuccess(
          `${
            !is_active
              ? "Użytkownik został pomyślnie zablokowany"
              : "Użytkownik został pomyślnie odblokowany"
          }`
        );
      })
      .catch(err => {
        notifyError("Nie udało się zmienić statusu użytkownika");
        console.error(err);
      });
  };

  const onSubmit = data => {
    setIsLoading(true);
    axiosSession
      .put(url, data)
      .then(response => {
        window.location.href = response.data.url;
      })
      .catch(err => {
        if (err?.response?.data) {
          Object.keys(err.response.data).forEach(item => {
            setError(item, "", err.response.data[item]);
          });
        } else {
          notifyError("Nie udało się zapisać zmian.");
        }
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <section className="mbc-backoffice external-users-edit">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href={urls.home}
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href={urls.list}
            title="Użytkownicy zewnętrzni"
          >
            Użytkownicy zewnętrzni
          </a>
          &nbsp;/&nbsp;
          <strong>Edycja</strong>
        </div>

        <div className="mbc-backoffice__header">
          <h2 className="mbc-backoffice__header__title">
            Edycja użytkownika zewnętrznego
          </h2>
          <hr className="mbc-backoffice__header__hr" />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="columns">
            <div className="column is-half">
              <Input
                name="first_name"
                label="Imię"
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
              <Input
                name="last_name"
                label="Nazwisko"
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
              <Input
                name="email"
                label="Adres E-MAIL"
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
            </div>
          </div>

          <div className="field columns external-users-edit">
            <div className="column is-half">
              <div className="external-users-edit__box">
                <span
                  className={`external-users-edit__description ${
                    !isBlocked ? "external-users-edit__description--bold" : ""
                  }`}
                >
                  Zablokowany
                </span>
                <RSwitch
                  checked={isBlocked}
                  onChange={() => handleUserActivity()}
                  onColor={mbc_light}
                  offColor={mbc_light}
                  offHandleColor={mbc_white}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onHandleColor={mbc_white}
                  handleDiameter={22}
                  height={15}
                  width={34}
                  className="column attributes-form__switch--border attributes-form__switch--is-marginless react-switch is-one-fifth is-paddingless"
                  id="metadata"
                  name="metadata"
                  ref={register}
                />
                <span
                  className={`external-users-edit__description ${
                    isBlocked ? "external-users-edit__description--bold" : ""
                  }`}
                >
                  Aktywny
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="buttons">
              <CancelButton
                returnLocation={urls.list}
                bodyContentSufix="edycję użytkownika?"
              />
              <button
                className={`button external-users-edit__btn ${
                  isLoading ? "is-loading" : ""
                }`}
                type="submit"
                title="Zapisz"
              >
                Zapisz
              </button>
            </div>
          </div>
        </form>

        <div className="mbc-backoffice__bottom">
          <a
            className="mbc-backoffice__goback is-uppercase has-text-weight-bold"
            href={urls.list}
          >
            &lt; Wróć do listy użytkowników zewnętrznych
          </a>
        </div>
      </div>
    </section>
  );
};

export default ExternalUsersEdit;
