import "./DirectoriesSearch.scss";

import React, { useEffect, useState } from "react";

import GenericModal from "@app/components/Modal/GenericModal";
import axiosSession from "@app/config/axiosSession";
import isEmpty from "lodash/isEmpty";
import useGenericToastify from "@app/hooks/useGenericToastify";

const DirectoriesDigitalItemExport = ({
  selectedObjects,
  isDigitalItemExportOpen,
  setIsDigitalItemExportOpen,
  groupData,
  setGroupData
}) => {
  const { notifySuccess, notifyError } = useGenericToastify();
  const [objectsToExport, setObjectsToExport] = useState([]);
  const [objectsNotForExport, setObjectsNotForExport] = useState([]);
  const [filteredData, setFilteredData] = useState(groupData);
  const [message, setMessage] = useState({});
  const [buttonSection, setButtonSection] = useState(true);
  const selectedToExport = selectedObjects;

  const url = "/api/archives/export/";
  const filterUrl = "/api/directories/group-assigment-filter-check/";

  useEffect(() => {
    if (!isEmpty(filteredData)) {
      axiosSession.post(filterUrl, filteredData).then(({ data }) => {
        setObjectsToExport(data.digital_items);
        setObjectsNotForExport(data.directories);
      });
    }
  }, [filteredData]);

  useEffect(() => {
    setObjectsToExport(
      selectedToExport.filter(item => item.object_class === "digital_item")
    );
    setObjectsNotForExport(
      selectedToExport.filter(item => item.object_class !== "digital_item")
    );
  }, [selectedToExport]);

  useEffect(() => {
    if (objectsNotForExport.length > 0 && objectsToExport.length > 0) {
      setMessage({
        headerTitle: "Wysyłka SIP",
        bodyContent: `Nie można eksportować części wybranych zasobów (${objectsNotForExport.length}). Czy chcesz kontynuować dla pozostałych?`,
        btnDeleteNo: "Nie",
        btnDeleteYes: "Tak"
      });
      setButtonSection(true);
    } else if (objectsToExport.length === 0) {
      setMessage({
        headerTitle: "Wysyłka SIP",
        bodyContent: "Musisz wybrać co najmniej jeden obiekt pojedynczy.",
        btnDeleteNo: "Zamknij"
      });
      setButtonSection(false);
    } else {
      setMessage({
        headerTitle: "Wysyłka SIP",
        bodyContent:
          "Czy chcesz rozpocząć proces eksportu wybranych elementów do archiwum?",
        btnDeleteNo: "Nie",
        btnDeleteYes: "Tak"
      });
      setButtonSection(true);
    }
  }, [objectsToExport, objectsNotForExport, filteredData]);

  const clearData = () => {
    setFilteredData([]);
    setGroupData({});
    setButtonSection(true);
    setIsDigitalItemExportOpen(false);
  };

  const exportDigitalItems = async () => {
    await axiosSession
      .post(url, {
        digital_items: isEmpty(groupData)
          ? objectsToExport.map(item => item.id)
          : objectsToExport
      })
      .then(() => {
        notifySuccess("Pomyślnie rozpoczęto proces eksportu");
        setMessage({
          headerTitle: "Wysyłka SIP",
          bodyContent: "Eksport w toku",
          btnDeleteNo: "Zamknij"
        });
        setButtonSection(false);
      })
      .catch(error => {
        console.error(error);
        notifyError("Nie udało się rozpocząć procesu eksportu");
      });
  };

  return (
    <GenericModal
      modalIsOpen={isDigitalItemExportOpen}
      setModalIsOpen={setIsDigitalItemExportOpen}
      labels={message}
      buttonSection={buttonSection}
      actionSubmit={exportDigitalItems}
      actionCancel={clearData}
    />
  );
};

export default DirectoriesDigitalItemExport;
