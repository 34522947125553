import "./GenericModal.scss";

import Modal from "react-responsive-modal";
import PropTypes from "prop-types";
import React from "react";

const GenericModal = ({
  itemData,
  labels,
  modalIsOpen,
  setModalIsOpen,
  borders,
  greyTheme,
  actionSubmit,
  actionCancel,
  buttonSection,
  closeOnOverlayClick,
  showItemName,
  frontoffice,
  withScroll,
  withoutHeader
}) => {
  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Modal
      open={modalIsOpen}
      onClose={() => (actionCancel ? actionCancel() : closeModal())}
      center
      classNames={{
        modal: `GenericModal ${frontoffice ? "GenericModal__Frontoffice" : ""}`
      }}
      focusTrapped={frontoffice}
      showCloseIcon={false}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      {!withoutHeader && (
        <div
          className={
            greyTheme
              ? "GenericModal__Header--grey-theme"
              : borders.header
              ? "GenericModal__Header--with-border"
              : "GenericModal__Header"
          }
        >
          <p className="GenericModal__Header__Title">{labels.headerTitle}</p>
        </div>
      )}
      <div
        className={
          greyTheme
            ? `GenericModal__Body--grey-theme ${
                withScroll ? "GenericModal__Body--scroll" : ""
              }`
            : "GenericModal__Body"
        }
      >
        {labels.bodyContent} {greyTheme || !showItemName ? "" : itemData.name}
      </div>
      <div
        className={
          greyTheme
            ? "GenericModal__Footer--grey-theme"
            : borders.footer
            ? "GenericModal__Footer--with-border"
            : "GenericModal__Footer"
        }
      >
        {buttonSection ? (
          <p className="GenericModal__Footer__Buttons buttons is-right is-fullwidth">
            <button
              className={`button GenericModal__Footer__Button ${
                frontoffice ? "button--is-frontoffice" : ""
              }`}
              onClick={() => (actionCancel ? actionCancel() : closeModal())}
            >
              {labels.btnDeleteNo}
            </button>
            <button
              className="button button--is-orange GenericModal__Footer__Button"
              onClick={() =>
                actionSubmit ? actionSubmit(itemData.id) : closeModal()
              }
            >
              {labels.btnDeleteYes}
            </button>
          </p>
        ) : (
          <p className="GenericModal__Footer__Buttons buttons is-right is-fullwidth">
            <button
              className={`button GenericModal__Footer__Button ${
                frontoffice ? "button--is-frontoffice" : ""
              }`}
              onClick={() => (actionCancel ? actionCancel() : closeModal())}
            >
              {labels.btnDeleteNo}
            </button>
          </p>
        )}
      </div>
    </Modal>
  );
};

GenericModal.propTypes = {
  labels: PropTypes.object,
  modalIsOpen: PropTypes.bool,
  setModalIsOpen: PropTypes.func,
  borders: PropTypes.object,
  greyTheme: PropTypes.bool,
  actionSubmit: PropTypes.func,
  itemData: PropTypes.object,
  buttonSection: PropTypes.bool,
  actionCancel: PropTypes.func,
  closeOnOverlayClick: PropTypes.bool,
  showItemName: PropTypes.bool,
  frontoffice: PropTypes.bool,
  withScroll: PropTypes.bool,
  withoutHeader: PropTypes.bool
};

GenericModal.defaultProps = {
  labels: {
    headerTitle: "Domyślny tytuł",
    contentLabel: "Domyślny modal",
    bodyContent: "Czy na pewno chcesz usunąć element?",
    btnDeleteNo: "Anuluj",
    btnDeleteYes: "Usuń"
  },
  borders: { header: true, footer: false },
  greyTheme: false,
  itemData: { id: null, name: null },
  buttonSection: true,
  closeOnOverlayClick: true,
  showItemName: true,
  frontoffice: false,
  withScroll: false,
  withoutHeader: false
};

export default GenericModal;
