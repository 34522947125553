import "./DirectoriesPreview.scss";

import React, { useEffect, useState } from "react";
import {
  deleteCollectionFromDirectories,
  getAttributesValue,
  getCollectionsListDirectories,
  getDirectoryInfo,
  getDirectoryPermissionInfo,
  getSchema,
  getThumbnailsInfo
} from "@app/services/services";
import {
  faCheck,
  faEye,
  faPen,
  faTimes,
  faTrash
} from "@fortawesome/free-solid-svg-icons";

import AttributesPreview from "./AttributesPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-responsive-modal";
import Preview from "@app/components/Preview/Preview";
import axiosSession from "@app/config/axiosSession";
import isEmpty from "lodash/isEmpty";

const DirectoriesPreview = ({ id }) => {
  const [isGroup, setIsGroup] = useState(false);
  const [rows, setRows] = useState();

  const [files, setFiles] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const [languagesSchema, setLanguagesSchema] = useState([]);
  const [attributesSchema, setAttributesSchema] = useState([]);
  const [attributes, setAttributes] = useState({});
  const [isAttributesVisible, setIsAttributesVisible] = useState(false);
  const [collections, setCollections] = useState([]);
  const [canEdit, setCanEdit] = useState(false);

  const [historyItemModal, setHistoryItemModal] = useState(false);
  const [historyItem, setHistoryItem] = useState({});

  const [historyItems, setHistoryItems] = useState([]);

  const onPreview = path => {
    window.open(path, "_blank").focus();
  };

  const urls = {
    editObject: (objectId, isObjectGroup) => `/directories/${objectId}/${isObjectGroup ? "group-object" : "directory"}-edit`
  };


  useEffect(() => {
    const fetchData = async () => {
      const [
        schema,
        values,
        collectionsList,
        directoryPermissionInfo,
        thumbnailsInfo,
        directoryInfo
      ] = await axiosSession.all([
        getSchema(id),
        getAttributesValue(id),
        getCollectionsListDirectories(id),
        getDirectoryPermissionInfo(id),
        getThumbnailsInfo(id),
        getDirectoryInfo(id)
      ]);

      setRows([
        { title: "Biblioteka", value: [directoryInfo.data.library_label] },
        { title: "Katalog nadrzędny", value: [directoryInfo.data.parent_label] },
        {
          title: `${!isGroup ? "Nazwa katalogu" : "Nazwa obiektu grupowego"}`,
          value: [directoryInfo.data.name]
        },
        { title: "Schemat opisowy", value: [directoryInfo.data.schema_label] }
      ]);
      setIsGroup(directoryInfo.data.is_group);
      setCanEdit(directoryInfo.data.can_edit);
      setLanguagesSchema(schema.data.languages);
      setAttributesSchema(schema.data.attributes);
      setAttributes(values.data);
      setPermissions(directoryPermissionInfo.data);
      setFiles(thumbnailsInfo.data.results);
      setCollections(collectionsList.data.collections);

      let history = directoryInfo.data.history.filter(item =>
        (item.change_details.length !== 0 && item.attributes.length !== 0) || item.history_type !== "Zmodyfikowane"
      )

      setHistoryItems(history);
    };
    if (id) {
      fetchData();
    }
  }, [id, isGroup]);

  const handleDeleteCollection = collectionId => {
    deleteCollectionFromDirectories(collectionId, id)
      .then(() =>
        setCollections(origin =>
          origin.filter(item => item.id !== collectionId)
        )
      )
      .catch(err => console.error(err));
  };

  useEffect(() => {
    if (!isEmpty(attributes)) {
      for (let key in attributes) {
        if (!isEmpty(attributes[key])) {
          setIsAttributesVisible(true);
        }
      }
    }
  }, [attributes]);


  const getHistoryActionIcon = action => {
    if (action === "Zmodyfikowane") {
      return <FontAwesomeIcon key={new Date()} icon={faPen} color="#2D9CDB" />;
    } else if (action === "Dodane") {
      return (
        <FontAwesomeIcon key={new Date()} icon={faCheck} color="#219653" />
      );
    } else {
      return (
        <FontAwesomeIcon key={new Date()} icon={faTimes} color="#EB5757" />
      );
    }
  };

  const showFullHistory = item => {
    setHistoryItem(item);
    setHistoryItemModal(true);
  };

  return (
    <section className="directories-preview">
      <div className="container">
        <div className="breadcrumbs">
          <a
            className="breadcrumbs__link"
            title="Strona główna"
            href="/admin-panel/home/"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/directories/"
            title="Katalogi i obiekty"
          >
            Drzewo zasobów
          </a>
          &nbsp;/&nbsp;
          {isGroup ? (
            <strong>Podgląd obiektu grupowego</strong>
          ) : (
            <strong>Podgląd katalogu</strong>
          )}
        </div>
        <div className="directories-preview__header">
          {!isGroup && (
            <h2 className="directories-preview__header__title">
              Podgląd katalogu
            </h2>
          )}
          {isGroup && (
            <h2 className="directories-preview__header__title">
              Podgląd obiektu grupowego
            </h2>
          )}
          <hr className="directories-preview__header__hr"></hr>
        </div>

        <div className="step">{rows && <Preview rows={rows} />}</div>

        {files.length > 0 && isGroup && (
          <div>
            <h2 className="directories-preview__header__title directories-preview__padding">
              Pliki
            </h2>
            <div className="step">
              <div className="columns is-1">
                <div className="column is-3 is-uppercase">Pliki:</div>
                <div className="column has-text-weight-bold">
                  {files.map((file, idx) => (
                    <p key={idx}>{file.name}</p>
                  ))}
                </div>
                <div className="column has-text-weight-bold">
                  {files.map((file, idx) => (
                    <button
                      key={idx}
                      onClick={() => onPreview(file.path)}
                      className="directories-preview__button"
                    >
                      Zobacz
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {isAttributesVisible && (
          <AttributesPreview
            languagesSchema={languagesSchema}
            attributesSchema={attributesSchema}
            attributes={attributes}
          />
        )}

        {collections.length > 0 && (
          <div className="step">
            <h2 className="directories-preview__header__title directories-preview--padding">
              Przypisano do kolekcji
            </h2>
            <div>
              <table className={"long"}>
                <tbody>
                  <tr className="row first">
                    <td className="column-element-long column-element-long--header">
                      Nazwa
                    </td>
                    <td className="column-element-long column-element-long--header">
                      Status
                    </td>
                    <td>
                      <div className="column-element-short column-element-short--header">
                        Akcje
                      </div>
                    </td>
                  </tr>
                  {collections.map((item, index) => (
                    <tr className="row" key={index}>
                      <td className="column-element-long">{item.full_path}</td>
                      <td className="column-element-long">{item.status}</td>
                      <td className="column-element-short">
                        <span
                          onClick={() => handleDeleteCollection(item.id)}
                          onKeyPress={() => handleDeleteCollection(item.id)}
                          role="button"
                          tabIndex="0"
                          className="generic-table__table__link"
                          title="Usuń"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {permissions.length > 0 && (
          <div className="step">
            <h2 className="directories-preview__header__title directories-preview--padding">
              Uprawnienia
            </h2>
            <div>
              <table>
                <tbody>
                  <tr className="row first">
                    <td className="column-element-first">GRUPY</td>
                    <td>
                      <div className="column-element">ODCZYT</div>
                    </td>
                    <td>
                      <div className="column-element">MODYFIKACJA</div>
                    </td>
                    {isGroup ? (
                      <td>
                        <div className="column-element">KOLEKCJE</div>
                      </td>
                    ) : (
                      ""
                    )}
                    <td>
                      <div className="column-element">PUBLIKACJA</div>
                    </td>
                  </tr>
                  {permissions.map(group => (
                    <tr className="row" key={group.group_id}>
                      <td className="column-element-first">
                        {group.group_name}
                      </td>
                      <td>
                        <div className="column-element">
                          {group.permission.find(
                            e => e.name == "view_directory_content"
                          ).value && (
                              <FontAwesomeIcon key={new Date()} icon={faCheck} />
                            )}
                        </div>
                      </td>
                      <td>
                        <div className="column-element">
                          {group.permission.find(
                            e => e.name == "change_directory"
                          ).value && (
                              <FontAwesomeIcon key={new Date()} icon={faCheck} />
                            )}
                        </div>
                      </td>
                      {isGroup ? (
                        <td>
                          <div className="column-element">
                            {group.permission.find(
                              e => e.name == "manage_directory_collection"
                            ).value && (
                                <FontAwesomeIcon
                                  key={new Date()}
                                  icon={faCheck}
                                />
                              )}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      <td>
                        <div className="column-element">
                          {group.permission.find(
                            e => e.name == "publish_directory_content"
                          ).value && (
                              <FontAwesomeIcon key={new Date()} icon={faCheck} />
                            )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {historyItems.length > 0 && (
          <div className="step">
            <h2 className="directories-preview__header__title directories-preview--padding">
              Historia zmian rekordu
            </h2>
            <table className="long">
              <tbody>
                <tr className="row first">
                  <td>
                    <div className="column-element-long--header">
                      OSOBA MODYFIKUJĄCA
                    </div>
                  </td>
                  <td>
                    <div className="column-element-long--header">
                      DATA MODYFIKACJI
                    </div>
                  </td>
                  <td>
                    <div className="column-element-long--header">
                      INFORMACJE
                    </div>
                  </td>
                  <td>
                    <div className="column-element-long--header">STATUS</div>
                  </td>
                  <td>
                    <div className="column-element-long--header">PODGLĄD</div>
                  </td>
                </tr>

                {historyItems.length > 0 &&
                  historyItems.map(item => (
                    <tr className="row" key={item.change_details}>
                      <td>
                        <div className="column-element-short">
                          {item.history_user}
                        </div>
                      </td>
                      <td>
                        <div className="column-element-short">
                          {item.history_date}
                        </div>
                      </td>
                      <td>
                        <div className="column-element-short">
                          {item.change_details}
                        </div>
                      </td>
                      <td>
                        <div className="column-element-short">
                          {getHistoryActionIcon(item.history_type)}{" "}
                          {item.history_type}
                        </div>
                      </td>
                      <td>
                        <div className="column-element-short">
                          {item.attributes.length > 0 ? (
                            <FontAwesomeIcon
                              onClick={() => showFullHistory(item)}
                              key={new Date()}
                              icon={faEye}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        <Modal
          open={historyItemModal}
          onClose={() => setHistoryItemModal(false)}
          center
          showCloseIcon
          classNames={{ modal: "GenericModal" }}
        >
          <div className="GenericModal__Header--with-border">
            <p className="GenericModal__Header__Title">
              Podgląd zmienionych atrybutów
            </p>
          </div>
          <div className="GenericModal__Body">
            <div className="grid">
              <div className="col-xs-12 col-sm-6 col-md-4">OSOBA MODYFIKUJĄCA:</div>
              <div className="col-xs-12 col-sm-6 col-md-8">{historyItem.history_user}</div>
              <div className="col-xs-12 col-sm-6 col-md-4">DATA MODYFIKACJI:</div>
              <div className="col-xs-12 col-sm-6 col-md-8">{historyItem.history_date}</div>
              <div className="col-xs-12 col-sm-6 col-md-3">INFORMACJE:</div>
              <div className="col-xs-12 col-sm-6 col-md-3"> {historyItem.attributes && historyItem.attributes.map((item, idx) => <li key={idx}>{item}</li>)}</div>
            </div>
          </div>
        </Modal>

        <div className="directories-preview--bottom">
          <a
            className="directories-preview--goback is-uppercase has-text-weight-bold"
            href={
              localStorage.backToDirectoriesSearch
                ? "/directories/search/"
                : "/directories/"
            }
          >
            &lt; Wróć do listy katalogów
          </a>
          {canEdit &&
            <a
              href={urls.editObject(id, isGroup)}
              className="directories-preview--goback is-uppercase has-text-weight-bold"
            >
              Edytuj
            </a>}
        </div>
      </div>
    </section>
  );
};

export default DirectoriesPreview;
