import "./DictionariesForm.scss";

import React, { useEffect, useState } from "react";
import { getDict, getDictSourceKeys } from "@app/services/services";

import DictionariesEntries from "@app/pages/Dictionaries/DictionariesEntries";
import Preview from "@app/components/Preview/Preview";

const hiddenUrlNames = ["NUKAT", "LOC"];

const DictionariesPreview = ({ id, hasCreatePermission }) => {
  const [rows, setRows] = useState(null);
  const [canEdit, setCanEdit] = useState(false);
  const [isTimeLimited, setIsTimeLimited] = useState(false);

  const editDictionary = `/dictionaries/${id}/edit/`;

  useEffect(() => {
    const fetchData = async id => {
      const dict = await getDict(id);
      const data = dict.data;
      let searchKey = [];
      setIsTimeLimited(data.is_time_limited);

      if (data.source !== null) {
        const searchKeys = await getDictSourceKeys(data.source);
        searchKey = searchKeys.data.find(elem => elem.id === data.search_key)
          ?.name;
      }

      setCanEdit(data.can_edit_permission);

      setRows([
        { title: "Nazwa", value: [data.name] },
        { title: "Źródło", value: [data.source_name] },
        {
          title: "Otwarty",
          value:
            data.is_open !== null
              ? [data.is_open ? "otwarty" : "zamknięty"]
              : []
        },
        {
          title: "Struktura",
          value: [data.is_flat ? "płaski" : "hierarchiczny"]
        },
        {
          title: "Czas",
          value:
            data.is_time_limited !== null
              ? [
                  data.is_time_limited
                    ? "z kontekstem czasu"
                    : "bez kontekstu czasu"
                ]
              : []
        },
        {
          title: "Adres URL",
          value:
            data.source_url !== null &&
            !hiddenUrlNames.includes(data.source_name)
              ? [data.source_url]
              : []
        },
        {
          title: "Szukaj po wartości",
          value: searchKey ? [searchKey] : []
        }
      ]);
    };

    if (id) {
      fetchData(id);
    }
  }, [id]);

  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a className="breadcrumbs__link" href="/dicts/" title="Słowniki">
            Słowniki
          </a>
          &nbsp;/&nbsp;
          <strong>Podgląd</strong>
        </div>

        <div className="mbc-backoffice__header">
          <h2 className="mbc-backoffice__header__title">Podgląd słownika</h2>
          <hr className="mbc-backoffice__header__hr" />
        </div>

        {rows && <Preview rows={rows} />}

        {id && (
          <>
            <hr className="dictionaries-form__hr" />
            <DictionariesEntries
              message=""
              dictionary={id}
              hasCreatePermission={hasCreatePermission}
              isTimeLimited={isTimeLimited}
            />
          </>
        )}

        <div className="dictionaries-form__bottom">
          <a
            className="dictionaries-form__goback is-uppercase has-text-weight-bold"
            href="/dictionaries/"
          >
            &lt; Wróć do listy słowników
          </a>

          {canEdit && (
            <a
              className="dictionaries-form__goback is-uppercase has-text-weight-bold"
              href={editDictionary}
            >
              Edytuj
            </a>
          )}
        </div>
      </div>
    </section>
  );
};

export default DictionariesPreview;
