import "./Rules.scss";

import React, { useEffect, useState } from "react";

import CKEditor from "ckeditor4-react";
import CancelButton from "@components/CancelButton/CancelButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericTable from "@app/components/Table/GenericTable";
import Input from "@app/components/Input/Input";
import axiosSession from "@app/config/axiosSession";
import ckEditorConfig from "@app/config/ckEditor";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

const TermsEdit = ({ id, editMode }) => {
  const { handleSubmit } = useForm();
  const { notifyError } = useGenericToastify();
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [submitType, setSubmitType] = useState("");
  const [isActiveTerm, setIsActiveTerm] = useState(false);
  const [contentError, setContentError] = useState(null);
  const [version, setVersion] = useState("");

  const tableStructure = [
    {
      header: "Data utworzenia",
      field: "created",
      accessor: "created",
      className: "w-3",
      sort: true
    },
    {
      header: "Wersja",
      field: "version",
      accessor: "version",
      className: "w-3",
      sort: true
    },
    {
      header: "Status",
      field: "status",
      accessor: "status",
      className: "w-3",
      sort: true
    },
    {
      header: "Akcje",
      className: "w-2 has-text-right"
    }
  ];

  const urls = {
    get: "/api/rules/terms/",
    edit: "/rules/terms/", //appends /${id}/edit
    cancel: "/cancel?url=/rules/terms/"
  };

  const onPreview = item => {
    window.location = `${urls.edit}${item.id}/preview/`;
  };

  const onEdit = item => {
    window.location = `${urls.edit}${item.id}/edit/`;
  };

  const customActions = item => {
    return (
      <React.Fragment>
        <td className="has-text-right">
          {item.archive ? (
            <span
              onClick={() => onPreview(item)}
              onKeyPress={() => onPreview(item)}
              role="button"
              tabIndex="0"
              className="generic-table__table__link"
              title="Edycja"
            >
              <FontAwesomeIcon icon="eye" />
            </span>
          ) : (
            <span
              onClick={() => onEdit(item)}
              onKeyPress={() => onEdit(item)}
              role="button"
              tabIndex="0"
              className="generic-table__table__link"
              title="Edycja"
            >
              <FontAwesomeIcon icon="pen" />
            </span>
          )}
        </td>
      </React.Fragment>
    );
  };

  const onSubmit = data => {
    setIsLoading(true);
    let errorMessage = "";
    let method;
    let url;

    if (submitType === "preview") {
      method = isActiveTerm ? "POST" : "PUT";
      url = isActiveTerm ? "/api/rules/terms/" : `/api/rules/terms/${id}/`;
      data.active = false;
      errorMessage =
        "Wystąpił błąd podczas zapisywania wersji roboczej regulaminu";
    } else {
      method = "POST";
      url = "/api/rules/terms/";
      data.active = true;
      errorMessage = "Wystąpił błąd podczas publikowania regulaminu";
    }
    data.content = content;
    axiosSession({ method: method, url: url, data: data })
      .then(response => {
        window.location.href = response.data.url;
      })
      .catch(error => {
        notifyError(errorMessage);
        if (error.response.data.content) {
          setContentError(error.response.data.content);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      axiosSession
        .get(`/api/rules/terms/${id}/`)
        .then(response => {
          setContent(response.data.content);
          setIsActiveTerm(response.data.active);
          setVersion(response.data.version);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [id]);

  return (
    <section className="rules-form">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/rules/terms/"
            title="Regulaminy"
          >
            Regulaminy
          </a>
          &nbsp;/&nbsp;
          <strong>Edycja regulaminu</strong>
        </div>
        <div>
          <p className={"titleHeader is-size-4 has-text-black"}>
            Edycja regulaminu
          </p>
          <hr className="hr hr--orange hr--margin-top" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="columns is-variable">
            <div className="column is-5">
              <Input
                name={"version"}
                label={"Wersja"}
                disabled={!!id}
                value={version}
              />
              <div className="column"></div>
            </div>
          </div>
          <div className="columns is-centered is-variable is-12">
            <div className="column">
              <label htmlFor="content" className="label">
                Treść
              </label>
              <CKEditor
                config={ckEditorConfig}
                readOnly={!editMode}
                onBeforeLoad={CKEDITOR => {
                  CKEDITOR.disableAutoInline = true;
                }}
                label="Treść"
                name="content"
                data={content}
                onChange={evt => {
                  setContent(evt.editor.getData());
                }}
              />
              {contentError ? (
                <p className="help is-danger">{contentError}</p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div>
            <div className="buttons is-right">
              <CancelButton
                returnLocation={urls.cancel}
                bodyContentSufix="edycję regulaminu?"
              />

              {editMode ? (
                <React.Fragment>
                  <button
                    onClick={() => {
                      setSubmitType("preview");
                      return true;
                    }}
                    className={`button ${isLoading ? "is-loading" : ""}`}
                    type="submit"
                  >
                    Zapisz szkic
                  </button>
                  <button
                    onClick={() => {
                      setSubmitType("publish");
                      return true;
                    }}
                    className={`button button--is-orange ${
                      isLoading ? "is-loading" : ""
                    }`}
                    type="submit"
                  >
                    Opublikuj
                  </button>
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
          </div>
        </form>
        <GenericTable
          structure={tableStructure}
          urls={urls}
          hasHeaderActions={false}
          hasSearch={false}
          tableHeader="Historia"
          disableDelete={true}
          customActions={customActions}
        />
      </div>
    </section>
  );
};

TermsEdit.defaultProps = {
  editMode: true
};

export default TermsEdit;
