import "./DirectoryForm.scss";

import React, { useEffect, useState } from "react";

import DropdownButton from "@components/DropdownButton/DropdownButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericModal from "@components/Modal/GenericModal";
import GenericTable from "@components/Table/GenericTable";
import Stepper from "./Stepper";
import TruncateMarkup from "react-truncate-markup";
import axiosSession from "@app/config/axiosSession";
import fileSize from "filesize";

let submitType = "";

const GroupObjectUploadStepForm = ({
  id,
  nextStepChange,
  previousStepChange,
  stepLength
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [objectHasFile, setObjectHasFile] = useState(false);
  const [uploadedFileId, setUploadedFileId] = useState(null);
  const [modalType, setModalType] = useState("");
  const [stepIsLoaded, setStepIsLoaded] = useState(false);
  const [tableShouldRefresh, setTableShouldRefresh] = useState(false);

  const returnLocation =
    localStorage.backToDirectoriesSearch !== undefined
      ? "/directories/search/"
      : "/directories/";

  useEffect(() => {
    if (id) {
      setStepIsLoaded(id);
    }
  }, [id]);

  const handleDraftSubmit = () => {
    submitType = "draft";
    submitPhoto();
    return true;
  };

  const handleNextSubmit = () => {
    submitType = "next";
    submitPhoto();
    return true;
  };

  const callbackFileUploaded = data => {
    if (data && data.count) {
      setObjectHasFile(true);
      setUploadedFileId(data.results[0].id);
    } else {
      setObjectHasFile(false);
    }
  };

  const handlePreviousSubmit = () => {
    submitType = "previous";
    submitPhoto();
    return true;
  };

  const tableStructure = [
    {
      header: "Nazwa pliku",
      field: "name",
      accessor: "name",
      className: "w-4",
      sort: true
    },
    {
      header: "Typ",
      field: "file_type",
      accessor: "file_type",
      className: "w-3",
      sort: true
    },
    {
      header: "Rozmiar",
      field: "size",
      accessor: "size",
      className: "w-3",
      sort: true
    },
    {
      header: "Akcje",
      className: "w-2"
    }
  ];

  const onPreview = file_path => {
    window.open(file_path, "_blank").focus();
  };

  const customRow = item => {
    return (
      <React.Fragment key={item.id}>
        <tr id={`row-${item.id}`}>
          <td>
            <TruncateMarkup>
              <span title={item.name}>{item.name}</span>
            </TruncateMarkup>
          </td>
          <td>plik miniaturki (.jpg, .png)</td>
          <td>
            <span title={item.size}>{fileSize(item.size)}</span>
          </td>
          <td>
            <span
              onClick={() => onPreview(item.path)}
              onKeyPress={() => onPreview(item.path)}
              role="button"
              tabIndex="0"
              className="generic-table__table__link"
              title="Podgląd"
            >
              <FontAwesomeIcon icon="eye" />
            </span>
            <span
              onClick={openModal}
              onKeyPress={openModal}
              role="button"
              tabIndex="0"
              className="generic-table__table__link"
              title="Usuń"
            >
              <FontAwesomeIcon icon="trash" />
            </span>
          </td>
        </tr>
      </React.Fragment>
    );
  };

  const urls = {
    get: `/api/directories/${id}/thumbnails/`,
    delete: `/api/directories/${id}/thumbnails/`
  };

  const dialogMessages = () => {
    if (modalType === "addOption") {
      return {
        btnDeleteNo: "Nie",
        btnDeleteYes: "Tak",
        bodyContent:
          "W systemie znajduje się już miniaturka dla tego obiektu grupowego. Dodając nową miniaturkę usuniesz poprzednią. Czy chcesz kontynuować?"
      };
    } else if (modalType === "cancel") {
      let operationString = "obiektu grupowego";
      let operationTypeString = id ? "edycję" : "dodanie nowego";
      return {
        bodyContent:
          "Klikając przycisk anuluj sprawiasz, że dane nie zostaną zapisane. Czy na pewno chcesz anulować " +
          operationTypeString +
          " " +
          operationString +
          "?",
        headerTitle: "Anuluj",
        btnDeleteNo: "Nie",
        btnDeleteYes: "Tak"
      };
    } else {
      return {
        btnDeleteNo: "Anuluj",
        btnDeleteYes: "Usuń",
        headerTitle: "Potwierdzenie usunięcia",
        bodyContent: "Czy na pewno chcesz usunąć miniaturkę?",
        contentLabel: "Potwierdzenie usunięcia miniaturki"
      };
    }
  };

  const messages = {
    deleteSuccess: "Miniaturka została poprawnie usunięta",
    deleteError: "Wystąpił problem podczas usuwania miniaturki",
    btnDeleteNo: "Anuluj",
    btnDeleteYes: "Usuń",
    headerTitle: "Potwierdzenie usunięcia",
    bodyContent: "Czy na pewno chcesz usunąć miniaturkę?",
    contentLabel: "Potwierdzenie usunięcia miniaturki"
  };

  const submitPhoto = () => {
    if (submitType === "draft") {
      location.href = returnLocation;
    } else if (submitType === "previous") {
      previousStepChange(id);
    } else {
      nextStepChange(id);
    }
  };

  const addFile = () => {
    if (objectHasFile) {
      openModal("addOption");
    } else {
      axiosSession
        .get(urls.get)
        .then(res => {
          if (res.data.count > 0) {
            setUploadedFileId(res.data.results[0].id);
            setObjectHasFile(true);
            openModal("addOption");
          } else {
            openFileUploadWindow();
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  const openFileUploadWindow = () => {
    window
      .open(`/directories/${id}/group-object/file-upload/`, "_blank")
      .focus();
  };

  const openModal = type => {
    setModalType(type);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const onCancel = () => {
    closeModal();
  };
  const onConfirm = () => {
    closeModal();
    if (modalType === "cancel") {
      location.href = returnLocation;
    } else {
      axiosSession
        .delete(`${urls.delete}`)
        .then(() => {
          if (modalType === "addOption") {
            openFileUploadWindow();
          }
          setTableShouldRefresh(true);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setTableShouldRefresh(false);
        });
    }
  };

  return (
    <section className="directory-form">
      <GenericModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        labels={dialogMessages()}
        actionSubmit={onConfirm}
        actionCancel={onCancel}
      />
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/directories/"
            title="Lista katalogów"
          >
            Drzewo zasobów
          </a>
          &nbsp;/&nbsp;
          <strong>
            {id ? "Edycja" : "Dodawanie"} {"obiektu grupowego"}
          </strong>
        </div>

        <div className="directory-form__header">
          <h2 className="directory-form__header__title">
            {id ? "Edycja" : "Dodawanie"} {"obiektu grupowego"}
          </h2>
          <hr className="directory-form__header__hr" />
        </div>

        <Stepper activeStep={2} stepLength={stepLength} />

        <div className={"container"}>
          <div className="directory-form__container">
            <button
              className="button button--is-black"
              type="button"
              onClick={() => {
                addFile();
              }}
            >
              Dodaj plik
            </button>

            <span>
              Po naciśnięciu przycisku otworzy się nowe okno,
              <br />w którym będzie można wgrać pliki.
            </span>
          </div>

          {stepIsLoaded && (
            <GenericTable
              structure={tableStructure}
              hasPagination={false}
              messages={messages}
              hasRefreshAction={true}
              hasHeaderActions={false}
              disableDelete={true}
              customRowWithActions={customRow}
              dataCallback={callbackFileUploaded}
              urls={urls}
              hasSearch={false}
              shouldRefresh={tableShouldRefresh}
            />
          )}
        </div>
        <div className="column is-12">
          <div className="level-right">
            <button
              className="button"
              onClick={() => openModal("cancel")}
              title="Anuluj i wróć do listy"
              type="button"
            >
              Anuluj
            </button>
            <button
              className="button button--right"
              type="button"
              onClick={handlePreviousSubmit}
              title="Poprzedni krok"
            >
              Wstecz
            </button>
            <DropdownButton
              mainOption={{
                type: "submit",
                title: "Dalej",
                href: "",
                onClick: handleNextSubmit
              }}
              options={[
                {
                  type: "submit",
                  title: "Zapisz szkic",
                  href: "",
                  id: 1,
                  onClick: handleDraftSubmit
                }
              ]}
              onClick
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default GroupObjectUploadStepForm;
