import "./Rules.scss";

import React, { useEffect, useState } from "react";

import CKEditor from "ckeditor4-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericModal from "@components/Modal/GenericModal";
import GenericTable from "@components/Table/GenericTable";
import Input from "@components/Input/Input";
import axiosSession from "@app/config/axiosSession";
import ckEditorConfig from "@app/config/ckEditor";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

// TODO Refactor merge into agreementscreate.js

const messages = {
  headerTitle: "Anuluj",
  bodyContent:
    "Klikając przycisk anuluj sprawiasz, że dane nie zostaną zapisane. Czy na pewno chcesz anulować edycję zgody?",
  btnDeleteNo: "Nie",
  btnDeleteYes: "Tak"
};

const AgreementsEdit = ({ id, editMode }) => {
  const { handleSubmit, register, errors, reset, setError } = useForm();
  const { notifyError } = useGenericToastify();
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [submitType, setSubmitType] = useState("");
  const [isActiveAgreement, setIsActiveAgreement] = useState(false);
  const [parentAgreement, setParentAgreement] = useState(null);
  const [contentError, setContentError] = useState(null);
  const [version, setVersion] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const tableStructure = [
    {
      header: "Data utworzenia",
      field: "created",
      accessor: "created",
      className: "w-3",
      sort: true
    },
    {
      header: "Wersja",
      field: "version",
      accessor: "version",
      className: "w-3",
      sort: true
    },
    {
      header: "Status",
      field: "status",
      accessor: "status",
      className: "w-3",
      sort: true
    },
    {
      header: "Akcje",
      className: "w-2 has-text-right"
    }
  ];

  const urls = {
    get: `/api/rules/agreement/${id}/history/`,
    edit: "/rules/agreements/" //appends /${id}/edit
  };

  const onEdit = item => {
    window.location = `${urls.edit}${item.id}/edit/`;
  };

  const onPreview = item => {
    window.location = `${urls.edit}${item.id}/preview/`;
  };

  const customActions = item => {
    return (
      <React.Fragment>
        <td className="has-text-right">
          {item.archive ? (
            <span
              onClick={() => onPreview(item)}
              onKeyPress={() => onPreview(item)}
              role="button"
              tabIndex="0"
              className="generic-table__table__link"
              title="Edycja"
            >
              <FontAwesomeIcon icon="eye" />
            </span>
          ) : (
            <span
              onClick={() => onEdit(item)}
              onKeyPress={() => onEdit(item)}
              role="button"
              tabIndex="0"
              className="generic-table__table__link"
              title="Edycja"
            >
              <FontAwesomeIcon icon="pen" />
            </span>
          )}
        </td>
      </React.Fragment>
    );
  };

  const onSubmit = data => {
    setIsLoading(true);
    let errorMessage = "";
    let url = "";
    let method = "";

    if (submitType === "preview") {
      method = isActiveAgreement ? "POST" : "PUT";
      url = isActiveAgreement
        ? "/api/rules/agreements/"
        : `/api/rules/agreements/${id}/`;
      data.active = false;
      errorMessage = "Wystąpił błąd podczas zapisywania wersji roboczej zgody";
    } else {
      method = "POST";
      url = "/api/rules/agreements/";
      data.active = true;
      errorMessage = "Wystąpił błąd podczas publikowania zgody";
    }

    data.content = content;

    if (parentAgreement === null && method === "POST") {
      data.parent_agreement = id;
    } else if (parentAgreement !== null) {
      data.parent_agreement = parentAgreement;
    }

    axiosSession({ method: method, url: url, data: data })
      .then(response => {
        window.location.href = response.data.url;
      })
      .catch(error => {
        notifyError(errorMessage);
        if (error.response.data.content) {
          setContentError(error.response.data.content);
        }
        if (error.response.data.short_content) {
          setError("short_content", "", error.response.data.short_content);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      axiosSession
        .get(`/api/rules/agreements/${id}/`)
        .then(response => {
          reset(response.data);
          setContent(response.data.content);
          setIsActiveAgreement(response.data.active);
          setParentAgreement(response.data.parent_agreement);
          setVersion(response.data.version);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [id, reset]);

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const onConfirm = () => {
    window.location.href = `/cancel?url=/rules/agreements/`;

    closeModal();
  };

  const onCancel = () => {
    closeModal();
  };

  return (
    <section className="rules-form">
      <GenericModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        labels={messages}
        actionSubmit={onConfirm}
        actionCancel={onCancel}
      />
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/rules/agreements/"
            title="Zgody"
          >
            Zgody
          </a>
          &nbsp;/&nbsp;
          <strong>{editMode ? "Edycja " : "Podgląd"} zgody</strong>
        </div>

        <div>
          <p className={"titleHeader is-size-4 has-text-black"}>
            {editMode ? "Edycja " : "Podgląd"} zgody
          </p>
          <hr className="hr hr--orange hr--margin-top" />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="columns is-variable">
            <div className="column is-5">
              <Input
                name={"short_content"}
                label={"Skrócona treść zgody"}
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                disabled={!!id}
                isRequired
              />
              <Input
                name={"version"}
                label={"Wersja"}
                disabled={!!id}
                value={version}
              />
              <div className="column"></div>
            </div>
          </div>
          <div className="columns is-centered is-variable is-12">
            <div className="column">
              <label htmlFor="content" className="label">
                Treść
              </label>
              <CKEditor
                config={ckEditorConfig}
                readOnly={!editMode}
                onBeforeLoad={CKEDITOR => {
                  CKEDITOR.disableAutoInline = true;
                }}
                label="Treść *"
                name="content"
                data={content}
                onChange={evt => setContent(evt.editor.getData())}
              />
              {contentError ? (
                <p className="help is-danger">{contentError}</p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div>
            <div className="buttons is-right">
              <button
                className="button"
                onClick={openModal}
                title="Anuluj i wróć do listy"
                type="button"
              >
                {editMode ? "Anuluj" : "Zamknij"}
              </button>

              {editMode ? (
                <React.Fragment>
                  <button
                    onClick={() => {
                      setSubmitType("preview");
                      return true;
                    }}
                    className={`button ${isLoading ? "is-loading" : ""}`}
                    type="submit"
                  >
                    Zapisz szkic
                  </button>
                  <button
                    onClick={() => {
                      setSubmitType("publish");
                      return true;
                    }}
                    className={`button button--is-orange ${
                      isLoading ? "is-loading" : ""
                    }`}
                    type="submit"
                  >
                    Opublikuj
                  </button>
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
          </div>
        </form>
        <GenericTable
          structure={tableStructure}
          urls={urls}
          hasHeaderActions={false}
          hasSearch={false}
          tableHeader="Historia"
          disableDelete={true}
          customActions={customActions}
        />
      </div>
    </section>
  );
};

AgreementsEdit.defaultProps = {
  editMode: true
};

export default AgreementsEdit;
