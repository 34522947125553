import React, { useEffect, useState } from "react";

import Input from "@components/Input/Input";
import axiosSession from "@app/config/axiosSession";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

const ReportForm = ({ id }) => {
  const { handleSubmit, register, errors, reset, setError } = useForm();
  const { notifyError } = useGenericToastify();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      axiosSession
        .get(`/api/users/global_admins/${id}/`)
        .then(res => {
          reset(res.data);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [id, reset]);

  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/reports"
            title="Administratorzy"
          >
            Raporty
          </a>
          &nbsp;/&nbsp;
          <strong>{id ? "Edytuj" : "Dodaj"} raport</strong>
        </div>
        <div className="mbc-backoffice__header">
          <h2 className="mbc-backoffice__header__title">
            {id ? "Edycja" : "Dodawanie"} raportu
          </h2>
          <hr className="mbc-backoffice__header__hr" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="columns is-centered is-variable is-8">
            <div className="column">
              <Input
                disabled={!!id}
                name="report_number"
                label="Numer raportu"
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
              <Input
                disabled={!!id}
                name="Numer umowy"
                label="Numer umowy"
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
              <Input
                name="last_name"
                label="Nazwisko"
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
              <Input
                name="email"
                label="Adres E-MAIL"
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ReportForm;
