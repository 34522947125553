import "./SearchStatistics.scss";

import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericTable from "@app/components/Table/GenericTable";
import TruncateMarkup from "react-truncate-markup";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import genericAdvancedQuery from "@app/helpers/genericAdvancedQuery";

const SearchStatistics = () => {
  const [shouldTableRefresh, setShouldTableRefresh] = useState(false);

  const tableStructure = [
    {
      header: "Data",
      accessor: "date",
      className: "w-2",
      sort: true
    },
    {
      header: "Użytkownik",
      accessor: "user",
      className: "w-2"
    },
    {
      header: "Typ wyszukiwania",
      accessor: "advanced",
      className: "w-3"
    },
    {
      header: "Biblioteka",
      accessor: "library",
      className: "w-2"
    },
    {
      header: "Parametry wyszukiwania",
      accessor: "query",
      className: "w-4"
    },
    {
      header: "Akcje",
      accessor: "domain",
      className: "w-1"
    }
  ];

  const urls = { get: "/api/search/backoffice/historical-queries/" };

  const customRow = (item, index) => {
    return (
      <React.Fragment key={index}>
        <tr id={`row-${item.id}-${item.object_class}`}>
          <td>
            <TruncateMarkup>
              <span className="directories-search__truncate" title={item.date}>
                {item.date}
              </span>
            </TruncateMarkup>
          </td>
          <td>
            <TruncateMarkup>
              <span className="directories-search__truncate" title={item.user}>
                {item.user}
              </span>
            </TruncateMarkup>
          </td>
          <td>
            <TruncateMarkup>
              <span
                className="directories-search__truncate"
                title={item.advanced ? "Zaawansowany" : "Prosty"}
              >
                {item.advanced ? "Zaawansowany" : "Prosty"}
              </span>
            </TruncateMarkup>
          </td>
          <td>
            <TruncateMarkup>
              <a
                href={`/library/${item.library.id}/preview/`}
                className="grid-link"
                title={item.library.name}
              >
                {item.library.name}
              </a>
            </TruncateMarkup>
          </td>
          <td>
            <span
              className="directories-search__truncate"
              title={!item.advanced ? item.query : genericAdvancedQuery(item)}
            >
              {!item.advanced ? item.query : genericAdvancedQuery(item)}
            </span>
          </td>
          <td>
            <span
              className="directories-search__truncate has-text-right"
              title={item.full_url}
            >
              <a
                href={item.full_url}
                role="button"
                tabIndex="0"
                className="generic-table__table__link"
                title="Przejdź do wyniku wyszukiwania"
              >
                <FontAwesomeIcon icon={faLink} />
              </a>
            </span>
          </td>
        </tr>
      </React.Fragment>
    );
  };

  return (
    <section className="mbc-backoffice search-statistics">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Historia wyszukiwania</strong>
        </div>

        <GenericTable
          customRowWithActions={customRow}
          structure={tableStructure}
          urls={urls}
          hasHeaderActions={false}
          searchField="search"
          shouldRefresh={shouldTableRefresh}
          setShouldRefresh={setShouldTableRefresh}
        />
      </div>
    </section>
  );
};

export default SearchStatistics;
