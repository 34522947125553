import "./AttributesPreview.scss";

import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import AttributesPreviewBuilder from "@app/pages/Directories/AttributesPreviewBuilder";
import generateAttributesValueForPreview from "@app/helpers/generateAttributesValueForPreview";
import isEmpty from "lodash/isEmpty";

const AttributesPreview = ({
  languagesSchema,
  attributesSchema,
  attributes
}) => {
  const [attributesSchemaForPreview, setAttributesSchemaForPreview] = useState(
    {}
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const [languagesForPreview, setLanguagesForPreview] = useState([]);

  useEffect(() => {
    const generateAttributesList = async () => {
      const languageAttributes = {};
      let languages = [];
      for (let [language, attributesForLanguage] of Object.entries(
        attributes
      )) {
        languageAttributes[language] = await generateAttributesValueForPreview(
          attributesForLanguage,
          attributesSchema
        );
        if (!isEmpty(languageAttributes[language])) {
          languages = languages.concat(
            languagesSchema.filter(item => item.code === language)
          );
        }
      }
      setAttributesSchemaForPreview(languageAttributes);
      const pl = languages.find(obj => obj.code === "PL");
      setLanguagesForPreview(
        [pl].concat(languages.filter(obj => obj.code !== "PL"))
      );
    };
    generateAttributesList();
  }, [attributesSchema, languagesSchema, attributes]);

  return (
    <div className="attributes-preview">
      <header className="attributes-preview__header">Metadane</header>
      <div className="attributes-preview__languages-section">
        {languagesForPreview ? (
          <Tabs
            selectedIndex={selectedTab}
            onSelect={tabIndex => {
              setSelectedTab(tabIndex);
            }}
          >
            <TabList>
              {languagesForPreview.map(language => (
                <Tab key={language?.id}>Język {language?.name}</Tab>
              ))}
            </TabList>
            {languagesForPreview.map(language => (
              <TabPanel key={language?.id}>
                {attributesSchema.length &&
                attributesSchemaForPreview[language?.code] ? (
                  <AttributesPreviewBuilder
                    key={`mfb-${selectedTab}-${language?.id}`}
                    schema={attributesSchema}
                    data={attributesSchemaForPreview[language?.code]}
                  />
                ) : (
                  ""
                )}
              </TabPanel>
            ))}
          </Tabs>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AttributesPreview;
