import "./DirectoryForm.scss";

import React from "react";

const steps = [
  { id: 1, title: "1", label: "Krok 1", active: false },
  { id: 2, title: "2", label: "Krok 2", active: true },
  { id: 3, title: "3", label: "Krok 3", active: false },
  { id: 4, title: "4", label: "Krok 4", active: false }
];

const Stepper = ({ activeStep, stepLength }) => {
  return (
    <div className="directory-form__stepper">
      <div className="directory-form__stepper__track" />
      {steps
        .filter(step => step.id <= stepLength)
        .map(step => {
          return (
            <div className="directory-form__step" key={`step_${step.id}`}>
              <div
                className={`directory-form__step__title ${
                  activeStep === step.id
                    ? "directory-form__step__title--is-active"
                    : ""
                }`}
              >
                {step.title}
              </div>
              <div className="directory-form__step__label">{step.label}</div>
            </div>
          );
        })}
    </div>
  );
};

export default Stepper;
