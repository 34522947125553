import React, {useEffect, useState} from "react";
import {faEye, faLock, faPen} from "@fortawesome/free-solid-svg-icons";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GenericModal from "@app/components/Modal/GenericModal";
import GenericTable from "@app/components/Table/GenericTable";
import TruncateMarkup from "react-truncate-markup";
import axiosSession from "@app/config/axiosSession";
import useGenericToastify from "@app/hooks/useGenericToastify";

const tableStructure = [
  {
    header: "Ostatnia modyfikacja",
    accessor: "modified",
    className: "w-3",
    sort: true
  },
  {
    header: "Pełna nazwa",
    accessor: "full_name",
    className: "w-4",
    sort: true
  },
  {
    header: "Nazwa",
    accessor: "name",
    className: "w-2",
    sort: true
  },
  {
    header: "Status",
    accessor: "is_blocked",
    className: "w-1",
    sort: true
  },
  {
    header: "Akcje",
    className: "w-2 has-text-right",
    accessor: "actions"
  }
];

const urls = {
  get: "/api/library/",
  add: "/library/create/",
  edit: "/library/",
  delete: "/api/library/"
};

const Libraries = ({ message, hasCreatePermission }) => {
  const [deleteItemData, setDeleteItemData] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [shouldTableRefresh, setShouldTableRefresh] = useState(false);
  const { notifySuccess, notifyError } = useGenericToastify();

  const messages = {
    deleteSuccess: "Biblioteka została poprawnie zarchiwizowana",
    deleteError: "Wystąpił problem podczas archiwizacji biblioteki",
    btnAdd: "Dodaj bibliotekę",
    btnDeleteNo: "Nie, nie archiwizuj",
    btnDeleteYes: "Tak, archiwizuj",
    headerTitle: "Potwierdzenie archiwizacji",
    bodyContent: `Czy na pewno chcesz zarchiwizować bibliotekę ${deleteItemData.name}?`,
    contentLabel: "Potwierdzenie archiwizacji biblioteki"
  };

  useEffect(() => {
    if (shouldTableRefresh) {
      setShouldTableRefresh(false);
    }
  }, [shouldTableRefresh]);

  const displayStatus = item => {
    if (item.is_deleted) {
      return "zarchwizowany"
    } else if (item.is_blocked) {
      return "zablokowany"
    } else {
      return "aktywny"
    }
  }

  const onDelete = (name, id) => event => {
    setDeleteItemData({ id, name });
    openModal();
  };

  const onConfirmDelete = () => {
    axiosSession
      .delete(`${urls.delete}${deleteItemData.id}/`)
      .then(() => {
        notifySuccess(messages.deleteSuccess);
        //TODO: usunąć setTimeout, po poprawieniu indeksera dla bibliotek
        setTimeout(() => {
          setShouldTableRefresh(true);
        }, 2500);
      })
      .catch(error => {
        if (error.response.data.detail) {
          notifyError(error.response.data.detail);
        } else {
          notifyError(messages.deleteError);
        }
        console.error(error);
      })
      .finally(() => closeModal());
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const customRow = item => {
    return (
      <React.Fragment key={item.index}>
        <tr key={item.index}>
          <td>
            <TruncateMarkup>
              <span title={item.modified}>{item.modified}</span>
            </TruncateMarkup>
          </td>
          <td>
            <TruncateMarkup>
              <span title={item.full_name}>{item.full_name}</span>
            </TruncateMarkup>
          </td>
          <td>
            <TruncateMarkup>
              <span title={item.name}>{item.name}</span>
            </TruncateMarkup>
          </td>
          <td>
            <TruncateMarkup>
              <span title={displayStatus(item)}>
                {displayStatus(item)}
              </span>
            </TruncateMarkup>
          </td>
          <td className="has-text-right">
            {item.actions.viewable &&
            <a
              href={`${urls.edit}${item.id}/preview/`}
              tabIndex="0"
              className="generic-table__table__link"
              title="Podgląd"
            >
              <FontAwesomeIcon icon={faEye}/>
            </a>
            }
            {item.actions.editable &&
            <a
              href={`${urls.edit}${item.id}/edit/`}
              tabIndex="0"
              className="generic-table__table__link"
              title="Edycja"
            >
              <FontAwesomeIcon icon={faPen}/>
            </a>
            }
            {item.actions.removeable &&
            <span
              onClick={onDelete(item.name, item.id)}
              onKeyPress={onDelete(item.name, item.id)}
              role="button"
              tabIndex="0"
              className="generic-table__table__link"
              title="Archiwizuj"
            >
              <FontAwesomeIcon icon={faLock}/>
            </span>
            }
          </td>
        </tr>
      </React.Fragment>
    );
  };

  return (
    <section className="mbc-backoffice">
      <GenericModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        labels={messages}
        actionSubmit={onConfirmDelete}
      />
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Lista bibliotek</strong>
        </div>
        <GenericTable
          structure={tableStructure}
          customRowWithActions={customRow}
          notification={message}
          messages={messages}
          urls={urls}
          shouldRefresh={shouldTableRefresh}
          setShouldRefresh={setShouldTableRefresh}
          hasHeaderActions={hasCreatePermission}
        />
      </div>
    </section>
  );
};

export default Libraries;
