import Checkbox from "../../components/Checkbox/Checkbox";
import PropTypes from "prop-types";
import React from "react";

export default function CheckboxColumn({
  items,
  name,
  setPermissionFields,
  permissionFields,
  darkTheme
}) {
  return (
    <div className="column">
      {items.map(item => (
        <Checkbox
          key={`${item.name}-${item.isChecked}`}
          defaultChecked={item.isChecked}
          name={name}
          darkTheme={darkTheme}
          onChange={() => {
            setPermissionFields(
              permissionFields.map(field => {
                if (field.permId === item.permId) {
                  return { ...field, isChecked: !field.isChecked };
                }
                return field;
              })
            );
          }}
        >
          {item.name}
        </Checkbox>
      ))}
    </div>
  );
}

CheckboxColumn.propTypes = {
  items: PropTypes.array,
  name: PropTypes.string,
  setPermissionFields: PropTypes.func,
  permissionFields: PropTypes.array,
  darkTheme: PropTypes.bool
};

CheckboxColumn.defaultProps = {
  darkTheme: false
};
