import "./CollectionsTable.scss";

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosSession from "../../config/axiosSession";
import useGenericToastify from "../../hooks/useGenericToastify";

const CollectionsTable = ({ table }) => {
  const [data, setData] = useState([]);
  const { notifySuccess } = useGenericToastify();

  const deleteItem = (itemToDelete) => {
    const toDelete = data.find((item) => item.full_path === itemToDelete);
    const itemType = toDelete.object_class === 'obiekt_pojedynczy' ? 'digital-item' : 'group-object';

    axiosSession
      .delete(`/api/collections/${id}/object/${toDelete.id}/?type=${itemType}`)
      .then(() => {
        notifySuccess(`Usunięto przypisanie ${toDelete.full_path}`);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setData(data.filter((item) => item.full_path !== itemToDelete));
      });
  };

  useEffect(() => {
    setData(table);
  }, [])

  useEffect(() => {
    renderRows();
  }, [data])

  const renderRows = () => {
    return data.map((item) => {
      const { object_class } = item;
      const objectIcon = object_class === 'obiekt_pojedynczy' ? 'file' : 'copy';
      return (
        <tr key={item.full_path}>
          <td className="collections-preview collections-preview--has-padding-left">
            <FontAwesomeIcon icon={objectIcon} className="has-object" />
            {item.full_path}
          </td>
          <td className="collections-preview">
            {item.status}
          </td>
          <td className="collections-preview--has-text-centered">
            <FontAwesomeIcon
              icon="trash"
              className="collections-table__delete-btn"
              onClick={() => deleteItem(item.full_path)}
            />
          </td>
        </tr>
      )
    });
  }

  const renderEmptyRow = () => {
    return (
      <tr>
        <td>Brak przypisanych elementów</td>
      </tr>
    );
  };

  return (
    <>
      <h2 className="collections-preview__header__title collections-preview__padding">
        Przypisano do kolekcji
      </h2>
      <div className="columns collections-table">
        <div className="column is-three-fifths">
          <table className="collections-preview__table">
            <thead>
              <tr className="collections-preview--has-white-background collections-table__heading">
                <th className="collections-preview has-object">
                  Nazwa
                </th>
                <th className="collections-preview">
                  Status
                </th>
                <th className="collections-preview--has-text-centered">
                  Akcje
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? renderRows() : renderEmptyRow()}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
};

CollectionsTable.propTypes = {
  id: PropTypes.string.isRequired,
  table: PropTypes.array.isRequired
};

export default CollectionsTable;
