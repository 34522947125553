import GenericTable from "@components/Table/GenericTable";
import React from "react";
import TruncateMarkup from "react-truncate-markup";

const tableStructure = [
  {
    header: "Nazwa atrybutu",
    accessor: "name",
    className: "w-4",
    sort: true
  },
  {
    header: "Skrócony opis zawartości pola",
    accessor: "description",
    className: "w-4",
    sort: true
  },
  {
    header: "Status",
    accessor: "active",
    className: "w-2",
    sort: true
  },
  {
    header: "Akcje",
    className: "w-2 has-text-right"
  }
];

const urls = {
  get: "/api/attributes/",
  add: "/attributes/create/",
  edit: "/attributes/", //appends /${id}/edit
  delete: "/api/attributes/" //appends /${id}/
};

const messages = {
  deleteSuccess: "Atrybut został poprawnie usunięty",
  deleteError: "Wystąpił problem podczas usuwania atrybutu",
  btnAdd: "Dodaj atrybut",
  btnDeleteNo: "Anuluj",
  btnDeleteYes: "Usuń",
  headerTitle: "Potwierdzenie usunięcia",
  bodyContent: "Czy na pewno chcesz usunąć atrybut?",
  contentLabel: "Potwierdzenie usunięcia atrybutu"
};

const customRow = item => {
  return (
    <React.Fragment>
      <td>
        <TruncateMarkup>
          <span title={item.name}>{item.name}</span>
        </TruncateMarkup>
      </td>
      <td>
        <TruncateMarkup>
          <span title={item.description}>{item.description}</span>
        </TruncateMarkup>
      </td>
      <td>
        <TruncateMarkup>
          <span title={item.active ? "aktywny" : "nieaktywny"}>
            {item.active ? "aktywny" : "nieaktywny"}
          </span>
        </TruncateMarkup>
      </td>
    </React.Fragment>
  );
};

const Attributes = ({ message, hasCreatePermission }) => {
  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Atrybuty</strong>
        </div>

        <GenericTable
          structure={tableStructure}
          messages={messages}
          customRow={customRow}
          notification={message}
          urls={urls}
          hasHeaderActions={hasCreatePermission}
        />
      </div>
    </section>
  );
};

export default Attributes;
