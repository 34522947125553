import "./DirectoryForm.scss";
import "react-tabs/style/react-tabs.css";

import { FormContext, useForm } from "react-hook-form";
import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  getAttributesValue,
  getDigitalItem,
  getItemAttributesValue,
  getItemSchema,
  getSchema
} from "@app/services/services";

import ClipLoader from "react-spinners/ClipLoader";
import Cookies from "js-cookie";
import DropdownButton from "@app/components/DropdownButton/DropdownButton";
import GenericModal from "@app/components/Modal/GenericModal";
import  MapComponent  from "./MapComponent";
import { NewFormBuilder } from "@app/components/FormBuilder/NewFormBuilder";
import Stepper from "./Stepper";
import axiosSession from "@app/config/axiosSession";
import convertFormDataToValue from "@app/helpers/convertFormDataToValue";
import convertValueToFormData from "@app/helpers/convertValueToFormData";
import find from "lodash/find";
import handleErrors from "@app/helpers/handleErrors";
import isEmpty from "lodash/isEmpty";
import updateAction from "@app/actions/updateAction";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useStateMachine } from "little-state-machine";

let submitType = "";
let formTypeString = "";
const messages = {
  headerTitle: "Anuluj",
  bodyContent: "",
  btnDeleteNo: "Nie",
  btnDeleteYes: "Tak"
};

export const StorageReload = createContext(null);

const AttributesValueStep = ({
  id,
  nextStepChange,
  previousStepChange,
  formType,
  stepLength
}) => {
  const { action, state } = useStateMachine(updateAction);
  const { handleSubmit, reset, ...formMethods } = useForm({});
  const { notifyError } = useGenericToastify();
  const isMounted = useRef(null);
  const [apiData, setApiData] = useState([]);
  const [libraryLanguage, setLibraryLanguage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabsError, setTabsError] = useState({});
  const [fieldsLength, setFieldsLength] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [defaultValueBtnLoading, setDefaultValueBtnLoading] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [position, setPosition] = useState([]);
  const [showMap, setShowMap] = useState(false)


  const isGroup = formType === "group-object";
  const isDigitalItem = formType === "digital-item";

  const returnLocation =
    localStorage.backToDirectoriesSearch !== undefined
      ? "/directories/search/"
      : "/directories/";

  const handleDraftSubmit = () => {
    submitType = "draft";
    return true;
  };

  const handlePreviousSubmit = () => {
    submitType = "previous";
    handleSubmit(onSubmit)();
  };

  const handleNextSubmit = () => {
    submitType = "next";
    return true;
  };

  const setErrorMessage = useCallback(() => {
    if (isDigitalItem) {
      return "Nie udało się zapisać obiektu";
    } else if (isGroup) {
      return "Nie udało się zapisać obiektu grupowego";
    } else {
      return "Nie udało się zapisać katalogu.";
    }
  }, [isDigitalItem, isGroup]);

  const handleDefaultValueClick = async () => {
    if (id) {
      const get_values_url = isDigitalItem
        ? `/api/digital_items/${id}/default-values/`
        : `/api/directories/${id}/default-values/`;
      let defaultData = {};
      setDefaultValueBtnLoading(true);
      await axiosSession
        .get(get_values_url)
        .then(response => {
          defaultData = response.data;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => setDefaultValueBtnLoading(false));
      const newState = state.data;
      const convertedData = {};
      const fieldsSize = {};
      for (const [key, value] of Object.entries(defaultData)) {
        const { values, sizeDict } = await convertValueToFormData(
          value,
          apiData
        );
        convertedData[key] = values;
        fieldsSize[key] = sizeDict;
      }
      const activeLanguageCode = find(libraryLanguage, [
        "id",
        `${selectedTab + 1}`
      ]).code;
      setFieldsLength(origin => ({ ...origin, ...fieldsSize }));
      action({ ...newState, ...convertedData });
      reset({
        ...newState[activeLanguageCode],
        ...convertedData[activeLanguageCode]
      });
    }
  };



  const openModal = () => {
    messages.bodyContent = `Klikając przycisk anuluj sprawiasz, że dane nie zostaną zapisane. Czy na pewno chcesz anulować ${id ? "edycję" : "dodanie nowego"
      } ${formTypeString}?`;

    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const onCancel = () => {
    closeModal();
  };

  const onConfirm = () => {
    closeModal();
    location.href = returnLocation;
    localStorage.backToDirectoriesSearch &&
      localStorage.removeItem("backToDirectoriesSearch");
  };


  const countFieldsLength = (attributes, name, holdingObj) => {
    if (typeof attributes === "object" && !Array.isArray(attributes)) {
      for (const [key, value] of Object.entries(attributes)) {
        countFieldsLength(value, `${name}${name ? "." : ""}${key}`, holdingObj);
      }
    } else if (Array.isArray(attributes)) {
      holdingObj[`${name}`] = attributes?.length ?? 1;
      attributes.forEach((item, idx) => {
        countFieldsLength(item, `${name}[${idx}]`, holdingObj);
      });
    }
  };

  useEffect(() => {
    switch (formType) {
      case "digital-item":
        formTypeString = "obiektu";
        break;
      case "directory":
        formTypeString = "katalogu";
        break;
      case "group-object":
        formTypeString = "obiektu grupowego";
        break;
    }
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      window.sessionStorage.clear();
    };
  }, [formType]);

  useEffect(() => {
    const fetchData = async () => {
      const [schema, attributeValues, item] = await axiosSession.all([
        isDigitalItem ? getItemSchema(id) : getSchema(id),
        isDigitalItem ? getItemAttributesValue(id) : getAttributesValue(id),
        isDigitalItem ? getDigitalItem(id): ''
      ]);
      setShowMap(schema.data.show_map)

      if(item.data?.longitude && item.data?.latitude){
        setMarkers([{ lng: item.data.longitude, lat: item.data.latitude }])
      }

      setLibraryLanguage(schema.data.languages);
      const schemaForm = schema.data.attributes;
      const language = find(schema.data.languages, ["id", `${selectedTab + 1}`])
        .code;
      const attributesValue = !isEmpty(attributeValues.data[language])
        ? attributeValues.data
        : schema.data.default_values;
      const fieldsSize = {};
      const convertedData = {};
      setApiData(schemaForm);
      for (const [key, value] of Object.entries(attributesValue)) {
        const tempAttr = {};
        const { values, sizeDict } = await convertValueToFormData(
          value,
          schemaForm
        );
        countFieldsLength(value, "", tempAttr);
        fieldsSize[key] = tempAttr;
        convertedData[key] = values;
      }
      setFormLoading(false);

      setFieldsLength(fieldsSize);
      action(convertedData);
      reset(convertedData[language]);
    };
    if (id && isMounted.current) {
      setFormLoading(true);
      fetchData();
    }
  }, [id]);

  const onSubmit = async data => {
    let errors = {};
    const activeLanguageCode = find(libraryLanguage, [
      "id",
      `${selectedTab + 1}`
    ]).code;
    const activeLanguageFormData = {
      [activeLanguageCode]: data
    };
    const dataValues = {
      ...state.data,
      ...activeLanguageFormData
    };
    action(dataValues);
    const requestLanguagesData = [];
    for (let [key, value] of Object.entries(dataValues)) {
      // Nie wiem dlaczego przy innych bibliotekach na devce nie znajduje tutaj zadnego jezyka,
      // to szybki fix, jak nic nie znajdzie bedzie jezyk polski
      // TODO: find out why we can't find any active language
      const language = find(libraryLanguage, ["code", key])?.id || 1;
      const attributesValue = await convertFormDataToValue(value, apiData);
      let validateField = false;
      setIsLoading(true);
      if (
        (id || submitType !== "draft" || submitType !== "previous") &&
        (isGroup || isDigitalItem)
      ) {
        validateField = true;
      }

      const formData = {
        attributes: attributesValue,
        language: parseInt(language),
        validate_field: validateField,
        longitude: position?.longitude?.toFixed(5) ?? null,
        latitude: position?.latitude?.toFixed(5) ?? null,
      };
      const createAttributesUrl = isDigitalItem
        ? `/api/digital_items/${id}/values/`
        : `/api/directories/${id}/values/`;

      const csrftoken = Cookies.get("csrftoken");
      const requestData = fetch(createAttributesUrl, {
        method: "PUT",
        body: JSON.stringify(formData),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken
        }
      });
      requestLanguagesData.push(requestData);
    }
    await Promise.all([...requestLanguagesData])
      .then(responses => {
        return Promise.all(
          responses.map(async response => {
            if (response.ok) {
              return response.json();
            } else {
              throw await response.json();
            }
          })
        );
      })
      .then(data => { })
      .catch(error => {
        const message = setErrorMessage();

        notifyError(message);
        const languageErrors = error?.attributes?.errors ?? "";
        const language_id = error?.attributes?.language ?? null;
        const key = find(libraryLanguage, ["id", language_id]).code;
        if (languageErrors) {
          errors[key] = { ...languageErrors };
        }
        if (key === activeLanguageCode && languageErrors) {
          handleErrors(languageErrors, formMethods.setError);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setIsLoading(false);
        }
      });

    if (isEmpty(errors)) {
      if (submitType === "draft") {
        location.href = returnLocation;
        localStorage.backToDirectoriesSearch &&
          localStorage.removeItem("backToDirectoriesSearch");
      } else if (submitType === "previous") {
        previousStepChange(id);
      } else {
        nextStepChange(id);
      }
    } else {
      setTabsError(errors);
    }
  };

  const setItemPosition = (itemPos) => {
    setPosition({longitude: itemPos?.lng, latitude: itemPos?.lat})
  }

  return (
    <section className="directory-form">
      <GenericModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        labels={messages}
        actionSubmit={onConfirm}
        actionCancel={onCancel}
        showItemName={false}
      />
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/directories/"
            title="Lista katalogów"
          >
            Drzewo zasobów
          </a>
          &nbsp;/&nbsp;
          <strong>
            {id ? "Edycja" : "Dodawanie"} {formTypeString}
          </strong>
        </div>

        <div className="directory-form__header">
          <h2 className="directory-form__header__title">
            {id ? "Edycja" : "Dodawanie"} {formTypeString}
          </h2>
          <hr className="directory-form__header__hr" />
        </div>

        {isGroup || isDigitalItem ? (
          <Stepper activeStep={3} stepLength={stepLength} />
        ) : (
          <Stepper activeStep={2} stepLength={stepLength} />
        )}

        {!formLoading && showMap && <MapComponent addPoint={setItemPosition} initialMarkers={markers}></MapComponent>}

        <div className="columns is-variable is-10 is-multiline">
          <div className="column is-11">
            {!formLoading && (
              <button
                className={`button default-value-button ${defaultValueBtnLoading ? "is-loading" : ""
                  }`}
                type="button"
                onClick={() => {
                  handleDefaultValueClick();
                }}
              >
                Wklej wartości dziedziczone
              </button>
            )}
          </div>
        </div>
        {!formLoading && libraryLanguage ? (
          <Tabs
            selectedIndex={selectedTab}
            onSelect={tabIndex => {
              const oldLanguageCode = find(libraryLanguage, [
                "id",
                `${selectedTab + 1}`
              ]).code;
              const data = {
                [oldLanguageCode]: formMethods.getValues({ nest: true })
              };
              action({ ...state.data, ...data });
              const languageCode = find(libraryLanguage, [
                "id",
                `${tabIndex + 1}`
              ]).code;
              reset(state.data[languageCode]);
              setSelectedTab(tabIndex);
              if (!isEmpty(tabsError[languageCode])) {
                handleErrors(tabsError[languageCode], formMethods.setError);
              }
            }}
          >
            <TabList>
              {libraryLanguage.map(language => (
                <Tab key={language.id}>Język {language.name}</Tab>
              ))}
            </TabList>
            {libraryLanguage.map(language => (
              <TabPanel key={language.id}>
                <FormContext {...formMethods}>
                  <form
                    id="attributes-value-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="columns is-variable is-10 is-multiline">
                      <div className="column is-8">
                        {apiData.length ? (
                          <NewFormBuilder
                            key={`mfb-${selectedTab}-${language.id}`}
                            data={apiData}
                            fieldsLength={fieldsLength[language.code]}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="column is-12 directory-form__buttons">
                        <div className="level-right">
                          <button
                            onClick={openModal}
                            className="button"
                            title="Anuluj i wróć do listy"
                            type="button"
                          >
                            Anuluj
                          </button>
                          <button
                            className="button button--margin"
                            type="button"
                            onClick={handlePreviousSubmit}
                            title="Poprzedni krok"
                          >
                            Wstecz
                          </button>
                          <DropdownButton
                            mainOption={{
                              type: "submit",
                              title: "Dalej",
                              href: "",
                              onClick: handleNextSubmit
                            }}
                            isLoading={isLoading}
                            options={[
                              {
                                type: "submit",
                                title: "Zapisz szkic",
                                href: "",
                                id: 1,
                                onClick: handleDraftSubmit
                              }
                            ]}
                            onClick
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </FormContext>
              </TabPanel>
            ))}
          </Tabs>
        ) : (
          <div className="step-form-loader">
            <ClipLoader size={120} color={"black"} loading={formLoading} />
            <br />
            <p>Trwa ładowanie formularza.</p>
            <p>Proszę czekać.</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default AttributesValueStep;
