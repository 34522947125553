import "./GenericFieldArray.scss";

import { ErrorMessage, useFieldArray } from "react-hook-form";
import React, { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericInput from "@app/components/Input/GenericInput";
import PropTypes from "prop-types";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";

const GenericDoubleInputFieldArray = ({
  name,
  control,
  errors,
  addButtonText,
  appendOption,
  deleteButtonTitle,
  inputName,
  inputLabel,
  pattern,
  secondInputName,
  secondInputLabel,
  secondPattern,
  removeAllArray,
  setRemoveAllArray,
  disabled,
  message,
  isRequired,
  secondIsRequired
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: name
  });

  useEffect(() => {
    if (removeAllArray) {
      remove();
      setRemoveAllArray(false);
    }
  }, [remove, removeAllArray, setRemoveAllArray]);

  return (
    <div className="columns is-full is-multiline">
      {fields.length == 0 && (
        <span className="generic-field-array__message">{message}</span>
      )}

      {fields.map((item, index) => {
        return (
          <React.Fragment key={item.id}>
            <div className="column is-5">
              <GenericInput
                control={control}
                name={`${name}[${index}].${inputName}`}
                label={inputLabel}
                defaultValue={`${item[inputName]}`}
                darkTheme={false}
                required={
                  isRequired
                    ? { required: "To pole jest wymagane!", ...pattern }
                    : { ...pattern }
                }
                errors={errors}
                disabled={disabled}
              />
            </div>
            <div className="column is-5">
              <GenericInput
                control={control}
                name={`${name}[${index}].${secondInputName}`}
                label={secondInputLabel}
                defaultValue={`${item[secondInputName]}`}
                darkTheme={false}
                required={
                  secondIsRequired
                    ? { required: "To pole jest wymagane!", ...secondPattern }
                    : { ...secondPattern }
                }
                errors={errors}
                disabled={disabled}
              />
            </div>
            {disabled ? (
              ""
            ) : (
              <div className="column is-2">
                <div
                  className="generic_field_array__field-row__btn"
                  onClick={() => remove(index)}
                  onKeyPress={() => remove(index)}
                  role="button"
                  tabIndex="0"
                  title={deleteButtonTitle}
                >
                  <FontAwesomeIcon icon="trash" />
                </div>
              </div>
            )}
          </React.Fragment>
        );
      })}
      {disabled ? (
        ""
      ) : (
        <div className="column is-full">
          <button
            className="button button--is-black"
            type="button"
            onClick={() => {
              append(appendOption);
            }}
          >
            {addButtonText}
          </button>
        </div>
      )}

      {!isEmpty(errors) && has(errors, name) ? (
        <div className="column is-full">
          <ErrorMessage
            as={<p className="help is-danger" />}
            errors={errors}
            name={name}
          />
        </div>
      ) : null}
    </div>
  );
};

GenericDoubleInputFieldArray.propTypes = {
  isRequired: PropTypes.bool,
  secondIsRequired: PropTypes.bool,
  pattern: PropTypes.object,
  secondPattern: PropTypes.object
};

GenericDoubleInputFieldArray.defaultProps = {
  isRequired: true,
  secondIsRequired: true,
  pattern: {},
  secondPattern: {}
};

export default GenericDoubleInputFieldArray;
