import "./DictionariesEntriesForm.scss";

import * as qs from "query-string";

import React, { useEffect, useRef, useState } from "react";
import { format, parse } from "date-fns";
import { useFieldArray, useForm } from "react-hook-form";

import CancelButton from "@components/CancelButton/CancelButton";
import DatePicker from "react-datepicker";
import DropdownButton from "@app/components/DropdownButton/DropdownButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericInput from "@app/components/Input/GenericInput";
import GenericSelect from "@app/components/Select/GenericSelect";
import NestedSelect from "@app/components/Select/NestedSelect";
import PropTypes from "prop-types";
import axiosSession from "@app/config/axiosSession";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import findIndex from "lodash/findIndex";
import { getDict } from "@app/services/services";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import pl from "date-fns/locale/pl";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useTranslation } from "react-i18next";

const DictionariesEntriesForm = ({ id, dictID, message }) => {
  const {
    handleSubmit,
    register,
    errors,
    setValue,
    control,
    setError,
    reset
  } = useForm({});
  const { notifySuccess, notifyError } = useGenericToastify();
  const isMounted = useRef(null);
  const [visibility] = useState([
    { id: 1, name: "Aktywny", value: true },
    { id: 2, name: "Nieaktywny", value: false }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [dictionary, setDictionary] = useState({});
  const [languages, setLanguages] = useState([]);
  const [data, setData] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [inputError, setInputError] = useState("");
  const [defaultParent, setDefaultParent] = useState(false);
  const [defaultParentId, setDefaultParentId] = useState(false);
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "translations"
  });

  const minDate = new Date("1299-01-01");

  const hierarchicSelectUrl = `/api/dicts/${dictionary.id}/entries/hierarchic-select-data`;
  const returnLocation = `/cancel?url=/dictionaries/${dictionary.id}/edit/`;

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(message.messages)) {
      notifySuccess(message.messages[0]);
    }
  }, [message, notifySuccess]);

  useEffect(() => {
    const fetchData = async () => {
      const dict = await getDict(dictID);
      setDictionary(dict.data);
      const data = {};

      data.visibility = visibility[0];
      data.dictionary_name = dict.data.name;
      data.dictionary = dict.data.id;

      const params = qs.parse(window.location.search);
      if (has(params, "entry_id")) {
        const entryId = parseInt(params.entry_id);

        if (!isNaN(entryId)) {
          data.parent = entryId;
          setValue("parent", entryId);
          setDefaultParentId(entryId);
        } else {
          notifyError("Nieprawidłowa wartość dla słownika nadrzędnego!");
        }
      }
      reset(data);
    };

    if (isMounted.current && dictID) {
      fetchData();
    }
  }, [dictID, setValue, visibility, notifyError, reset]);

  useEffect(() => {
    if (defaultParentId) {
      axiosSession
        .get(`/api/dicts/entries/${defaultParentId}/`)
        .then(res => {
          setDefaultParent({
            ...res.data,
            id: defaultParentId,
            name: res.data.main_content
          });
        })
        .catch(err => console.error(err));
    }
  }, [defaultParentId]);

  useEffect(() => {
    if (id) {
      axiosSession.get(`/api/dicts/entries/${id}`).then(({ data }) => {
        let dataWithParentId = data;
        if (data.parent) {
          setDefaultParent(data.parent);
          dataWithParentId.parent = data.parent.id;
        } else {
          dataWithParentId.parent = false;
        }
        setData(dataWithParentId);
        setDictionary(data.dictionary);
      });
    }
  }, [id]);

  useEffect(() => {
    if (data.id) {
      if (data.start) {
        setStartDate(parse(data.start, "dd.MM.yyyy", new Date()));
      }
      if (data.end) {
        setEndDate(parse(data.end, "dd.MM.yyyy", new Date()));
      }
    }
  }, [data]);

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(dictionary)) {
      const dataTranslations = data.translations.map(translation => {
        return {
          id: translation.id,
          language:
            languages[
              findIndex(languages, ({ id }) => id === translation.language)
            ],
          content: translation.content
        };
      });
      data.dictionary = dictionary.id;
      data.dictionary_name = dictionary.name;
      const is_visible =
        visibility[
          findIndex(visibility, ({ value }) => value === data.is_visible)
        ];
      data.visibility = is_visible;
      data.translations = dataTranslations;
      reset(omit({ ...data }, "end", "start"));
    }
  }, [data, dictionary, languages, reset, setValue, visibility]);

  useEffect(() => {
    axiosSession
      .get("/api/dicts/languages/select-data/")
      .then(({ data }) => {
        setLanguages(data.results);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    !isEmpty(errors)
      ? notifyError("Nie udało się zapisać wartości słownikowej.")
      : null;
  }, [errors, notifyError]);

  const onSubmit = data => {
    setIsLoading(true);

    resetErrors();

    data.translations = !isEmpty(data.translations)
      ? data.translations.map(option => {
          return { content: option.content, language: option.language.id };
        })
      : [];

    data.is_visible = data.visibility.value;
    if (startDate) {
      data.start = format(startDate, "dd.MM.yyyy");
    } else {
      data.start = null;
    }

    if (endDate) {
      data.end = format(endDate, "dd.MM.yyyy");
    } else {
      data.end = null;
    }

    let method = "";
    let url = "";

    if (id) {
      method = "PUT";
      url = `/api/dicts/entries/${id}/`;
    } else {
      method = "POST";
      url = "/api/dicts/entries/";
    }

    if (!data.parent) {
      data.parent = null;
    }

    axiosSession({ method: method, url: url, data: data })
      .then(() => {
        if (data.action === "new") {
          window.location = `/dictionaries/${dictionary.id}/entries/create`;
        } else {
          window.location = `/dictionaries/${dictionary.id}/edit/`;
        }
      })
      .catch(error => {
        if (error.response.data.main_content) {
          setError("main_content", "", error.response.data.main_content);
        }
        if (error.response.data.parent) {
          setError("parent", "", error.response.data.parent);
        }
        if (error.response.data.is_visible) {
          setError("visibility", "", error.response.data.is_visible);
        }
        if (error.response.data.translations) {
          setError("translations", "", error.response.data.translations);
        }
        if (error.response.data.non_field_errors) {
          setInputError(error.response.data.non_field_errors[0]);
        }

        notifyError("Nie udało się zapisać wartości słownikowej.");
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetErrors = () => {
    setInputError(false);
  };

  return (
    <section className="dictionaries-entries-form">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href={`/dictionaries/`}
            title="Słowniki"
          >
            Słowniki
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href={`/dictionaries/${dictionary.id}/edit/`}
            title="Słownik"
          >
            Edycja słownika
          </a>
          &nbsp;/&nbsp;
          <strong>
            {id ? "Edycja wartości " : "Dodaj  wartości do "} słownika
          </strong>
        </div>

        <div className="dictionaries-entries-form__header">
          <h2 className="dictionaries-entries-form__header__title">
            {id ? "Edycja wartości" : "Dodawanie wartości"}
          </h2>
          <hr className="dictionaries-entries-form__header__hr" />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="columns is-variable">
            <div className="column is-half-desktop is-full-mobile">
              <input type="hidden" name="dictionary" ref={register} />
              <input type="hidden" value="add" name="action" ref={register} />
              <GenericInput
                control={control}
                name="dictionary_name"
                label="Słownik"
                darkTheme={false}
                disabled={true}
                errors={errors}
                register={register}
              />
              {dictionary.is_flat || dictionary.source || !dictionary.id ? (
                ""
              ) : (
                <NestedSelect
                  name="parent"
                  url={hierarchicSelectUrl}
                  placeholder="Wybierz nadrzędny"
                  label="Nadrzędny"
                  control={control}
                  errors={errors}
                  darkTheme={false}
                  disabled={false}
                  defaultParent={defaultParent}
                />
              )}
              {dictionary.source ? (
                <GenericInput
                  control={control}
                  name="main_content"
                  label="Nazwa"
                  darkTheme={false}
                  errors={errors}
                  required
                  drawer
                  dictionary_id={dictID}
                />
              ) : (
                <GenericInput
                  control={control}
                  name="main_content"
                  label="Nazwa"
                  darkTheme={false}
                  errors={errors}
                  required
                />
              )}

              <GenericInput
                control={control}
                name="key"
                label="KLUCZ"
                placeholder="np: DW - Wrocław - Breslau"
                darkTheme={false}
                required={{ required: false }}
                errors={errors}
              />

              {dictionary.is_time_limited ? (
                <div className="dictionaries-entries-form__section columns">
                  <div className="column is-3">
                    <div className="field">
                      <label htmlFor="start" className="label">
                        Czas trwania
                      </label>
                      <div className="control mbc-input">
                        <DatePicker
                          name="start"
                          className="input"
                          locale={pl}
                          dateFormat="dd.MM.yyyy"
                          selected={startDate}
                          onChange={date => {
                            setStartDate(date);
                            if (date >= endDate) setEndDate();
                          }}
                          minDate={minDate}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                      {!isEmpty(errors) && has(errors, "start")
                        ? errors["start"].message.map((message, index) => (
                            <p key={index} className="help is-danger">
                              {message}
                            </p>
                          ))
                        : null}
                    </div>
                  </div>
                  <div className="column is-3">
                    <div className="field">
                      <label htmlFor="end" className="label">
                        &nbsp;
                      </label>
                      <div className="control mbc-input">
                        <DatePicker
                          name="end"
                          className="input"
                          locale={pl}
                          dateFormat="dd.MM.yyyy"
                          selected={endDate}
                          onChange={date => setEndDate(date)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </div>
                      {!isEmpty(errors) && has(errors, "end")
                        ? errors["end"].message.map((message, index) => (
                            <p key={index} className="help is-danger">
                              {message}
                            </p>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="dictionaries-entries-form__section">
            <button
              className="button button--is-black"
              type="button"
              onClick={() => {
                append({ language: { id: "", name: "" }, content: "" });
              }}
            >
              Dodaj tłumaczenie
            </button>
          </div>
          <div>
            {!isEmpty(errors) && has(errors, "translations.message")
              ? errors["translations"].message.map((message, index) => (
                  <p key={index} className="help is-danger">
                    {message}
                  </p>
                ))
              : null}
          </div>
          <div className="dictionaries-entries-form__section">
            {fields.map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  <div className="columns">
                    <div className="column is-half-desktop">
                      <div className="columns">
                        <div className="column is-5">
                          <GenericSelect
                            options={languages}
                            placeholder="Wybierz język"
                            control={control}
                            required={{
                              required: "To pole jest wymagane!",
                              validate: value =>
                                value.id !== "" || "To pole jest wymagane!"
                            }}
                            defaultValue={item.language}
                            name={`translations[${index}].language`}
                            errors={errors}
                            label="Język tłumaczenia"
                          />
                        </div>
                        <div className="column is-7 d-flex">
                          <div className="w-100">
                            <GenericInput
                              control={control}
                              name={`translations[${index}].content`}
                              label="Tłumaczenie"
                              defaultValue={`${item.content}`}
                              darkTheme={false}
                              required={{ required: "To pole jest wymagane!" }}
                              errors={errors}
                            />
                          </div>
                          <div className="item-m">
                            <span
                              className="dictionaries-entries-form__field-row__btn"
                              onClick={() => remove(index)}
                              onKeyPress={() => remove(index)}
                              role="button"
                              tabIndex="0"
                              title="Usuń tłumaczenie"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>

          <div className="dictionaries-entries-form__section columns">
            <div className="column is-half-desktop is-full-mobile">
              <GenericSelect
                options={visibility}
                placeholder="Wybierz status"
                control={control}
                required={{ required: "To pole jest wymagane!" }}
                name="visibility"
                errors={errors}
                label="Status"
              />
            </div>
          </div>

          <div>
            {inputError ? (
              <div className="level is-danger is-size-6">
                <div className="level-left section-danger has-text-danger">
                  <span className="level-item icon">
                    <i className="fas fa-exclamation-triangle" />
                  </span>
                  {inputError}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="column is-12">
              <div className="level-right button-dictionaries-panel">
                <CancelButton
                  returnLocation={returnLocation}
                  bodyContentSufix={`${
                    id ? "edycję" : "dodanie nowej"
                  } wartości?`}
                />
                {id ? <button
                    type="submit"
                    onClick={() => { setValue("action", "edit") }}
                    className="button button--is-orange button--save">
                    {t("cms.dictionaries.save")}
                    </button>
                    : <DropdownButton
                    mainOption={{
                    type: "submit",
                    title: "Dodaj i utwórz nową",
                    href: "",
                    onClick: () => {
                      setValue("action", "new");
                    }
                  }}
                  isLoading={isLoading}
                  options={[
                    {
                      type: "submit",
                      title: "Dodaj",
                      href: "",
                      id: 1,
                      onClick: () => {
                        setValue("action", " ");
                      }
                    }
                  ]}
                />}
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

DictionariesEntriesForm.propTypes = {
  id: PropTypes.string,
  dictID: PropTypes.string
};

DictionariesEntriesForm.defaultProps = {
  id: "",
  dictID: ""
};

export default DictionariesEntriesForm;
