import "@app/scss/variables.scss";

import React, { useEffect, useState } from "react";

import Preview from "@app/components/Preview/Preview";
import axiosSession from "@app/config/axiosSession";

const GroupPreview = ({ id }) => {
  const [rows, setRows] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [canEdit, setCanEdit] = useState(false);

  const urls = {
    getGroupInfo: `/api/users/groups/${id}/`,
    editGroup: `/users/groups/${id}/edit/`
  };

  useEffect(() => {
    if (id) {
      axiosSession
        .get(urls.getGroupInfo)
        .then(({ data }) => {
          setRows([
            { title: "Nazwa", value: [data.name] },
            { title: "Biblioteka", value: [data.library.name] }
          ]);
          setPermissions(data.permissions);
          setCanEdit(data.can_edit_permission);
        })
        .catch(err => console.error(err));
    }
  }, [id]);

  useEffect(() => {
    if (permissions) {
      axiosSession
        .get("/api/users/permissions/")
        .then(({ data }) => {
          const perms = [];
          permissions.forEach(permissionId => {
            const permission = data.find(elem => elem.id == permissionId);
            if (permission != undefined) {
              perms.push(permission.name);
            }
          });
          setRows(rows.concat({ title: "Uprawnienia", value: perms }));
        })
        .catch(err => console.error(err));
    }
  }, [permissions]);

  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/users/groups/"
            title="Biblioteki"
          >
            Grupy
          </a>
          &nbsp;/&nbsp;
          <strong>Podgląd</strong>
        </div>

        <div className="mbc-backoffice__header">
          <h2 className="mbc-backoffice__header__title">Podgląd grupy</h2>
          <hr className="mbc-backoffice__header__hr" />
        </div>

        {rows && <Preview rows={rows} />}

        <div className="mbc-backoffice__bottom">
          <a
            className="mbc-backoffice__goback is-uppercase has-text-weight-bold"
            href="/users/groups/"
          >
            &lt; Wróć do listy grup
          </a>

          {canEdit &&
          <a
            className="mbc-backoffice__goback is-uppercase has-text-weight-bold"
            href={urls.editGroup}
          >
            Edytuj
          </a>}
        </div>
      </div>
    </section>
  );
};

export default GroupPreview;
