import "./NewFormBuilder.scss";

import React, { useEffect, useState } from "react";
import {
  faPlusCircle,
  faSearch,
  faTrash
} from "@fortawesome/free-solid-svg-icons";

import DictionariesCheckExternalValue from "@app/pages/Dictionaries/DictionariesCheckExternalValue";
import { Drawer } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericAsyncSelect from "@app/components/Select/GenericAsyncSelect";
import GenericDatePicker from "@app/components/GenericDatePicker/GenericDatePicker";
import GenericInput from "@app/components/Input/GenericInput";
import Modal from "react-responsive-modal";
import NestedSelect from "@app/components/Select/NestedSelect";
import SimpleDictionariesEntriesForm from "@app/pages/Dictionaries/SimpleDictionariesEntriesForm";
import { useFormContext } from "react-hook-form";

const FieldsContext = React.createContext({});

const TextField = props => {
  const { control, errors } = useFormContext();
  const { isArray } = props;
  const resultProps = { ...props, control, errors };
  return (
    <div className={`column is-${isArray ? "12" : "11"}`}>
      <GenericInput {...resultProps} required={{ required: false }} />
    </div>
  );
};

const TextFieldWithDrawer = props => {
  const { control, errors } = useFormContext();
  const { isArray } = props;
  const resultProps = { ...props, control, errors };
  return (
    <div className={`column is-${isArray ? "12" : "11"}`}>
      <GenericInput {...resultProps} required={{ required: false }} drawer />
    </div>
  );
};

const TextAreaField = props => {
  const { control, errors } = useFormContext();
  const { isArray } = props;
  const resultProps = { ...props, control, errors };
  return (
    <div className={`column is-${isArray ? "12" : "11"}`}>
      <GenericInput
        {...resultProps}
        required={{ required: false }}
        tag="textarea"
      />
    </div>
  );
};

const NumberField = props => {
  const { control, errors } = useFormContext();
  const pattern = {
    pattern: {
      value: /[0-9]/,
      message: "Podana wartosć nie jest wartością liczbową"
    }
  };
  const { isArray } = props;
  const resultProps = { ...props, control, errors };
  return (
    <div className={`column is-${isArray ? "12" : "11"}`}>
      <GenericInput {...resultProps} required={{ required: false, pattern }} />
    </div>
  );
};

const DateField = props => {
  const { control, errors } = useFormContext();
  const { isArray } = props;
  const resultProps = { ...props, control, errors };

  return (
    <div className={`column is-${isArray ? "12" : "11"}`}>
      <GenericDatePicker {...resultProps} required={{ required: false }} />
    </div>
  );
};

const SelectField = props => {
  const { control, errors } = useFormContext();
  const {
    dictionary_url,
    frequent,
    dictionary_id,
    creatable,
    isFlat,
    dictionaryDefaultValue,
    isArray
  } = props;
  const resultProps = { ...props, control, errors };
  const isMounted = React.useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [reloadSelect, setReloadSelect] = useState(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current || reloadSelect) {
      setReloadSelect(false);
    }
  }, [reloadSelect]);

  return (
    <div className="columns is-full is-multiline">
      <div
        className={`column is-${isArray ? (creatable ? "11" : "12") : "11"} `}
      >
        {isFlat ? (
          <GenericAsyncSelect
            {...resultProps}
            required={{ required: false }}
            isMulti={frequent}
            dictionary_url={dictionary_url}
            reloadSelect={reloadSelect}
          />
        ) : (
          <NestedSelect
            {...resultProps}
            required={{ required: false }}
            multiple={frequent}
            url={dictionary_url}
            defaultParent={dictionaryDefaultValue}
          />
        )}
      </div>
      {creatable && (
        <div
          className="column is-1"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "3.5%"
          }}
        >
          <FontAwesomeIcon
            icon={faPlusCircle}
            onClick={() => {
              setOpenModal(true);
            }}
            data-tooltip="Dodaj nową wartość"
          />
        </div>
      )}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        classNames={{
          modal: "GenericModal"
        }}
      >
        <div className="GenericModal__Header--with-border">
          <p className="GenericModal__Header__Title">
            Dodaj wartość słownikową
          </p>
        </div>
        <div className="GenericModal__Body">
          <SimpleDictionariesEntriesForm
            dictID={dictionary_id}
            setOpenModal={setOpenModal}
            setReloadSelect={setReloadSelect}
          />
        </div>
      </Modal>
    </div>
  );
};

const fieldMapping = {
  text: TextField,
  date: DateField,
  number: NumberField,
  select: SelectField,
  textarea: TextAreaField,
  text_with_drawer: TextFieldWithDrawer
};

const ArrayField = ({ field, size }) => {
  const [datas, setData] = useState([{ id: 0 }]);

  React.useEffect(() => {
    if (size > 1) {
      const newDatas = [...Array(size)].map((_, i) => ({ id: i }));
      setData(newDatas);
    }
  }, [size]);
  const renderedFields = [];
  const remove = removedId => {
    setData(origin => origin.filter(data => data.id !== removedId));
  };

  const add = () => {
    setData([...datas, { id: datas.length }]);
  };

  for (const [index, data] of datas.entries()) {
    let dataId = data.id;
    const fieldsData = [
      {
        ...field,
        name: `${field.name}[${dataId}]`,
        type: field.subType,
        showLabel: !(field.subType !== "group" && index > 0)
      }
    ];
    renderedFields.push(
      <div key={dataId} className="columns is-full is-multiline">
        <div className="column is-11" style={{ paddingBottom: "0" }}>
          <Form fields={fieldsData} isArray />
        </div>
        {index !== 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginTop: "3.5%"
            }}
            onClick={() => remove(dataId)}
            onKeyPress={() => remove(dataId)}
            role="button"
            tabIndex="0"
            title="Usuń wartość atrybutu"
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
  return (
    <>
      <div>{renderedFields}</div>
      <div className="column is-full array-field-column">
        <span
          className="array-field-add-button is-uppercase column has-text-weight-bold"
          role="button"
          tabIndex="0"
          onClick={() => add()}
          onKeyPress={() => add()}
        >
          Dodaj kolejną wartość
        </span>
      </div>
    </>
  );
};

export const Form = ({ fields, isArray = false }) => {
  const [visible, setVisible] = useState(false);
  const fieldsLength = React.useContext(FieldsContext);
  return (
    <>
      {fields.map(field => {
        switch (field.type) {
          case "array": {
            if (field.subType === "select") {
              const newField = { ...field, type: field.subType };
              return <Form fields={[newField]} />;
            }

            const size = fieldsLength?.[field.name] ?? 1;
            return <ArrayField key={field.name} field={field} size={size} />;
          }
          case "group": {
            const showDrawer = () => {
              setVisible(true);
            };

            const handleClose = () => {
              setVisible(false);
            };
            const fields = field.fields.map(item => ({
              ...item,
              name: `${field.name}.${item.name}`
            }));
            return (
              <ul className="group-field" key={field.name}>
                <div className="group-field__title">
                  <p className="group-field__title__label">{field.label}</p>
                  {field.is_external && (
                    <div
                      className="label-with-drawer__button"
                      onClick={showDrawer}
                      onKeyPress={showDrawer}
                      role="button"
                      tabIndex="0"
                      title="Wyszukaj rekord wzorcowy"
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </div>
                  )}
                </div>
                <div className="group-field__indentation">
                  <Drawer
                    width={400}
                    placement="right"
                    closable={true}
                    onClose={handleClose}
                    visible={visible}
                    mask={false}
                  >
                    <DictionariesCheckExternalValue
                      dictID={field.dictionary_id}
                    />
                  </Drawer>
                  <Form fields={fields} isArray={isArray} />
                </div>
              </ul>
            );
          }
          default:
            // eslint-disable-next-line no-case-declarations
            const FieldComponent = fieldMapping[field.type];
            if (FieldComponent !== undefined) {
              return (
                <React.Fragment key={field.name}>
                  <FieldComponent
                    isArray={isArray}
                    name={field.name}
                    label={field.marc_label ? `${field.label} (${field.marc_label})` : `${field.label}`}
                    dictionary_url={field.dictionary_url}
                    dictionary_id={field.dictionary_id}
                    frequent={field.frequent}
                    creatable={field.creatable}
                    isFlat={field.is_flat}
                    dictionaryDefaultValue={field.dictionary_default_value}
                    showLabel={field?.showLabel ?? true}
                  />
                </React.Fragment>
              );
            }
        }
      })}
    </>
  );
};

export function NewFormBuilder({ data, fieldsLength }) {
  return (
    <FieldsContext.Provider value={fieldsLength}>
      <Form fields={data} />
    </FieldsContext.Provider>
  );
}
