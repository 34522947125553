import "./DirectoryForm.scss";

import React, { useEffect, useState } from "react";
import { changeFileType, getFileTypes } from "@app/services/services";

import DropdownButton from "@components/DropdownButton/DropdownButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericModal from "@components/Modal/GenericModal";
import GenericSelect from "@components/Select/GenericSelect";
import GenericTable from "@components/Table/GenericTable";
import Stepper from "./Stepper";
import TruncateMarkup from "react-truncate-markup";
import axiosSession from "@app/config/axiosSession";
import fileSize from "filesize";
import findIndex from "lodash/findIndex";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

let submitType = "";

const DigitalItemUploadStepForm = ({
  id,
  nextStepChange,
  previousStepChange,
  stepLength
}) => {
  const { errors, control, getValues, setValue } = useForm({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState({});
  const [fileTypes, setFileTypes] = useState([]);
  const [modalType, setModalType] = useState("");
  const [stepIsLoaded, setStepIsLoaded] = useState(false);
  const { notifySuccess, notifyError } = useGenericToastify();
  const [tableShouldRefresh, setTableShouldRefresh] = useState(false);
  const [fileId, setFileId] = useState(null);

  const returnLocation =
    localStorage.backToDirectoriesSearch !== undefined
      ? "/directories/search/"
      : "/directories/";

  const changeType = async (formData, file, selectValue) => {
    const values = getValues({ nest: true })["file_type"];

    try {
      await changeFileType(id, file.id, formData);
      notifySuccess("Typ pliku został zmieniony poprawnie");
      setSelectedFileType({
        ...values,
        [file.id]: selectValue
      });
    } catch (error) {
      selectValue = values?.[file.id];
      if (error.response.data.file_type) {
        notifyError(error.response.data.file_type[0]);
      } else {
        notifyError("Wystąpił problem podczas zmiany typu pliku");
      }
    }

    setValue(`file_type[${file.id}]`, selectValue);
  };

  const onFileTypeSelectedChange = file => ([selectedOption]) => {
    const formData = {
      file_type: selectedOption.id,
      path: file.path,
      filename: file.filename
    };

    changeType(formData, file, selectedOption);

    return selectedOption;
  };

  const handleDraftSubmit = () => {
    submitType = "draft";
    submitPhoto();
    return true;
  };

  const handleNextSubmit = () => {
    submitType = "next";
    submitPhoto();
    return true;
  };

  const handlePreviousSubmit = () => {
    submitType = "previous";
    submitPhoto();
    return true;
  };

  const tableStructure = [
    {
      header: "Nazwa pliku",
      field: "filename",
      accessor: "name",
      className: "w-4",
      sort: true
    },
    {
      header: "Typ",
      field: "file_type",
      accessor: "file_type",
      className: "w-3",
      sort: true
    },
    {
      header: "Rozmiar",
      field: "size",
      accessor: "size",
      className: "w-3",
      sort: true
    },
    {
      header: "Akcje",
      className: "w-2"
    }
  ];

  const fileType = index => {
    return fileTypes[findIndex(fileTypes, ({ id }) => id === index)];
  };

  const downloadFile = (url, filename) => {
    axiosSession
      .request({
        url: url,
        method: "GET",
        responseType: "blob"
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  const customRow = item => {
    return (
      <React.Fragment key={item.id}>
        <tr id={`row-${item.id}`}>
          <td>
            <TruncateMarkup>
              <span title={item.filename}>{item.filename}</span>
            </TruncateMarkup>
          </td>
          <td>
            <GenericSelect
              options={fileTypes}
              compactView
              placeholder="Typ pliku"
              control={control}
              errors={errors}
              defaultValue={
                selectedFileType?.[item.id] ?? fileType(item.file_type)
              }
              handleSelectChange={onFileTypeSelectedChange(item)}
              name={`file_type[${item.id}]`}
            />
          </td>
          <td>
            <span title={item.size}>{fileSize(item.size)}</span>
          </td>
          <td>
            <span
              onClick={() => downloadFile(item.path, item.filename)}
              onKeyPress={() => downloadFile(item.path, item.filename)}
              role="button"
              tabIndex="0"
              className="generic-table__table__link"
              title="Pobierz"
            >
              <FontAwesomeIcon icon="file-download" />
            </span>
            <span
              onClick={() => deleteFile(item.id)}
              onKeyPress={() => deleteFile(item.id)}
              role="button"
              tabIndex="0"
              className="generic-table__table__link"
              title="Usuń"
            >
              <FontAwesomeIcon icon="trash" />
            </span>
          </td>
        </tr>
      </React.Fragment>
    );
  };
  const urls = {
    get: `/api/digital_items/${id}/files/`,
    delete: `/api/digital_items/${id}/files/`
  };

  const submitPhoto = () => {
    if (submitType === "draft") {
      location.href = returnLocation;
    } else if (submitType === "previous") {
      previousStepChange(id);
    } else {
      nextStepChange(id);
    }
  };

  const openFileUploadWindow = () => {
    window
      .open(`/directories/${id}/digital-item/file-upload/`, "_blank")
      .focus();
  };

  const dialogMessages = () => {
    if (modalType === "cancel") {
      return {
        bodyContent: `Klikając przycisk anuluj sprawiasz, że dane nie zostaną zapisane. Czy na pewno chcesz anulować ${
          id ? "edycję" : "dodanie nowego"
        } obiektu pojedynczego?`,
        headerTitle: "Anuluj",
        btnDeleteNo: "Nie",
        btnDeleteYes: "Tak"
      };
    } else {
      return {
        btnDeleteNo: "Anuluj",
        btnDeleteYes: "Usuń",
        headerTitle: "Potwierdzenie usunięcia",
        bodyContent: "Czy na pewno chcesz usunąć plik?",
        contentLabel: "Potwierdzenie usunięcia pliku"
      };
    }
  };

  const openModal = type => {
    setModalType(type);
    setModalIsOpen(true);
  };

  const deleteFile = id => {
    setFileId(id);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const onCancel = () => {
    closeModal();
  };
  const onConfirm = () => {
    closeModal();
    if (modalType === "cancel") {
      location.href = returnLocation;
    } else {
      axiosSession
        .delete(`${urls.delete}${fileId}/`)
        .then(() => {
          setTableShouldRefresh(true);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setTableShouldRefresh(false);
        });
    }
  };

  useEffect(() => {
    if (id) {
      setStepIsLoaded(!!id);
    }
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fileTypes = await getFileTypes();
        setFileTypes(fileTypes.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <section className="directory-form">
      <GenericModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        actionSubmit={onConfirm}
        actionCancel={onCancel}
        labels={dialogMessages()}
        showItemName={false}
      />
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/directories/"
            title="Lista katalogów"
          >
            Drzewo zasobów
          </a>
          &nbsp;/&nbsp;
          <strong>
            {id ? "Edycja" : "Dodawanie"} {"obiektu"}
          </strong>
        </div>

        <div className="directory-form__header">
          <h2 className="directory-form__header__title">
            {id ? "Edycja" : "Dodawanie"} {"obiektu"}
          </h2>
          <hr className="directory-form__header__hr" />
        </div>

        <Stepper activeStep={2} stepLength={stepLength} />

        <div className={"container"}>
          <div className="directory-form__container">
            <button
              className="button button--is-black"
              type="button"
              onClick={() => {
                openFileUploadWindow();
              }}
            >
              Dodaj pliki
            </button>

            <span>
              Po naciśnięciu przycisku otworzy się nowe okno,
              <br />w którym będzie można wgrać pliki.
            </span>
          </div>

          {stepIsLoaded && (
            <GenericTable
              structure={tableStructure}
              hasFilters
              isFileFilter
              filtersOptions={fileTypes}
              filterLabel="Typ pliku"
              filtersValues="file_types"
              searchField="file_types"
              hasRefreshAction
              hasHeaderActions={false}
              disableDelete
              customRowWithActions={customRow}
              urls={urls}
              hasSearch={false}
              shouldRefresh={tableShouldRefresh}
            />
          )}
        </div>
        <div className="column is-12">
          <div className="level-right">
            <button
              className="button"
              onClick={() => openModal("cancel")}
              title="Anuluj i wróć do listy"
              type="button"
            >
              Anuluj
            </button>
            <button
              className="button button--margin"
              type="button"
              onClick={handlePreviousSubmit}
              title="Poprzedni krok"
            >
              Wstecz
            </button>
            <DropdownButton
              mainOption={{
                type: "submit",
                title: "Dalej",
                href: "",
                onClick: handleNextSubmit
              }}
              options={[
                {
                  type: "submit",
                  title: "Zapisz szkic",
                  href: "",
                  id: 1,
                  onClick: handleDraftSubmit
                }
              ]}
              onClick
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DigitalItemUploadStepForm;
