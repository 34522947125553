import "./MessagesPreview.scss";

import React, { useEffect, useState } from "react";

import axiosSession from "@app/config/axiosSession";
import isEmpty from "lodash/isEmpty";
import sanitizeHtml from "sanitize-html";
import useGenericToastify from "@app/hooks/useGenericToastify";

const MessagesPreview = ({ id }) => {
  const [dataToPreview, setDataToPreview] = useState([]);
  const [content, setContent] = useState();
  const [editable, setEditable] = useState(false);
  const { notifyError } = useGenericToastify();

  const urls = {
    getData: `/api/notifications/${id}/`,
    home: "/admin-panel/home/",
    messagesList: "/messages/",
    edit: "/messages/edit/"
  };

  const defaultOptions = {
    allowedTags: [
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "blockquote",
      "p",
      "a",
      "ul",
      "ol",
      "li",
      "b",
      "i",
      "strong",
      "em",
      "s",
      "table",
      "thead",
      "caption",
      "tbody",
      "tr",
      "th",
      "td"
    ]
  };

  useEffect(() => {
    axiosSession
      .get(urls.getData)
      .then(({ data }) => {
        const generatedDataToPreview = [
          { label: "Tytuł", content: data.title },
          { label: "Status", content: data.status },
          {
            label: "Biblioteki",
            content: data.libraries.map(item => item.library__name)
          },
          {
            label: "Grupy użytkowników",
            content: data.groups.map(item => item.name)
          },
          { label: "Data wysłania", content: data.sending_datetime }
        ];
        setDataToPreview(generatedDataToPreview);
        setEditable(data.status === "Oczekujący");
        setContent(data.content);
      })
      .catch(error => {
        notifyError("Pobieranie danych nie powiodło się");
        console.error(error);
      });
  }, []);

  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href={urls.home}
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href={urls.messagesList}
            title="Komunikaty"
          >
            Komunikaty
          </a>
          &nbsp;/&nbsp;
          <strong>Podgląd komunikatu</strong>
        </div>

        <div className="mbc-backoffice__header">
          <p className="mbc-backoffice__header__title">Podgląd komunikatu</p>
          <hr className="mbc-backoffice__header__hr" />
        </div>

        {!isEmpty(dataToPreview) && (
          <div className="preview-section">
            <ul className="preview-list">
              {dataToPreview.map((item, index) => (
                <li key={index} className="preview-list__item">
                  <p className="preview-list__item-label">{item.label}</p>
                  {Array.isArray(item.content) ? (
                    <div>
                      {item.content.map((item, index) => (
                        <p key={index} className="preview-list__item-content">
                          {item}
                        </p>
                      ))}
                    </div>
                  ) : (
                    <p className="preview-list__item-content">{item.content}</p>
                  )}
                </li>
              ))}
              <li className="preview-list__item">
                <p className="preview-list__item-label">Treść</p>
                <div
                  className="preview-list__item-content"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(content, defaultOptions)
                  }}
                ></div>
              </li>
            </ul>
          </div>
        )}

        <div className="mbc-backoffice__bottom">
          <a
            className="mbc-backoffice__goback is-uppercase has-text-weight-bold"
            href={urls.messagesList}
          >
            &lt; Wróć do listy komunikatów
          </a>

          {editable && (
            <a
              className="mbc-backoffice__goback is-uppercase has-text-weight-bold"
              href={`${urls.edit}${id}/`}
            >
              Edytuj
            </a>
          )}
        </div>
      </div>
    </section>
  );
};

export default MessagesPreview;
