import "./AttributesForm.scss";

import React, { useEffect, useState } from "react";
import {
  getAttributeFeatures,
  getAttributeInfo,
  getAttributeTypes,
  getAttributes,
  getDictLanguages,
  getDicts
} from "@app/services/services";

import CancelButton from "@components/CancelButton/CancelButton";
import GenericFieldArray from "@app/components/GenericFieldArray/GenericFieldArray";
import GenericRadioGroup from "@app/components/Radio/GenericRadioGroup";
import GenericSelect from "@app/components/Select/GenericSelect";
import Input from "@app/components/Input/Input";
import RSwitch from "react-switch";
import axiosSession from "@app/config/axiosSession";
import findIndex from "lodash/findIndex";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

const mbc_light = "#f6f6f6";
const mbc_white = "#000000";

// this value is a backend representation of dictionary attrib
const attributeDictionaryId = 5;
const attributeDictionaryTextId = 6;

const AttributesForm = ({ id }) => {
  const {
    handleSubmit,
    register,
    errors,
    control,
    setError,
    setValue,
    reset
  } = useForm();

  const [attributeTypes, setAttritubeTypes] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [features, setFeatures] = useState([]);
  const [validators, setValidators] = useState([]);
  const [statusItems] = useState([
    { value: true, name: "aktywny" },
    { value: false, name: "nieaktywny" }
  ]);
  const [metadata, setMetadata] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [availableFeatures, setAvailableFeatures] = useState([]);
  const [availableValidators, setAvailableValidators] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [frequent, setFrequent] = useState(false);
  const [required, setRequired] = useState(false);
  const [visible, setVisible] = useState(true);
  const { notifyError } = useGenericToastify();
  const [removeAllArray, setRemoveAllArray] = useState(false);
  const [isInternal, setIsInternal] = useState(null);
  const [dictionaries, setDictionaries] = useState([]);
  const [isDictSelect, setIsDictSelect] = useState(false);
  const [isComplex, setIsComplex] = useState(false);
  const [hasDict, setHasDict] = useState(false);
  const [isFile, setIsFile] = useState(false);

  const returnLocation = "/cancel?url=/attributes/";
  const fileOptionId = 8;

  const handleSelectChange = ([selectedOption]) => {
    return selectedOption;
  };

  const handleAttributeTypeChange = ([selectedOption]) => {
    setIsComplex(selectedOption.id === 9);
    selectedOption.id === attributeDictionaryId ||
    selectedOption.id === attributeDictionaryTextId
      ? setIsDictSelect(true)
      : setIsDictSelect(false);
    setIsFile(selectedOption.id === fileOptionId);
    setFeatures(
      availableFeatures.filter(
        f =>
          f.attribute_type.length == 0 ||
          includes(f.attribute_type, selectedOption.id)
      )
    );
    setValidators(
      availableValidators.filter(
        f =>
          f.attribute_type.length == 0 ||
          includes(f.attribute_type, selectedOption.id)
      )
    );
    setIsInternal(selectedOption.id === attributeDictionaryId ? true : false);
    setRemoveAllArray(true);
    return selectedOption;
  };

  const handleFrequentChange = () => {
    setFrequent(!frequent);
  };

  const handleVisibleChange = () => {
    setVisible(!visible);
  };
  const handleRequiredChange = () => {
    setRequired(!required);
  };
  const handleHasDictChange = () => {
    setHasDict(origin => !origin);
  };

  const switchActiveText = isActive => {
    return isActive ? "attributes-form__switch--active-text" : "";
  };

  useEffect(() => {
    !isEmpty(errors) ? notifyError("Nie udało się zapisać atrybutu.") : null;
  }, [errors, notifyError]);

  useEffect(() => {
    const fetchData = async () => {
      const [
        languages,
        attributeTypes,
        attributeFeatures,
        attributes
      ] = await axiosSession.all([
        getDictLanguages(),
        getAttributeTypes(),
        getAttributeFeatures(),
        getAttributes({ hierarchic: false })
      ]);
      setLanguages(languages.data.results);
      setAttritubeTypes(attributeTypes.data);
      setFeatures(attributeFeatures.data.features);
      setAvailableFeatures(attributeFeatures.data.features);
      setValidators(attributeFeatures.data.validators);
      setAvailableValidators(attributeFeatures.data.validators);
      setAttributes(attributes.data);
    };

    if (!id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    const fetchDictData = async () => {
      try {
        const dictionaries = await getDicts(isInternal);
        setDictionaries(dictionaries.data);
      } catch (error) {
        console.error(error);
      }
    };
    if (isInternal !== null) {
      fetchDictData();
    }
  }, [isInternal]);

  useEffect(() => {
    const fetchData = async () => {
      const [
        languages,
        attributeTypes,
        attributeFeatures,
        attributes,
        attributeDetail,
        dictionaries
      ] = await axiosSession.all([
        getDictLanguages(),
        getAttributeTypes(),
        getAttributeFeatures(),
        getAttributes({ hierarchic: false }),
        getAttributeInfo(id),
        getDicts(null)
      ]);
      setLanguages(languages.data.results);
      setDictionaries(dictionaries.data);
      setAttritubeTypes(attributeTypes.data);
      setFeatures(attributeFeatures.data.features);
      setAvailableFeatures(attributeFeatures.data.features);
      setValidators(attributeFeatures.data.validators);
      setAvailableValidators(attributeFeatures.data.validators);
      setAttributes(attributes.data);
      setIsComplex(attributeDetail.data.attribute_type === 9);
      const dataTranslations = attributeDetail.data.translations.map(
        translation => {
          return {
            id: translation.id,
            language:
              languages.data.results[
                findIndex(
                  languages.data.results,
                  ({ id }) => id === translation.language
                )
              ],
            content: translation.content
          };
        }
      );
      const dataValidators = attributeDetail.data.validators.map(validator => {
        return {
          id: validator.id,
          validator:
            attributeFeatures.data.validators[
              findIndex(
                attributeFeatures.data.validators,
                ({ id }) => id === validator.name
              )
            ],
          value: validator.value
        };
      });
      const attributeType =
        attributeTypes.data[
          findIndex(
            attributeTypes.data,
            ({ id }) => id === attributeDetail.data.attribute_type
          )
        ];
      const active =
        statusItems[
          findIndex(
            statusItems,
            ({ value }) => value === attributeDetail.data.active
          )
        ];

      const relatedAttributes = attributeDetail.data.related_attributes.map(
        related_attribute => {
          return attributes.data[
            findIndex(attributes.data, ({ id }) => id === related_attribute)
          ];
        }
      );
      if (
        attributeType.id == attributeDictionaryId ||
        attributeType.id == attributeDictionaryTextId
      ) {
        setIsDictSelect(true);
        attributeDetail.data.dictionaries =
          dictionaries.data[
            findIndex(
              dictionaries.data,
              ({ id }) => id === attributeDetail.data.serializer_dictionary_id
            )
          ];
      } else if (attributeDetail.data.serializer_dictionary_id) {
        setHasDict(true);
        attributeDetail.data.dictionaries =
          dictionaries.data[
            findIndex(
              dictionaries.data,
              ({ id }) => id === attributeDetail.data.serializer_dictionary_id
            )
          ];
      } else {
        const dataFeatures = attributeDetail.data.features.map(feature => {
          return {
            id: feature.id,
            feature:
              attributeFeatures.data.features[
                findIndex(
                  attributeFeatures.data.features,
                  ({ id }) => id === feature.name
                )
              ],
            value: feature.value
          };
        });
        attributeDetail.data.features = dataFeatures;
        setFeatures(
          attributeFeatures.data.features.filter(
            f =>
              f.attribute_type.length == 0 ||
              includes(f.attribute_type, attributeType.id)
          )
        );
      }
      attributeDetail.data.related_attributes = relatedAttributes;
      attributeDetail.data.active = active;
      attributeDetail.data.attribute_type = attributeType;
      attributeDetail.data.translations = dataTranslations;
      attributeDetail.data.validators = dataValidators;
      setValidators(
        attributeFeatures.data.validators.filter(
          f =>
            f.attribute_type.length == 0 ||
            includes(f.attribute_type, attributeType.id)
        )
      );

      setVisible(attributeDetail.data.visible);
      setFrequent(attributeDetail.data.frequent);
      setRequired(attributeDetail.data.required);
      setMetadata(!attributeDetail.data.technical);
      setHasDict(!!attributeDetail.data.serializer_dictionary_id);
      reset(omit(attributeDetail.data, "frequent", "required", "visible"));
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  const onSubmit = data => {
    let method = "";
    let url = "";
    setIsLoading(true);
    data.attribute_type = data.attribute_type.id;
    data.dictionaries =
      data.attribute_type === attributeDictionaryId ||
      data.attribute_type === attributeDictionaryTextId ||
      isDictSelect ||
      data.has_dict === "true"
        ? data.dictionaries.id
        : null;
    data.active = data.active.value;
    let related_attributes_list = !isEmpty(data.related_attributes)
      ? data.related_attributes.map(f => f.id)
      : [];
    let translations_list = !isEmpty(data.translations)
      ? data.translations.map(f => ({
          language: f.language.id,
          content: f.content
        }))
      : [];
    let features_list = !isEmpty(data.features)
      ? data.features.map(f => ({
          name: f.feature.id,
          value: f.value
        }))
      : [];
    let validators_list = !isEmpty(data.validators)
      ? data.validators.map(f => ({
          name: f.validator.id,
          value: f.value
        }))
      : [];
    data.features = features_list;
    data.validators = validators_list;
    data.technical = !metadata;
    data.descriptive = metadata;
    data.related_attributes = related_attributes_list;
    data.translations = translations_list;
    if (id) {
      method = "PUT";
      url = `/api/attributes/${id}/`;
    } else {
      method = "POST";
      url = `/api/attributes/`;
    }
    axiosSession({ method: method, url: url, data: data })
      .then(response => {
        location.href = response.data.url;
      })
      .catch(error => {
        notifyError("Nie udało się zapisać atrybutu.");
        if (error.response.data) {
          for (let [key, value] of Object.entries(error.response.data)) {
            if (!Array.isArray(value) && typeof value === "object") {
              for (let [key2, value2] of Object.entries(value)) {
                if (!Array.isArray(value2) && typeof value2 === "object") {
                  for (let [key3, value3] of Object.entries(value2)) {
                    setError(`${key}[${key2}][${key3}]`, "", `${value3}`);
                  }
                } else {
                  setError(`${key}[${key2}]`, "", `${value2}`);
                }
              }
            } else {
              setError(`${key}`, "", `${value}`);
            }
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section className="mbc-backoffice attributes-form">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a className="breadcrumbs__link" href="/attributes/" title="Atrybuty">
            Atrybuty
          </a>
          &nbsp;/&nbsp;
          <strong>{id ? "Edytuj" : "Dodaj"} atrybut</strong>
        </div>

        <div className="mbc-backoffice__header">
          <h2 className="mbc-backoffice__header__title">
            {id ? "Edycja" : "Dodawanie"} atrybutu
          </h2>
          <hr className="mbc-backoffice__header__hr" />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="columns is-multiline">
            <div className="column is-half-desktop is-full-mobile">
              <Input
                name={"name"}
                label={"Nazwa"}
                darkTheme={false}
                register={register({ required: "To pole jest wymagane!" })}
                errors={errors}
                isRequired
              />
            </div>
            <div className="column is-full">
              <GenericFieldArray
                name="translations"
                control={control}
                errors={errors}
                addButtonText="Dodaj tłumaczenie"
                appendOption={{ language: { id: "", name: "" }, content: "" }}
                deleteButtonTitle="Usuń tłumaczenie"
                selectName="language"
                selectOptions={languages}
                selectPlaceholder="Wybierz język"
                selectLabel="Wybierz język tłumaczenia"
                handleSelectChange={handleSelectChange}
                inputName="content"
                inputLabel={"Tłumaczenie"}
              />
            </div>

            <div className="column is-half-desktop is-full-mobile">
              <Input
                name="description"
                label="Skrócony opis zawartości pola"
                darkTheme={false}
                register={register({ required: "To pole jest wymagane!" })}
                errors={errors}
                placeholder="twórca, redaktor, fotograf, etc"
                isRequired
              />
              <GenericSelect
                options={attributeTypes}
                placeholder="Wybierz typ atrybutu"
                control={control}
                required={{
                  required: "To pole jest wymagane!"
                }}
                handleSelectChange={handleAttributeTypeChange}
                name="attribute_type"
                errors={errors}
                label="Typ atrybutu"
                isRequire
              />
              {isDictSelect ? (
                <GenericSelect
                  options={dictionaries}
                  placeholder="Wybierz słownik"
                  control={control}
                  required={{
                    required: "To pole jest wymagane!"
                  }}
                  handleSelectChange={handleSelectChange}
                  name="dictionaries"
                  errors={errors}
                  label="Słownik"
                  isRequire
                />
              ) : (
                ""
              )}
            </div>

            {!isDictSelect && !isComplex ? (
              <div className="column is-full">
                <GenericFieldArray
                  name="features"
                  control={control}
                  errors={errors}
                  addButtonText="Dodaj cechę"
                  appendOption={{ feature: { id: "", name: "" }, value: "" }}
                  deleteButtonTitle="Usuń cechę"
                  selectName="feature"
                  selectOptions={features}
                  selectPlaceholder="Wybierz cechę"
                  selectLabel="Cecha typu atrybutu"
                  handleSelectChange={handleSelectChange}
                  inputName="value"
                  inputLabel="format(regex)"
                  removeAllArray={removeAllArray}
                  setRemoveAllArray={setRemoveAllArray}
                />
              </div>
            ) : (
              ""
            )}

            <div className="column is-full">
              {!isComplex && (
                <GenericFieldArray
                  name="validators"
                  control={control}
                  errors={errors}
                  addButtonText="Dodaj walidację"
                  appendOption={{ validation: { id: "", name: "" }, value: "" }}
                  deleteButtonTitle="Usuń walidację"
                  selectName="validator"
                  selectOptions={validators}
                  selectPlaceholder="Wybierz walidację"
                  selectLabel="Walidacja"
                  handleSelectChange={handleSelectChange}
                  inputName="value"
                  inputLabel="Wartość"
                  removeAllArray={removeAllArray}
                  setRemoveAllArray={setRemoveAllArray}
                />
              )}
            </div>

            <div className="column is-half-desktop is-full-mobile">
              {!isComplex && !isDictSelect && !isFile ? (
                <Input
                  name="default_value"
                  control={control}
                  label="Wartość domyślna"
                  darkTheme={false}
                  register={register({ required: false })}
                  errors={errors}
                  placeholder=""
                />
              ) : (
                ""
              )}

              <GenericSelect
                options={statusItems}
                placeholder="Wybierz status"
                control={control}
                required={{ required: "To pole jest wymagane!" }}
                handleSelectChange={handleSelectChange}
                name="active"
                errors={errors}
                getOptionValue={option => `${option.value}`}
                label="Status"
                isRequire
              />
              <div className="field attributes-form--padding-tb">
                <label className="columns" htmlFor="metadata">
                  <span className={"column " + switchActiveText(metadata)}>
                    metadane opisowe
                  </span>
                  <RSwitch
                    checked={!metadata}
                    onChange={() => setMetadata(!metadata)}
                    onColor={mbc_light}
                    offColor={mbc_light}
                    offHandleColor={mbc_white}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onHandleColor={mbc_white}
                    handleDiameter={22}
                    height={15}
                    width={34}
                    className="column attributes-form__switch--border react-switch is-one-fifth is-paddingless"
                    id="metadata"
                    name="metadata"
                    ref={register}
                  />
                  <span className={"column " + switchActiveText(!metadata)}>
                    metadane techniczne
                  </span>
                </label>
              </div>
              <div className="attributes-form--padding-bottom">
                <GenericRadioGroup
                  register={register}
                  name="required"
                  groupLabel="Atrybut wymagany"
                  valueSelected={required}
                  handleRadioChange={handleRequiredChange}
                />
              </div>
              <div className="attributes-form--padding-bottom">
                <GenericRadioGroup
                  valueSelected={visible}
                  register={register}
                  name="visible"
                  groupLabel="Atrybut wyświetlany domyślnie"
                  handleRadioChange={handleVisibleChange}
                />
              </div>
              <div className="attributes-form--padding-bottom">
                <GenericRadioGroup
                  valueSelected={frequent}
                  register={register}
                  name="frequent"
                  groupLabel="Atrybut jest powtarzalny"
                  handleRadioChange={handleFrequentChange}
                />
              </div>
              {isComplex && (
                <>
                  <GenericSelect
                    options={attributes}
                    placeholder="Wybierz atrybuty"
                    control={control}
                    required={{ required: false }}
                    handleSelectChange={handleSelectChange}
                    name="related_attributes"
                    errors={errors}
                    getOptionValue={option => `${option.name}`}
                    label="Atrybut złożony z atrybutów"
                    isMulti
                  />
                  <div className="attributes-form--padding-bottom">
                    <GenericRadioGroup
                      valueSelected={hasDict}
                      register={register}
                      name="has_dict"
                      groupLabel="Podpowiadanie ze słownika?"
                      handleRadioChange={handleHasDictChange}
                    />
                  </div>
                  {hasDict && (
                    <GenericSelect
                      options={dictionaries}
                      placeholder="Wybierz słownik"
                      control={control}
                      required={{
                        required: "To pole jest wymagane!"
                      }}
                      handleSelectChange={handleSelectChange}
                      name="dictionaries"
                      errors={errors}
                      label="Słownik"
                      isRequire
                    />
                  )}
                </>
              )}
            </div>
          </div>

          <div className="buttons">
            <CancelButton
              returnLocation={returnLocation}
              bodyContentSufix={`${id ? "edycję" : "dodanie nowego"} atrybutu?`}
            />
            <button
              className={`button library-form__btn ${
                isLoading ? "is-loading" : ""
              }`}
              type="submit"
            >
              Zapisz
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default AttributesForm;
