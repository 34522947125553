import React, { useEffect, useRef, useState } from "react";

import CancelButton from "@components/CancelButton/CancelButton";
import Checkbox from "@components/Checkbox/Checkbox";
import DropdownButton from "@components/DropdownButton/DropdownButton";
import Stepper from "./Stepper";
import axiosSession from "@app/config/axiosSession";
import handleErrors from "@app/helpers/handleErrors";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";
import valuesIn from "lodash/valuesIn";

let submitType = "";

const CollectionsPermissionsStep = ({ id, previousStepChange, stepLength }) => {
  const { handleSubmit, register, errors, setError } = useForm();
  const { notifyError } = useGenericToastify();
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const isMounted = useRef(null);

  const urls = {
    formUrl: `/api/collections/${id}/permissions/`,
    publishUrl: `/api/collections/${id}/status/`,
    collections: "/collections/",
    returnLocation: "/cancel?url=/collections/"
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      axiosSession
        .get(urls.formUrl)
        .then(res => {
          setPermissions(res.data);
        })
        .finally(() => setIsLoading(false));
    }
  }, [id]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleDraftSubmit = () => {
    submitType = "draft";
    return true;
  };

  const handlePublishSubmit = () => {
    submitType = "publish";
    return true;
  };

  const handlePreviousSubmit = () => {
    submitType = "previous";
    handleSubmit(onSubmit)();
  };

  const publish = async () => {
    try {
      await axiosSession.post(urls.publishUrl, { status: "published" });
    } catch (error) {
      console.error(error);
      notifyError(error.response.data[0]);
      return false;
    }
    return true;
  };

  const onSubmit = async data => {
    setIsLoading(true);
    let parsedPermissions = Object.keys(data).map(item => {
      return { pk: parseInt(item), permissions: valuesIn(data[item]) };
    });
    const toPublish = submitType === "publish";

    let formData = {
      groups: parsedPermissions
    };

    try {
      const response = await axiosSession.post(urls.formUrl, formData);
      if (submitType === "previous") {
        previousStepChange(id);
      } else {
        let successfulPublished = true;
        if (toPublish) {
          successfulPublished = await publish();
        }
        if (successfulPublished) {
          location.href = response.data.url ?? urls.collections;
        }
      }
    } catch (error) {
      console.error(error);
      notifyError("Nie udało się zapisać kolekcji.");
      handleErrors(error.response.data, setError);
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  };

  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href={urls.collections}
            title="Lista katalogów"
          >
            Katalogi i obiekty
          </a>
          &nbsp;/&nbsp;
          <strong>{`${id ? "Edycja" : "Dodawanie"} kolekcji`}</strong>
        </div>

        <div className="mbc-backoffice__header">
          <h2 className="mbc-backoffice__header__title">
            {`${id ? "Edycja" : "Dodawanie"} kolekcji`}
          </h2>
          <hr className="mbc-backoffice__header__hr" />
        </div>

        <Stepper activeStep={4} stepLength={stepLength} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="columns is-variable is-8 is-multiline">
            <div className="column is-12">
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th rowSpan={2}>GRUPY</th>
                    <th colSpan={4}>UPRAWNIENIA</th>
                  </tr>
                  <tr>
                    <th>Odczyt</th>
                    <th>Modyfikacja</th>
                    <th>Obiekty</th>
                    <th>Publikacja</th>
                  </tr>
                </thead>
                <tbody>
                  {permissions.length ? (
                    permissions.map(group => {
                      return (
                        <tr key={group.group_id}>
                          <td>{group.group_name}</td>
                          {group.permissions ? (
                            group.permissions.map(permission => {
                              return (
                                !permission.hidden && (
                                  <td key={`p-${permission.id}`}>
                                    <div className="mbc-checkbox">
                                      <Checkbox
                                        name={`${group.group_id}`}
                                        value={permission.id}
                                        register={register}
                                        defaultChecked={permission.value}
                                        disabled={!permission.editable}
                                        errors={errors}
                                        darkTheme={false}
                                      >
                                        {}
                                      </Checkbox>
                                    </div>
                                  </td>
                                )
                              );
                            })
                          ) : (
                            <td colSpan={3}> - </td>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={3}>Brak uprawnień</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="column is-12">
              <div className="level-right">
                <CancelButton
                  returnLocation={urls.returnLocation}
                  bodyContentSufix={`${
                    id ? "edycję" : "dodanie nowej"
                  } kolekcji?`}
                />
                <button
                  className="button button--previous"
                  type="button"
                  onClick={handlePreviousSubmit}
                  title="Poprzedni krok"
                >
                  Wstecz
                </button>
                <DropdownButton
                  mainOption={{
                    type: "submit",
                    title: "Zapisz",
                    href: "",
                    onClick: handleDraftSubmit
                  }}
                  isLoading={isLoading}
                  options={[
                    {
                      type: "submit",
                      title: "Publikuj",
                      href: "",
                      id: 1,
                      onClick: handlePublishSubmit
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};
export default CollectionsPermissionsStep;
