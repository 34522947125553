import React, { useEffect, useState } from "react";

import GenericSelect from "@components/Select/GenericSelect";
import Input from "@components/Input/GenericInput";
import Preview from "@components/Preview/Preview";
import axiosSession from "axios";
import isEmpty from "lodash/isEmpty";

const indicators = [...Array(10).keys()].map(i => ({ id: i }));

const SchemasFormMarc = ({
  marcFields,
  control,
  errors,
  setValue,
  marc21,
  dcOptions,
  getValues,
  attribute,
  setTempValues,
  isComplex,
  idx,
  isPreview,
  marcAttribute
}) => {
  const [chosenMarc, setChosenMarc] = useState(null);
  const [marcSubfields, setMarcSubfields] = useState([]);
  const [isDisabledSubfield, setIsDisabledSubfield] = useState(true);
  const [marcChanged, setMarcChanged] = useState(false);
  const [marcSubfieldsPreview, setMarcSubfieldsPreview] = useState([]);
  const [rows, setRows] = useState(null);

  const setValuesArray = (targets, values) => {
    targets.forEach((item, idx2) =>
      setValue(
        `marc21[${attribute.place - 1}].${
          isComplex ? `attribute[${idx}].` : ""
        }${item}`,
        values[idx2]
      )
    );
  };

  const handleMarcChange = value => {
    let tempValues = getValues();
    tempValues[
      `marc21[${attribute.place - 1}].${
        isComplex ? `attribute[${idx}].` : ""
      }marc21_subfield`
    ] = null;
    tempValues[
      `marc21[${attribute.place - 1}].${
        isComplex ? `attribute[${idx}].` : ""
      }marc21`
    ] = value[0];
    Object.entries(tempValues).forEach(([key, value]) => {
      setValue(key, value);
    });
    setMarcChanged(true);
    setTempValues(tempValues);
    setIsDisabledSubfield(false);
    setChosenMarc(value[0]);
    return value[0];
  };

  const handleSubfieldChange = value => {
    setValuesArray(["marc21_subfield"], [value[0]]);
    setTempValues(getValues());
    return value[0];
  };

  const handleIndicator1Change = value => {
    setValuesArray(["indicator1"], [value[0]]);
    setTempValues(getValues());
    return value[0];
  };

  const handleIndicator2Change = value => {
    setValuesArray(["indicator2"], [value[0]]);
    setTempValues(getValues());
    return value[0];
  };

  const handleOptionChange = value => {
    setValuesArray(["option"], [value[0]]);
    setTempValues(getValues());
    return value[0];
  };

  useEffect(() => {
    if (marc21 === null) {
      setValuesArray(
        [
          "marc21_subfield",
          "indicator1",
          "indicator2",
          "separator",
          "place",
          "option"
        ],
        [null, null, null, "", "", dcOptions[0]]
      );
    }
    if (marc21) {
      setChosenMarc(marc21);
      axiosSession
        .get(`/api/attributes/${marc21.id}/marc21_subfield/`)
        .then(({ data }) => {
          let tempValues = getValues();
          tempValues[
            `marc21[${attribute.place - 1}].${
              isComplex ? `attribute[${idx}].` : ""
            }marc21_subfield`
          ] = data.find(
            x =>
              x.id ===
              getValues()[
                `marc21[${attribute.place - 1}].${
                  isComplex ? `attribute[${idx}].` : ""
                }marc21_subfield`
              ]
          );
          setTempValues(tempValues);
          setValue(
            `marc21[${attribute.place - 1}].${
              isComplex ? `attribute[${idx}].` : ""
            }marc21_subfield`,
            tempValues[
              `marc21[${attribute.place - 1}].${
                isComplex ? `attribute[${idx}].` : ""
              }marc21_subfield`
            ]
          );
          setMarcSubfields(data);
          setIsDisabledSubfield(false);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [marc21]);

  useEffect(() => {
    if (chosenMarc) {
      axiosSession
        .get(`/api/attributes/${chosenMarc.id}/marc21_subfield/`)
        .then(({ data }) => {
          setMarcSubfields(data);
          setIsDisabledSubfield(false);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [chosenMarc]);

  useEffect(() => {
    if (
      isPreview &&
      !isEmpty(marcAttribute) &&
      marcAttribute.marc21_subfield !== null
    ) {
      axiosSession
        .get(`/api/attributes/${marcAttribute.marc21}/marc21_subfield/`)
        .then(({ data }) => {
          setMarcSubfieldsPreview(
            data.filter(item => item.id === marcAttribute.marc21_subfield)[0]
          );
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, []);

  useEffect(() => {
    if (isPreview) {
      const marcAttributeInfo = marcFields.filter(
        item => item.id === marcAttribute?.marc21
      );
      setRows([
        {
          title: "Pole marc21",
          value: [
            !isEmpty(marcAttribute)
              ? `${marcAttributeInfo[0].tag} - ${marcAttributeInfo[0].field_name}`
              : "-"
          ]
        },
        {
          title: "Identyfikator podpola",
          value: [marcSubfieldsPreview?.tag ?? "-"]
        },
        { title: "Separator", value: [marcAttribute?.separator ?? "-"] },
        {
          title: "Kolejność wyświetlania - marc21",
          value: [marcAttribute?.place ?? "-"]
        },
        { title: "Wskaźnik 1", value: [marcAttribute?.indicator1 ?? "-"] },
        { title: "Wskaźnik 2", value: [marcAttribute?.indicator2 ?? "-"] },
        {
          title: "Opcje konwersji",
          value: [
            !isEmpty(marcAttribute)
              ? dcOptions.filter(item => item.id === marcAttribute.option)[0]
                  .title
              : "-"
          ]
        }
      ]);
    }
  }, [marcSubfieldsPreview]);

  return (
    <div className="marc21-form">
      {isPreview ? (
        !isEmpty(rows) && <Preview rows={rows} displayFalseValues />
      ) : (
        <div className="marc21-form_fields">
          <div className="columns is-variable ">
            <div className="column is-half-desktop is-full-mobile">
              <GenericSelect
                options={marcFields}
                placeholder={"pole marc21"}
                label={"pole marc21"}
                name={`marc21[${attribute.place - 1}].${
                  isComplex ? `attribute[${idx}].` : ""
                }marc21`}
                handleSelectChange={value => handleMarcChange(value, idx)}
                control={control}
                errors={errors}
                getOptionLabel={option =>
                  `${option.tag} - ${option.field_name}`
                }
                isClearable
                filterOption={(option, inputValue) =>
                  (option.label.toString().match(inputValue) || []).length > 0
                }
              />
            </div>
          </div>
          <div className="columns is-variable ">
            <div className="column is-half-desktop is-full-mobile">
              <GenericSelect
                placeholder={"identyfikator podpola"}
                label={"identyfikator podpola"}
                name={`marc21[${attribute.place - 1}].${
                  isComplex ? `attribute[${idx}].` : ""
                }marc21_subfield`}
                handleSelectChange={handleSubfieldChange}
                options={marcSubfields}
                control={control}
                errors={errors}
                isDisabled={isDisabledSubfield}
                getOptionLabel={option => `${option.tag}`}
                matchProp={"field_name"}
                filterOption={(option, inputValue) =>
                  (option.label.toString().match(inputValue) || []).length > 0
                }
              />
            </div>
          </div>
          <div className="columns is-variable ">
            <div className="column is-half-desktop is-full-mobile">
              <Input
                name={`marc21[${attribute.place - 1}].${
                  isComplex ? `attribute[${idx}].` : ""
                }separator`}
                label={"separator"}
                control={control}
                errors={errors}
              />
            </div>
          </div>
          <div className="columns is-variable ">
            <div className="column is-half-desktop is-full-mobile">
              <Input
                name={`marc21[${attribute.place - 1}].${
                  isComplex ? `attribute[${idx}].` : ""
                }place`}
                label={"Kolejność wyświetlania - marc21"}
                control={control}
                errors={errors}
              />
            </div>
          </div>
          <div className="columns is-variable ">
            <div className="column is-half-desktop is-full-mobile">
              <GenericSelect
                isClearable
                placeholder={"Wskaźnik 1"}
                label={"Wskaźnik 1"}
                name={`marc21[${attribute.place - 1}].${
                  isComplex ? `attribute[${idx}].` : ""
                }indicator1`}
                options={indicators}
                control={control}
                errors={errors}
                handleSelectChange={handleIndicator1Change}
                getOptionLabel={option => `${option.id}`}
              />
            </div>
          </div>
          <div className="columns is-variable ">
            <div className="column is-half-desktop is-full-mobile">
              <GenericSelect
                isClearable
                placeholder={"Wskaźnik 2"}
                label={"Wskaźnik 2"}
                name={`marc21[${attribute.place - 1}].${
                  isComplex ? `attribute[${idx}].` : ""
                }indicator2`}
                options={indicators}
                control={control}
                errors={errors}
                maxMenuHeight={250}
                handleSelectChange={handleIndicator2Change}
                getOptionLabel={option => `${option.id}`}
              />
            </div>
          </div>
          <div className="columns is-variable ">
            <div className="column is-half-desktop is-full-mobile">
              <GenericSelect
                placeholder={"Opcje konwersji"}
                label={"Opcje konwersji"}
                required={{ required: "To pole jest wymagane!" }}
                name={`marc21[${attribute.place - 1}].${
                  isComplex ? `attribute[${idx}].` : ""
                }option`}
                handleSelectChange={handleSubfieldChange}
                options={dcOptions}
                defaultValue={dcOptions[0]}
                control={control}
                errors={errors}
                getOptionLabel={option => `${option.title}`}
                maxMenuHeight={180}
                handleSelectChange={handleOptionChange}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SchemasFormMarc.defaultProps = {
  isComplex: false,
  isPreview: false
};

export default SchemasFormMarc;
