import "react-tabs/style/react-tabs.css";

import { FormContext, useForm } from "react-hook-form";
import React, { createContext, useEffect, useRef, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  getCollectionAttributesValue,
  getCollectionSchema
} from "@app/services/collectionServices";

import CancelButton from "@components/CancelButton/CancelButton";
import ClipLoader from "react-spinners/ClipLoader";
import Cookies from "js-cookie";
import DropdownButton from "@app/components/DropdownButton/DropdownButton";
import { NewFormBuilder } from "@app/components/FormBuilder/NewFormBuilder";
import Stepper from "@app/pages/Directories/Stepper";
import axiosSession from "@app/config/axiosSession";
import convertFormDataToValue from "@app/helpers/convertFormDataToValue";
import convertValueToFormData from "@app/helpers/convertValueToFormData";
import find from "lodash/find";
import handleErrors from "@app/helpers/handleErrors";
import isEmpty from "lodash/isEmpty";
import updateAction from "@app/actions/updateAction";
import useGenericToastify from "@app/hooks/useGenericToastify";
import { useStateMachine } from "little-state-machine";

let submitType = "";

export const StorageReload = createContext(null);

const CollectionsAttributesValueStep = ({
  id,
  nextStepChange,
  previousStepChange,
  stepLength
}) => {
  const { action, state } = useStateMachine(updateAction);
  const { handleSubmit, reset, ...formMethods } = useForm({});
  const { notifyError } = useGenericToastify();
  const isMounted = useRef(null);
  const [apiData, setApiData] = useState([]);
  const [libraryLanguage, setLibraryLanguage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabsError, setTabsError] = useState({});
  const [activeLanguageFieldsLength, setActiveLanguageFieldsLength] = useState(
    {}
  );
  const [fieldsLength, setFieldsLength] = useState({});
  const [formLoading, setFormLoading] = useState(false);

  const returnLocation = "/cancel?url=/collections/";

  const handleDraftSubmit = () => {
    submitType = "draft";
    return true;
  };

  const handlePreviousSubmit = () => {
    submitType = "previous";
    handleSubmit(onSubmit)();
  };

  const handleNextSubmit = () => {
    submitType = "next";
    return true;
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      window.sessionStorage.clear();
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const [schema, attributeValues] = await axiosSession.all([
        getCollectionSchema(id),
        getCollectionAttributesValue(id)
      ]);
      setLibraryLanguage(schema.data.languages);
      const language = find(schema.data.languages, ["id", `${selectedTab + 1}`])
        .code;

      const attributesValue = !isEmpty(attributeValues.data[language])
        ? attributeValues.data
        : schema.data.default_values;

      const schemaForm = schema.data.attributes;
      const fieldsSize = {};
      const convertedData = {};
      setApiData(schemaForm);
      for (const [key, value] of Object.entries(attributesValue)) {
        const { values, sizeDict } = await convertValueToFormData(
          value,
          schemaForm
        );
        convertedData[key] = values;
        fieldsSize[key] = sizeDict;
      }
      setFormLoading(false);

      setFieldsLength(fieldsSize);
      action(convertedData);

      setActiveLanguageFieldsLength(fieldsSize[language]);
      reset(convertedData[language]);
    };
    if (id && isMounted.current) {
      setFormLoading(true);
      fetchData();
    }
  }, [id]);

  const onSubmit = async data => {
    let errors = {};
    const validateField = true;
    const activeLanguageCode = find(libraryLanguage, [
      "id",
      `${selectedTab + 1}`
    ]).code;
    const activeLanguageFormData = {
      [activeLanguageCode]: data
    };
    const dataValues = {
      ...state.data,
      ...activeLanguageFormData
    };
    action(dataValues);
    const requestLanguagesData = [];

    for (let [key, value] of Object.entries(dataValues)) {
      const language = find(libraryLanguage, ["code", key]).id;
      const attributesValue = await convertFormDataToValue(value, apiData);
      setIsLoading(true);
      const formData = {
        attributes: attributesValue,
        language: parseInt(language),
        validate_field: validateField
      };

      const csrftoken = Cookies.get("csrftoken");
      const requestData = fetch(`/api/collections/${id}/values/`, {
        method: "PUT",
        body: JSON.stringify(formData),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken
        }
      });
      requestLanguagesData.push(requestData);
    }

    await Promise.all([...requestLanguagesData])
      .then(responses => {
        return Promise.all(
          responses.map(async response => {
            if (response.ok) {
              return response.json();
            } else {
              throw await response.json();
            }
          })
        );
      })
      .then(data => {})
      .catch(error => {
        notifyError("Nie udało się zapisać kolekcji");
        const languageErrors = error?.attributes?.errors ?? "";
        const language_id = error?.attributes?.language ?? null;
        const key = find(libraryLanguage, ["id", language_id]).code;
        if (languageErrors) {
          errors[key] = { ...languageErrors };
        }
        if (key === activeLanguageCode && languageErrors) {
          handleErrors(languageErrors, formMethods.setError);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setIsLoading(false);
        }
      });

    if (isEmpty(errors)) {
      if (submitType === "draft") {
        location.href = "/collections/";
      } else if (submitType === "previous") {
        previousStepChange(id);
      } else {
        nextStepChange(id);
      }
    } else {
      setTabsError(errors);
    }
  };

  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/collections/"
            title="Lista kolekcji"
          >
            Kolekcje
          </a>
          &nbsp;/&nbsp;
          <strong>{id ? "Edycja" : "Dodawanie"} kolekcji</strong>
        </div>

        <div className="mbc-backoffice__header">
          <h2 className="mbc-backoffice__header__title">
            {id ? "Edycja" : "Dodawanie"} kolekcji
          </h2>
          <hr className="mbc-backoffice__header__hr" />
        </div>

        <Stepper activeStep={3} stepLength={stepLength} />

        {!formLoading && libraryLanguage ? (
          <Tabs
            selectedIndex={selectedTab}
            onSelect={tabIndex => {
              const oldLanguageCode = find(libraryLanguage, [
                "id",
                `${selectedTab + 1}`
              ]).code;
              const data = {
                [oldLanguageCode]: formMethods.getValues({ nest: true })
              };
              action({ ...state.data, ...data });
              const languageCode = find(libraryLanguage, [
                "id",
                `${tabIndex + 1}`
              ]).code;
              setActiveLanguageFieldsLength(fieldsLength[languageCode]);
              reset(state.data[languageCode]);
              setSelectedTab(tabIndex);
              if (!isEmpty(tabsError[languageCode])) {
                handleErrors(tabsError[languageCode], formMethods.setError);
              }
            }}
          >
            <TabList>
              {libraryLanguage.map(language => (
                <Tab key={language.id}>Język {language.name}</Tab>
              ))}
            </TabList>
            {libraryLanguage.map(language => (
              <TabPanel key={language.id}>
                <FormContext {...formMethods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="columns is-variable is-10 is-multiline">
                      <div className="column is-8">
                        {apiData.length ? (
                          <NewFormBuilder
                            key={`mfb-${selectedTab}-${language.id}`}
                            data={apiData}
                            fieldsLength={activeLanguageFieldsLength}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="column is-12">
                        <div className="level-right mbc-backoffice__buttons">
                          <CancelButton
                            returnLocation={returnLocation}
                            bodyContentSufix={`${
                              id ? "edycję" : "dodanie nowej"
                            } kolekcji?`}
                          />
                          <button
                            className="button button--previous"
                            type="button"
                            onClick={handlePreviousSubmit}
                            title="Poprzedni krok"
                          >
                            Wstecz
                          </button>
                          <DropdownButton
                            mainOption={{
                              type: "submit",
                              title: "Dalej",
                              href: "",
                              onClick: handleNextSubmit
                            }}
                            isLoading={isLoading}
                            options={[
                              {
                                type: "submit",
                                title: "Zapisz szkic",
                                href: "",
                                id: 1,
                                onClick: handleDraftSubmit
                              }
                            ]}
                            onClick
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </FormContext>
              </TabPanel>
            ))}
          </Tabs>
        ) : (
          <div className="step-form-loader">
            <ClipLoader size={120} color={"black"} loading={formLoading} />
            <br />
            <p style={{ color: "black" }}>Trwa ładowanie formularza.</p>
            <p style={{ color: "black" }}>Proszę czekać.</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default CollectionsAttributesValueStep;
