import React, { useState } from "react";
import { StateMachineProvider, createStore } from "little-state-machine";

import AttributesValueStep from "../pages/Directories/AttributesValueStep";
import DigitalItemUploadStepForm from "../pages/Directories/DigitalItemUploadStepForm";
import GeneralInfoStep from "../pages/Directories/GeneralInfoStep";
import PermissionsStep from "../pages/Directories/PermissionsStep";

createStore({ data: {} });
window.sessionStorage.clear();

const DigitalItemStepForm = ({ id, formType, fileServer }) => {
  const [step, setStep] = useState(1);
  const [itemId, setItemId] = useState(id);
  const [stepLength, setStepLength] = useState(4);

  const nextStep = responseId => {
    setStep(step + 1);
    setItemId(responseId);
  };

  const previousStep = responseId => {
    setStep(step - 1);
    setItemId(responseId);
  };

  switch (step) {
    case 1:
      return (
        <GeneralInfoStep
          id={itemId}
          formType={formType}
          nextStepChange={nextStep}
          stepLength={stepLength}
        />
      );
    case 2:
      return (
        <DigitalItemUploadStepForm
          id={itemId}
          previousStepChange={previousStep}
          nextStepChange={nextStep}
          fileServer={fileServer}
          stepLength={stepLength}
        />
      );
    case 3:
      return (
        <StateMachineProvider>
          <AttributesValueStep
            id={itemId}
            formType={formType}
            previousStepChange={previousStep}
            nextStepChange={nextStep}
            stepLength={stepLength}
          />
        </StateMachineProvider>
      );
    case 4:
      return (
        <PermissionsStep
          id={itemId}
          formType={formType}
          previousStepChange={previousStep}
          stepLength={stepLength}
        />
      );
    default:
      return null;
  }
};

export default DigitalItemStepForm;
