import "./DictionariesEntriesForm.scss";

import * as qs from "query-string";

import React, { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericInput from "../../components/Input/GenericInput";
import GenericSelect from "../../components/Select/GenericSelect";
import Input from "../../components/Input/Input";
import NestedSelect from "../../components/Select/NestedSelect";
import axiosSession from "../../config/axiosSession";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { getDict } from "../../services/services";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import pl from "date-fns/locale/pl";
import useGenericToastify from "../../hooks/useGenericToastify";

const SimpleDictionariesEntriesForm = ({
  dictID,
  setOpenModal,
  setReloadSelect
}) => {
  const [visibility] = useState([
    { id: 1, name: "Aktywny", value: true },
    { id: 2, name: "Nieaktywny", value: false }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [dictionary, setDictionary] = useState({});
  const [languages, setLanguages] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [inputError, setInputError] = useState("");
  const [defaultParent, setDefaultParent] = useState(false);
  const isMounted = useRef(null);

  const { handleSubmit, register, errors, control, setError, reset } = useForm(
    {}
  );
  const { fields, append, remove } = useFieldArray({
    control,
    name: "translations"
  });

  const minDate = new Date("1299-01-01");

  const { notifyError } = useGenericToastify();

  const hierarchicSelectUrl = `/api/dicts/${dictionary.id}/entries/hierarchic-select-data`;

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const dict = await getDict(dictID);
      setDictionary(dict.data);
      const data = {};

      data.visibility = visibility[0];
      data.dictionary_name = dict.data.name;
      data.dictionary = dict.data.id;
      reset(data);
    };

    if (isMounted.current && dictID) {
      fetchData();
    }
  }, [dictID, visibility, reset]);

  useEffect(() => {
    axiosSession
      .get("/api/dicts/languages/select-data/")
      .then(({ data }) => {
        setLanguages(data.results);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    !isEmpty(errors)
      ? notifyError("Nie udało się zapisać wartości słownikowej.")
      : null;
  }, [errors, notifyError]);

  const onSubmit = data => {
    setIsLoading(true);

    resetErrors();

    data.translations = !isEmpty(data.translations)
      ? data.translations.map(option => {
          return { content: option.content, language: option.language.id };
        })
      : [];

    data.is_visible = data.visibility.value;
    if (startDate) {
      data.start = format(startDate, "dd.MM.yyyy");
    } else {
      data.start = null;
    }

    if (endDate) {
      data.end = format(endDate, "dd.MM.yyyy");
    } else {
      data.end = null;
    }
    const method = "POST";
    const url = "/api/dicts/entries/";

    if (!data.parent) {
      data.parent = null;
    }

    axiosSession({ method: method, url: url, data: data })
      .then(() => {
        setOpenModal(false);
        setReloadSelect(true);
      })
      .catch(error => {
        if (error.response.data.main_content) {
          setError("main_content", "", error.response.data.main_content);
        }
        if (error.response.data.parent) {
          setError("parent", "", error.response.data.parent);
        }
        if (error.response.data.is_visible) {
          setError("visibility", "", error.response.data.is_visible);
        }
        if (error.response.data.translations) {
          setError("translations", "", error.response.data.translations);
        }
        if (error.response.data.non_field_errors) {
          setInputError(error.response.data.non_field_errors[0]);
        }

        notifyError("Nie udało się zapisać wartości słownikowej.");
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetErrors = () => {
    setInputError(false);
  };

  return (
    <section className="dictionaries-entries-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="columns is-variable">
          <div className="column is-half-desktop is-full-mobile">
            <input type="hidden" name="dictionary" ref={register} />
            <input type="hidden" value="add" name="action" ref={register} />
            <Input
              name={"dictionary_name"}
              label={"Słownik"}
              darkTheme={false}
              disabled={true}
              errors={errors}
              register={register}
            />
            {dictionary.is_flat || dictionary.source || !dictionary.id ? (
              ""
            ) : (
              <NestedSelect
                name="parent"
                url={hierarchicSelectUrl}
                placeholder="Wybierz nadrzędny"
                label="Nadrzędny"
                control={control}
                errors={errors}
                darkTheme={false}
                disabled={false}
                defaultParent={defaultParent}
              />
            )}

            <Input
              name={"main_content"}
              label={"Nazwa"}
              darkTheme={false}
              register={register({ required: "To pole jest wymagane!" })}
              errors={errors}
              isRequired
            />

            {dictionary.is_time_limited ? (
              <div className="dictionaries-entries-form__section columns">
                <div className="column is-3">
                  <div className="field">
                    <label htmlFor="start" className="label">
                      Czas trwania
                    </label>
                    <div className="control mbc-input">
                      <DatePicker
                        name="start"
                        className="input"
                        locale={pl}
                        dateFormat="dd.MM.yyyy"
                        selected={startDate}
                        onChange={date => {
                          setStartDate(date);
                          if (date >= endDate) setEndDate();
                        }}
                        minDate={minDate}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </div>
                    {!isEmpty(errors) && has(errors, "start")
                      ? errors["start"].message.map((message, index) => (
                          <p key={index} className="help is-danger">
                            {message}
                          </p>
                        ))
                      : null}
                  </div>
                </div>
                <div className="column is-3">
                  <div className="field">
                    <label htmlFor="end" className="label">
                      &nbsp;
                    </label>
                    <div className="control mbc-input">
                      <DatePicker
                        name="end"
                        className="input"
                        locale={pl}
                        dateFormat="dd.MM.yyyy"
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                      />
                    </div>
                    {!isEmpty(errors) && has(errors, "end")
                      ? errors["end"].message.map((message, index) => (
                          <p key={index} className="help is-danger">
                            {message}
                          </p>
                        ))
                      : null}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="dictionaries-entries-form__section">
          <button
            className="button button--is-black"
            type="button"
            onClick={() => {
              append({ language: { id: "", name: "" }, content: "" });
            }}
          >
            Dodaj tłumaczenie
          </button>
        </div>
        <div>
          {!isEmpty(errors) && has(errors, "translations.message")
            ? errors["translations"].message.map((message, index) => (
                <p key={index} className="help is-danger">
                  {message}
                </p>
              ))
            : null}
        </div>
        <div className="dictionaries-entries-form__section">
          {fields.map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                <div className="columns">
                  <div className="column is-half-desktop">
                    <div className="columns">
                      <div className="column is-5">
                        <GenericSelect
                          options={languages}
                          placeholder="Wybierz język"
                          control={control}
                          required={{
                            required: "To pole jest wymagane!",
                            validate: value =>
                              value.id !== "" || "To pole jest wymagane!"
                          }}
                          defaultValue={item.language}
                          name={`translations[${index}].language`}
                          errors={errors}
                          label="Język tłumaczenia"
                        />
                      </div>
                      <div className="column is-7 d-flex">
                        <div className="w-100">
                          <GenericInput
                            control={control}
                            name={`translations[${index}].content`}
                            label="Tłumaczenie"
                            defaultValue={`${item.content}`}
                            darkTheme={false}
                            required={{ required: "To pole jest wymagane!" }}
                            errors={errors}
                          />
                        </div>
                        <div className="item-m">
                          <span
                            className="dictionaries-entries-form__field-row__btn"
                            onClick={() => remove(index)}
                            onKeyPress={() => remove(index)}
                            role="button"
                            tabIndex="0"
                            title="Usuń tłumaczenie"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>

        <div className="dictionaries-entries-form__section columns">
          <div className="column is-half-desktop is-full-mobile">
            <GenericSelect
              options={visibility}
              placeholder="Wybierz status"
              control={control}
              required={{ required: "To pole jest wymagane!" }}
              name="visibility"
              errors={errors}
              label="Status"
            />
          </div>
        </div>

        <div>
          {inputError ? (
            <div className="level is-danger is-size-6">
              <div className="level-left section-danger has-text-danger">
                <span className="level-item icon">
                  <i className="fas fa-exclamation-triangle" />
                </span>
                {inputError}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="column is-12">
            <div className="level-right">
              <button
                className={`button button--is-orange ${
                  isLoading ? "is-loading" : ""
                }`}
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                Zapisz
              </button>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default SimpleDictionariesEntriesForm;
