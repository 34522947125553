import "./CollectionsThumbnail.scss";

import React, { useEffect, useState } from "react";

import DropdownButton from "@components/DropdownButton/DropdownButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericModal from "@components/Modal/GenericModal";
import GenericTable from "@components/Table/GenericTable";
import Stepper from "@app/pages/Directories/Stepper";
import TruncateMarkup from "react-truncate-markup";
import axiosSession from "@app/config/axiosSession";
import fileSize from "filesize";

const CollectionsThumbnailUploadStep = ({
  id,
  nextStepChange,
  previousStepChange,
  stepLength
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [collectionHasFile, setCollectionHasFile] = useState(false);
  const [modalType, setModalType] = useState("");
  const [stepIsLoaded, setStepIsLoaded] = useState(false);
  const [tableShouldRefresh, setTableShouldRefresh] = useState(false);

  let submitType = "";

  const urls = {
    get: `/api/collections/${id}/thumbnails/`,
    delete: `/api/collections/${id}/thumbnails/`
  };

  const messages = {
    deleteSuccess: "Miniaturka została poprawnie usunięta",
    deleteError: "Wystąpił problem podczas usuwania miniaturki",
    btnDeleteNo: "Anuluj",
    btnDeleteYes: "Usuń",
    headerTitle: "Potwierdzenie usunięcia",
    bodyContent: "Czy na pewno chcesz usunąć miniaturkę?",
    contentLabel: "Potwierdzenie usunięcia miniaturki"
  };

  const tableStructure = [
    {
      header: "Nazwa pliku",
      field: "name",
      accessor: "name",
      className: "w-6",
      sort: true
    },
    {
      header: "Rozmiar",
      field: "size",
      accessor: "size",
      className: "w-4",
      sort: true
    },
    {
      header: "Akcje",
      className: "w-2"
    }
  ];

  useEffect(() => {
    if (id) {
      setStepIsLoaded(id);
    }
  }, [id]);

  const handleDraftSubmit = () => {
    submitType = "draft";
    submitPhoto();
    return true;
  };

  const handleNextSubmit = () => {
    submitType = "next";
    submitPhoto();
    return true;
  };

  const handlePreviousSubmit = () => {
    submitType = "previous";
    submitPhoto();
    return true;
  };

  const onPreview = file_path => {
    window.open(file_path, "_blank").focus();
  };

  const callbackFileUploaded = data => {
    if (data && data.count) {
      setCollectionHasFile(true);
    } else {
      setCollectionHasFile(false);
    }
  };

  const submitPhoto = () => {
    if (submitType === "draft") {
      location.href = "/collections/";
    } else if (submitType === "previous") {
      previousStepChange(id);
    } else {
      nextStepChange(id);
    }
  };

  const openFileUploadWindow = () => {
    window.open(`/collections/${id}/file-upload/`, "_blank").focus();
  };

  const addFile = () => {
    if (collectionHasFile) {
      openModal("addOption");
    } else {
      axiosSession
        .get(urls.get)
        .then(res => {
          if (res.data.count > 0) {
            setCollectionHasFile(true);
            openModal("addOption");
          } else {
            openFileUploadWindow();
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  const onConfirm = () => {
    closeModal();
    if (modalType === "cancel") {
      const url = "/collections/";
      window.location.href = `/cancel?url=${url}`;
    } else {
      axiosSession
        .delete(`${urls.delete}`)
        .then(() => {
          if (modalType === "addOption") {
            openFileUploadWindow();
          }
          setTableShouldRefresh(true);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setTableShouldRefresh(false);
        });
    }
  };

  const openModal = type => {
    setModalType(type);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const onCancel = () => {
    closeModal();
  };

  const dialogMessages = () => {
    if (modalType === "addOption") {
      return {
        btnDeleteNo: "Nie",
        btnDeleteYes: "Tak",
        headerTitle: "Uwaga",
        bodyContent:
          "W systemie znajduje się już miniaturka dla tej kolekcji. Dodając nową miniaturkę usuniesz poprzednią. Czy chcesz kontynuować?"
      };
    } else if (modalType === "cancel") {
      return {
        bodyContent: `Klikając przycisk anuluj sprawiasz, że dane nie zostaną zapisane. Czy na pewno chcesz anulować ${
          id ? "edycję" : "dodanie nowej"
        } kolekcji?`,
        headerTitle: "Anuluj",
        btnDeleteNo: "Nie",
        btnDeleteYes: "Tak"
      };
    } else {
      return {
        btnDeleteNo: "Anuluj",
        btnDeleteYes: "Usuń",
        headerTitle: "Potwierdzenie usunięcia",
        bodyContent: "Czy na pewno chcesz usunąć miniaturkę?",
        contentLabel: "Potwierdzenie usunięcia miniaturki"
      };
    }
  };

  const customRow = item => {
    return (
      <React.Fragment key={item.id}>
        <tr id={`row-${item.id}`}>
          <td>
            <TruncateMarkup>
              <span title={item.name}>{item.name}</span>
            </TruncateMarkup>
          </td>
          <td>
            <span title={item.size}>{fileSize(item.size)}</span>
          </td>
          <td>
            <span
              onClick={() => onPreview(item.path)}
              onKeyPress={() => onPreview(item.path)}
              role="button"
              tabIndex="0"
              className="generic-table__table__link"
              title="Podgląd"
            >
              <FontAwesomeIcon icon="eye" />
            </span>
            <span
              onClick={openModal}
              onKeyPress={openModal}
              role="button"
              tabIndex="0"
              className="generic-table__table__link"
              title="Usuń"
            >
              <FontAwesomeIcon icon="trash" />
            </span>
          </td>
        </tr>
      </React.Fragment>
    );
  };

  return (
    <section className="mbc-backoffice">
      <GenericModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        labels={dialogMessages()}
        actionSubmit={onConfirm}
        actionCancel={onCancel}
        showItemName={false}
      />
      <div className="container">
        <div className="breadcrumbs">
          <a
            className="breadcrumbs__link"
            href="/admin-panel/home/"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/collections/"
            title="Kolekcje"
          >
            Kolekcje
          </a>
          &nbsp;/&nbsp;
          <strong>
            {id ? "Edycja" : "Dodawanie"} {"kolekcji"}
          </strong>
        </div>

        <div className="mbc-backoffice__header">
          <h2 className="mbc-backoffice__header__title">
            {id ? "Edycja" : "Dodawanie"} {"kolekcji"}
          </h2>
          <hr className="mbc-backoffice__header__hr" />
        </div>

        <Stepper activeStep={2} stepLength={stepLength} />

        <div className={"container"}>
          <div className="collections-thumbnail__container">
            <button
              className="button button--is-black"
              type="button"
              onClick={() => {
                addFile();
              }}
            >
              Dodaj pliki
            </button>

            <span>
              Po naciśnięciu przycisku otworzy się nowe okno,
              <br />w którym będzie można wgrać pliki.
            </span>
          </div>

          {stepIsLoaded && (
            <GenericTable
              structure={tableStructure}
              hasPagination={false}
              messages={messages}
              hasRefreshAction={true}
              hasHeaderActions={false}
              disableDelete={true}
              customRowWithActions={customRow}
              dataCallback={callbackFileUploaded}
              urls={urls}
              hasSearch={false}
              shouldRefresh={tableShouldRefresh}
            />
          )}
        </div>

        <div className="column is-12">
          <div className="level-right">
            <button
              className="button"
              onClick={() => openModal("cancel")}
              title="Anuluj i wróć do listy"
              type="button"
            >
              Anuluj
            </button>
            <button
              className="button button--previous"
              type="button"
              onClick={handlePreviousSubmit}
              title="Poprzedni krok"
            >
              <FontAwesomeIcon icon="caret-left" />
              &nbsp;Wstecz
            </button>
            <DropdownButton
              mainOption={{
                type: "submit",
                title: "Dalej",
                href: "",
                onClick: handleNextSubmit
              }}
              options={[
                {
                  type: "submit",
                  title: "Zapisz szkic",
                  href: "",
                  id: 1,
                  onClick: handleDraftSubmit
                }
              ]}
              onClick
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CollectionsThumbnailUploadStep;
