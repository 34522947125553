import { StateMachineProvider, createStore } from "little-state-machine";

import AdminForm from "@app/pages/Admins/AdminForm";
import AdminPanel from "@app/pages/Menu/AdminPanel";
import Admins from "@app/pages/Admins/Admins";
import Agreements from "@app/pages/Rules/Agreements";
import AgreementsCreate from "@app/pages/Rules/AgreementsCreate";
import AgreementsEdit from "@app/pages/Rules/AgreementsEdit";
import AgreementsPreview from "@app/pages/Rules/AgreementsPreview";
import Attributes from "@app/pages/Attributes/Attributes";
import AttributesForm from "@app/pages/Attributes/AttributesForm";
import AttributesPreview from "@app/pages/Attributes/AttributesPreview";
import Collections from "@app/pages/Collections/Collections";
import CollectionsFileUpload from "@app/pages/Collections/CollectionsFileUpload";
import CollectionsPreview from "@app/pages/Collections/CollectionsPreview";
import CollectionsStepForm from "@app/containers/CollectionsStepForm";
import Dictionaries from "@app/pages/Dictionaries/Dictionaries";
import DictionariesEntriesForm from "@app/pages/Dictionaries/DictionariesEntriesForm";
import DictionariesForm from "@app/pages/Dictionaries/DictionariesForm";
import DictionariesPreview from "@app/pages/Dictionaries/DictionariesPreview";
import DigitalItemPreview from "@app/pages/Directories/DigitalItemPreview";
import DigitalItemStepForm from "./DigitalItemStepForm";
import Directories from "@app/pages/Directories/Directories";
import DirectoriesFileUpload from "@app/pages/Directories/DirectoriesFileUpload";
import DirectoriesPreview from "@app/pages/Directories/DirectoriesPreview";
import DirectoriesSearch from "@app/pages/Directories/DirectoriesSearch";
import DirectoryStepForm from "./DirectoryStepForm";
import ExternalUsers from "@app/pages/ExternalUsers/ExternalUsers";
import ExternalUsersEdit from "@app/pages/ExternalUsers/ExternalUsersEdit";
import ExternalUsersPreview from "@app/pages/ExternalUsers/ExternalUsersPreview";
import GroupForm from "@app/pages/Groups/GroupForm";
import GroupObjectStepForm from "./GroupObjectStepForm";
import GroupPreview from "@app/pages/Groups/GroupPreview";
import Groups from "@app/pages/Groups/Groups";
import Libraries from "@app/pages/Libraries/Libraries";
import LibraryForm from "@app/pages/Libraries/LibraryForm";
import LibraryPreview from "@app/pages/Libraries/LibraryPreview";
import LocalAdminForm from "@app/pages/LocalAdmins/LocalAdminForm";
import LocalAdmins from "@app/pages/LocalAdmins/LocalAdmins";
import Messages from "@app/pages/Messages/Messages";
import MessagesAdd from "@app/pages/Messages/MessagesAdd";
import MessagesPreview from "@app/pages/Messages/MessagesPreview";
import NewsletterSetupForm from "@app/pages/Newsletter/NewsletterSetup";
import ObjectImport from "@app/pages/ObjectImport/ObjectImport";
import ObjectImport_v2 from "@app/pages/ObjectImport/ObjectImport_v2";
import OcrPanel from "@app/pages/OcrPanel/OcrPanel";
import React from "react";
import ReportForm from "@app/pages/Reports/ReportForm";
import ReportedErrors from "@app/pages/ReportedErrors/ReportedErrors";
import Reports from "@app/pages/Reports/Reports";
import Schemas from "@app/pages/Attributes/Schemas";
import SchemasForm from "@app/pages/Attributes/SchemasForm";
import SchemasPreview from "@app/pages/Attributes/SchemasPreview";
import SearchStatistics from "@app/pages/Statistics/SearchStatistics";
import ShowUnacceptedRules from "@app/pages/Rules/ShowUnacceptedRules";
import Synonyms from "@app/pages/Dictionaries/Synonyms";
import Terms from "@app/pages/Rules/Terms";
import TermsEdit from "@app/pages/Rules/TermsEdit";
import TermsPreview from "@app/pages/Rules/TermsPreview";
import UsersPreview from "@app/pages/Admins/UsersPreview";
import WorkerForm from "@app/pages/Workers/WorkerForm";
import Workers from "@app/pages/Workers/Workers";

createStore({ data: {} });
window.sessionStorage.clear();

const App = ({
  component,
  messages,
  id,
  directory_type,
  unaccepted_terms,
  unaccepted_agreements,
  file_server,
  library_id,
  has_create_permission,
  has_mass_operation_permission,
  has_sip_permission,
  is_superuser
}) => {
  if (unaccepted_terms) {
    return <ShowUnacceptedRules type="terms" backoffice />;
  }
  if (unaccepted_agreements) {
    return <ShowUnacceptedRules type="agreements" backoffice />;
  }

  switch (component) {
    case "AdminPanel":
      return <AdminPanel userMenuPermissions={user_menu_permissions} />;
    case "ExternalUsers":
      return <ExternalUsers message={messages} />;
    case "ExternalUsersPreview":
      return <ExternalUsersPreview id={id} />;
    case "ExternalUsersEdit":
      return <ExternalUsersEdit id={id} />;
    case "Groups":
      return (
        <Groups
          message={messages}
          hasCreatePermission={has_create_permission}
        />
      );
    case "GroupForm":
      return <GroupForm id={id} />;
    case "GroupPreview":
      return <GroupPreview id={id} />;
    case "Workers":
      return (
        <Workers
          message={messages}
          hasCreatePermission={has_create_permission}
        />
      );
    case "WorkerForm":
      return <WorkerForm id={id} />;
    case "WorkerPreview":
      return <UsersPreview id={id} type="worker" />;
    case "TermsPreview":
      return <TermsPreview id={id} />;
    case "TermsEdit":
      return <TermsEdit id={id} />;
    case "TermsList":
      return <Terms message={messages} />;
    case "AgreementsCreate":
      return <AgreementsCreate />;
    case "AgreementsEdit":
      return <AgreementsEdit id={id} />;
    case "AgreementsPreview":
      return <AgreementsPreview id={id} />;
    case "AgreementsShow":
      return <AgreementsEdit id={id} editMode={false} />;
    case "AgreementsList":
      return (
        <Agreements
          message={messages}
          hasCreatePermission={has_create_permission}
        />
      );
    case "LocalAdmins":
      return (
        <LocalAdmins
          message={messages}
          hasCreatePermission={has_create_permission}
        />
      );
    case "LocalAdminForm":
      return <LocalAdminForm id={id} />;
    case "LocalAdminPreview":
      return <UsersPreview id={id} type="localAdmin" />;
    case "Admins":
      return (
        <Admins
          message={messages}
          hasCreatePermission={has_create_permission}
        />
      );
    case "AdminForm":
      return <AdminForm id={id} />;
    case "AdminPreview":
      return <UsersPreview id={id} type="admin" />;
    case "Libraries":
      return (
        <Libraries
          message={messages}
          hasCreatePermission={has_create_permission}
        />
      );
    case "LibraryForm":
      return <LibraryForm id={id} />;
    case "LibraryPreview":
      return <LibraryPreview id={id} />;
    case "ObjectImport":
      return <ObjectImport />;
      case "ObjectImport_v2":
        return <ObjectImport_v2 />;
    case "OcrPanel":
      return <OcrPanel />;
    case "Collections":
      return (
        <Collections
          message={messages}
          hasCreatePermission={has_create_permission}
        />
      );
    case "CollectionsPreview":
      return <CollectionsPreview id={id} />;
    case "Dictionaries":
      return (
        <Dictionaries
          message={messages}
          hasCreatePermission={has_create_permission}
        />
      );
    case "DictionariesForm":
      return (
        <DictionariesForm
          id={id}
          message={messages}
          hasCreatePermission={has_create_permission}
        />
      );
    case "DictionariesEntriesFormCreate":
      return <DictionariesEntriesForm dictID={id} message={messages} />;
    case "DictionariesEntriesForm":
      return <DictionariesEntriesForm id={id} message={messages} />;
    case "DictionariesPreview":
      return (
        <DictionariesPreview
          id={id}
          hasCreatePermission={has_create_permission}
        />
      );
    case "Schemas":
      return (
        <Schemas
          message={messages}
          hasCreatePermission={has_create_permission}
        />
      );
    case "SchemasForm":
      return <SchemasForm id={id} />;
    case "SchemasPreview":
      return <SchemasPreview id={id} />;
    case "Attributes":
      return (
        <Attributes
          message={messages}
          hasCreatePermission={has_create_permission}
        />
      );
    case "AttributesForm":
      return <AttributesForm id={id} />;
    case "AttributesPreview":
      return <AttributesPreview id={id} />;
    case "DigitalItemPreview":
      return <DigitalItemPreview id={id} />;
    case "SearchStatistics":
      return <SearchStatistics />;
    case "ReportedErrors":
      return <ReportedErrors />;
    case "Messages":
      return (
        <Messages
          message={messages}
          hasCreatePermission={has_create_permission}
        />
      );
    case "MessagesAdd":
      return <MessagesAdd id={id} />;
    case "MessagesPreview":
      return <MessagesPreview id={id} />;
    case "Directories":
      return (
        <Directories
          message={messages}
          hasCreatePermission={has_create_permission}
          hasSipPermission={has_sip_permission}
        />
      );
    case "DirectoriesSearch":
      return (
        <StateMachineProvider>
          <DirectoriesSearch
            hasCreatePermission={has_create_permission}
            hasMassOperationPermission={has_mass_operation_permission}
            hasSipPermission={has_sip_permission}
          />
        </StateMachineProvider>
      );
    case "DirectoriesPreview":
      return <DirectoriesPreview id={id} directory_type={directory_type} />;
    case "DirectoriesFileUpload":
      return (
        <DirectoriesFileUpload
          id={id}
          fileServer={file_server}
          formType={directory_type}
        />
      );
    case "DirectoryStepForm":
      return <DirectoryStepForm id={id} formType={directory_type} />;
    case "GroupObjectStepForm":
      return (
        <GroupObjectStepForm
          id={id}
          fileServer={file_server}
          formType={directory_type}
        />
      );
    case "DigitalItemStepForm":
      return (
        <DigitalItemStepForm
          id={id}
          fileServer={file_server}
          formType={directory_type}
        />
      );
    case "CollectionsFileUpload":
      return (
        <CollectionsFileUpload
          id={id}
          fileServer={file_server}
          formType={"collections"}
        />
      );
    case "CollectionsStepForm":
      return (
        <CollectionsStepForm
          id={id}
          fileServer={file_server}
          libraryId={library_id}
          isSuperUser={is_superuser}
        />
      );
    case "NewsletterSetupForm":
      return <NewsletterSetupForm />;
    case "Synonyms":
      return <Synonyms fileServer={file_server} />;
    case "Reports":
      return (
        <Reports
          message={messages}
          hasCreatePermission={has_create_permission}
        />
      );
    case "ReportForm":
      return <ReportForm id={id} />;

    default:
      return null;
  }
};

export default App;
