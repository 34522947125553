import { isValid, parse } from "date-fns";

import axiosSession from "@app/config/axiosSession";
import find from "lodash/find";

const convertValueToFormData = async (data, schema, type = null) => {
  let values = {};
  let sizeDict = {};
  for (const [key, value] of Object.entries(data)) {
    const field = find(schema, ["name", key]);
    if (field) {
      const fieldType = type ? type : field.type;
      let fieldArray = [];
      switch (fieldType) {
        case "array":
          if (field.subType === "select") {
            if (field.is_flat || field.is_flat === null) {
              if (value?.[0]) {
                const dict_url = "/api/dicts/" + field.dictionary_id + "/entries/select-data/"
                const items = await axiosSession.get(dict_url, {
                  params: { id__in: `${value.map(v => parseInt(v))}` }
                });
                values[key] = items?.data?.results;
              } else {
                values[key] = null;
              }
            } else {
              values[key] = value.map(v => parseInt(v));
            }
            break;
          } else if (field.subType === "group") {
            for (const f of data[field.name]) {
              const { values } = await convertValueToFormData(f, field.fields);
              fieldArray.push(values);
            }
          } else {
            let valuesList = [];
            for (const f of data[field.name]) {
              const { values } = await convertValueToFormData(
                { [field.name]: f },
                [{ ...field, type: field.subType }]
              );
              valuesList.push(values[field.name]);
            }
            sizeDict[field.name] = data[field.name].length;
            values[key] = valuesList;
            break;
          }
          sizeDict[field.name] = data[field.name].length;
          values[key] = fieldArray;
          break;
        case "date": {
          const dateValue = parse(value, "dd.MM.yyyy", new Date());
          values[key] = isValid(dateValue) ? [dateValue] : null;
          break;
        }
        case "group": {
          const convertedData = await convertValueToFormData(
            data[field.name],
            field.fields
          );

          values[key] = convertedData.values;
          break;
        }
        case "select":
          if (!field.is_flat && field.is_flat !== null) {
            values[key] = value ? parseInt(value) : "";
          } else {
            if (value) {
              // eslint-disable-next-line no-case-declarations
              const dict_url = "/api/dicts/" + field.dictionary_id + "/entries/select-data/"
              const items = await axiosSession.get(dict_url, {
                params: { id__in: value }
              });
              if (items?.data?.results?.length === 1 && !field.frequent) {
                values[key] = items?.data?.results[0];
              } else {
                values[key] = items?.data?.results;
              }
            } else {
              values[key] = null;
            }
          }
          break;
        default:
          values[key] = value;
      }
    }
  }
  return { values, sizeDict };
};

export default convertValueToFormData;
