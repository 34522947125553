import "@app/i18n";
import "@app/helpers/logout";
import "@app/helpers/sentry";
import 'leaflet/dist/leaflet.css'

import App from "@app/containers/App";
import React from "react";
import ReactDOM from "react-dom";
import Sidebar from "@components/Sidebar/Sidebar";

const mbcApp = document.getElementById("mbc_app");
const mbcSidebar = document.getElementById("mbc_sidebar");

if (document.body.contains(mbcApp)) {
  ReactDOM.render(
    <App
      component={component}
      messages={messages}
      previous_url={previous_url}
      captcha={registerCaptchaKey}
      confirmation={confirmation}
      id={id}
      directory_type={directory_type}
      unaccepted_terms={unaccepted_terms}
      unaccepted_agreements={unaccepted_agreements}
      uid={uid}
      username={username}
      token={token}
      file_server={file_server}
      library_id={library_id}
      has_create_permission={has_create_permission}
      has_mass_operation_permission={has_mass_operation_permission}
      has_sip_permission={has_sip_permission}
      user_menu_permissions={user_menu_permissions}
      is_superuser={is_superuser}
    />,
    mbcApp
  );
}

if (document.body.contains(mbcSidebar)) {
  ReactDOM.render(
    user_menu_permissions.length > 0 && (
      <Sidebar
        location={window.location.pathname}
        userMenuPermissions={user_menu_permissions}
      />
    ),
    mbcSidebar
  );
}
