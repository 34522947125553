import React, {useState} from 'react';

import Modal from "react-responsive-modal";
import NestedSelect from "@app/components/Select/NestedSelect";
import axiosSession from "@app/config/axiosSession";
import {useForm} from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

const DirectoriesObjectTransfer = ({
  libraryId,
  isModalObjectTransferOpen,
  setIsModalObjectTransferOpen,
  objectsToTransfer,
  setObjectsToTransfer
}) => {

  const {notifySuccess, notifyError} = useGenericToastify();
  const {control, errors, getValues} = useForm();

  const [reindexInfo, setReindexInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const hierarchicSelectUrl = `/api/directories/${libraryId}/hierarchic-select-data`;
  const groupAssigmentUrl = `/api/directories/group-assigment/`;

  const modalMessage = {
    headerTitle: "Przenoszenie zasobów",
    bodyContent: "Reindeksacja w toku. Proszę odświeżyć stronę."
  };

  const saveChanges = item => {
    const parentId = getValues({nest: true})["hierarchical"];
    if (parentId === undefined) return notifyError("Wybierz miejsce docelowe aby kontynuować");
    axiosSession
      .post(groupAssigmentUrl, {
        ...item,
        parent: parentId
      })
      .then(() => setReindexInfo(true))
      .catch((error) => {
        const {parent} = error.response.data;
        if (parent === undefined) {
          notifyError("Zasób nie może być nadrzędny względem siebie.");
        } else {
          notifyError(`${parent[0]}`);
        }
      })
      .finally(() => setIsLoading(false))
    return setIsLoading(true);
  }

  const closeModal = () => {
    setIsModalObjectTransferOpen(false);
    setObjectsToTransfer({});
  };

  return (
    <>
      <Modal
        open={isModalObjectTransferOpen}
        onClose={() => closeModal()}
        center
        showCloseIcon={false}
        classNames={{modal: "GenericModal"}}
      >
        <div className="GenericModal__Header--with-border">
          <p className="GenericModal__Header__Title">
            {modalMessage.headerTitle}
          </p>
        </div>

        <div className="GenericModal__Body">
          {!reindexInfo &&
          <NestedSelect
            name="hierarchical"
            placeholder="Wybierz"
            label="Wartość nadrzędna"
            required={{required: true}}
            control={control}
            errors={errors}
            darkTheme={false}
            disabled={false}
            url={hierarchicSelectUrl}
          />
          }
          {reindexInfo && <span className="GenericModal__Body--info">{modalMessage.bodyContent}</span>}
        </div>

        <div className="GenericModal__Footer">
          <p className="GenericModal__Footer__Buttons buttons is-right is-fullwidth">
            {reindexInfo ?
              <button
                className="button button--is-orange GenericModal__Footer__Button"
                onClick={() => closeModal()}
              >
                Zamknij
              </button>
              :
              <React.Fragment>
                <button
                  className="button GenericModal__Footer__Button"
                  onClick={() => closeModal()}
                >
                  Anuluj
                </button>
                <button
                  className={`button button--is-orange GenericModal__Footer__Button ${isLoading ? "is-loading" : ""}`}
                  onClick={() => saveChanges(objectsToTransfer)}
                >
                  Zapisz
                </button>
              </React.Fragment>
            }
          </p>
        </div>
      </Modal>
    </>
  );
};

export default DirectoriesObjectTransfer;
