import "@uppy/core/dist/style.css";
import "@uppy/progress-bar/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "./FileUpload.scss";

import * as Polish from "@uppy/locales/lib/pl_PL";

import React, { useEffect, useRef, useState } from "react";

import { Dashboard } from "@uppy/react";
import GenericModal from "../Modal/GenericModal";
import GenericSelect from "@components/Select/GenericSelect";
import Tus from "@uppy/tus";
import Uppy from "@uppy/core";
import axiosSession from "@app/config/axiosSession";
import { setPageNumbers } from "@app/services/services";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

const GoldenRetriever = require("@uppy/golden-retriever");

const FileUpload = ({ id, fileServer, formType, fileUploadURL }) => {
  const [photoInfo, setPhotoInfo] = useState([]);
  const [failedUploadedFiles, setFailedUploadedFiles] = useState([]);
  const [submiting, setSubmiting] = useState(false);
  const { notifyError } = useGenericToastify();
  const [photoIsUploaded, setPhotoIsUploaded] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const isDigitalItem = formType === "digital-item";
  const messages = {
    bodyContent:
      "Plik został załadowany poprawnie. Indeksacja stron może zająć kilka minut.",
    btnDeleteNo: "Kontynuuj dodawanie",
    btnDeleteYes: "Zamknij kartę"
  };
  const filetypes = [
    { id: 1, name: "pdf", value: 1, fileExtension: [".pdf"] },
    {
      id: 2,
      name: "tiff, tif",
      value: 2,
      fileExtension: [".tiff", ".tif"]
    },
    {
      id: 3,
      name: "jpg",
      value: 3,
      fileExtension: [".jpg"]
    },
    {
      id: 4,
      name: "plik miniaturki",
      value: 4,
      fileExtension: [".jpg", ".jpeg", ".png"]
    },
    { id: 5, name: "xml", value: 5, fileExtension: [".xml"] },
    { id: 6, name: "djvu", value: 6, fileExtension: [".djvu"] },
    { id: 7, name: "inne", value: 7 }
  ];
  const [currentFileType, setCurrentFileType] = useState([]);
  const maxNumberOfFiles =
    isDigitalItem && currentFileType.name === filetypes[3].name
      ? 1
      : isDigitalItem
      ? 0
      : 1;

  const { errors, control } = useForm({});

  const uppy = useRef(
    Uppy({
      id: "uppy1",
      autoProceed: false,
      debug: true,
      locale: Polish,
      allowMultipleUploads: true
    })
      .use(Tus, {
        endpoint: fileServer,
        limit: 5
      })
      .use(GoldenRetriever, {
        serviceWorker: false,
        indexedDB: {
          maxFileSize: 2 * 1024 * 1024 * 1024, // 2GB => Each file
          maxTotalSize: 1024 * 1024 * 1024 * 1024 // 1 TB
        }
      })
      .on("complete", result => {
        const { successful } = result;
        const filesToUpload = successful.map(item => ({
          filename: item.name,
          path: item.uploadURL,
          size: item.size
        }));
        setPhotoIsUploaded(true);
        if (isDigitalItem) {
          setPhotoInfo(filesToUpload);
        } else {
          setPhotoInfo({
            name: successful[0].name,
            path: successful[0].uploadURL,
            size: successful[0].size
          });
        }
      })
  );

  const filesUpload = async () => {
    setSubmiting(true);
    if (isDigitalItem) {
      for (const data of photoInfo) {
        await uploadPhoto(data);
      }
      setSubmiting(false);
      setModalIsOpen(true);
    } else {
      uploadPhoto(photoInfo, true);
    }
    uppy.current.reset();
    setFailedUploadedFiles([]);
    if (currentFileType.value !== filetypes[3].value) setPages();
  };

  const setPages = () => {
    try {
      setPageNumbers(id, currentFileType.value);
    } catch (error) {
      console.error(error.response);
    }
  };

  const uploadPhoto = (data, singleUpload = false) => {
    data.file_type = currentFileType.value;
    if (isDigitalItem) {
      data.digital_item = id;
    }
    return axiosSession
      .post(fileUploadURL, data)
      .then(() => {
        setPhotoIsUploaded(false);
      })
      .catch(error => {
        const err = error.response.data;
        const msg = errorCode => {
          notifyError(errorCode);
          setFailedUploadedFiles(origin => [
            ...origin,
            (data = { filename: data.filename, error: errorCode })
          ]);
        };
        if (err["size"]) {
          msg(err["size"][0]);
        }
        if (err["name"]) {
          msg(err["name"][0]);
        }
        if (err["file_type"]) {
          msg(err["file_type"][0]);
        }
        if (!err) {
          msg("Wystąpił błąd podczas ładowania plików.");
        }
      })
      .finally(() => {
        if (singleUpload) {
          setModalIsOpen(true);
          setSubmiting(false);
        }
      });
  };

  useEffect(() => {
    const currentUppyInstance = uppy.current;
    return () => currentUppyInstance.close();
  }, [uppy]);

  useEffect(() => {
    isDigitalItem
      ? setCurrentFileType(filetypes[0])
      : setCurrentFileType(filetypes[3]);
  }, []);

  useEffect(() => {
    uppy.current.opts.restrictions.allowedFileTypes =
      currentFileType.fileExtension;
    uppy.current.opts.restrictions.maxNumberOfFiles = maxNumberOfFiles;
    uppy.current.reset();
  }, [currentFileType]);

  const handleSelect = data => {
    setCurrentFileType(data[0]);
  };
  useEffect(() => {
    if (photoIsUploaded) {
      window.onbeforeunload = function() {
        return "Dodane pliki nie zostały zapisane w systemie. Czy na pewno chcesz wyjść, nie zapisując plików?";
      };
    } else {
      window.onbeforeunload = undefined;
    }
  }, [photoIsUploaded]);

  return (
    <div className="file-upload">
      <GenericModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        labels={messages}
        actionSubmit={() => {
          window.onbeforeunload = undefined;
          window.close();
        }}
        actionCancel={() => {
          setModalIsOpen(false);
        }}
        showItemName={false}
        withoutHeader
      />
      <div className="columns">
        <div className="column is-3">
          <GenericSelect
            placeholder={currentFileType.name}
            options={isDigitalItem ? filetypes : []}
            defaultValue={currentFileType}
            name="schemes"
            label="TYP PLIKÓW"
            errors={errors}
            control={control}
            handleSelectChange={handleSelect}
            isDisabled={!isDigitalItem}
          />
        </div>
      </div>
      <Dashboard uppy={uppy.current} height={300} showProgressDetails={true} />
      <button
        className={`button ${
          photoIsUploaded ? "button--uploaded" : "button--is-black"
        } ${submiting ? "is-loading" : ""}`}
        type="button"
        disabled={!photoIsUploaded}
        onClick={() => {
          filesUpload();
        }}
      >
        Zapisz w systemie
      </button>
      {failedUploadedFiles.length > 0 && (
        <table className="file-upload__table">
          <thead>
            <tr>
              <th>Nazwa pliku</th>
              <th>Błąd</th>
            </tr>
          </thead>
          <tbody>
            {failedUploadedFiles.map(item => {
              return (
                <tr key={item.filename}>
                  <td>{item.filename}</td>
                  <td>{item.error}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default FileUpload;
