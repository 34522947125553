import find from "lodash/find";
import { format } from "date-fns";

const convertFormDataToValue = async (data, schema, type = null) => {
  let values = {};
  for (const [key, value] of Object.entries(data)) {
    const field = find(schema, ["name", key]);
    const fieldType = type ? type : field.type;
    let fieldArray = [];
    switch (fieldType) {
      case "array":
        if (field.subType === "select") {
          if (field.is_flat) {
            values[key] = value ? value.map(v => v.id) : "";
          } else {
            values[key] = value;
          }
          break;
        } else if (field.subType === "group") {
          for (const f of data[field.name]) {
            fieldArray.push(await convertFormDataToValue(f, field.fields));
          }
          values[key] = fieldArray;
        } else {
          let valuesList = [];
          for (const value of data[field.name]) {
            const convertedValue = await convertFormDataToValue(
              { [field.name]: value },
              [{ ...field, type: field.subType }]
            );
            valuesList.push(convertedValue[field.name]);
          }
          values[key] = valuesList;
        }
        break;
      case "group": {
        const convertedValue = await convertFormDataToValue(
          data[field.name],
          field.fields
        );
        values[key] = convertedValue;
        break;
      }
      case "date":
        values[key] = value ? format(value[0], "dd.MM.yyyy") : "";
        break;
      case "select":
        if (field.is_flat) {
          values[key] = value ? value.id : "";
        } else {
          values[key] = value ? value : "";
        }
        break;
      default:
        values[key] = value ? value : "";
    }
  }
  return values;
};

export default convertFormDataToValue;
