import GenericTable from "@components/Table/GenericTable";
import React from "react";

const tableStructure = [
  {
    header: "Imię",
    field: "first_name",
    accessor: "user__first_name",
    className: "w-3",
    sort: true
  },
  {
    header: "Nazwisko",
    field: "last_name",
    accessor: "user__last_name",
    className: "w-3",
    sort: true
  },
  {
    header: "Login",
    field: "username",
    accessor: "user__username",
    className: "w-4",
    sort: true
  },
  {
    header: "Akcje",
    className: "w-2 has-text-right"
  }
];

const urls = {
  get: "/api/users/global_admins/",
  add: "/users/global_admins/create/",
  edit: "/users/global_admins/", //appends /${id}/update
  delete: "/api/users/global_admins/" //appends /${id}/
};

const messages = {
  deleteSuccess: "Administrator został poprawnie usunięty",
  deleteError: "Wystąpił problem podczas usuwania Administratora",
  deleteConfirm: "Czy na pewno chcesz usunąć Administratora?",
  btnAdd: "Dodaj Administratora",
  btnDeleteNo: "Anuluj",
  btnDeleteYes: "Usuń",
  headerTitle: "Potwierdzenie usunięcia",
  bodyContent: "Czy na pewno chcesz usunąć Administratora?",
  contentLabel: "Potwierdzenie usunięcia Administratora"
};

const Admins = ({ message, hasCreatePermission }) => {
  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Administratorzy globalni</strong>
        </div>
        <GenericTable
          structure={tableStructure}
          notification={message}
          messages={messages}
          urls={urls}
          deleteItemName="username"
          editItemValueName="user_id"
          disablePreview={false}
          hasHeaderActions={hasCreatePermission}
        />
      </div>
    </section>
  );
};

export default Admins;
