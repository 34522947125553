import "./Schemas.scss";

import React, { useState } from "react";

import {
  faMinusSquare,
  faPlusSquare
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericTable from "@components/Table/GenericTable";
import TruncateMarkup from "react-truncate-markup";

const tableStructure = [
  {
    header: "Nazwa",
    accessor: "name",
    className: "w-2",
    sort: true
  },
  {
    header: "Wersja",
    accessor: "version",
    className: "w-1",
    sort: true
  },
  {
    header: "Opis schematu",
    accessor: "description",
    className: "w-3",
    sort: true
  },
  {
    header: "Atrybuty",
    field: "attributes",
    accessor: "attributes",
    className: "w-3",
    sort: false
  },
  {
    header: "Status",
    accessor: "active",
    className: "w-2",
    sort: true
  },
  {
    header: "Akcje",
    className: "w-2 has-text-right"
  }
];

const urls = {
  get: "/api/attributes/schema/",
  add: "/attributes/schemas/create/",
  edit: "/attributes/schemas/", //appends /${id}/edit
  delete: "/api/attributes/schema/"
};

const messages = {
  deleteSuccess: "Schemat został poprawnie usunięty",
  deleteError: "Wystąpił problem podczas usuwania schematu",
  btnAdd: "Dodaj schemat",
  btnDeleteNo: "Anuluj",
  btnDeleteYes: "Usuń",
  headerTitle: "Potwierdzenie usunięcia",
  bodyContent: "Czy na pewno chcesz usunąć schemat?",
  contentLabel: "Potwierdzenie usunięcia schematu"
};

const Schemas = ({ message, hasCreatePermission }) => {
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [shouldTableRefresh, setShouldTableRefresh] = useState(false);

  const expandContent = (index, isExpanded) => {
    if (isExpanded) {
      const indexes = [...expandedIndexes];
      indexes.splice(indexes.indexOf(index), 1);
      setExpandedIndexes(indexes);
    } else {
      setExpandedIndexes(expandedIndexes.concat([index]));
    }
    setShouldTableRefresh(true);
  };

  const customRow = item => {
    const isMultipleLine = item.attributes.length > 1;
    const isExpanded = expandedIndexes.includes(item.id);

    const attributes = item.attributes.map((attribute, index) => (
      <a
        key={index}
        href={`/attributes/${attribute.id}/preview/`}
        className="grid-link"
      >
        {`${attribute.name}${index === item.attributes.length - 1 ? "" : ", "}`}
      </a>
    ));

    return (
      <React.Fragment key={item.id}>
        <td>
          <TruncateMarkup>
            <span title={item.name}>{item.name}</span>
          </TruncateMarkup>
        </td>
        <td>{item.version}</td>
        <td>
          <TruncateMarkup>
            <span title={item.description}>{item.description}</span>
          </TruncateMarkup>
        </td>
        <td>
          {isMultipleLine ? (
            <div>
              <div className="extended-object">
                <FontAwesomeIcon
                  className="extended-object__icon"
                  icon={!isExpanded ? faPlusSquare : faMinusSquare}
                  onClick={() => expandContent(item.id, isExpanded)}
                  title={!isExpanded ? "Wyświetl zawartość" : "Zwiń zawartość"}
                />
                <p
                  className="extended-object__element"
                  title={!isExpanded ? "Wyświetl zawartość" : "Zwiń zawartość"}
                >
                  {!isExpanded ? "Wyświetl zawartość" : "Zwiń zawartość"}
                </p>
              </div>
              {isExpanded && <span title="Atrybuty">{attributes}</span>}
            </div>
          ) : (
            <TruncateMarkup>
              <span title="Atrybuty">{attributes}</span>
            </TruncateMarkup>
          )}
        </td>
        <td>
          <TruncateMarkup>
            <span title={item.active ? "aktywny" : "nieaktywny"}>
              {item.active ? "aktywny" : "nieaktywny"}
            </span>
          </TruncateMarkup>
        </td>
      </React.Fragment>
    );
  };

  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Schematy opisowe</strong>
        </div>

        <GenericTable
          structure={tableStructure}
          messages={messages}
          customRow={customRow}
          notification={message}
          urls={urls}
          shouldRefresh={shouldTableRefresh}
          setShouldRefresh={setShouldTableRefresh}
          hasHeaderActions={hasCreatePermission}
        />
      </div>
    </section>
  );
};
export default Schemas;
