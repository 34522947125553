import "./DirectoryForm.scss";

import React, { useEffect, useRef, useState } from "react";

import CancelButton from "@components/CancelButton/CancelButton";
import Checkbox from "@components/Checkbox/Checkbox";
import DropdownButton from "@components/DropdownButton/DropdownButton";
import Stepper from "./Stepper";
import axiosSession from "@app/config/axiosSession";
import handleErrors from "@app/helpers/handleErrors";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";
import valuesIn from "lodash/valuesIn";

let submitType = "";

const PermissionsStep = ({ id, formType, previousStepChange, stepLength }) => {
  const { handleSubmit, register, errors, setError } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const { notifyError } = useGenericToastify();
  const isGroup = formType === "group-object";
  const isDigitalItem = formType === "digital-item";
  const isPublishable = isGroup || isDigitalItem;
  const isMounted = useRef(null);

  let formUrl = `/api/directories/${id}/permission/`;
  const formLabel = isDigitalItem
    ? "obiektu"
    : isGroup
    ? "obiektu grupowego"
    : "katalogu";

  if (isDigitalItem) {
    formUrl = `/api/digital_items/${id}/permission/`;
  }

  const returnLocation =
    localStorage.backToDirectoriesSearch !== undefined
      ? "/directories/search/"
      : "/directories/";

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      axiosSession
        .get(formUrl)
        .then(res => {
          setPermissions(res.data);
        })
        .finally(() => setIsLoading(false));
    }
  }, [id]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleDraftSubmit = () => {
    submitType = "draft";
    return true;
  };

  const handlePublishSubmit = () => {
    submitType = "publish";
    return true;
  };

  const handlePreviousSubmit = () => {
    submitType = "previous";
    handleSubmit(onSubmit)();
  };

  const onSubmit = data => {
    setIsLoading(true);
    let parsedPermissions = Object.keys(data).map(item => {
      return { name: item, permissions: valuesIn(data[item]) };
    });

    let publish_state = false;

    if (submitType === "publish") {
      publish_state = true;
    }
    let formData = {
      group_permissions: parsedPermissions,
      publish: publish_state
    };

    axiosSession
      .put(formUrl, formData)
      .then(() => {
        if (submitType === "draft") {
          location.href = returnLocation;
        } else if (submitType === "previous") {
          previousStepChange(id);
        } else {
          location.href = returnLocation;
        }
      })
      .catch(error => {
        console.error(error);
        notifyError("Nie udało się zapisać katalogu.");
        handleErrors(error.response.data, setError);
      })
      .finally(() => {
        if (isMounted.current) {
          setIsLoading(false);
        }
      });
  };

  return (
    <section className="directory-form">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/directories/"
            title="Lista katalogów"
          >
            Drzewo zasobów
          </a>
          &nbsp;/&nbsp;
          <strong>
            {id ? "Edycja" : "Dodawanie"} {formLabel}
          </strong>
        </div>

        <div className="directory-form__header">
          <h2 className="directory-form__header__title">
            {id ? "Edycja" : "Dodawanie"} {formLabel}
          </h2>
          <hr className="directory-form__header__hr" />
        </div>

        {isGroup || isDigitalItem ? (
          <Stepper activeStep={4} stepLength={stepLength} />
        ) : (
          <Stepper activeStep={3} stepLength={stepLength} />
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="columns is-variable is-8 is-multiline">
            <div className="column is-12">
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th rowSpan={2}>GRUPY</th>
                    <th colSpan={isGroup || isDigitalItem ? 4 : 3}>
                      UPRAWNIENIA
                    </th>
                  </tr>
                  <tr>
                    <th>Odczyt</th>
                    <th>Modyfikacja</th>
                    {(isGroup || isDigitalItem) && <th>Kolekcje</th>}
                    <th>Publikacja</th>
                  </tr>
                </thead>
                <tbody>
                  {permissions.length ? (
                    permissions.map(group => {
                      return (
                        <tr key={group.group_id}>
                          <td>{group.group_name}</td>
                          {group.permission ? (
                            group.permission.map(permission => {
                              return (
                                <td key={`p-${permission.id}`}>
                                  <div className="mbc-checkbox">
                                    <Checkbox
                                      name={`${group.group_id}`}
                                      value={permission.id}
                                      register={register}
                                      defaultChecked={permission.value}
                                      disabled={!permission.editable}
                                      errors={errors}
                                      darkTheme={false}
                                    >
                                      {}
                                    </Checkbox>
                                  </div>
                                </td>
                              );
                            })
                          ) : (
                            <td colSpan={isGroup || isDigitalItem ? 4 : 3}>
                              {" "}
                              -{" "}
                            </td>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={isGroup || isDigitalItem ? 5 : 4}>
                        Brak uprawnień
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="column is-12">
              <div className="level-right">
                <CancelButton
                  returnLocation={returnLocation}
                  bodyContentSufix={`${
                    id ? "edycję" : "dodanie nowego"
                  } ${formLabel}?`}
                />
                <button
                  className="button button--right"
                  type="button"
                  onClick={handlePreviousSubmit}
                  title="Poprzedni krok"
                >
                  Wstecz
                </button>
                <DropdownButton
                  mainOption={{
                    type: "submit",
                    title: isPublishable ? "Publikuj" : "Zapisz",
                    onClick: handlePublishSubmit
                  }}
                  isLoading={isLoading}
                  options={[
                    {
                      type: "submit",
                      title: "Zapisz szkic",
                      id: 1,
                      onClick: handleDraftSubmit
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default PermissionsStep;
