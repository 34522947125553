import "./GenericSelect.scss";

import { Controller, ErrorMessage } from "react-hook-form";

import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import React from "react";
import axiosSession from "@app/config/axiosSession";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";

const GenericAsyncSelect = ({
  options,
  required,
  control,
  handleSelectChange,
  name,
  label,
  defaultValue,
  isDisabled,
  placeholder,
  errors,
  isMulti,
  isClearable,
  getOptionValue,
  getOptionLabel,
  hideSelectedOptions,
  onBlur,
  maxMenuHeight,
  darkTheme,
  styles,
  isSearchable,
  noOptionsMessage,
  closeMenuOnSelect,
  className,
  dictionary_url,
  reloadSelect
}) => {
  const whiteTextLabel = darkTheme ? "" : "__black";
  const errorStyle = !isEmpty(errors) && has(errors, name) ? "is-danger" : "";
  const fetchData = async inputValue => {
    return await axiosSession
      .get(dictionary_url, { params: { name: inputValue } })
      .then(({ data: { results } }) => {
        return results;
      })
      .catch(err => {
        console.error(err);
      });
  };
  return (
    <div className={`field mbc-select ${className}`}>
      {label && (
        <label
          htmlFor={name}
          className={`label mbc-select__label${whiteTextLabel}`}
        >
          {label}
        </label>
      )}
      <div className="control">
        {!reloadSelect && (
          <Controller
            as={
              <AsyncSelect
                classNamePrefix="react-select"
                className={`${errorStyle} mbc-select__label__black`}
                options={options}
                onBlur={onBlur}
                maxMenuHeight={maxMenuHeight}
                styles={styles}
                isSearchable={isSearchable}
                noOptionsMessage={noOptionsMessage}
                closeMenuOnSelect={closeMenuOnSelect}
                cacheOptions
                defaultOptions
              />
            }
            loadOptions={fetchData}
            control={control}
            placeholder={placeholder}
            rules={required}
            onChange={handleSelectChange}
            name={name}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
            isClearable={isClearable}
            getOptionLabel={
              getOptionLabel ? getOptionLabel : option => `${option.name}`
            }
            getOptionValue={
              getOptionValue ? getOptionValue : option => `${option.id}`
            }
            isMulti={isMulti}
            hideSelectedOptions={hideSelectedOptions}
          />
        )}
      </div>
      <ErrorMessage
        as={<p className="help is-danger" />}
        errors={errors}
        name={name}
      />
    </div>
  );
};

GenericAsyncSelect.propTypes = {
  options: PropTypes.array,
  required: PropTypes.object,
  control: PropTypes.object,
  handleSelectChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  isMulti: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  getOptionValue: PropTypes.func,
  maxMenuHeight: PropTypes.number,
  darkTheme: PropTypes.bool,
  isSearchable: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  className: PropTypes.string
};
GenericAsyncSelect.defaultProps = {
  isMulti: false,
  hideSelectedOptions: false,
  defaultValue: null,
  isSearchable: true,
  noOptionsMessage: () => "Brak opcji",
  closeMenuOnSelect: true,
  className: "",
  placeholder: "Wybierz..."
};

export default GenericAsyncSelect;
