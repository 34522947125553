import "./LocalAdminForm.scss";

import React, { useEffect, useState } from "react";

import CancelButton from "@components/CancelButton/CancelButton";
import GenericSelect from "@components/Select/GenericSelect";
import Input from "@components/Input/Input";
import axiosSession from "@app/config/axiosSession";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

const LocalAdminForm = ({ id }) => {
  const {
    handleSubmit,
    register,
    errors,
    setValue,
    reset,
    setError,
    control
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [inputError, setInputError] = useState("");
  const { notifyError } = useGenericToastify();
  const [libraries, setLibraries] = useState([]);

  const returnLocation = "/cancel?url=/users/admins/";

  const resetErrors = () => {
    setInputError(false);
  };

  useEffect(() => {
    if (id) {
      axiosSession
        .get(`/api/users/admins/${id}/`)
        .then(res => {
          const resetData = { ...res.data.user, library: res.data.library };
          reset(resetData);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [id, reset, setValue]);

  useEffect(() => {
    !isEmpty(errors)
      ? notifyError("Nie udało się zapisać administratora lokalnego.")
      : null;
  }, [errors, notifyError]);

  useEffect(() => {
    axiosSession
      .get("/api/library/select-data/")
      .then(res => {
        setLibraries(res.data);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  const onSubmit = data => {
    setIsLoading(true);
    let method = "";
    let url = "";

    data.groups = [];
    let formData = {
      user: data,
      library: data.library.id
    };

    resetErrors();

    if (id) {
      method = "PUT";
      url = `/api/users/admins/${id}/`;
    } else {
      method = "POST";
      url = "/api/users/admins/create/";
    }

    axiosSession({ method: method, url: url, data: formData })
      .then(response => {
        setIsLoading(false);
        window.location.href = response.data.url;
      })
      .catch(error => {
        notifyError();
        if (error.response.data.user) {
          if (error.response.data.user.username) {
            setError("username", "", error.response.data.user.username);
          }

          if (error.response.data.user.first_name) {
            setError("first_name", "", error.response.data.user.first_name);
          }

          if (error.response.data.user.last_name) {
            setError("last_name", "", error.response.data.user.last_name);
          }

          if (error.response.data.user.email) {
            setError("email", "", error.response.data.user.email);
          }
        }

        if (error.response.data.library) {
          setError("library", "", error.response.data.library);
        }

        if (error.response.data.non_field_errors) {
          setInputError(error.response.data.non_field_errors[0]);
        }
        notifyError("Nie udało się zapisać administratora lokalnego.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // TODO: library should be reqiured field
  return (
    <section className="local-admin-form">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/users/admins/"
            title="Administratorzy lokalni"
          >
            Administratorzy lokalni
          </a>
          &nbsp;/&nbsp;
          <strong>{id ? "Edytuj" : "Dodaj"} administratora lokalnego</strong>
        </div>

        <div className="local-admin-form__header">
          <h2 className="local-admin-form__header__title">
            {id ? "Edycja" : "Dodawanie"} administratora lokalnego
          </h2>
          <hr className="local-admin-form__header__hr" />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="columns is-centered is-variable is-8">
            <div className="column">
              <Input
                disabled={!!id}
                name={"username"}
                label={"Nazwa użytkownika (LOGIN)"}
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
              <Input
                name={"first_name"}
                label={"Imię"}
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
              <Input
                name={"last_name"}
                label={"Nazwisko"}
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
              <Input
                name={"email"}
                label={"Adres E-MAIL"}
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
            </div>
            <div className="column">
              <GenericSelect
                options={libraries}
                placeholder="Wybierz bibliotekę"
                control={control}
                required={{ required: "To pole jest wymagane!" }}
                name="library"
                isDisabled={!!id}
                errors={errors}
                label="Biblioteka"
                isRequire
              />
            </div>
          </div>
          <div>
            {inputError ? (
              <div className="level is-danger is-size-6">
                <div className="level-left section-danger has-text-danger">
                  <span className="level-item icon">
                    <i className="fas fa-exclamation-triangle" />
                  </span>
                  {inputError}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="buttons">
              <CancelButton
                returnLocation={returnLocation}
                bodyContentSufix={`${
                  id ? "edycję" : "dodanie nowego"
                } administratora lokalnego?`}
              />
              <button
                className={`button local-admin-form__btn ${
                  isLoading ? "is-loading" : ""
                }`}
                type="submit"
              >
                Zapisz
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default LocalAdminForm;
