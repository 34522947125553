import React, { useEffect, useState } from "react";

import Preview from "@components/Preview/Preview";
import axiosSession from "@app/config/axiosSession";

const AttributesPreview = ({ id }) => {
  const [rows, setRows] = useState([]);
  const [canEdit, setCanEdit] = useState(false);

  const urls = {
    get: `/api/attributes/${id}/details/`,
    edit: `/attributes/${id}/edit/`
  };

  useEffect(() => {
    if (id) {
      axiosSession
        .get(urls.get)
        .then(({ data }) => {
          setCanEdit(data.can_edit_permission);
          setRows([
            { title: "Nazwa", value: [data.name] },
            {
              title: "Tłumaczenie",
              value: data.translations_names.map(
                el => `${el.language} - ${el.content}`
              )
            },
            {
              title: "Skrócony opis zawartości pola",
              value: [data.description]
            },
            { title: "Typ atrybutu", value: [data.attribute_type_name] },
            {
              title: "Cechy typu atrybutu",
              value: data.features_values_names.map(
                el => `${el.name}: ${el.value}`
              )
            },
            {
              title: "Walidacja",
              value: data.validators_values_names.map(
                el => `${el.name}: ${el.value}`
              )
            },
            {
              title: "Status",
              value: [data.active ? "aktywne" : "nieaktywne"]
            },
            {
              title: "Metadane",
              value: [
                data.technical ? "metadane techniczne" : "metadane opisowe"
              ]
            },
            {
              title: "Atrybut wymagany",
              value: [data.required ? "TAK" : "NIE"]
            },
            {
              title: "Atrybut wyświetlany domyślnie",
              value: [data.visible ? "TAK" : "NIE"]
            },
            {
              title: "Atrybut jest powtarzalny",
              value: [data.frequent ? "TAK" : "NIE"]
            },
            {
              title: "Atrybut złożony z atrybutów",
              value: [data.related_attributes_names.join(", ")]
            }
          ]);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [id]);

  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a className="breadcrumbs__link" href="/attributes/" title="Atrybuty">
            Atrybuty
          </a>
          &nbsp;/&nbsp;
          <strong>Podgląd</strong>
        </div>

        <div className="mbc-backoffice__header">
          <h2 className="mbc-backoffice__header__title">Podgląd atrybutu</h2>
          <hr className="mbc-backoffice__header__hr" />
        </div>

        {rows && <Preview rows={rows} />}

        <div className="mbc-backoffice__bottom">
          <a
            className="mbc-backoffice__goback is-uppercase has-text-weight-bold"
            href="/attributes/"
          >
            &lt; Wróć do listy atrybutów
          </a>
          {canEdit &&
          <a
            className="mbc-backoffice__goback is-uppercase has-text-weight-bold"
            href={urls.edit}
          >
            Edytuj
          </a>}
        </div>
      </div>
    </section>
  );
};

export default AttributesPreview;
