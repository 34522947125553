import "@app/scss/GenericTable.scss";

import React, { useCallback, useEffect, useState } from "react";
import {
  faArrowLeft,
  faArrowRight,
  faCaretDown,
  faCaretUp,
  faCheck,
  faEye,
  faFilter,
  faPen,
  faSync,
  faTimes,
  faTrash
} from "@fortawesome/free-solid-svg-icons";

import Checkbox from "@app/components/Checkbox/Checkbox";
import Cookies from "js-cookie";
import DropdownButton from "@app/components/DropdownButton/DropdownButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericModal from "@app/components/Modal/GenericModal";
import GenericSelect from "@app/components/Select/GenericSelect";
import GenericRadioGroup from "@app/components/Radio/GenericRadioGroup";
import Icon from "@ant-design/icons";
import PropTypes from "prop-types";
import { Switch } from "antd";
import TruncateMarkup from "react-truncate-markup";
import axiosSession from "@app/config/axiosSession";
import dataFilter from "lodash/filter";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";
import useWindowWidth from "@app/hooks/useWindowResize";

const GenericTable = ({
  structure,
  customRow,
  customActions,
  customRowWithActions,
  hasPagination,
  notification,
  itemsPerPage,
  messages,
  urls,
  deleteItemName,
  editItemValueName,
  hasSearch,
  hasFilters,
  filtersOptions,
  filtersValues,
  filtersCopyrightValues,
  hasHeaderActions,
  hasRefreshAction,
  tableHeader,
  disableDelete,
  disablePreview,
  dataCallback,
  expandedRowData,
  searchField,
  queryParams,
  shouldRefresh,
  setShouldRefresh,
  buttonOnClick,
  isSelectable,
  libraryId,
  groupActions,
  advancedSearch,
  searchType,
  setSearchType,
  defaultTableFilters,
  customCheckboxHandler,
  styleClassNames,
  tableMessages,
  groupOperationBtnsActive,
  isCopyrightFilter,
  isArchiveFilter,
  isSignedFilter,
  isFileFilter,
  additionalParameters,
  headerActionsAlignRight,
  setQueryId,
  filterLabel,
  inlineFilters,
  massOperationPerm,
  sipOperationPerm,
  expandRow
}) => {
  const { handleSubmit, control, errors } = useForm();
  const [data, setData] = useState([]);
  const [empty, setEmpty] = useState("");
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState([]);
  const [pagination, setPagination] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [activeItemPerPage, setActiveItemPerPage] = useState(
    itemsPerPage?.[0] ?? 10
  );
  const [ordering, setOrdering] = useState("");
  const [filter, setFilter] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(defaultTableFilters);
  const [copyrightSelectedFilters, setCopyrightSelectedFilters] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { notifySuccess, notifyError } = useGenericToastify();
  const width = useWindowWidth();
  const [deleteItemData, setDeleteItemData] = useState({});
  const [uploadData, setUploadData] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  // reseted on filter or search change
  const [selectedData, setSelectedData] = useState([]);

  const [filterObjects, setFilterObjects] = useState([]);
  const [filterCopyright, setFilterCopyright] = useState([]);
  const [filterArchive, setFilterArchive] = useState(false);
  const [filterSigned, setFilterSigned] = useState(false);
  const [compactView, setCompactView] = useState(false);

  const changeTableView = checked => {
    Cookies.set("compact-view", checked);
    setCompactView(checked);
  };

  const searchByMenu = [
    {
      id: 1,
      name: "Atrybuty",
      label: "fields"
    },
    {
      id: 2,
      name: "Treść",
      label: "text"
    },
    {
      id: 3,
      name: "Atrybuty i treść",
      label: "all"
    }
  ];

  useEffect(() => {
    const compact = Cookies.get("compact-view");
    if (compact) {
      setCompactView(compact === "true");
    }
  }, []);

  useEffect(() => {
    if (expandedRowData.index !== undefined) {
      const position = expandedRowData.index + 1;

      if (!data[expandedRowData.index]?.expanded) {
        setData([
          ...data.slice(0, position),
          ...expandedRowData.rows,
          ...data.slice(position)
        ]);
      } else {
        const filtered = dataFilter(data, e =>
          e.ancestors
            ? !e.ancestors.includes(data[expandedRowData.index].id)
            : !data
                .find(x => x.id === e.parent)
                .ancestors.includes(
                  data[expandedRowData.index].id &&
                    e.parent !== data[expandedRowData.index].id
                )
        );

        setData([...filtered]);
      }

      if (data[expandedRowData.index]) {
        data[expandedRowData.index].expanded = expandedRowData.expanded;
      }
    }
  }, [expandedRowData]);

  useEffect(() => {
    if (!isEmpty(notification.messages)) {
      notifySuccess(notification.messages[0]);
    }
  }, [notification, notifySuccess]);

  useEffect(() => {
    retrieveData();
  }, [
    retrieveData,
    width,
    activePage,
    ordering,
    filter,
    activeItemPerPage,
    uploadData,
    libraryId
  ]);

  useEffect(() => {
    if (shouldRefresh) {
      retrieveData();
    }
  }, [retrieveData, shouldRefresh]);

  useEffect(() => {
    if (
      hasFilters &&
      (filtersValues === "filter" ||
        filtersValues === "status" ||
        filtersValues === "library_id")
    ) {
      return setFilterObjects(filtersOptions);
    }
    if (hasFilters) {
      if (isFileFilter) {
        setFilterObjects(
          filtersOptions.filter(
            item =>
              item.id !== "directory" ||
              item.id !== "digital_item" ||
              item.id !== "group_object"
          )
        );
      } else {
        setFilterObjects(
          filtersOptions.filter(
            item =>
              item.id === "directory" ||
              item.id === "digital_item" ||
              item.id === "group_object"
          )
        );
        setFilterCopyright(
          filtersOptions.filter(
            item =>
              item.id !== "directory" &&
              item.id !== "digital_item" &&
              item.id !== "group_object"
          )
        );
      }
    }
  }, [filtersOptions, hasFilters]);

  const retrieveData = useCallback(() => {
    setEmpty(tableMessages.loading);

    const params = new URLSearchParams();

    params.append("limit", activeItemPerPage);
    params.append("page", activePage);
    params.append("ordering", ordering);

    if (filter) params.append(searchField, filter);

    if (selectedFilters.length) {
      if (filtersValues === "library_id") {
        const ids = selectedFilters.join(",");
        params.append(filtersValues, ids);
      } else {
        selectedFilters.forEach(elem => params.append(filtersValues, elem));
      }
    }
    copyrightSelectedFilters.forEach(elem =>
      params.append(filtersCopyrightValues, elem)
    );

    if (searchType) {
      params.append("type", searchType);
    }
    if (queryParams) {
      params.append("queryParams", queryParams);
    }
    if (libraryId) {
      params.append("library", libraryId);
    }
    if (filterArchive) {
      params.append("in_archive", filterArchive);
    }
    if (filterSigned) {
      params.append("signed", filterSigned);
    }
    if (!isEmpty(additionalParameters)) {
      Object.entries(additionalParameters).forEach(([key, value]) => {
        params.append(key, value);
      });
    }

    axiosSession
      .get(urls.get, { params })
      .then(res => {
        if (res.data.count === 0) {
          setEmpty(tableMessages.noItems);
        }

        res.data.results.forEach(item => {
          if (item?.expanded) {
            // TODO: very primitive solution of itemIndex
            const itemIndex = item.parent === null ? 0 : 1;
            item.expanded = false;
            expandRow(item, itemIndex);
          }
        });

        const pages_number =
          res.data.pages_number ||
          Math.ceil(res.data.count / activeItemPerPage);

        let resPages = [...Array(pages_number).keys()].map(page => page + 1);
        let resPagination = {
          last: getLastPage(res.data.count),
          prev: getPrevPage(),
          next: getNextPage(res.data.count)
        };

        if (resPages.length >= 5) {
          if (!resPagination.prev) {
            resPages = [1, 2, 3, 4, resPages.length];
          } else if (!resPagination.next) {
            resPages = [
              1,
              resPagination.last - 3,
              resPagination.last - 2,
              resPagination.last - 1,
              resPagination.last
            ];
          } else if (resPagination.last === resPagination.next) {
            resPages = [
              1,
              activePage - 2,
              activePage - 1,
              activePage,
              resPagination.last
            ];
          } else {
            resPages = [
              1,
              resPagination.prev,
              activePage,
              resPagination.next,
              resPagination.last
            ];
          }
        }

        setPagination(resPagination);
        setPages(resPages);

        setTotal(res.data.count);
        if (dataCallback) dataCallback(res.data);
        if (customCheckboxHandler) {
          customCheckboxHandler.onRetrieve(
            res.data.results,
            setData,
            setSelectAll
          );
        } else {
          res.data.results.forEach((e, idx) => {
            e.ancestors = [];
            e.id = e.id ? e.id : idx;
            if (isSelectable) {
              e.isChecked = selectedData.includes(e.id) ? true : false;
            }
          });
          setData(res.data.results);
          if (
            res.data.results.filter(elem => elem.isChecked == true).length ==
              res.data.results.length &&
            res.data.results.length > 0
          ) {
            setSelectAll(true);
          } else {
            setSelectAll(false);
          }
        }
      })
      .catch(err => {
        setEmpty(tableMessages.fetchError);
        console.error(err);
      });
  }, [
    activeItemPerPage,
    activePage,
    ordering,
    searchField,
    filter,
    selectedFilters,
    copyrightSelectedFilters,
    searchType,
    queryParams,
    libraryId,
    urls.get,
    filtersValues,
    dataCallback,
    isSelectable,
    filterArchive,
    filterSigned
  ]);

  const getLastPage = count => {
    return count <= activeItemPerPage
      ? null
      : Math.ceil(count / activeItemPerPage);
  };

  const getPrevPage = () => {
    return activePage <= 2 ? null : activePage - 1;
  };

  const getNextPage = count => {
    return activePage == Math.ceil(count / activeItemPerPage)
      ? null
      : activePage + 1;
  };

  const onCheckboxChange = item => {
    const position = item.target.name;

    if (item.target.checked) {
      setSelectedData(selectedData.concat(data[position]));
    } else {
      setSelectedData(
        selectedData.filter(elem => elem.id != data[position].id)
      );
    }

    setData(
      data.map(elem => {
        if (elem.id == data[position].id) {
          return { ...elem, isChecked: !elem.isChecked };
        }
        return elem;
      })
    );
  };

  const onSelectAll = () => {
    const mappedData = data.map(elem => ({ ...elem, isChecked: !selectAll }));

    const toAdd = [];
    let selected = selectedData;

    mappedData.forEach(elem => {
      if (elem.isChecked && !selectedData.find(e => e.id == elem.id)) {
        toAdd.push(elem);
      }
      if (!elem.isChecked) {
        selected = selected.filter(e => e.id != elem.id);
      }
    });

    setSelectedData(selected.concat(toAdd));
    setData(mappedData);
    setSelectAll(!selectAll);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const onSetItemsPerPage = item => {
    setActivePage(1);
    setActiveItemPerPage(item);
  };

  const onSetActivePage = page => event => {
    event.preventDefault();
    let activePage = page ? parseInt(page) : 1;
    setActivePage(activePage);
  };

  const onSetOrdering = column => {
    setOrdering(column);
  };

  const onFilter = params => event => {
    if (event.key === "Enter" || params !== undefined) {
      setQueryId("");
      setActivePage(1);
      if (customCheckboxHandler) {
        customCheckboxHandler.reset();
      } else {
        setSelectedData([]);
      }
      setFilter(event.target.value);
    }
  };

  const onDelete = (item, name, id) => {
    setDeleteItemData({ id: item[id], name: item[name] });
    openModal();
  };

  const reducePages = pages => {
    if (pages.length != 5) {
      return pages;
    }

    let lastPage = pages[pages.length - 1];

    if (lastPage == 5) {
      return pages;
    }
    if (activePage > 3 && activePage < lastPage - 2) {
      return [1, "..."].concat(pages.slice(1, 4), ["...", lastPage]);
    }
    if (activePage <= 3) {
      return pages.slice(0, 4).concat(["...", lastPage]);
    }
    if (activePage >= lastPage - 2) {
      return [1, "..."].concat(pages.slice(1));
    }
  };

  const actions = item => {
    const itemPreviewURL = `${urls.edit}${item[editItemValueName]}/preview/`;
    const itemEditURL = `${urls.edit}${item[editItemValueName]}/edit/`;

    return item.actions ? (
      <td className="has-text-right">
        {item.actions.viewable && (
          <a
            href={itemPreviewURL}
            className="generic-table__table__link"
            title="Podgląd"
          >
            <FontAwesomeIcon icon={faEye} />
          </a>
        )}
        {item.actions.editable && (
          <a
            href={itemEditURL}
            className="generic-table__table__link"
            title="Edycja"
          >
            <FontAwesomeIcon icon={faPen} />
          </a>
        )}
        {item.actions.removeable && (
          <button
            onClick={() => onDelete(item, deleteItemName, editItemValueName)}
            className="generic-table__table__link"
            title="Usuń"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        )}
      </td>
    ) : (
      <td className="has-text-right">
        {!disablePreview && (
          <a
            href={itemPreviewURL}
            className="generic-table__table__link"
            title="Podgląd"
          >
            <FontAwesomeIcon icon={faEye} />
          </a>
        )}
        <a
          href={itemEditURL}
          className="generic-table__table__link"
          title="Edycja"
        >
          <FontAwesomeIcon icon={faPen} />
        </a>
        {/* TODO Refactor custom action */}
        {!disableDelete && (
          <button
            onClick={() => onDelete(item, deleteItemName, editItemValueName)}
            className="generic-table__table__link"
            title="Usuń"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        )}
      </td>
    );
  };

  const getField = (object, path) => {
    return get(object, path);
  };

  const onConfirmDelete = id => {
    axiosSession
      .delete(`${urls.delete}${id}/`)
      .then(() => {
        if (total % activeItemPerPage == 1) {
          setActivePage(activePage > 1 ? activePage - 1 : 1);
        }
        notifySuccess(messages.deleteSuccess);
        retrieveData();
      })
      .catch(error => {
        if (error.response.data.detail) {
          notifyError(error.response.data.detail);
        } else {
          notifyError(messages.deleteError);
        }
        console.error(error);
      })
      .finally(() => closeModal());
  };

  const onFilterSelect = value => {
    if (selectedFilters.includes(value)) {
      setSelectedFilters(selectedFilters.filter(item => item !== value));
    } else {
      setSelectedFilters(origin => [...origin, value]);
    }
  };

  const onCopyrightFilterSelect = value => {
    if (copyrightSelectedFilters.includes(value)) {
      setCopyrightSelectedFilters(
        copyrightSelectedFilters.filter(item => item !== value)
      );
    } else {
      setCopyrightSelectedFilters(origin => [...origin, value]);
    }
  };

  const onArchiveFilter = () => {
    setFilterArchive(filterArchive => !filterArchive);
  };

  const onSignedFilter = e => {
    setFilterSigned(JSON.parse(e.target.value));
  };

  const onShowFilters = () => {
    setShowFilters(!showFilters);
  };

  let body = data.map((item, index) =>
    customRowWithActions ? (
      isSelectable ? (
        customCheckboxHandler ? (
          customRowWithActions(item, index, e =>
            customCheckboxHandler.handler(data, setData, e)
          )
        ) : (
          customRowWithActions(item, index, onCheckboxChange)
        )
      ) : (
        customRowWithActions(item, index)
      )
    ) : customRow ? (
      <tr key={item.id}>
        {isSelectable && (
          <td>
            <Checkbox
              name={`${index}`}
              onChange={onCheckboxChange}
              checked={item.isChecked}
              darkTheme={false}
            />
          </td>
        )}
        {customRow(item)}
        {customActions ? customActions(item) : actions(item)}
      </tr>
    ) : (
      <tr key={item.id}>
        {isSelectable && (
          <td>
            <Checkbox
              name={`${index}`}
              onChange={onCheckboxChange}
              checked={item.isChecked}
              darkTheme={false}
            />
          </td>
        )}
        {structure.map((column, index) =>
          column.field ? (
            <td key={index}>
              <TruncateMarkup key={index}>
                <div
                  title={getField(item, column.field)}
                  className="truncate-markup-element"
                >
                  {!column.url ? (
                    getField(item, column.field)
                  ) : (
                    <a
                      className="grid-link"
                      href={column.url.replace("<id>", item.library.id)}
                    >
                      {getField(item, column.field)}
                    </a>
                  )}
                </div>
              </TruncateMarkup>
            </td>
          ) : null
        )}
        {customActions ? customActions(item) : actions(item)}
      </tr>
    )
  );

  const handleChange = data => {
    setSearchType(data[0].label);
    setShouldRefresh(true);
  };

  const renderFilterCheckbox = () => {
    return (
      <div className="filters">
        <div
          className={`filters__box ${
            inlineFilters ? "filters__box--inline-filters" : ""
          }`}
        >
          <p
            className={`filters__title ${
              inlineFilters ? "filters__title--inline-filters" : ""
            }`}
          >
            {filterLabel}
          </p>
          <div
            className={
              filterLabel === "Biblioteka" ? "filters__box__columns" : ""
            }
          >
            {filterObjects.map(option => (
              <React.Fragment key={option.id}>
                <Checkbox
                  name={option.name}
                  value={option.id}
                  darkTheme={false}
                  onChange={() => onFilterSelect(option.id)}
                  defaultChecked={
                    defaultTableFilters.find(elem => elem == option.id)
                      ? true
                      : false
                  }
                >
                  {option.name}
                </Checkbox>
              </React.Fragment>
            ))}
          </div>
        </div>

        {isCopyrightFilter && (
          <div className="filters__box">
            <p className="filters__title">Prawa autorskie</p>
            {filterCopyright.map(option => (
              <React.Fragment key={option.id}>
                <Checkbox
                  name={option.name}
                  value={option.id}
                  darkTheme={false}
                  onChange={() => onCopyrightFilterSelect(option.id)}
                >
                  {option.name}
                </Checkbox>
              </React.Fragment>
            ))}
          </div>
        )}

        {isArchiveFilter && (
          <div className="filters__box">
            <p className="filters__title">Archiwum</p>
            <Checkbox
              name="Dostępne w archiwum"
              value="archive"
              darkTheme={false}
              onChange={onArchiveFilter}
            >
              Dostępne w archiwum
            </Checkbox>
          </div>
        )}

        {isSignedFilter && (
          <div className="filters__box">
            <p className="filters__title">Podpisany</p>
            <GenericRadioGroup
              name="signed"
              groupLabel=""
              radiosText={{
                yes: "Tak",
                no: "Nie"
              }}
              handleRadioChange={onSignedFilter}
              valueSelected={filterSigned}
            />
          </div>
        )}
      </div>
    );
  };

  const mainOptionNoPermission =
    (groupActions.mainOption?.massOperation && !massOperationPerm) ||
    (groupActions.mainOption?.sipOperation && !sipOperationPerm);

  return (
    <section
      className={`${compactView ? "generic-table--compact" : "generic-table"}`}
    >
      <GenericModal
        itemData={deleteItemData}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        labels={messages}
        actionSubmit={onConfirmDelete}
      />

      <div
        className={`generic-table__controls ${
          headerActionsAlignRight ? "generic-table__controls--right" : ""
        }`}
      >
        {tableHeader && (
          <section className="column is-12 bottom-border--black">
            <p className="generic-table__title">{tableHeader}</p>
          </section>
        )}

        {hasSearch && (
          <div className="generic-table__search-input">
            <input
              className="input generic-table__filter__search table_search generic-table__mobile"
              value={searchInput}
              onChange={e => setSearchInput(e.target.value)}
              type="text"
              onKeyUp={onFilter()}
              placeholder={tableMessages.search.placeholder}
            />
            {libraryId && (
              <form
                className={"generic-table__search-type"}
                onSubmit={handleSubmit(() => {})}
              >
                <GenericSelect
                  options={searchByMenu}
                  defaultValue={searchByMenu.find(
                    elem => elem.label == searchType
                  )}
                  name="test"
                  control={control}
                  errors={errors}
                  handleSelectChange={handleChange}
                />
              </form>
            )}
            <button
              className="button button--is-light generic-table__mobile"
              onClick={onFilter(searchInput)}
              value={searchInput}
            >
              {tableMessages.search.button}
            </button>
          </div>
        )}

        {!isEmpty(groupActions) && (
          <DropdownButton
            darkTheme={true}
            mainOption={{
              title: groupActions.mainOption.title,
              onClick: () => {
                if (!groupOperationBtnsActive || mainOptionNoPermission) return;
                groupActions.mainOption.action(selectedData);
              },
              disabled: mainOptionNoPermission
            }}
            options={groupActions.options.map((option, index) => {
              const noPermission =
                (option.massOperation && !massOperationPerm) ||
                (option.sipOperation && !sipOperationPerm);
              return {
                id: index,
                title: option.title,
                onClick: () => {
                  if (!groupOperationBtnsActive || noPermission) return;
                  option.action(selectedData, filter, selectedFilters);
                },
                disabled: noPermission
              };
            })}
          />
        )}

        {hasHeaderActions &&
          (buttonOnClick ? (
            <button
              onClick={buttonOnClick}
              className="button button--is-orange generic-table__mobile"
            >
              {messages.btnAdd}
            </button>
          ) : (
            <a
              className="button button--is-orange generic-table__mobile"
              href={urls.add}
              title={messages.btnAdd}
            >
              {messages.btnAdd}
            </a>
          ))}
      </div>

      {advancedSearch || hasFilters ? (
        <section className="generic-table__controls generic-table__controls--small-margin">
          <div>
            {advancedSearch && (
              <button
                className="button button--is-light"
                onClick={advancedSearch}
              >
                Wyszukiwanie zaawansowane
              </button>
            )}
          </div>

          {hasFilters ? (
            <div className="level-right">
              <div
                className="generic-table--cursor-pointer"
                onClick={onShowFilters}
                onKeyPress={onShowFilters}
                role="button"
                tabIndex="0"
                title="Filtruj"
              >
                {" "}
                <span>
                  <strong>Filtruj</strong>&ensp;
                  <FontAwesomeIcon icon={faFilter} />
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
        </section>
      ) : (
        ""
      )}
      {showFilters ? (
        <div className="generic-table__filters">
          <div className="mbc-checkbox mbc-checkbox--column">
            {renderFilterCheckbox()}
          </div>
          <div className="level-right">
            <button
              className="button button--is-black"
              type="button"
              onClick={() => {
                retrieveData();
                if (customCheckboxHandler) {
                  customCheckboxHandler.reset();
                } else {
                  setSelectedData([]);
                }
              }}
            >
              Filtruj
            </button>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="generic-table__pagination">
        <div className="generic-table__pagination__settings">
          <div>
            Tryb kompaktowy:&nbsp;
            <Switch
              checkedChildren={
                <Icon component={() => <FontAwesomeIcon icon={faCheck} />} />
              }
              unCheckedChildren={
                <Icon component={() => <FontAwesomeIcon icon={faTimes} />} />
              }
              onChange={changeTableView}
              checked={compactView}
              className="generic-table__view-switch"
            />
            &nbsp;
          </div>
          {hasRefreshAction && (
            <div className="level-item">
              <div
                onClick={() => {
                  setUploadData(!uploadData);
                }}
                onKeyPress={() => {
                  setUploadData(!uploadData);
                }}
                role="button"
                tabIndex="0"
                title="Odśwież tabelę"
              >
                <span className="generic-table--cursor-pointer">
                  |&nbsp;Odśwież tabelę&nbsp;
                  <FontAwesomeIcon icon={faSync} />
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="generic-table__pagination__max-page">
          {hasPagination && (
            <div className="generic-table__pagination__per-page">
              {tableMessages.pagination.show}
              &nbsp;
              {itemsPerPage.map(item => (
                <React.Fragment key={item}>
                  <button
                    onClick={() => onSetItemsPerPage(item)}
                    className={`button is-small generic-table__pagination__button ${
                      activeItemPerPage === item
                        ? "generic-table__pagination__button--is-active"
                        : ""
                    }`}
                  >
                    {item}
                  </button>
                </React.Fragment>
              ))}
              &nbsp; {tableMessages.pagination.results}&nbsp;
            </div>
          )}
        </div>
      </div>
      <div className="generic-table__table-container">
        <table className={styleClassNames}>
          <thead className="generic-table__table__header">
            <tr>
              {isSelectable && (
                <th className="generic-table__table__column generic-table__table__column--w-1">
                  <Checkbox
                    name="select-all"
                    onChange={
                      customCheckboxHandler
                        ? () =>
                            customCheckboxHandler.onSelectAll(
                              data,
                              setData,
                              selectAll,
                              setSelectAll
                            )
                        : onSelectAll
                    }
                    checked={selectAll}
                  />
                </th>
              )}
              {structure.map(column => (
                <React.Fragment key={column.header}>
                  {column.sort === true ? (
                    <th
                      className={`generic-table__table__column
                      generic-table__table__column--${column.className}`}
                    >
                      <div className="generic-table__table__th">
                        <div>{column.header}</div>
                        <div>
                          <div className="generic-table__table__column__sort-icons">
                            <FontAwesomeIcon
                              icon={faCaretUp}
                              size="xs"
                              className={`generic-table__pagination__link
                                  ${
                                    column.accessor === ordering
                                      ? "generic-table__pagination__link--is-active"
                                      : ""
                                  }
                            `}
                              onClick={() => onSetOrdering(column.accessor)}
                            />
                            <FontAwesomeIcon
                              icon={faCaretDown}
                              size="xs"
                              className={`generic-table__pagination__link
                              ${
                                `-${column.accessor}` === ordering
                                  ? "generic-table__pagination__link--is-active"
                                  : ""
                              }
                            `}
                              onClick={() =>
                                onSetOrdering(`-${column.accessor}`)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  ) : (
                    <th
                      className={`generic-table__table__column--${column.className}`}
                    >
                      {column.header}
                    </th>
                  )}
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {total > 0 ? (
              body
            ) : (
              <tr>
                <td
                  colSpan={
                    isSelectable ? structure.length + 1 : structure.length
                  }
                  className="has-text-centered"
                >
                  {empty}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="generic-table__pagination--right">
        {hasPagination && pages.length > 1 && (
          <div className="generic-table__pagination__pages">
            <button
              className="generic-table__pagination__button button"
              disabled={activePage === 1}
              onClick={onSetActivePage(pagination.prev)}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            {reducePages(pages).map((page, i) => {
              if (page == "...") {
                return (
                  <React.Fragment key={i}>
                    <span>...</span>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={i}>
                    <button
                      onClick={onSetActivePage(page)}
                      className={`button is-small generic-table__pagination__button ${
                        activePage === page
                          ? "generic-table__pagination__button--is-active"
                          : ""
                      }`}
                    >
                      {page}
                    </button>
                  </React.Fragment>
                );
              }
            })}
            <button
              className="generic-table__pagination__button button"
              disabled={pagination.next == null}
              onClick={onSetActivePage(pagination.next)}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
            {tableMessages.pagination.goto}:
            <input
              className="generic-table__pagination__input"
              type="text"
              title={tableMessages.pagination.goto}
              value={activePage}
              onChange={e => setActivePage(parseInt(e.target.value) || "")}
            />
          </div>
        )}
      </div>
    </section>
  );
};

GenericTable.propTypes = {
  itemsPerPage: PropTypes.array,
  structure: PropTypes.array,
  notification: PropTypes.object,
  messages: PropTypes.object,
  deleteItemName: PropTypes.string,
  editItemValueName: PropTypes.string,
  hasSearch: PropTypes.bool,
  searchField: PropTypes.string,
  filtersValues: PropTypes.string,
  filtersCopyrightValues: PropTypes.string,
  filterLabel: PropTypes.string,
  inlineFilters: PropTypes.bool,
  queryParams: PropTypes.string,
  hasHeaderActions: PropTypes.bool,
  hasRefreshAction: PropTypes.bool,
  tableHeader: PropTypes.string,
  disableDelete: PropTypes.bool,
  disablePreview: PropTypes.bool,
  hasPagination: PropTypes.bool,
  isSelectable: PropTypes.bool,
  isSignedFilter: PropTypes.bool,
  libraryId: PropTypes.string,
  groupActions: PropTypes.object,
  searchType: PropTypes.string,
  defaultTableFilters: PropTypes.array,
  styleClassNames: PropTypes.string,
  dropdownDisabled: PropTypes.bool,
  additionalParameters: PropTypes.object,
  headerActionsAlignRight: PropTypes.bool,
  setQueryId: PropTypes.func,
  expandRow: PropTypes.func
};

GenericTable.defaultProps = {
  itemsPerPage: [10, 25, 50],
  customRow: false,
  customActions: false,
  hasPagination: true,
  customRowWithActions: false,
  deleteItemName: "name",
  editItemValueName: "id",
  notification: {},
  hasSearch: true,
  searchField: "search_field",
  filtersValues: "filters_values",
  filtersCopyrightValues: "",
  hasFilters: false,
  filtersOptions: {},
  filterLabel: "Zasoby",
  inlineFilters: false,
  queryParams: "",
  hasHeaderActions: true,
  hasRefreshAction: false,
  tableHeader: "",
  disableDelete: false,
  disablePreview: true,
  dataCallback: false,
  expandedRowData: [],
  shouldRefresh: false,
  isSelectable: false,
  isSignedFilter: false,
  customCheckboxHandler: false,
  libraryId: "",
  groupActions: {},
  groupOperationBtnsActive: true,
  defaultTableFilters: [],
  dropdownDisabled: false,
  additionalParameters: {},
  advancedSearch: false,
  setQueryId: () => {},
  expandRow: () => {},
  messages: {
    deleteSuccess: "Rekord został poprawnie usunięty",
    deleteError: "Wystąpił problem podczas usuwania rekordu",
    deleteConfirm: "Czy na pewno chcesz usunąć rekord?",
    btnAdd: "Dodaj",
    btnDeleteNo: "Anuluj",
    btnDeleteYes: "Usuń"
  },
  urls: {
    get: "", //ex. "/api/users/workers/",
    add: "", //ex. "/users/worker/create/",
    edit: "", //ex. "/users/worker/" appends /${id}/update
    delete: "" //ex."/api/users/destroy/" appends /${id}/
  },
  styleClassNames: "generic-table__table table is-striped",
  tableMessages: {
    pagination: {
      show: "Wyświetl",
      results: "wyników",
      goto: "Idź do"
    },
    search: {
      placeholder: "Szukaj...",
      button: "Szukaj"
    },
    loading: "Ładowanie",
    noItems: "Brak danych",
    fetchError: "Błąd podczas pobierania danych."
  },
  headerActionsAlignRight: false
};

export default GenericTable;
