import React, { useEffect, useState } from "react";
import { faDownload, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericDatePicker from "@app/components/GenericDatePicker/GenericDatePicker";
import GenericInput from "@components/Input/GenericInput";
import GenericModal from "@app/components/Modal/GenericModal";
import GenericSelect from "@components/Select/GenericSelect";
import GenericTable from "@components/Table/GenericTable";
import Modal from "react-responsive-modal";
import TruncateMarkup from "react-truncate-markup";
import axiosSession from "@app/config/axiosSession";
import moment from "moment";
import { useForm } from "react-hook-form";
import useGenericToastify from "../../hooks/useGenericToastify";

const Reports = (message, hasCreatePermission) => {
  const [reportModalIsOpen, setReportModalIsOpen] = useState(false);
  const maxDate = new Date();
  const { handleSubmit, control, errors, reset } = useForm();
  const [modalTitle, setModalTitle] = useState("");
  const { notifySuccess, notifyError } = useGenericToastify();
  const [update, setUpdate] = useState(false);

  const [libraries, setLibraries] = useState([]);
  const [library, setLibrary] = useState({});
  const [reportId, setReportId] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [shouldTableRefresh, setShouldTableRefresh] = useState(false);
  const [deleteItemData, setDeleteItemData] = useState({});

  const [isSigned, setIsSigned] = useState(false);
  const signed = [
    { id: 0, name: "Tak", value: true },
    { id: 1, name: "Nie", value: false }
  ];

  const handleChange = data => {
    setLibrary(data[0]);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const handleSignedChange = data => {
    setIsSigned(data[0]);
  };

  const tableStructure = [
    {
      header: "Nazwa biblioteki",
      accessor: "library",
      className: "w-2",
      sort: true
    },
    {
      header: "Numer raportu",
      accessor: "number",
      className: "w-2",
      sort: true
    },
    {
      header: "Data wygenerowania",
      accessor: "created",
      className: "w-2",
      sort: true
    },
    {
      header: "Podpisany",
      accessor: "signed",
      className: "w-2",
      sort: true
    },
    {
      header: "Uwagi",
      accessor: "comments",
      className: "w-2",
      sort: false
    },
    {
      header: "Akcje",
      className: "w-2 has-text-right"
    }
  ];
  const urls = {
    get: "/api/reports/",
    create: "/api/reports/",
    update: `/api/reports/${reportId}/`,
    delete: "/api/reports/"
  };

  const messages = {
    deleteSuccess: "Raport został poprawnie usunięty",
    deleteError: "Wystąpił problem podczas usuwania raportu",
    btnAdd: "Wygeneruj raport",
    btnDeleteNo: "Anuluj",
    btnDeleteYes: "Usuń",
    headerTitle: "Potwierdzenie usunięcia",
    bodyContent: "Czy na pewno chcesz usunąć raport?",
    contentLabel: "Potwierdzenie usunięcia raportu"
  };

  const customRow = item => {
    return (
      <React.Fragment>
        <td>
          <span title={item.library}>{item.library}</span>
        </td>
        <td>
          <span title={item.number}>{item.number}</span>
        </td>
        <td>
          <span title={item.created}>{item.created}</span>
        </td>
        <td>
          <TruncateMarkup>
            <span title={item.signed ? "Tak" : "Nie"}>
              {item.signed ? "Tak" : "Nie"}
            </span>
          </TruncateMarkup>
        </td>
        <td>
          <TruncateMarkup>
            <span title={item.comments}>{item.comments}</span>
          </TruncateMarkup>
        </td>
      </React.Fragment>
    );
  };
  const reportButtonOnClick = () => {
    reset({});
    setModalTitle("Dodaj raport");
    setReportModalIsOpen(true);
  };

  const previewReport = id => {
    setModalTitle("Podgląd / Edycja");
    setReportModalIsOpen(true);
    setReportId(id);
  };

  const onDelete = item => {
    setDeleteItemData({ id: item.id, name: item.name });
    openModal();
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const customActions = item => {
    return (
      <React.Fragment>
        <td className="has-text-right">
          <span
            onClick={() => previewReport(item.id)}
            onKeyPress={() => previewReport(item.id)}
            role="button"
            tabIndex="0"
            className="generic-table__table__link"
            title="Edycja"
          >
            <FontAwesomeIcon icon={faPen} />
          </span>
          <a
            href={`/reports/download/${item.id}`}
            role="button"
            tabIndex="0"
            className="generic-table__table__link"
            title="Pobierz"
          >
            <FontAwesomeIcon icon={faDownload} />
          </a>
          <button
            onClick={() => onDelete(item)}
            className="generic-table__table__link"
            title="Usuń"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </td>
      </React.Fragment>
    );
  };

  const onConfirmDelete = id => {
    axiosSession
      .delete(`${urls.delete}${id}/`)
      .then(() => {
        setUpdate(!update);
        notifySuccess(messages.deleteSuccess);
        setShouldTableRefresh(true);
      })
      .catch(error => {
        console.error(error);

        if (error.response.data.detail) {
          notifyError(error.response.data.detail);
        } else {
          notifyError(messages.deleteError);
        }
        console.error(error);
      })
      .finally(() => closeModal());
  };

  const onSubmit = data => {
    const submitData = data;
    submitData.date_from = moment(
      Array.isArray(submitData.date_from)
        ? submitData.date_from[0]
        : submitData.date_from
    ).format("DD.MM.YYYY");
    submitData.date_to = moment(
      Array.isArray(submitData.date_to)
        ? submitData.date_to[0]
        : submitData.date_to
    ).format("DD.MM.YYYY");
    submitData.library = library.id;
    submitData.signed = isSigned.value;
    axiosSession({
      method: reportId ? "PUT" : "POST",
      url: reportId ? urls.update : urls.create,
      data: submitData
    })
      .then(() => {
        setUpdate(!update);
        setReportModalIsOpen(false);
        setShouldTableRefresh(true);
      })
      .catch(error => {
        notifyError("Nie udało się zapisać raportu.");
        console.error(error);
      });
  };

  useEffect(() => {
    axiosSession
      .get("/api/library/select-data/")
      .then(({ data }) => {
        setLibraries(data);
      })
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    if (!reportModalIsOpen) {
      setReportId(null);
    }
  }, [reportModalIsOpen]);

  useEffect(() => {
    if (reportId) {
      axiosSession
        .get(`/api/reports/${reportId}/`)
        .then(({ data }) => {
          const convertedData = data;
          convertedData.library = libraries.find(
            library => library.id === data.library
          );
          convertedData.signed = signed.find(
            item => item.value === data.signed
          );
          setLibrary(convertedData.library);
          setIsSigned(convertedData.signed);
          reset(convertedData);
        })
        .catch(err => console.error(err));
    }
  }, [reportId]);

  return (
    <section className="mbc-backoffice" key={`${update}-report`}>
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Raporty</strong>
        </div>

        <Modal
          open={reportModalIsOpen}
          onClose={() => setReportModalIsOpen(false)}
          classNames={{ modal: "GenericModal" }}
        >
          <div className="GenericModal__Header--with-border">
            <p className="GenericModal__Header__Title">{modalTitle}</p>
          </div>
          <div className="GenericModal__Body">
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <GenericSelect
                  options={libraries}
                  label="Biblioteka"
                  name="library"
                  control={control}
                  errors={errors}
                  isDisabled={!!reportId}
                  handleSelectChange={handleChange}
                />
                <GenericInput
                  disabled={!!reportId}
                  name="number"
                  label="Numer raportu"
                  type="text"
                  errors={errors}
                  control={control}
                  required={{ required: "To pole jest wymagane!" }}
                />
                <GenericInput
                  disabled={!!reportId}
                  name="contract"
                  label="Numer umowy"
                  type="text"
                  errors={errors}
                  control={control}
                />
                <GenericDatePicker
                  disabled={!!reportId}
                  name="date_from"
                  label="Data od"
                  dateFormat="dd.MM.yyyy"
                  errors={errors}
                  control={control}
                  required={{ required: "To pole jest wymagane!" }}
                  defaultValue={maxDate}
                  maxDate={maxDate}
                />

                <GenericDatePicker
                  disabled={!!reportId}
                  name="date_to"
                  label="Data do"
                  dateFormat="dd.MM.yyyy"
                  errors={errors}
                  control={control}
                  required={{ required: "To pole jest wymagane!" }}
                  defaultValue={maxDate}
                  toDate={maxDate}
                />
                <GenericSelect
                  options={signed}
                  label="Podpisany"
                  name="signed"
                  control={control}
                  errors={errors}
                  handleSelectChange={handleSignedChange}
                />
                <GenericInput
                  name="comments"
                  label="Uwagi"
                  tag="textarea"
                  required={{ required: "To pole jest wymagane!" }}
                  errors={errors}
                  control={control}
                />
                <div className="level-right">
                  <button
                    className="button"
                    onClick={() => setReportModalIsOpen(false)}
                    title="Anuluj i wróć do listy"
                    type="button"
                  >
                    Anuluj
                  </button>
                  <button className="button button--is-orange" type="submit">
                    {reportId ? "Edytuj" : "Generuj"} raport
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>

        <GenericModal
          itemData={deleteItemData}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          labels={messages}
          actionSubmit={onConfirmDelete}
        />

        <GenericTable
          structure={tableStructure}
          messages={messages}
          customRow={customRow}
          notification={message}
          urls={urls}
          hasHeaderActions={true}
          hasSearch={false}
          buttonOnClick={reportButtonOnClick}
          customActions={customActions}
          shouldRefresh={shouldTableRefresh}
          setShouldRefresh={setShouldTableRefresh}
          hasFilters={true}
          isSignedFilter
          filtersValues="library_id"
          filtersOptions={libraries}
          filterLabel="Biblioteka"
        />
      </div>
    </section>
  );
};

export default Reports;
