import "./DirectoriesPreview.scss";

import React, { useEffect, useState } from "react";
import {
  deleteCollectionFromDigitalItem,
  getCollectionsListDigitalItem,
  getDigitalItemInfoRespond,
  getFileRespond,
  getItemAttributesValue,
  getItemSchema,
  getPermissionsRespond,
} from "@app/services/services";
import {
  faCheck,
  faEye,
  faPen,
  faTimes,
  faTrash
} from "@fortawesome/free-solid-svg-icons";

import AttributesPreview from "./AttributesPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MapComponent from "./MapComponent";
import Modal from "react-responsive-modal";
import Preview from "@app/components/Preview/Preview";
import axiosSession from "@app/config/axiosSession";
import isEmpty from "lodash/isEmpty";

const DigitalItemPreview = ({ id }) => {
  const [canEdit, setCanEdit] = useState(false);
  const [rows, setRows] = useState([]);

  const [files, setFiles] = useState([]);
  const [languagesSchema, setLanguagesSchema] = useState([]);
  const [attributesSchema, setAttributesSchema] = useState([]);
  const [attributes, setAttributes] = useState({});
  const [isAttributesVisible, setIsAttributesVisible] = useState(false);

  const [permissions, setPermissions] = useState([]);
  const [collections, setCollections] = useState([]);
  const [historyItemModal, setHistoryItemModal] = useState(false);
  const [historyItem, setHistoryItem] = useState({});

  const [historyItems, setHistoryItems] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [showMap, setShowMap] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const [
        schema,
        values,
        permissionsRespond,
        fileRespond,
        digitalItemInfoRespond,
        collectionsList
      ] = await axiosSession.all([
        getItemSchema(id),
        getItemAttributesValue(id),
        getPermissionsRespond(id),
        getFileRespond(id),
        getDigitalItemInfoRespond(id),
        getCollectionsListDigitalItem(id)
      ]);

      setRows([
        { title: "Biblioteka", value: [digitalItemInfoRespond.data.library_label] },
        { title: "Katalog nadrzędny", value: [digitalItemInfoRespond.data.directory_label] },
        { title: "Nazwa", value: [digitalItemInfoRespond.data.name] },
        { title: "Schemat opisowy", value: [digitalItemInfoRespond.data.schema_label] },
        {
          title: "Prawa autorskie",
          value: [digitalItemInfoRespond.data.copyright_label]
        }
      ]);
      setShowMap(schema.data.show_map)

      setLanguagesSchema(schema.data.languages);
      setAttributesSchema(schema.data.attributes);
      setAttributes(values.data);

      setPermissions(permissionsRespond.data);
      setFiles(fileRespond.data.results);

      setCanEdit(digitalItemInfoRespond.data.can_edit);
      setCollections(collectionsList.data.collections);
      
      setMarkers([{ lng: digitalItemInfoRespond.data.longitude, lat: digitalItemInfoRespond.data.latitude }])

      let history = digitalItemInfoRespond.data.history.filter(item =>
        (item.change_details.length !== 0 || item.attributes.length !== 0)
      )
      setHistoryItems(history);
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  const onPreview = path => {
    window.open(path, "_blank").focus();
  };

  const handleDeleteCollection = collectionId => {
    deleteCollectionFromDigitalItem(collectionId, id)
      .then(() =>
        setCollections(origin =>
          origin.filter(item => item.id !== collectionId)
        )
      )
      .catch(err => console.error(err));
  };

  useEffect(() => {
    if (!isEmpty(attributes)) {
      for (let key in attributes) {
        if (!isEmpty(attributes[key])) {
          setIsAttributesVisible(true);
        }
      }
    }
  }, [attributes]);

  const getFileType = fileTypeId => {
    switch (fileTypeId) {
      case 1:
        return "pdf";
      case 2:
        return "tiff, tif";
      case 3:
        return "jpg";
      case 4:
        return "plik miniaturki";
      case 5:
        return "xml, txt";
      case 6:
        return "djvu";
      case 7:
        return "inne";
      default:
        return "nieznany";
    }
  };

  const renderFileListTable = () => {
    return files.map((file, idx) => (
      <tr key={`${idx}${Date.now()}`}>
        <td>{file.filename}</td>
        <td>{getFileType(file.file_type)}</td>
        <td>
          <button onClick={() => onPreview(file.path)}>Zobacz</button>
        </td>
      </tr>
    ));
  };

  const getHistoryActionIcon = action => {
    if (action === "Zmodyfikowane") {
      return <FontAwesomeIcon key={new Date()} icon={faPen} color="#2D9CDB" />;
    } else if (action === "Dodane") {
      return (
        <FontAwesomeIcon key={new Date()} icon={faCheck} color="#219653" />
      );
    } else {
      return (
        <FontAwesomeIcon key={new Date()} icon={faTimes} color="#EB5757" />
      );
    }
  };

  const showFullHistory = item => {
    setHistoryItem(item);
    setHistoryItemModal(true);
  };

  return (
    <section className="directories-preview">
      <div className="container">
        <div className="breadcrumbs">
          <a
            className="breadcrumbs__link"
            title="Strona główna"
            href="/admin-panel/home/"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/directories/"
            title="Katalogi i obiekty"
          >
            Katalogi i obiekty
          </a>
          &nbsp;/&nbsp;
          <strong>Podgląd obiektu</strong>
        </div>
        <div className="directories-preview__header">
          <h2 className="directories-preview__header__title">
            Podgląd obiektu
          </h2>
          <hr className="directories-preview__header__hr"></hr>
        </div>
        <div className="step">{rows && <Preview rows={rows} />}</div>
        {files.length > 0 && (
          <div>
            <h2 className="directories-preview__header__title directories-preview--padding">
              Pliki
            </h2>
            <div className="step">
              <div className="columns">
                <table className="files-preview-table">
                  <thead>
                    <tr>
                      <th>Plik</th>
                      <th>Rodzaj</th>
                      <th>Akcje</th>
                    </tr>
                  </thead>
                  <tbody>{renderFileListTable()}</tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {isAttributesVisible && (
          <AttributesPreview
            languagesSchema={languagesSchema}
            attributesSchema={attributesSchema}
            attributes={attributes}
          />
        )}

        {collections.length > 0 && (
          <div className="step">
            <h2 className="directories-preview__header__title directories-preview--padding">
              Przypisano do kolekcji
            </h2>
            <div>
              <table className={"long"}>
                <tbody>
                  <tr className="row first">
                    <td className="column-element-long column-element-long--header">
                      Nazwa
                    </td>
                    <td className="column-element-long column-element-long--header">
                      Status
                    </td>
                    <td>
                      <div className="column-element-short column-element-short--header">
                        Akcje
                      </div>
                    </td>
                  </tr>
                  {collections.map((item, index) => (
                    <tr className="row" key={index}>
                      <td className="column-element-long">{item.full_path}</td>
                      <td className="column-element-long">{item.status}</td>
                      <td className="column-element-short">
                        <span
                          onClick={() => handleDeleteCollection(item.id)}
                          onKeyPress={() => handleDeleteCollection(item.id)}
                          role="button"
                          tabIndex="0"
                          className="generic-table__table__link"
                          title="Usuń"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {permissions.length > 0 && (
          <div className="step">
            <h2 className="directories-preview__header__title directories-preview--padding">
              Uprawnienia
            </h2>
            <div>
              <table>
                <tbody>
                  <tr className="row first">
                    <td className="column-element-first">GRUPY</td>
                    <td>
                      <div className="column-element">ODCZYT</div>
                    </td>
                    <td>
                      <div className="column-element">MODYFIKACJA</div>
                    </td>
                    <td>
                      <div className="column-element">KOLEKCJE</div>
                    </td>
                    <td>
                      <div className="column-element">PUBLIKACJA</div>
                    </td>
                  </tr>
                  {permissions.map(group => (
                    <tr className="row" key={group.group_id}>
                      <td className="column-element-first">
                        {group.group_name}
                      </td>
                      <td>
                        <div className="column-element">
                          {group.permission.find(
                            e => e.name == "view_digitalitem"
                          ).value && (
                              <FontAwesomeIcon key={new Date()} icon={faCheck} />
                            )}
                        </div>
                      </td>
                      <td>
                        <div className="column-element">
                          {group.permission.find(
                            e => e.name == "change_digitalitem"
                          ).value && (
                              <FontAwesomeIcon key={new Date()} icon={faCheck} />
                            )}
                        </div>
                      </td>
                      <td>
                        <div className="column-element">
                          {group.permission.find(
                            e => e.name == "manage_digitalitem_collection"
                          ).value && (
                              <FontAwesomeIcon key={new Date()} icon={faCheck} />
                            )}
                        </div>
                      </td>
                      <td>
                        <div className="column-element">
                          {group.permission.find(
                            e => e.name == "publish_digitalitem"
                          ).value && (
                              <FontAwesomeIcon key={new Date()} icon={faCheck} />
                            )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {historyItems.length > 0 && (
          <div className="step">
            <h2 className="directories-preview__header__title directories-preview--padding">
              Historia zmian rekordu
            </h2>
            <table className="long">
              <tbody>
                <tr className="row first">
                  <td>
                    <div className="column-element-long--header">
                      OSOBA MODYFIKUJĄCA
                    </div>
                  </td>
                  <td>
                    <div className="column-element-long--header">
                      DATA MODYFIKACJI
                    </div>
                  </td>
                  <td>
                    <div className="column-element-long--header">
                      INFORMACJE
                    </div>
                  </td>
                  <td>
                    <div className="column-element-long--header">STATUS</div>
                  </td>
                  <td>
                    <div className="column-element-long--header">PODGLĄD</div>
                  </td>
                </tr>

                {historyItems.length > 0 &&
                  historyItems.map(item => (
                    <tr className="row" key={item.change_details}>
                      <td>
                        <div className="column-element-short">
                          {item.history_user}
                        </div>
                      </td>
                      <td>
                        <div className="column-element-short">
                          {item.history_date}
                        </div>
                      </td>
                      <td>
                        <div className="column-element-short">
                          {item.change_details}
                        </div>
                      </td>
                      <td>
                        <div className="column-element-short">
                          {getHistoryActionIcon(item.history_type)}{" "}
                          {item.history_type}
                        </div>
                      </td>
                      <td>
                        <div className="column-element-short">
                          {item.attributes.length > 0 ? (
                            <FontAwesomeIcon
                              onClick={() => showFullHistory(item)}
                              key={new Date()}
                              icon={faEye}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}


        {markers.length > 0 && showMap && (
          <div className="step">
            <h2 className="directories-preview__header__title directories-preview--padding">
              Położenie obiektu na mapie
            </h2>
            {<MapComponent initialMarkers={markers} preview={true}></MapComponent>}

          </div>
        )}


        <Modal
          open={historyItemModal}
          onClose={() => setHistoryItemModal(false)}
          center
          showCloseIcon
          classNames={{ modal: "GenericModal" }}
        >
          <div className="GenericModal__Header--with-border">
            <p className="GenericModal__Header__Title">
              Podgląd zmienionych atrybutów
            </p>
          </div>
          <div className="GenericModal__Body">
            <div className="grid">
              <div className="col-xs-12 col-sm-6 col-md-4">OSOBA MODYFIKUJĄCA:</div>
              <div className="col-xs-12 col-sm-6 col-md-8">{historyItem.history_user}</div>
              <div className="col-xs-12 col-sm-6 col-md-4">DATA MODYFIKACJI:</div>
              <div className="col-xs-12 col-sm-6 col-md-8">{historyItem.history_date}</div>
              <div className="col-xs-12 col-sm-6 col-md-3">INFORMACJE:</div>
              <div className="col-xs-12 col-sm-6 col-md-3"> {historyItem.attributes && historyItem.attributes.map((item, idx) => <li key={idx}>{item}</li>)}</div>
            </div>
          </div>
        </Modal>
        <div className="directories-preview--bottom">
          <a
            className="directories-preview--goback is-uppercase has-text-weight-bold"
            href={
              localStorage.backToDirectoriesSearch
                ? "/directories/search/"
                : "/directories/"
            }
          >
            &lt; Wróć do listy katalogów
          </a>
          {canEdit && (
            <a
              href={`/directories/${id}/digital-item-edit`}
              className="directories-preview--goback is-uppercase has-text-weight-bold"
            >
              Edytuj
            </a>
          )}
        </div>
      </div>
    </section>
  );
};

export default DigitalItemPreview;
