import axiosSession from "@app/config/axiosSession";
import find from "lodash/find";

const generateAttributesValueForPreview = async (data, schema, type = null) => {
  let values = {};

  for (const [key, value] of Object.entries(data)) {
    const field = find(schema, ["name", key]);

    if (field) {
      const fieldType = type ? type : field.type;
      let fieldArray = [];

      switch (fieldType) {
        case "array":
          if (field.subType === "select") {
            if (field.is_flat || field.is_flat === null) {
              const dict_url = "/api/dicts/" + field.dictionary_id + "/entries/select-data/"
              let urlDictionary = `${dict_url}?id__in=${value.join(
                ","
              )}`;
              const items = await axiosSession.get(urlDictionary);
              values[key] = {
                name: field.label,
                values: items.data.results.map(item => item.name)
              };
            } else {
              if (field.dictionary_default_value) {
                values[key] = {
                  name: field.label,
                  values: field.dictionary_default_value.map(
                    value => value.name
                  )
                };
              }
            }
            break;
          } else if (field.subType === "group") {
            for (const f of data[field.name]) {
              const values = await generateAttributesValueForPreview(
                f,
                field.fields
              );
              fieldArray.push(values);
            }
          } else {
            let valuesList = [];
            for (const f of data[field.name]) {
              const values = await generateAttributesValueForPreview(
                { [field.name]: f },
                [{ ...field, type: field.subType }]
              );
              valuesList.push(values[field.name]?.values);
            }
            values[key] = { name: field.label, values: valuesList };
            break;
          }
          values[key] = fieldArray;
          break;
        case "group": {
          const convertedData = await generateAttributesValueForPreview(
            data[field.name],
            field.fields
          );
          values[key] = convertedData;
          break;
        }
        case "select":
          if (!field.is_flat && field.is_flat !== null) {
            values[key] = {
              name: field.label,
              values: field.dictionary_default_value?.name ?? ""
            };
          } else {
            // eslint-disable-next-line no-case-declarations
            const dict_url = "/api/dicts/" + field.dictionary_id + "/entries/select-data/"
            const items = await axiosSession.get(dict_url);
            values[key] = find(items.data.results, ["id", parseInt(value)]);
            values[key] = {
              name: field.label,
              values:
                find(items.data.results, ["id", parseInt(value)])?.name ?? ""
            };
          }
          break;
        case "date": {
          values[key] = { name: field.label, values: value };
          break;
        }
        default:
          values[key] = { name: field.label, values: value };
      }
    }
  }
  return values;
};

export default generateAttributesValueForPreview;
