import "@app/scss/variables.scss";

import * as qs from "query-string";

import React, { useEffect, useRef, useState } from "react";
import {
  createCollection,
  editCollection,
  getCollectionDetail,
  getLibraries,
  getSchemas
} from "@app/services/collectionServices";

import DropdownButton from "@components/DropdownButton/DropdownButton";
import GenericFieldArray from "@app/components/GenericFieldArray/GenericFieldArray";
import GenericModal from "@components/Modal/GenericModal";
import GenericSelect from "@components/Select/GenericSelect";
import Input from "@components/Input/Input";
import NestedSelect from "@components/Select/NestedSelect";
import Stepper from "@app/pages/Directories/Stepper";
import axiosSession from "@app/config/axiosSession";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import { getDictLanguages } from "@app/services/services";
import handleErrors from "@app/helpers/handleErrors";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

let submitType = "";
let modalType = "";
const messages = {
  headerTitle: "Anuluj",
  bodyContent: "",
  btnDeleteNo: "Nie",
  btnDeleteYes: "Tak"
};

const CollectionsGeneralInfoStep = ({
  id,
  nextStepChange,
  stepLength,
  libraryId,
  isSuperUser
}) => {
  const {
    handleSubmit,
    register,
    errors,
    control,
    setError,
    reset,
    setValue,
    getValues
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [schema, setSchema] = useState([]);
  const [selectedLibrary, setSelectedLibrary] = useState({});
  const [defaultParentCollectionId, setDefaultParentCollectionId] = useState(
    false
  );
  const [defaultParentCollection, setDefaultParentCollection] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSchema, setSelectedSchema] = useState({});
  const [hierarchicSelectUrl, setHierarchicSelectUrl] = useState("");
  const [allLibraries, setAllLibraries] = useState([]);
  const { notifyError } = useGenericToastify();
  const isMounted = useRef(null);

  const returnLocation = "/cancel?url=/collections/";

  const getHierarchicCollectionParentUrl = libraryId => {
    return `/api/library/${libraryId}/collections/hierarchic-select-data/`;
  };

  const handleSelectChange = ([selectedOption]) => {
    return selectedOption;
  };

  const handleLibrarySelectChange = ([selectedOption]) => {
    setHierarchicSelectUrl(getHierarchicCollectionParentUrl(selectedOption.id));
    return selectedOption;
  }

  const handleSchemaChange = ([selectedOption]) => {
    if (id) {
      const schema = getValues()["schema"];
      if (!isEqual(selectedOption, schema)) {
        setSelectedSchema(selectedOption);
        openModal("schemaChange");
        return schema;
      }
    } else {
      return selectedOption;
    }
  };

  const handleDraftSubmit = () => {
    submitType = "draft";
    return true;
  };

  const handleNextSubmit = () => {
    submitType = "next";
    return true;
  };

  const openModal = type => {
    modalType = type;
    if (type === "cancel") {
      const operationTypeString = id ? "edycję" : "dodanie nowej";
      messages.bodyContent = `Klikając przycisk anuluj sprawiasz, że dane nie zostaną zapisane. Czy na pewno chcesz anulować ${operationTypeString} kolekcji?`;
    } else {
      messages.bodyContent =
        "Zmiana schematu spowoduje usunięcie dotychczas dodanych wartości atrybutów. Czy jesteś pewien?";
    }
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const onCancel = () => {
    closeModal();
  };
  const onConfirm = () => {
    if (modalType === "cancel") {
      window.location.href = returnLocation;
    } else {
      setValue("schema", selectedSchema);
    }
    closeModal();
  };

  const onSubmit = async data => {
    setIsLoading(true);

    data.library = data.libraryName.id;
    data.schema = data.schema.id;
    data.parent = data.parent ? data.parent : "";

    const translations_list = !isEmpty(data.translations)
      ? data.translations.map(f => ({
          language: f.language.id,
          content: f.content
        }))
      : [];
    data.translations = translations_list;

    try {
      let response = null;
      if (id) {
        response = await editCollection(id, data);
      } else {
        response = await createCollection(data);
      }
      if (submitType === "draft") {
        location.href = response.data.url;
      } else {
        nextStepChange(response.data.id);
      }
    } catch (error) {
      notifyError("Nie udało się zapisać kolekcji");
      handleErrors(error.response.data, setError);
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    !isEmpty(errors) ? notifyError("Nie udało się zapisać kolekcji") : null;
  }, [errors, notifyError]);

  useEffect(() => {
    const fetchData = async () => {
      const [languages, libraries, schemas] = await axiosSession.all([
        getDictLanguages(),
        getLibraries(),
        getSchemas()
      ]);
      setLanguages(languages.data.results);
      setSchema(schemas.data);
      const data = {};

      if (isSuperUser) {
        setAllLibraries(libraries.data);
      } else {
        data.libraryName = find(libraries.data, ["id", parseInt(libraryId)]);
      }
      setHierarchicSelectUrl(getHierarchicCollectionParentUrl(libraryId));

      const params = qs.parse(window.location.search);
      if (has(params, "collection_id")) {
        const collectionId = parseInt(params.collection_id);

        if (!isNaN(collectionId)) {
          setValue("parent", collectionId);
          data.parent = collectionId;
          setDefaultParentCollectionId(collectionId);
        } else {
          notifyError("Nieprawidłowa wartość dla kolekcji nadrzędnej!");
        }
      }
      reset(data);
    };
    if (isMounted.current && libraryId) {
      fetchData();
    }
  }, [libraryId, notifyError, reset, setValue]);

  useEffect(() => {
    const fetchData = async () => {
      const [
        languages,
        collection,
        libraries,
        schemas
      ] = await axiosSession.all([
        getDictLanguages(),
        getCollectionDetail(id),
        getLibraries(),
        getSchemas()
      ]);
      setLanguages(languages.data.results);
      const data = collection.data;
      data.schema = find(schemas.data, ["id", data.schema]);
      const library = find(libraries.data, ["id", data.library]);
      data.libraryName = library;
      if (isSuperUser) {
        setAllLibraries(libraries.data);
      }

      const dataTranslations = data.translations.map(
        ({ id, content, language }) => ({
          id,
          content,
          language: languages.data.results.find(lang => lang.id === language)
        })
      );
      data.translations = dataTranslations;

      reset(data);
      setDefaultParentCollectionId(data.parent);
      setSelectedLibrary(library);
      setSchema(schemas.data);
      setHierarchicSelectUrl(getHierarchicCollectionParentUrl(library.id));
    };
    if (isMounted.current && id) {
      fetchData();
    }
  }, [id, reset]);

  useEffect(() => {
    const fetchData = async () => {
      const collection = await getCollectionDetail(defaultParentCollectionId);
      setDefaultParentCollection({
        ...collection.data,
        id: defaultParentCollectionId,
        name: collection.data.internal_name
      });
    };
    if (isMounted.current && defaultParentCollectionId) {
      fetchData();
    }
  }, [defaultParentCollectionId]);

  return (
    <section className="mbc-backoffice">
      <GenericModal
        itemData={selectedSchema}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        labels={messages}
        actionSubmit={onConfirm}
        actionCancel={onCancel}
        showItemName={false}
      />
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/collections/"
            title="Lista kolekcji"
          >
            Kolekcje
          </a>
          &nbsp;/&nbsp;
          <strong>{id ? "Edycja" : "Dodawanie"} kolekcji</strong>
        </div>

        <div className="mbc-backoffice__header">
          <h2 className="mbc-backoffice__header__title">
            {id ? "Edycja" : "Dodawanie"} kolekcji
          </h2>
          <hr className="mbc-backoffice__header__hr" />
        </div>

        <Stepper activeStep={1} stepLength={stepLength} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="columns is-multiline">
            <div className="column is-7-desktop is-full-tablet is-full-mobile">
              <GenericSelect
                options={allLibraries}
                placeholder="Wybierz bibliotekę"
                name="libraryName"
                label="Biblioteka"
                handleSelectChange={handleLibrarySelectChange}
                required={{ required: "To pole jest wymagane!" }}
                control={control}
                errors={errors}
                isDisabled={!isSuperUser || allLibraries.length === 1}
                isRequired
              />
            </div>

            <div className="column is-7-desktop is-full-tablet is-full-mobile">
              <NestedSelect
                key={hierarchicSelectUrl}
                name="parent"
                url={hierarchicSelectUrl}
                placeholder="Wybierz kolekcję nadrzędną"
                label="Kolekcja nadrzędna"
                control={control}
                errors={errors}
                darkTheme={false}
                defaultParent={defaultParentCollection}
                disabled={false}
              />
            </div>

            <div className="column is-7-desktop is-full-tablet is-full-mobile">
              <Input
                name="internal_name"
                label="Nazwa wewnętrzna"
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />
            </div>
            <div className="column is-full">
              <GenericFieldArray
                name="translations"
                control={control}
                errors={errors}
                addButtonText="Dodaj tłumaczenie"
                appendOption={{ language: { id: "", name: "" }, content: "" }}
                deleteButtonTitle="Usuń tłumaczenie"
                selectName="language"
                selectOptions={languages}
                selectPlaceholder="Wybierz język"
                selectLabel="Wybierz język tłumaczenia"
                handleSelectChange={handleSelectChange}
                inputName="content"
                inputLabel={"Tłumaczenie"}
              />
            </div>

            <div className="column is-7-desktop is-full-tablet is-full-mobile">
              <GenericSelect
                options={schema}
                placeholder="Wybierz schemat opisowy"
                control={control}
                required={{ required: "To pole jest wymagane!" }}
                name="schema"
                errors={errors}
                handleSelectChange={handleSchemaChange}
                label="Schemat opisowy"
                isRequire
              />
            </div>

            <div className="column is-7-desktop is-full-tablet is-full-mobile">
              <Input
                name="dlibra_identifier"
                label="Indentyfikator dLibra"
                errors={errors}
                register={register({
                  required: false
                })}
              />
            </div>

            <div className="column is-7-desktop is-full-tablet is-full-mobile">
              <Input
                name="oai_pmh_identifier"
                label="Indentyfikator OAI-PMH"
                errors={errors}
                register={register({
                  required: false
                })}
              />
            </div>



            <div className="column is-7-desktop is-full-tablet is-full-mobile">
              <Input
                tag="textarea"
                name="oai_pmh_description"
                label="Opis OAI_PMH"
                errors={errors}
                register={register({
                  required: false
                })}
              />
            </div>

            <div className="column is-12">
              <div className="level-right mbc-backoffice__buttons">
                <button
                  onClick={() => openModal("cancel")}
                  className="button"
                  title="Anuluj i wróć do listy"
                  type="button"
                >
                  Anuluj
                </button>
                <DropdownButton
                  mainOption={{
                    type: "submit",
                    title: "Dalej",
                    href: "",
                    onClick: handleNextSubmit
                  }}
                  isLoading={isLoading}
                  options={[
                    {
                      type: "submit",
                      title: "Zapisz szkic",
                      href: "",
                      id: 1,
                      onClick: handleDraftSubmit
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default CollectionsGeneralInfoStep;
