import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  faCheck,
  faPen,
  faPlus,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import {
  faMinusSquare,
  faPlusSquare
} from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericModal from "@components/Modal/GenericModal";
import GenericTable from "@components/Table/GenericTable";
import TruncateMarkup from "react-truncate-markup";
import axiosSession from "@app/config/axiosSession";
import useGenericToastify from "@app/hooks/useGenericToastify";

const DictionariesEntries = ({
  dictionary,
  message,
  dataCallback,
  hasCreatePermission,
  isTimeLimited
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteItemData] = useState({});
  const [expandedRowData, setExpandedRowData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const { notifySuccess, notifyError } = useGenericToastify();

  const expandRow = (row, index) => {
    if (row.expanded) {
      setExpandedRowData({
        index: index,
        expanded: false,
        rows: []
      });
    } else {
      let level = row.level ? row.level : 0;
      row.level = level;
      let ancestors = row.ancestors ? row.ancestors : [];
      row.ancestors = ancestors;

      axiosSession.get(urls.get, { params: { parent: row.id } }).then(res => {
        let ancestor = [res.data.results[0].parent];
        let data = res.data.results.map(di => ({
          ...di,
          level: level + 1,
          ancestors: ancestors + ancestor
        }));
        setExpandedRowData({
          index: index,
          expanded: true,
          rows: data
        });
      });
    }
  };

  const tableStructure = [
    {
      header: "Wartość",
      accessor: "main_content",
      className: "w-3",
      sort: true
    },
    {
      header: "Status",
      className: "w-2",
      accessor: "is_visible",
      sort: true
    },
    {
      header: "Języki",
      accessor: "language",
      className: "w-2"
    },
    {
      header: "Kontekst czasu",
      accessor: "from",
      className: "w-3"
    },
    {
      header: "Akcje",
      className: "w-2 has-text-right"
    }
  ];

  const urls = {
    get: `/api/dicts/${dictionary}/entries/`,
    edit: "/dicts/entries/", //appends /${id}/edit
    delete: "/api/dicts/entries/", //appends /${id}/
    add: `/dicts/${dictionary}/entries/create/`
  };

  const messages = {
    deleteSuccess: "Wartość słownikowa została poprawnie usunięta",
    deleteError: "Wystąpił problem podczas usuwania wartości słownikowej",
    btnAdd: "Dodaj wartości",
    btnDeleteNo: "Anuluj",
    btnDeleteYes: "Usuń",
    headerTitle: "Potwierdzenie usunięcia",
    bodyContent: "Czy na pewno chcesz usunąć wartość słownikową?",
    contentLabel: "Potwierdzenie usunięcia wartości słownikowej"
  };

  const onDelete = id => {
    setSelectedItem(id);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setShouldRefresh(false);
    setModalIsOpen(false);
  };

  const onConfirmDelete = () => {
    axiosSession
      .delete(`${urls.delete}${selectedItem}/`)
      .then(() => {
        notifySuccess(messages.deleteSuccess);
        setShouldRefresh(true);
      })
      .catch(error => {
        if (error.response.data.detail) {
          notifyError(error.response.data.detail);
        } else {
          notifyError(messages.deleteError);
        }
        console.error(error);
      })
      .finally(() => closeModal());
  };

  const actions = item => {
    return (
      <td className="has-text-right">
        {item.actions.editable && (
          <a
            href={`${urls.edit}${item.id}/edit/`}
            className="generic-table__table__link"
            title="Edycja"
          >
            <FontAwesomeIcon icon={faPen} />
          </a>
        )}
        {item.actions.creation && (
          <a
            href={`${urls.add}?entry_id=${item.id}`}
            className="generic-table__table__link"
            title="Dodaj"
          >
            <FontAwesomeIcon icon={faPlus} />
          </a>
        )}
        {item.actions.removeable && (
          <span
            onClick={() => onDelete(item.id)}
            onKeyPress={() => onDelete(item.id)}
            role="button"
            tabIndex="0"
            className="generic-table__table__link"
            title="Edycja"
          >
            <FontAwesomeIcon icon={faTrash} />
          </span>
        )}
      </td>
    );
  };

  const customRow = (item, index) => {
    return (
      <React.Fragment key={item.id}>
        <tr id={`row-${item.id}`}>
          <td className={item.has_children ? "has-children" : ""}>
            {item.has_children && (
              <span
                className={`generic-table__table__link ${
                  item.level ? `indent-with-extend-${item.level}` : ""
                }`}
                onClick={() => expandRow(item, index)}
                onKeyPress={() => expandRow(item, index)}
                title="Rozwiń wiersz"
                role="button"
                tabIndex="0"
              >
                <FontAwesomeIcon
                  icon={item.expanded ? faMinusSquare : faPlusSquare}
                />
              </span>
            )}
            &nbsp;
            <TruncateMarkup title={item.main_content}>
              <span
                className={
                  item.has_children
                    ? ""
                    : item.level
                    ? `indent-${item.level}`
                    : ""
                }
              >
                {item.main_content}
              </span>
            </TruncateMarkup>
          </td>
          <td>{item.is_visible ? <FontAwesomeIcon icon={faCheck} /> : ""}</td>
          <td>{item.languages.join()}</td>
          {isTimeLimited && (
            <td>
              {item.start} {item.end ? `- ${item.end}` : ""}
            </td>
          )}
          {actions(item)}
        </tr>
      </React.Fragment>
    );
  };

  return (
    <section className="dictionaries-values">
      <GenericModal
        itemData={deleteItemData}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        labels={messages}
        actionSubmit={onConfirmDelete}
      />

      <div className="container">
        <GenericTable
          shouldRefresh={shouldRefresh}
          setShouldRefresh={setShouldRefresh}
          structure={
            isTimeLimited
              ? tableStructure
              : tableStructure.filter(elem => elem.header !== "Kontekst czasu")
          }
          customRowWithActions={customRow}
          messages={messages}
          urls={urls}
          dataCallback={dataCallback}
          expandedRowData={expandedRowData}
          queryParams={dictionary}
          hasHeaderActions={hasCreatePermission}
        />
      </div>
    </section>
  );
};

DictionariesEntries.propTypes = {
  isTimeLimited: PropTypes.bool
};

DictionariesEntries.defaultProps = {
  isTimeLimited: true
};

export default DictionariesEntries;
