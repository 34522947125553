import React, { useState } from "react";
import { StateMachineProvider, createStore } from "little-state-machine";

import CollectionsAttributesValueStep from "../pages/Collections/CollectionsAttributesValueStep";
import CollectionsGeneralInfoStep from "../pages/Collections/CollectionsGeneralInfoStep";
import CollectionsPermissionsStep from "../pages/Collections/CollectionsPermissionsStep";
import CollectionsThumbnailUploadStep from "../pages/Collections/CollectionsThumbnailUploadStep";

createStore({ data: {} });
window.sessionStorage.clear();

const CollectionsStepForm = ({ id, fileServer, libraryId, isSuperUser }) => {
  const [step, setStep] = useState(1);
  const [itemId, setItemId] = useState(id);
  const [stepLength, setStepLength] = useState(4);

  const nextStep = responseId => {
    setStep(step + 1);
    setItemId(responseId);
  };

  const previousStep = responseId => {
    setStep(step - 1);
    setItemId(responseId);
  };

  switch (step) {
    case 1:
      return (
        <CollectionsGeneralInfoStep
          id={itemId}
          nextStepChange={nextStep}
          stepLength={stepLength}
          libraryId={libraryId}
          isSuperUser={isSuperUser === "True"}
        />
      );
    case 2:
      return (
        <CollectionsThumbnailUploadStep
          id={itemId}
          nextStepChange={nextStep}
          previousStepChange={previousStep}
          stepLength={stepLength}
          libraryId={libraryId}
        />
      );
    case 3:
      return (
        <StateMachineProvider>
          <CollectionsAttributesValueStep
            id={itemId}
            previousStepChange={previousStep}
            nextStepChange={nextStep}
            stepLength={stepLength}
          />
        </StateMachineProvider>
      );
    case 4:
      return (
        <CollectionsPermissionsStep
          id={itemId}
          previousStepChange={previousStep}
          stepLength={stepLength}
        />
      );
    default:
      return null;
  }
};

export default CollectionsStepForm;
