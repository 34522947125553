import "./Collections.scss";

import React, { useEffect, useState } from "react";
import {
  faEye,
  faLock,
  faPen,
  faPlus,
  faShareSquare,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import {
  faMinusSquare,
  faPlusSquare
} from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericModal from "../../components/Modal/GenericModal";
import GenericSelect from "../../components/Select/GenericSelect";
import GenericTable from "../../components/Table/GenericTable";
import TruncateMarkup from "react-truncate-markup";
import axiosSession from "../../config/axiosSession";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import useGenericToastify from "../../hooks/useGenericToastify";

const Collections = ({ message, hasCreatePermission }) => {
  const { handleSubmit, control, errors, setValue } = useForm();
  const { notifySuccess, notifyError } = useGenericToastify();
  const [expandedRowData, setExpandedRowData] = useState([]);
  const [itemData, setItemData] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [libraries, setLibraries] = useState([]);
  const [shouldTableRefresh, setShouldTableRefresh] = useState(false);
  const [library, setLibrary] = useState({});
  const [modalAction, setModalAction] = useState({});
  const [messages, setMessages] = useState({});
  const [expandedFinish, setExpandedFinish] = useState(true);

  const urls = {
    get: `/api/library/${library.id}/collections/`,
    add: `/library/${library.id}/collections/create/`,
    delete: "/api/collections/",
    preview: id => `/collections/${id}/preview/`,
    edit: id => `/collections/${id}/edit/`,
    add_children: id => `${urls.add}?collection_id=${id}`
  };

  const deleteMessages = {
    deleteSuccess: "Kolekcja została poprawnie usunięta",
    deleteError: "Wystąpił problem podczas usuwania kolekcji",
    btnDeleteNo: "Anuluj",
    btnDeleteYes: "Usuń",
    headerTitle: "Potwierdzenie usunięcia",
    bodyContent: "Czy na pewno chcesz usunąć kolekcję?",
    contentLabel: "Potwierdzenie usunięcia kolekcji"
  };

  const publishMessages = {
    headerTitle: "Potwierdzenie zmiany statusu",
    bodyContent:
      "Uwaga, spowoduje to zmianę statusu na opublikowany, czy na pewno chcesz kontynuować?",
    deleteSuccess: "Kolekcja została poprawnie opublikowana",
    deleteError: "Wystąpił problem podczas publikowania kolekcji",
    btnDeleteNo: "Nie, nie publikuj",
    btnDeleteYes: "Tak, opublikuj"
  };

  const archiveMessages = {
    headerTitle: "Potwierdzenie zmiany statusu",
    bodyContent:
      "Uwaga, spowoduje to zmianę statusu na archiwalny, czy na pewno chcesz kontynuować?",
    deleteSuccess: "Kolekcja została poprawnie zarchiwizowana",
    deleteError: "Wystąpił problem podczas archiwizowania kolekcji",
    btnDeleteNo: "Nie, nie archiwizuj",
    btnDeleteYes: "Tak, archiwizuj"
  };

  const statuses = {
    published: "published",
    draft: "draft",
    archived: "archived"
  };

  const tableStructure = [
    {
      header: "Nazwa",
      accessor: "internal_name",
      className: "w-6",
      sort: true
    },
    {
      header: "Status",
      accessor: "status",
      className: "w-3"
    },
    {
      header: "Akcje",
      className: "w-3"
    }
  ];

  useEffect(() => {
    axiosSession
      .get("/api/library/select-data/")
      .then(({ data }) => {
        setLibraries(data);
        if (data.length > 0) {
          setLibrary(data[0]);
          setValue("library", data[0]);
        }
      })
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    if (shouldTableRefresh) {
      setShouldTableRefresh(false);
    }
  }, [shouldTableRefresh]);

  useEffect(() => {
    if (!isEmpty(message.messages)) {
      notifySuccess(message.messages[message.messages.length - 1]);
    }
  }, [message, notifySuccess]);

  const expandRow = (row, index) => {
    if (row.expanded) {
      setExpandedRowData({
        index: index,
        expanded: false,
        rows: []
      });
    } else {
      setExpandedFinish(false);
      let level = row.level ? row.level : 0;
      row.level = level;
      let ancestors = row.ancestors ? row.ancestors : [];
      row.ancestors = ancestors;

      axiosSession.get(urls.get, { params: { parent: row.id } }).then(res => {
        let ancestor = [res.data.results[0].parent];
        let data = res.data.results.map(di => ({
          ...di,
          level: level + 1,
          ancestors: ancestors + ancestor
        }));
        setExpandedRowData({
          index: index,
          expanded: true,
          rows: data
        });
        setExpandedFinish(true);
      });
    }
  };

  const onDelete = item => {
    setItemData({ id: item.id, name: item.internal_name });
    setMessages(deleteMessages);
    setModalAction({ type: "delete", id: item.id });
    setModalIsOpen(true);
  };

  const onConfirmDelete = id => {
    axiosSession
      .delete(`${urls.delete}${id}/`)
      .then(() => {
        notifySuccess(messages.deleteSuccess);
        setShouldTableRefresh(true);
      })
      .catch(error => {
        if (error.response.data.detail) {
          notifyError(error.response.data.detail);
        } else {
          notifyError(messages.deleteError);
        }
        console.error(error);
      })
      .finally(() => {
        setModalIsOpen(false);
        setShouldTableRefresh(false);
      });
  };

  const onPublish = item => {
    setItemData({ id: item.id, name: item.internal_name });
    setMessages(publishMessages);
    setModalAction({ type: "publish", id: item.id });
    setModalIsOpen(true);
  };

  const onConfirmPublish = id => onSetStatus(statuses.published, id);

  const onArchive = item => {
    setItemData({ id: item.id, name: item.internal_name });
    setMessages(archiveMessages);
    setModalAction({ type: "archive", id: item.id });
    setModalIsOpen(true);
  };

  const onConfirmArchive = id => onSetStatus(statuses.archived, id);

  const onSetStatus = (status, id) => {
    axiosSession
      .post(`/api/collections/${id}/status/`, { status: status })
      .then(() => {
        notifySuccess(messages.deleteSuccess);
        setShouldTableRefresh(true);
      })
      .catch(error => {
        if (error.response.data.detail) {
          notifyError(error.response.data.detail);
        } else {
          notifyError(messages.deleteError);
        }
        console.error(error);
      })
      .finally(() => {
        setModalIsOpen(false);
        setShouldTableRefresh(false);
      });
  };

  const onModalAction = () => {
    switch (modalAction.type) {
      case "delete":
        onConfirmDelete(modalAction.id);
        break;
      case "archive":
        onConfirmArchive(modalAction.id);
        break;
      case "publish":
        onConfirmPublish(modalAction.id);
        break;
    }
  };

  const actions = item => {
    return (
      <td className="has-text-right">
        {item.actions.to_publish && (
          <span
            onClick={() => onPublish(item)}
            onKeyPress={() => onPublish(item)}
            role="button"
            tabIndex="0"
            className="generic-table__table__link"
            title="Opublikuj"
          >
            <FontAwesomeIcon icon={faShareSquare} />
          </span>
        )}
        {item.actions.to_archive && (
          <span
            onClick={() => onArchive(item)}
            onKeyPress={() => onArchive(item)}
            role="button"
            tabIndex="0"
            className="generic-table__table__link"
            title="Archiwizuj"
          >
            <FontAwesomeIcon icon={faLock} />
          </span>
        )}
        {item.actions.editable && (
          <a
            href={urls.preview(item.id)}
            tabIndex="0"
            className="generic-table__table__link"
            title="Podgląd"
          >
            <FontAwesomeIcon icon={faEye} />
          </a>
        )}
        {item.actions.editable && (
          <a
            href={urls.edit(item.id)}
            tabIndex="0"
            className="generic-table__table__link"
            title="Edycja"
          >
            <FontAwesomeIcon icon={faPen} />
          </a>
        )}
        {item.actions.removable && (
          <span
            onClick={() => onDelete(item)}
            onKeyPress={() => onDelete(item)}
            role="button"
            tabIndex="0"
            className="generic-table__table__link"
            title="Usuń"
          >
            <FontAwesomeIcon icon={faTrash} />
          </span>
        )}
        {item.actions.can_add_children && (
          <a
            href={urls.add_children(item.id)}
            tabIndex="0"
            className="generic-table__table__link"
            title="Dodaj"
          >
            <FontAwesomeIcon icon={faPlus} />
          </a>
        )}
      </td>
    );
  };

  const customRow = (item, index) => {
    return (
      <React.Fragment key={item.id}>
        <tr id={`row-${item.id}`}>
          <td className={item.has_children ? "has-children" : ""}>
            {item.has_children && (
              <span
                className={`generic-table__table__link ${
                  item.level ? `indent-with-extend-${item.level}` : ""
                }`}
                onClick={() => (expandedFinish ? expandRow(item, index) : "")}
                onKeyPress={() =>
                  expandedFinish ? expandRow(item, index) : ""
                }
                title={"Rozwiń wiersz"}
                role="button"
                tabIndex="0"
              >
                <FontAwesomeIcon
                  icon={item.expanded ? faMinusSquare : faPlusSquare}
                />
              </span>
            )}
            &nbsp;
            <TruncateMarkup>
              <span
                title={item.internal_name}
                className={
                  (item.has_children
                    ? ""
                    : item.level
                    ? `indent-${item.level}`
                    : "") + " collection"
                }
              >
                {item.internal_name}
              </span>
            </TruncateMarkup>
          </td>
          <td>{item.status}</td>
          {actions(item)}
        </tr>
      </React.Fragment>
    );
  };

  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Kolekcje</strong>
        </div>

        <GenericModal
          itemData={itemData}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          labels={messages}
          actionSubmit={onModalAction}
        />

        {!isEmpty(library) ? (
          <GenericTable
            customRowWithActions={customRow}
            structure={tableStructure}
            messages={{ btnAdd: "Dodaj kolekcję" }}
            urls={urls}
            expandedRowData={expandedRowData}
            shouldRefresh={shouldTableRefresh}
            hasHeaderActions={hasCreatePermission}
            expandRow={expandRow}
          />
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default Collections;
