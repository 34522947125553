import "./ReportedErrors.scss";

import React, { useState } from "react";
import { faEye, faMinusCircle, faPen } from "@fortawesome/free-solid-svg-icons";
import {
  faMinusSquare,
  faPlusSquare
} from "@fortawesome/free-regular-svg-icons";
import {
  notifyError,
  notifySuccess
} from "@app/plugins/mirador_annotation/src/config/ToastConfig";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericInput from "@app/components/Input/GenericInput";
import GenericTable from "@app/components/Table/GenericTable";
import Modal from "react-responsive-modal";
import TruncateMarkup from "react-truncate-markup";
import axiosSession from "@app/config/axiosSession";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";

const ReportedErrors = () => {
  const { handleSubmit, errors, control } = useForm();
  const [shouldTableRefresh, setShouldTableRefresh] = useState(false);
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewModalObject, setPreviewModalObject] = useState({});
  const [notificationId, setNotificationId] = useState(null);
  const [operationType, setOperationType] = useState(null);
  const [permissionInPreview, setPermissionInPreview] = useState({accept: false, reject: false});

  const filters = [
    {
      id: 1,
      name: "Oczekująca"
    },
    {
      id: 2,
      name: "Zaakceptowana"
    },
    {
      id: 3,
      name: "Odrzucona"
    }
  ];

  const tableStructure = [
    {
      header: "Nazwa publikacji",
      accessor: "digital_item",
      className: "w-2"
    },
    {
      header: "Data",
      accessor: "created",
      className: "w-2",
      sort: true
    },
    {
      header: "Użytkownik",
      accessor: "user",
      className: "w-2"
    },
    {
      header: "Treść zmiany",
      accessor: "content",
      className: "w-2"
    },
    {
      header: "Status",
      accessor: "status",
      className: "w-2",
      sort: true
    },
    {
      header: "Akcje",
      accessor: "domain",
      className: "w-2"
    }
  ];

  const urls = { get: "/api/digital_items/correction/all/" };
  const acceptedUrl = `/api/digital_items/correction/${notificationId}/accepted/`;

  const modalMessageRejection = {
    headerTitle: "Odrzucenie zgłoszenia",
    bodyContent: "Powód odrzucenia",
    btnDeleteNo: "Anuluj",
    btnDeleteYes: "Odrzuć"
  };

  const modalMessageAccept = {
    headerTitle: "Akceptacja zgłoszenia",
    bodyContent:
      "Czy chcesz zaakceptować zgłoszenie? Spowoduje to otworzenie nowej zakładki na stronie edycji obiektu.",
    btnDeleteNo: "Anuluj",
    btnDeleteYes: "Akceptuj"
  };

  const modalMessagePreview = {
    headerTitle: "Podgląd zgłoszenia",
    btnDeleteNo: "Anuluj"
  };

  const openPreviewModal = (objectId, canAccept, canReject) => {
    axiosSession
      .get(urls.get)
      .then(({ data: { results } }) => {
        setPreviewModalObject(results.filter(item => item.id === objectId)[0]);
        setPermissionInPreview({accept: canAccept, reject: canReject});
        setIsPreviewModalOpen(true);
      })
      .catch(err => {
        console.error(err);
        return notifyError("Nie można wywołać podglądu.");
      });
  };

  const confirmOperation = data => {
    const successMessages =
      operationType === "reject"
        ? "Odrzucono zgłoszenie o błędzie"
        : "Zaakceptowano zgłoszenie o błędzie";
    axiosSession
      .patch(acceptedUrl, {
        status: operationType === "reject" ? 3 : 2,
        comment: data.content
      })
      .then(({ data }) => {
        setIsModalOpen(false);
        setIsPreviewModalOpen(false);
        setShouldTableRefresh(true);
        setNotificationId(null);
        setPreviewModalObject({});
        setPermissionInPreview({accept: false, reject: false});
        if (operationType === "accept") {
          window.open(data.url, "_blank");
        }
        setOperationType(null);
        return notifySuccess(successMessages);
      })
      .catch(err => {
        console.error(err);
        return notifyError("Operacja nie powiodła się.");
      })
      .finally(() => {
        setShouldTableRefresh(false);
      });
  };

  const expandContent = (index, isExpanded) => {
    if (isExpanded) {
      const indexes = Array.from(expandedIndexes);
      indexes.splice(indexes.indexOf(index.toString()), 1);
      setExpandedIndexes(indexes);
    } else {
      setExpandedIndexes(Array.from(expandedIndexes + index));
    }
    setShouldTableRefresh(true);
  };

  const customRow = (item, objectIndex) => {
    const isMultipleLine = item.content.length > 1;
    const isExpanded = expandedIndexes.includes(item.id.toString());
    const expandedContent = [];
    item.content.map((element, index) => {
      expandedContent.push(
        <div key={index} className="extended-object">
          <TruncateMarkup>
            <p
              className="extended-object__element"
              title={item.content[index].category}
            >
              {`${item.content[index].object}: ${item.content[index].category}`}
            </p>
          </TruncateMarkup>
        </div>
      );
    });

    return (
      <React.Fragment key={objectIndex}>
        <tr id={`row-${item.id}-${item.object_class}`}>
          <td>
            <TruncateMarkup>
              <span
                className="directories-search__truncate"
                title={item.digital_item}
              >
                {item.digital_item}
              </span>
            </TruncateMarkup>
          </td>
          <td>
            <TruncateMarkup>
              <span
                className="directories-search__truncate"
                title={item.created}
              >
                {item.created}
              </span>
            </TruncateMarkup>
          </td>
          <td>
            <TruncateMarkup>
              <span className="directories-search__truncate" title={item.user}>
                {item.user}
              </span>
            </TruncateMarkup>
          </td>
          <td>
            <span
              className="directories-search__truncate"
              title={item.content[0].category}
            >
              {isMultipleLine ? (
                <div>
                  <div className="extended-object">
                    <FontAwesomeIcon
                      className="extended-object__icon"
                      icon={!isExpanded ? faPlusSquare : faMinusSquare}
                      onClick={() => expandContent(item.id, isExpanded)}
                      title={
                        !isExpanded ? "Wyświetl zawartość" : "Zwiń zawartość"
                      }
                    />
                    <p
                      className="extended-object__element"
                      title={
                        !isExpanded ? "Wyświetl zawartość" : "Zwiń zawartość"
                      }
                    >
                      {!isExpanded ? "Wyświetl zawartość" : "Zwiń zawartość"}
                    </p>
                  </div>
                  {isExpanded && expandedContent}
                </div>
              ) : (
                <TruncateMarkup>
                  <p
                    className="extended-object__element"
                    title={item.content[0].category}
                  >
                    {`${item.content[0].object}: ${item.content[0].category}`}
                  </p>
                </TruncateMarkup>
              )}
            </span>
          </td>
          <td>
            <TruncateMarkup>
              <span
                className="directories-search__truncate"
                title={item.status}
              >
                {item.status}
              </span>
            </TruncateMarkup>
          </td>
          <td>
            <div
              className="directories-search__truncate has-text-right"
              title={item.full_url}
            >
              {item.actions.viewable &&
              <span
                onClick={() => openPreviewModal(item.id, item.actions.editable, item.actions.removeable)}
                onKeyPress={() => openPreviewModal(item.id, item.actions.editable, item.actions.removeable)}
                role="button"
                tabIndex="0"
                className="generic-table__table__link"
                title="Podgląd"
              >
                <FontAwesomeIcon icon={faEye} />
              </span>}
              {item.actions.editable &&
              <span
                onClick={() => {
                  setNotificationId(item.id);
                  setOperationType("accept");
                  setIsModalOpen(true);
                }}
                onKeyPress={() => {
                  setNotificationId(item.id);
                  setOperationType("accept");
                  setIsModalOpen(true);
                }}
                role="button"
                tabIndex="0"
                className="generic-table__table__link"
                title="Popraw"
              >
                <FontAwesomeIcon icon={faPen} />
              </span>}
              {item.actions.removeable &&
              <span
                onClick={() => {
                  setNotificationId(item.id);
                  setOperationType("reject");
                  setIsModalOpen(true);
                }}
                onKeyPress={() => {
                  setNotificationId(item.id);
                  setOperationType("reject");
                  setIsModalOpen(true);
                }}
                role="button"
                tabIndex="0"
                className="generic-table__table__link"
                title="Odrzuć"
              >
                <FontAwesomeIcon icon={faMinusCircle} />
              </span>}
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  };

  const Entry = ({ title, content }) => {
    return (
      <div className="entry">
        <div className="entry__title">{title}:</div>
        <div className="entry__content">{content || "-"}</div>
      </div>
    );
  };

  return (
    <section className="mbc-backoffice reported-errors">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Zgłaszane błędy</strong>
        </div>

        <Modal
          open={isModalOpen}
          onClose={() => {
            setNotificationId(null);
            setIsModalOpen(false);
          }}
          center
          showCloseIcon={false}
          classNames={{ modal: "GenericModal" }}
        >
          <div className="GenericModal__Header--with-border">
            <p className="GenericModal__Header__Title">
              {operationType === "reject"
                ? modalMessageRejection.headerTitle
                : modalMessageAccept.headerTitle}
            </p>
          </div>
          <div className="GenericModal__Body">
            <div className="column">
              {operationType === "reject" ? (
                <GenericInput
                  label={modalMessageRejection.bodyContent}
                  placeholder=""
                  name="content"
                  darkTheme={false}
                  control={control}
                  tag="textarea"
                  required={{ required: "To pole jest wymagane" }}
                  errors={errors}
                />
              ) : (
                modalMessageAccept.bodyContent
              )}
            </div>
          </div>
          <div className="GenericModal__Footer">
            <p className="GenericModal__Footer__Buttons buttons is-right is-fullwidth">
              <button
                className="button GenericModal__Footer__Button"
                onClick={() => {
                  setNotificationId(null);
                  setIsModalOpen(false);
                }}
              >
                {operationType === "reject"
                  ? modalMessageRejection.btnDeleteNo
                  : modalMessageAccept.btnDeleteNo}
              </button>
              <button
                className="button button--is-orange GenericModal__Footer__Button"
                onClick={handleSubmit(data => confirmOperation(data))}
              >
                {operationType === "reject"
                  ? modalMessageRejection.btnDeleteYes
                  : modalMessageAccept.btnDeleteYes}
              </button>
            </p>
          </div>
        </Modal>

        <Modal
          open={isPreviewModalOpen}
          onClose={() => {
            setNotificationId(null);
            setIsPreviewModalOpen(false);
            setPreviewModalObject({});
            setPermissionInPreview({accept: false, reject: false});
          }}
          center
          showCloseIcon={false}
          classNames={{ modal: "GenericModal" }}
        >
          <div className="GenericModal__Header--with-border">
            <p className="GenericModal__Header__Title">
              {modalMessagePreview.headerTitle}
            </p>
          </div>

          <div className="GenericModal__Body">
            {!isEmpty(previewModalObject) && (
              <div className="">
                <div className="reported-errors__preview">
                  <Entry
                    title="Nazwa publikacji"
                    content={previewModalObject.digital_item}
                  />
                  <Entry title="Status" content={previewModalObject.status} />
                  {previewModalObject.status === "Odrzucona" && (
                    <Entry
                      title="Powód odrzucenia"
                      content={previewModalObject.comment}
                    />
                  )}
                  <Entry title="Data" content={previewModalObject.created} />
                  <Entry title="Użytkownik" content={previewModalObject.user} />

                  {previewModalObject.content.map((item, index) => (
                    <div key={index} className="entry__content-wrapper">
                      <Entry title="Atrybut / Plik" content={item.object} />
                      <Entry title="Kategoria" content={item.category} />
                      <Entry title="Komentarz" content={item.content} />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="GenericModal__Footer">
            <div className="GenericModal__Footer__Buttons buttons is-right is-fullwidth">
              <button
                className="button button--is-light"
                onClick={() => {
                  setNotificationId(null);
                  setIsPreviewModalOpen(false);
                }}
              >
                {modalMessagePreview.btnDeleteNo}
              </button>
              {permissionInPreview.accept &&
              <button
                className="button button--is-orange"
                onClick={() => {
                  setNotificationId(previewModalObject.id);
                  setOperationType("accept");
                  setIsModalOpen(true);
                }}
              >
                Popraw
              </button>}
              {permissionInPreview.reject &&
              <button
                className="button button--is-orange"
                onClick={() => {
                  setNotificationId(previewModalObject.id);
                  setOperationType("reject");
                  setIsModalOpen(true);
                }}
              >
                Odrzuć
              </button>}
            </div>
          </div>
        </Modal>

        <GenericTable
          customRowWithActions={customRow}
          structure={tableStructure}
          urls={urls}
          hasHeaderActions={false}
          searchField="search_field"
          shouldRefresh={shouldTableRefresh}
          setShouldRefresh={setShouldTableRefresh}
          filtersOptions={filters}
          filterLabel="Status"
          inlineFilters
          filtersValues="status"
          defaultTableFilters={[1, 2, 3]}
          hasFilters
        />
      </div>
    </section>
  );
};

export default ReportedErrors;
