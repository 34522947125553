import FileUpload from "@components/FileUpload/FileUpload";
import React from "react";

//TODO: merge ColectionsFileUpload and DirectoriesFileUpload into one component

const CollectionsFileUpload = ({ id, fileServer, formType }) => {
  const endpoint = `/api/collections/${id}/thumbnails/`

  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="mbc-backoffice__header">
          <h2 className="mbc-backoffice__header__title">
            Trwa dodawanie plików
          </h2>
          <hr className="mbc-backoffice__header__hr" />
          <span>
            W tym oknie występuje wysyłanie plików na serwer. Pozostaw to okno
            otwarte na czas zakończenia wysyłania. Celem dalszej pracy możesz
            powrócić do wcześniejszego okna.
          </span>
        </div>

        <div className={"container"}>
          <FileUpload
            id={id}
            fileServer={fileServer}
            formType={formType}
            fileUploadURL={endpoint}
          />
        </div>
      </div>
    </section>
  );
};

export default CollectionsFileUpload;
