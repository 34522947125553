import React, { useEffect, useState } from "react";

import GenericSelect from "@components/Select/GenericSelect";
import Input from "@components/Input/Input";
import Modal from "react-responsive-modal";
import axiosSession from "@app/config/axiosSession";
import handleErrors from "@app/helpers/handleErrors";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

const NewsletterSetup = () => {
  const { notifySuccess, notifyError } = useGenericToastify();
  const { handleSubmit, register, errors, control, setError } = useForm();
  const [subscriptionConfiguration, setSubscriptionConfiguration] = useState(
    {}
  );
  const [mounted, setMounted] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const subscriptionFrequency = [
    { id: 1, name: "codziennie" },
    { id: 2, name: "co tydzień" },
    { id: 3, name: "co dwa tygodnie" },
    { id: 4, name: "co miesiąc" }
  ];

  const subscriptionUrl = "/api/users/subscription_frequency/";

  useEffect(() => {
    if (!mounted || shouldRefresh) {
      axiosSession
        .get(subscriptionUrl)
        .then(({ data }) => {
          if (data.count > 0) {
            setSubscriptionConfiguration(data.results[0]);
          }
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          setMounted(true);
          setShouldRefresh(false);
        });
    }
  }, [mounted, shouldRefresh]);

  const getFrequency = id => {
    return subscriptionFrequency.find(object => object.id === id) || {};
  };

  const onSubmit = data => {
    const formData = {
      ...data,
      frequency: data.frequency.id
    };

    axiosSession
      .post(subscriptionUrl, formData)
      .then(() => {
        notifySuccess("Konfiguracja newslettera została poprawnie zmieniona");
        setShouldRefresh(true);
      })
      .catch(err => {
        console.error(err);
        notifyError("Wystąpił błąd podczas konfiguracji newslettera");
        handleErrors(err.response.data, setError);
      })
      .finally(() => setDisplayModal(false));
  };

  return (
    <div className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Newsletter</strong>
        </div>

        {mounted && (
          <>
            <div className="mbc-backoffice__header">
              <h2 className="mbc-backoffice__header__title">
                Konfiguracja newslettera
              </h2>
              <hr className="mbc-backoffice__header__hr" />
            </div>

            {!isEmpty(subscriptionConfiguration) ? (
              <>
                <div className="columns">
                  <div className="is-uppercase column is-3">Częstotliwość:</div>
                  <div className="has-text-weight-bold column">
                    <p className="has-text-black">
                      {getFrequency(subscriptionConfiguration.frequency)
                        ?.name || ""}
                    </p>
                  </div>
                </div>
                <div className="columns ">
                  <div className="is-uppercase column is-3">Godzina:</div>
                  <div className="has-text-weight-bold column">
                    <p className="has-text-black">
                      {subscriptionConfiguration.time}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <div className="column has-text-black is-paddingless">
                Newsletter nie został jeszcze skonfigurowany.
              </div>
            )}
            <div className="buttons">
              <button
                className="button button--is-orange"
                onClick={() => setDisplayModal(true)}
              >
                {!isEmpty(subscriptionConfiguration)
                  ? "Zmień konfigurację"
                  : "Dodaj konfigurację"}
              </button>
            </div>
          </>
        )}

        <Modal
          open={displayModal}
          onClose={() => setDisplayModal(false)}
          classNames={{
            modal: "GenericModal"
          }}
        >
          {mounted && (
            <>
              <div className="GenericModal__Header--with-border">
                <p className="GenericModal__Header__Title">
                  {!isEmpty(subscriptionConfiguration)
                    ? "Edycja konfiguracji newslettera"
                    : "Tworzenie konfiguracji newslettera"}
                </p>
              </div>

              <div className="GenericModal__Body">
                <div className="columns is-centered is-variable is-8">
                  <div className="column">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Input
                        label="Godzina"
                        type="time"
                        name="time"
                        defaultValue={
                          !isEmpty(subscriptionConfiguration)
                            ? subscriptionConfiguration.time
                            : "00:00"
                        }
                        register={register({
                          required: "To pole jest wymagane!"
                        })}
                        errors={errors}
                      />

                      <GenericSelect
                        options={subscriptionFrequency}
                        label="Częstotliwość"
                        defaultValue={
                          !isEmpty(subscriptionConfiguration)
                            ? getFrequency(subscriptionConfiguration.frequency)
                            : subscriptionFrequency[0]
                        }
                        name="frequency"
                        control={control}
                        errors={errors}
                      />

                      <button
                        type="submit"
                        className="button button--is-orange is-pulled-right"
                      >
                        Zapisz
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default NewsletterSetup;
