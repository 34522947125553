import React, { useState } from "react";

import { faEye, faMinusCircle, faPen } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericModal from "@app/components/Modal/GenericModal";
import GenericTable from "@app/components/Table/GenericTable";
import TruncateMarkup from "react-truncate-markup";
import axiosSession from "@app/config/axiosSession";
import useGenericToastify from "@app/hooks/useGenericToastify";

const Messages = ({ message, hasCreatePermission }) => {
  const { notifyError, notifySuccess } = useGenericToastify();
  const [shouldTableRefresh, setShouldTableRefresh] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [messageId, setMessageId] = useState(null);

  const tableStructure = [
    {
      header: "Tytuł",
      accessor: "title",
      className: "w-2"
    },
    {
      header: "Data wysyłki",
      accessor: "sending_datetime",
      className: "w-2",
      sort: true
    },
    {
      header: "Status",
      accessor: "status",
      className: "w-2",
      sort: true
    },
    {
      header: "Akcje",
      accessor: "actions",
      className: "w-1"
    }
  ];

  const urlsForTable = { get: "/api/notifications/", add: "/messages/add/" };
  const urls = {
    edit: "/messages/edit/",
    preview: "/messages/preview/",
    cancel: "/api/notifications/"
  };

  const messages = { btnAdd: "Dodaj komunikat" };
  const modalMessages = {
    headerTitle: "Potwierdzenie anulowania komunikatu",
    bodyContent:
      "Uwaga, spowoduje to anulowanie wysyłki komunikatu. Czy na pewno chcesz kontynuować?",
    btnDeleteNo: "Nie",
    btnDeleteYes: "Tak"
  };

  const cancelMessage = messageId => {
    axiosSession
      .delete(`${urls.cancel}${messageId}/`)
      .then(() => {
        notifySuccess("Poprawnie anulowano wysyłkę komunikatu.");
        setModalIsOpen(false);
        setMessageId(null);
        setShouldTableRefresh(true);
      })
      .catch(error => {
        console.error(error);
        notifyError("Anulowanie komunikatu nie powiodło się.");
      });
  };

  const customRow = (item, index) => {
    return (
      <React.Fragment key={index}>
        <tr id={`row-${item.id}`}>
          <td>
            <TruncateMarkup>
              <span className="directories-search__truncate" title={item.title}>
                {item.title}
              </span>
            </TruncateMarkup>
          </td>
          <td>
            <TruncateMarkup>
              <span
                className="directories-search__truncate"
                title={item.sending_datetime}
              >
                {item.sending_datetime}
              </span>
            </TruncateMarkup>
          </td>
          <td>
            <TruncateMarkup>
              <span
                className="directories-search__truncate"
                title={item.status}
              >
                {item.status}
              </span>
            </TruncateMarkup>
          </td>
          <td>
            <div
              className="directories-search__truncate has-text-right"
              title={item.actions}
            >
              {item.actions.viewable && (
                <a
                  href={`${urls.preview}${item.id}/`}
                  role="button"
                  tabIndex="0"
                  className="generic-table__table__link"
                  title="Podgląd"
                >
                  <FontAwesomeIcon icon={faEye} />
                </a>
              )}
              {item.actions.editable && (
                <a
                  href={`${urls.edit}${item.id}/`}
                  role="button"
                  tabIndex="0"
                  className="generic-table__table__link"
                  title="Edytuj"
                >
                  <FontAwesomeIcon icon={faPen} />
                </a>
              )}
              {item.actions.removeable && (
                <span
                  onClick={() => {
                    setMessageId(item.id);
                    setModalIsOpen(true);
                  }}
                  onKeyPress={() => {
                    setMessageId(item.id);
                    setModalIsOpen(true);
                  }}
                  role="button"
                  tabIndex="0"
                  className="generic-table__table__link"
                  title="Anuluj"
                >
                  <FontAwesomeIcon icon={faMinusCircle} />
                </span>
              )}
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  };

  return (
    <section className="mbc-backoffice">
      <GenericModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        labels={modalMessages}
        actionSubmit={() => cancelMessage(messageId)}
      />
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Komunikaty</strong>
        </div>

        <GenericTable
          customRowWithActions={customRow}
          structure={tableStructure}
          urls={urlsForTable}
          hasHeaderActions={hasCreatePermission}
          headerActionsAlignRight={true}
          shouldRefresh={shouldTableRefresh}
          setShouldRefresh={setShouldTableRefresh}
          messages={messages}
          hasSearch={false}
          notification={message}
        />
      </div>
    </section>
  );
};

export default Messages;
