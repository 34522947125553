import "./LibraryForm.scss";

import React, { useEffect, useState } from "react";

import Preview from "@app/components/Preview/Preview";
import axiosSession from "@app/config/axiosSession";

const LibraryPreview = ({ id }) => {
  const [rows, setRows] = useState(null);
  const [isDeleted, setIsDeleted] = useState(true);
  const [canEdit, setCanEdit] = useState(false);

  const editUrl = `/library/${id}/edit/`

  useEffect(() => {
    if (id) {
      axiosSession
        .get(`/api/library/${id}/`)
        .then(({ data }) => {
          setRows([
            { title: "Pełna nazwa", value: [data.full_name] },
            { title: "Nazwa (skrót)", value: [data.name] },
            { title: "Domena", value: [data.domain] },
            { title: "Adres URL (bip)", value: data.url ? [data.url] : [] },
            {
              title: "Status",
              value: [data.is_blocked ? "nieaktywny" : "aktywny"]
            },
            {
              title: "Ustawianie geolokalizacji zbiorów",
              value: data.show_map ? ["tak"] : ["nie"]

            },
            {
              title: "Języki",
              value: data.languages.map(elem => elem.name)
            },
            {
              title:
                "Adresy IP uprawnione do logowania do panelu administracyjnego",
              value: data.login_ip_addresses.map(
                elem => elem.address + elem.mask
              )
            },
            {
              title:
                "Adresy IP uprawnione do odczytu obiektów chronionych prawem autorskim",
              value: data.objects_ip_addresses.map(
                elem => elem.address + elem.mask
              )
            }
          ]);
          setIsDeleted(data.is_deleted);
          setCanEdit(data.can_edit_permission);
        })
        .catch(err => console.error(err));
    }
  }, [id]);

  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a className="breadcrumbs__link" href="/library/" title="Biblioteki">
            Biblioteki
          </a>
          &nbsp;/&nbsp;
          <strong>Podgląd</strong>
        </div>

        <div className="mbc-backoffice__header">
          <h2 className="mbc-backoffice__header__title">Podgląd biblioteki</h2>
          <hr className="mbc-backoffice__header__hr" />
        </div>

        {rows && <Preview rows={rows} />}

        <div className="mbc-backoffice__bottom">
          <a
            className="mbc-backoffice__goback is-uppercase has-text-weight-bold"
            href="/library/"
          >
            &lt; Wróć do listy bibliotek
          </a>

          {!isDeleted && canEdit && <a
            className="mbc-backoffice__goback is-uppercase has-text-weight-bold"
            href={editUrl}
          >
            Edytuj
          </a>}
        </div>
      </div>
    </section>
  );
};

export default LibraryPreview;
