import React, { useEffect, useState } from "react";

import Collapsible from "react-collapsible";
import Preview from "@components/Preview/Preview";
import SchemasFormMarc from "./SchemasFormMarc";
import axiosSession from "@app/config/axiosSession";

const dcOptions = [
  { id: 1, title: "Sklej wartości" },
  { id: 2, title: "Sklej odpowiadające wartości" },
  { id: 3, title: "Dodaj jako nową wartość" },
  { id: 4, title: "Wartość atrybutu jest wskaźnikiem 1" },
  { id: 5, title: "Wartość atrybutu jest wskaźnikiem 2" }
];

const SchemasPreview = ({ id }) => {
  const [parentId, setParentId] = useState(null);
  const [rows, setRows] = useState(null);
  const [marcAttributes, setMarcAttributes] = useState([]);
  const [attributesList, setAttributesList] = useState([]);
  const [marcFields, setMarcFields] = useState([]);
  const [isWithMarc, setIsWithMarc] = useState(false);
  const [complexAttributes, setComplexAttributes] = useState({});

  const attribute_complex_type = 9;

  const apiRequestLoop = array => {
    const promiseArray = [];
    array.forEach(attr => {
      promiseArray.push(
        axiosSession
          .get(`/api/attributes/${attr.attribute.id}/children/`)
          .then(({ data }) => {
            return { data: data, attribute: attr.attribute, idx: attr.idx };
          })
      );
    });
    return axiosSession.all(promiseArray);
  };

  useEffect(() => {
    if (id) {
      axiosSession
        .get(`/api/attributes/schema/${id}/`)
        .then(({ data }) => {
          setRows([
            { title: "Nazwa", value: [data.name] },
            { title: "Opis schematu", value: [data.description] },
            {
              title: "Status",
              value: [data.active ? "aktywne" : "nieaktywne"]
            },
            {
              title: "Schemat złożony z atrybutów",
              value: data.attributes.map(el => el.name)
            }
          ]);
          setAttributesList(data.attributes);
          setIsWithMarc(data.marc21.length > 0);
          setMarcAttributes(data.marc21);
          if (data.parent) {
            setParentId(data.parent);
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [id]);

  useEffect(() => {
    if (attributesList.length > 0) {
      const complexList = [];
      attributesList.forEach((item, idx) => {
        if (item.attribute_type === attribute_complex_type) {
          complexList.push({ attribute: item, idx });
        }
      });
      apiRequestLoop(complexList).then(results => {
        let tempObj = Object.assign({}, complexAttributes);
        results.forEach(result => {
          const tempComplexArray = result.data.map(node => {
            return {
              id: node.id,
              name: node.name,
              path: node.path
            };
          });
          tempObj[`${result.attribute.id}`] = tempComplexArray;
        });
        setComplexAttributes(tempObj);
      });
    }
  }, [attributesList]);

  useEffect(() => {
    axiosSession
      .get("/api/attributes/marc21_field/")
      .then(({ data }) => {
        setMarcFields(data.results);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (parentId) {
      axiosSession
        .get(`/api/attributes/schema/${parentId}/`)
        .then(({ data }) => {
          setRows(
            rows.concat({
              title: "Schemat utworzony na podstawie",
              value: [data.name]
            })
          );
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [parentId]);

  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/attributes/schemas/"
            title="Schematy"
          >
            Schematy opisowe
          </a>
          &nbsp;/&nbsp;
          <strong>Podgląd</strong>
        </div>

        <div className="mbc-backoffice__header">
          <h2 className="mbc-backoffice__header__title">Podgląd schematu</h2>
          <hr className="mbc-backoffice__header__hr" />
        </div>

        {rows && <Preview rows={rows} />}
        {isWithMarc && marcAttributes.length > 0 ? (
          <div>
            {attributesList.map(attr => {
              const marcAttribute = Object.assign(
                {},
                marcAttributes.find(x => x.attribute === attr.id)
              );
              return (
                <React.Fragment key={attr.id}>
                  <Collapsible
                    transitionTime={200}
                    trigger={`${attr.name} ${
                      attr.attribute_type === attribute_complex_type
                        ? "- atrybut złożony"
                        : ""
                    }`}
                    classParentString="Collapsible--Preview"
                  >
                    {attr.attribute_type === attribute_complex_type &&
                    Object.keys(complexAttributes).length > 0 ? (
                      <>
                        {Object.keys(complexAttributes).includes(
                          `${attr.id}`
                        ) && (
                          <>
                            {complexAttributes[`${attr.id}`].map(
                              (item, idx) => {
                                let correctAttribute = {};
                                marcAttributes.map(marcAttribute => {
                                  let isCorrectAttribute = true;
                                  item.path.length ===
                                  marcAttribute.parent_attribute_path.length
                                    ? null
                                    : (isCorrectAttribute = false);
                                  item.path.map(id =>
                                    marcAttribute.parent_attribute_path.includes(
                                      id
                                    )
                                      ? null
                                      : (isCorrectAttribute = false)
                                  );
                                  if (isCorrectAttribute) {
                                    correctAttribute = marcAttribute;
                                  }
                                });
                                return (
                                  <div key={item.name}>
                                    <div>
                                      <h3 className="schemas-form__complex-header has-text-weight-bold">
                                        Atrybut: {item.name}
                                      </h3>
                                      <SchemasFormMarc
                                        attribute={attr}
                                        marcFields={marcFields}
                                        dcOptions={dcOptions}
                                        isComplex={true}
                                        idx={idx}
                                        marcAttribute={correctAttribute}
                                        isPreview
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <SchemasFormMarc
                        attribute={attr}
                        marcFields={marcFields}
                        dcOptions={dcOptions}
                        marcAttribute={marcAttribute}
                        isPreview
                      />
                    )}
                  </Collapsible>
                </React.Fragment>
              );
            })}
          </div>
        ) : (
          ""
        )}

        <div className="mbc-backoffice__bottom">
          <a
            className="mbc-backoffice__goback is-uppercase has-text-weight-bold"
            href="/attributes/schemas/"
          >
            &lt; Wróć do listy schematów
          </a>
        </div>
      </div>
    </section>
  );
};

export default SchemasPreview;
