import "@app/scss/variables.scss";

import React, { useEffect, useState } from "react";

import Preview from "@app/components/Preview/Preview";
import axiosSession from "@app/config/axiosSession";

const ExternalUsersPreview = ({ id }) => {
  const [rows, setRows] = useState(null);
  const [canEdit, setCanEdit] = useState(false);

  const urls = {
    getData: `/api/users/external-users/${id}/`,
    home: "/admin-panel/home/",
    list: "/users/external-users/",
    edit: `/users/external-users/${id}/edit/`
  };

  useEffect(() => {
    axiosSession
      .get(urls.getData)
      .then(({ data }) => {
        setCanEdit(data.can_edit_permission);
        setRows([
          {
            title: "Nazwa użytkownika (login)",
            value: [data.username]
          },
          {
            title: "Imię",
            value: [data.first_name]
          },
          {
            title: "Nazwisko",
            value: [data.last_name]
          },
          {
            title: "Adres e-mail",
            value: [data.email]
          },
          {
            title: "Typ rejestracji",
            value: [data.log_by]
          },
          {
            title: "Status",
            value: [data.status]
          }
        ]);
      })
      .catch(err => console.error(err));
  }, []);

  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href={urls.home}
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href={urls.list}
            title="Użytkownicy zewnętrzni"
          >
            Użytkownicy zewnętrzni
          </a>
          &nbsp;/&nbsp;
          <strong>Podgląd</strong>
        </div>

        <div className="mbc-backoffice__header">
          <h2 className="mbc-backoffice__header__title">
            Podgląd użytkownika zewnętrznego
          </h2>
          <hr className="mbc-backoffice__header__hr" />
        </div>

        {rows && <Preview rows={rows} />}

        <div className="mbc-backoffice__bottom">
          <a
            className="mbc-backoffice__goback is-uppercase has-text-weight-bold"
            href={urls.list}
          >
            &lt; Wróć do listy użytkowników zewnętrznych
          </a>
          {canEdit &&
          <a
            className="mbc-backoffice__goback is-uppercase has-text-weight-bold"
            href={urls.edit}
          >
            Edytuj
          </a>}
        </div>
      </div>
    </section>
  );
};

export default ExternalUsersPreview;
