import "./NestedSelect.scss";

import { Controller, ErrorMessage } from "react-hook-form";
import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { TreeSelect } from "antd";
import axiosSession from "../../config/axiosSession";
import find from "lodash/find";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";

const NestedSelect = ({
  url,
  control,
  showSearch,
  name,
  label,
  placeholder,
  required,
  darkTheme,
  disabled,
  errors,
  isMulti,
  defaultParent,
  isRequire,
  multiple,
  defaultExpandFirst
}) => {
  const [treeData, setTreeData] = useState([]);
  const [initialTreeData, setInitialTreeData] = useState([]);
  const [previousDefault, setPreviousDefault] = useState({});
  const [isMounted, setIsMounted] = useState(false);
  const [hasExpanded, setHasExpanded] = useState(false);

  const onLoadData = treeNode => {
    return axiosSession
      .get(url, { params: { parent_id: treeNode.id } })
      .then(({ data }) => {
        const treeDataIds = treeData.map(({ id }) => id);
        const tempTreeData = treeData.concat(
          data.filter(
            node =>
              node !== undefined &&
              node.id !== defaultParent?.id &&
              !treeDataIds.includes(node.id)
          )
        );
        setTreeData(treeDataMapper([...tempTreeData]));
        setInitialTreeData(tempTreeData);
      });
  };

  useEffect(() => {
    if (url) {
      setIsMounted(false);
      axiosSession.get(`${url}`).then(({ data }) => {
        setTreeData(treeDataMapper([...data]));
        setInitialTreeData(data);
        setTimeout(() => setIsMounted(true), 500);
      });
    }
  }, [url]);

  useEffect(() => {

    const fetchDefaultExpanded = async () => {
      const parents = treeData.filter(item => !item.isLeaf);

      if (parents.length > 0) {
        await onLoadData(parents[0]);
      }

      setHasExpanded(true);
    };

    if (
      defaultExpandFirst &&
      Array.isArray(treeData) &&
      treeData.length === 1 &&
      !hasExpanded
    ) {
      fetchDefaultExpanded();
    } else if (!hasExpanded && Array.isArray(treeData) && treeData.length > 0) {
      setHasExpanded(true);
    }
  }, [treeData, hasExpanded]);

  useEffect(() => {

    setPreviousDefault(defaultParent);
    if (defaultParent && isMounted && !multiple) {
      const tempTreeData = treeData
        .filter(
          node =>
            node !== undefined &&
            node.title !== `Domyślny: ${previousDefault.name}` &&
            node.id !== defaultParent.id
        )
        .concat({
          id: defaultParent.id,
          pId: defaultParent.parent,
          title: `Domyślny: ${defaultParent.name}`,
          isLeaf: false
        });
      setTreeData(treeDataMapper([...tempTreeData]));
      setInitialTreeData(tempTreeData);
    } else if (defaultParent && multiple) {
      let tempTreeData = [];
      defaultParent.forEach(el => {
        tempTreeData.push({
          id: el.id,
          pId: el.parent,
          title: el.name,
          isLeaf: el?.isLeaf ?? false
        });
      });
      const tempTree2 = treeData
        .filter(
          node => node !== undefined && find(tempTreeData, node) === undefined
        )
        .concat(tempTreeData);
      setTreeData(treeDataMapper([...tempTree2]));
      setInitialTreeData(tempTree2);
    }
  }, [isMounted, defaultParent]);

  const onSearch = async value => {
    if (value.length < 3) {
      setTreeData([...initialTreeData]);
    } else {
      await axiosSession
        .get(url, { params: { search: value } })
        .then(({ data }) => {
          setTreeData(treeDataMapper([...data]));
        });
    }
  };

  const treeDataMapper = (treeDataArray) => {
    return treeDataArray.map(item => ({...item, ...{value: item.id}}))
  }

  const whiteTextLabel = darkTheme ? "" : "__black";
  const errorStyle =
    !isEmpty(errors) && has(errors, name) ? "is-danger is-error" : "";
  return (
    <div className="field mbc-nested-select">
      <label
        htmlFor={name}
        className={"label mbc-select__label" + whiteTextLabel}
      >
        {label}
        {isRequire && " *"}
      </label>

      <div className="control">
        <Controller
          as={
            <TreeSelect
              treeDataSimpleMode
              className={`${errorStyle}`}
              treeDefaultExpandedKeys={[
                ...(treeData?.length > 0 ? [treeData?.[0].id] : [])
              ]}
              placeholder={placeholder}
              treeData={treeData}
              showSearch={showSearch}
              allowClear={false}
              showArrow={true}
              filterTreeNode={() => {
                return true;
              }}
              onSearch={onSearch}
              loadData={onLoadData}
              multiple={multiple}
            />
          }
          name={name}
          control={control}
          disabled={disabled || (defaultExpandFirst && !hasExpanded)}
          rules={required}
        />
        {hasExpanded}
      </div>
      <ErrorMessage
        as={<p className="help is-danger" />}
        errors={errors}
        name={name}
      />
    </div>
  );
};
NestedSelect.propTypes = {
  url: PropTypes.string,
  showSearch: PropTypes.bool,
  required: PropTypes.object,
  control: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  isMulti: PropTypes.bool,
  darkTheme: PropTypes.bool,
  defaultExpandFirst: PropTypes.bool
};
NestedSelect.defaultProps = {
  isMulti: false,
  showSearch: true,
  isRequire: false,
  multiple: false,
  defaultExpandFirst: false,
  placeholder: "Wybierz..."
};
export default NestedSelect;
