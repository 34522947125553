import "./DirectoryForm.scss";

import * as qs from "query-string";

import React, { useCallback, useEffect, useRef, useState } from "react";

import DropdownButton from "@app/components/DropdownButton/DropdownButton";
import GenericModal from "@app/components/Modal/GenericModal";
import GenericSelect from "@app/components/Select/GenericSelect";
import Input from "@app/components/Input/Input";
import NestedSelect from "@app/components/Select/NestedSelect";
import Stepper from "./Stepper";
import axiosSession from "@app/config/axiosSession";
import findIndex from "lodash/findIndex";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

let submitType = "";
let modalType = "";
const messages = {
  headerTitle: "Anuluj",
  bodyContent: "",
  btnDeleteNo: "Nie",
  btnDeleteYes: "Tak"
};

const GeneralInfoStep = ({ id, formType, nextStepChange, stepLength }) => {
  const {
    handleSubmit,
    register,
    errors,
    control,
    setError,
    reset,
    setValue,
    getValues
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [schema, setSchema] = useState([]);
  const { notifyError } = useGenericToastify();
  const [libraries, setLibraries] = useState([]);
  const [disableParent, setDisableParent] = useState(true);
  const [data, setData] = useState({});
  const [copyright, setCopyright] = useState([]);
  const isGroup = formType === "group-object";
  const isDigitalItem = formType === "digital-item";
  const isMounted = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSchema, setSelectedSchema] = useState({});
  const [hierarchicSelectUrl, setHierarchicSelectUrl] = useState("");
  const [defaultParentId, setDefaultParentId] = useState(false);
  const [defaultParent, setDefaultParent] = useState(false);
  const [defaultLibrary, setDefaultLibrary] = useState("");
  const [hasReset, setHasReset] = useState(false);

  const directoryUrl = `/api/directories/directory/`;
  const directoriesUrl = `/api/directories/`;

  const formLabel = isDigitalItem
    ? "obiektu"
    : isGroup
      ? "obiektu grupowego"
      : "katalogu";

  let formUrl = `/api/directories/directory/${id}/`;
  if (isDigitalItem) {
    formUrl = `/api/digital_items/${id}/`;
  }
  const handleLibraryChange = ([selectedOption]) => {
    setValue("parent", undefined);
    setDisableParent(false);
    setHierarchicSelectUrl(
      `${directoriesUrl}${selectedOption.id}/hierarchic-select-data/`
    );
    setDefaultParent(false);
    let dataWithoutId = Object.assign({}, data);
    dataWithoutId.parent = undefined;
    setData(dataWithoutId);
    return selectedOption;
  };

  const handleCopyrightChange = ([selectedOption]) => {
    return selectedOption;
  };

  const handleSchemaChange = ([selectedOption]) => {
    if (id) {
      const schema = getValues()["schema"];
      if (!isEqual(selectedOption, schema)) {
        setSelectedSchema(selectedOption);
        openModal("schemaChange");
        return schema;
      }
    } else {
      return selectedOption;
    }
  };

  const returnLocation =
    localStorage.backToDirectoriesSearch !== undefined
      ? "/directories/search/"
      : "/directories/";

  const openModal = type => {
    modalType = type;
    if (type === "cancel") {
      messages.bodyContent = `Klikając przycisk anuluj sprawiasz, że dane nie zostaną zapisane. Czy na pewno chcesz anulować ${id ? "edycję" : "dodanie nowego"
        } ${formLabel}?`;
    } else {
      messages.bodyContent =
        "Zmiana schematu spowoduje usunięcie dotychczas dodanych wartości atrybutów. Czy jesteś pewien?";
    }
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const onCancel = () => {
    closeModal();
  };
  const onConfirm = () => {
    if (modalType === "cancel") {
      location.href = returnLocation;
    } else {
      setValue("schema", selectedSchema);
    }
    closeModal();
  };

  useEffect(() => {
    // executed when component mounted
    isMounted.current = true;
    return () => {
      // executed when unmount
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const params = qs.parse(window.location.search);
    if (has(params, "directory_id")) {
      const directoryId = parseInt(params.directory_id);

      if (!isNaN(directoryId)) {
        setValue("parent", directoryId);
        const tempData = Object.assign({}, data);
        axiosSession
          .get(`${directoryUrl}${directoryId}/`)
          .then(directoryData => {
            setDefaultLibrary(directoryData.data.library);
            tempData.library = directoryData.data.library;
            setData(tempData);
          });

        tempData.parent = directoryId;
        setData(tempData);

        setDefaultParentId(directoryId);
        setHierarchicSelectUrl(
          `${directoriesUrl}${defaultLibrary}/hierarchic-select-data/`
        );
      } else {
        notifyError("Nieprawidłowa wartość dla katalogu nadrzędnego!");
      }
    }
  }, [
    defaultLibrary,
    defaultParent,
    setDefaultParentId,
    setValue,
    notifyError,
    setHierarchicSelectUrl
  ]);

  useEffect(() => {
    let errorMessage = setErrorMessage();
    !isEmpty(errors) ? notifyError(errorMessage) : null;
  }, [errors, notifyError, setErrorMessage]);

  useEffect(() => {
    if (isMounted.current) {
      axiosSession
        .get("/api/attributes/schema/select-data/", {
          params: { forinstance: id, isdigitalitem: isDigitalItem }
        })
        .then(response => {
          setSchema(response.data);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [id, isDigitalItem]);

  useEffect(() => {
    if (isMounted.current) {
      axiosSession
        .get("/api/library/select-data/")
        .then(res => {
          setLibraries(res.data);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [defaultParentId]);

  useEffect(() => {
    if (isMounted.current) {
      axiosSession
        .get("/api/digital_items/copyright/select-data/")
        .then(res => {
          setCopyright(res.data);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, []);

  useEffect(() => {
    if (id && isMounted.current) {
      axiosSession
        .get(formUrl)
        .then(({ data }) => {
          let dataWithParentId = Object.assign({}, data);
          if (isDigitalItem) {
            setHierarchicSelectUrl(
              `${directoriesUrl}${data.library_id}/hierarchic-select-data/`
            );
            setDefaultParentId(data.directory);
            dataWithParentId.parent = data.directory;
          } else {
            setHierarchicSelectUrl(
              `${directoriesUrl}${data.library}/hierarchic-select-data/`
            );
            setDefaultParentId(data.parent);
          }
          setData(dataWithParentId);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [formUrl, id, reset]);

  useEffect(() => {
    if (defaultParentId) {
      axiosSession
        .get(`${directoryUrl}${defaultParentId}/`)
        .then(({ data }) => {
          setDefaultParent(data);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [defaultParentId]);

  useEffect(() => {
    if (
      !hasReset &&
      !isEmpty(data) &&
      !isEmpty(libraries) &&
      !isEmpty(schema) &&
      ((data.digital_item_type && !isEmpty(copyright)) ||
        !data.digital_item_type) &&
      isMounted.current
    ) {
      const tempData = Object.assign({}, data);
      const dataSchema =
        schema[findIndex(schema, ({ id }) => id === tempData.schema)];
      tempData.schema = dataSchema;
      if (isDigitalItem) {
        tempData.copyright = copyright.find(
          obj => obj.id === tempData.copyright
        );
        tempData.library =
          libraries[
          findIndex(libraries, ({ id }) => id === tempData.library_id)
          ];
      } else {
        const dataLibrary =
          libraries[findIndex(libraries, ({ id }) => id === tempData.library)];
        tempData.library = dataLibrary;
      }
      setDisableParent(false);
      setHasReset(true);
      reset(tempData);
    }
  }, [copyright, data, libraries, reset, schema, hasReset]);

  const setErrorMessage = useCallback(() => {
    if (formType === "digital-item") {
      return "Nie udało się zapisać obiektu";
    }
    if (isGroup) {
      return "Nie udało się zapisać obiektu grupowego";
    } else {
      return "Nie udało się zapisać katalogu.";
    }
  }, [formType, isGroup]);

  const handleDraftSubmit = () => {
    submitType = "draft";
    return true;
  };

  const handleNextSubmit = () => {
    submitType = "next";
    return true;
  };

  const onSubmit = data => {
    let method = "";
    let url = "";
    setIsLoading(true);

    data.library = data.library.id;
    data.schema = data.schema.id;
    if (isDigitalItem) {
      data.copyright = data.copyright.id;
      data.directory = data.parent;
    } else {
      data.is_group = isGroup;
    }

    if (id) {
      method = "PUT";
      url = formUrl;
    } else {
      method = "POST";
      url = isDigitalItem ? `/api/digital_items/` : `/api/directories/`;
    }

    axiosSession({ method: method, url: url, data: data })
      .then(response => {
        if (submitType === "draft") {
          location.href = returnLocation;
        } else {
          nextStepChange(response.data.id);
        }
      })
      .catch(error => {
        if (error.response.data.parent) {
          setError("parent", "", error.response.data.parent);
        }
        if (error.response.data.library) {
          setError("library", "", error.response.data.library);
        }
        if (error.response.data.schema) {
          setError("schema", "", error.response.data.schema);
        }
        if (error.response.data.name) {
          setError("name", "", error.response.data.name);
        }
        if (error.response.data.description) {
          setError("description", "", error.response.data.description);
        }
        if (error.response.data.copyright) {
          setError("copyright", "", error.response.data.copyright);
        }
        if (
          Array.isArray(error.response.data) &&
          error.response.data.length > 0
        ) {
          notifyError(error.response.data[0]);
        }

        const errorMessage = setErrorMessage();
        notifyError(errorMessage);
      })
      .finally(() => {
        if (isMounted.current) {
          setIsLoading(false);
        }
      });
  };

  return (
    <section className="directory-form">
      <GenericModal
        itemData={selectedSchema}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        labels={messages}
        actionSubmit={onConfirm}
        actionCancel={onCancel}
        showItemName={false}
      />
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/directories/"
            title="Lista katalogów"
          >
            Drzewo zasobów
          </a>
          &nbsp;/&nbsp;
          <strong>
            {id ? "Edycja" : "Dodawanie"} {formLabel}
          </strong>
        </div>

        <div className="directory-form__header">
          <h2 className="directory-form__header__title">
            {id ? "Edycja" : "Dodawanie"} {formLabel}
          </h2>
          <hr className="directory-form__header__hr" />
        </div>

        <Stepper activeStep={1} stepLength={stepLength} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="columns is-variable is-8 is-multiline">
            <div className="column is-5">
              <GenericSelect
                options={libraries}
                placeholder="Wybierz bibliotekę"
                control={control}
                required={{ required: "To pole jest wymagane!" }}
                handleSelectChange={handleLibraryChange}
                name="library"
                errors={errors}
                label="Wybierz bibliotekę"
                isRequire
              />
              <NestedSelect
                key={hierarchicSelectUrl}
                name="parent"
                url={hierarchicSelectUrl}
                placeholder="Wybierz"
                label="Nadrzędny"
                required={{ required: "To pole jest wymagane!" }}
                control={control}
                errors={errors}
                darkTheme={false}
                defaultParent={defaultParent}
                disabled={disableParent}
                isRequire
                defaultExpandFirst
              />
              <Input
                name={"name"}
                label={`Nazwa ${formType === "directory" ? "katalogu" : "obiektu"
                  }`}
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
                isRequired
              />

              <GenericSelect
                options={schema}
                placeholder="Wybierz schemat opisowy"
                control={control}
                required={{ required: "To pole jest wymagane!" }}
                name="schema"
                errors={errors}
                handleSelectChange={handleSchemaChange}
                label="Schemat opisowy"
                isRequire
              />

              {isDigitalItem && (
                <>
                  <GenericSelect
                    options={copyright}
                    placeholder="Wybierz prawa autorskie"
                    control={control}
                    required={{ required: "To pole jest wymagane!" }}
                    handleSelectChange={handleCopyrightChange}
                    name="copyright"
                    errors={errors}
                    label="Wybierz prawa autorskie"
                    isRequire
                  />
                </>
              )}

              {!isGroup && !isDigitalItem && (
                <Input
                  tag="textarea"
                  name={"description"}
                  register={register()}
                  label={"Opis"}
                  errors={errors}
                />
              )}
            </div>
            <div className="column is-12">
              <div className="level-right">
                <button
                  onClick={() => openModal("cancel")}
                  className="button"
                  title="Anuluj i wróć do listy"
                  type="button"
                >
                  Anuluj
                </button>
                <DropdownButton
                  mainOption={{
                    type: "submit",
                    title: "Dalej",
                    href: "",
                    onClick: handleNextSubmit
                  }}
                  isLoading={isLoading}
                  options={[
                    {
                      type: "submit",
                      title: "Zapisz szkic",
                      href: "",
                      id: 1,
                      onClick: handleDraftSubmit
                    }
                  ]}
                  onClick
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default GeneralInfoStep;
