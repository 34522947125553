import "@app/scss/variables.scss";

import React, { useEffect, useState } from "react";

import Preview from "@app/components/Preview/Preview";
import axiosSession from "@app/config/axiosSession";

const UsersPreview = ({ id, type }) => {
  const [rows, setRows] = useState(null);
  const [canEdit, setCanEdit] = useState(false);

  const urls = {
    admin: `/api/users/global_admins/${id}/`,
    localAdmin: `/api/users/admins/${id}/`,
    worker: `/api/users/workers/${id}/`
  };

  const texts = {
    admin: {
      goback: "administratorów",
      link: "/users/global_admins/",
      edit: `/users/global_admins/${id}/edit/`,
      breadcrumb: "Administratorzy globalni",
      preview: "administratora"
    },
    localAdmin: {
      goback: "administratorów",
      link: "/users/admins/",
      edit: `/users/admins/${id}/edit/`,
      breadcrumb: "Administratorzy lokalni",
      preview: "administratora"
    },
    worker: {
      goback: "redaktorów",
      link: "/users/workers/",
      edit: `/users/worker/${id}/edit/`,
      breadcrumb: "Użytkownicy wewnętrzni",
      preview: "pracownika biblioteki"
    }
  };

  useEffect(() => {
    if (id) {
      axiosSession
        .get(urls[type])
        .then(({ data }) => {
          setCanEdit(data.can_edit_permission);
          setRows([
            {
              title: "Nazwa użytkownika (login)",
              value: type == "admin" ? [data.username] : [data.user.username]
            },
            {
              title: "Imię",
              value:
                type == "admin" ? [data.first_name] : [data.user.first_name]
            },
            {
              title: "Nazwisko",
              value: type == "admin" ? [data.last_name] : [data.user.last_name]
            },
            {
              title: "Adres e-mail",
              value: type == "admin" ? [data.email] : [data.user.email]
            },
            {
              title: "Biblioteka",
              value: data.library ? [data.library.name] : []
            },
            {
              title: "Grupy uprawnień",
              value:
                type != "admin" ? data.user.groups.map(elem => elem.name) : []
            }
          ]);
        })
        .catch(err => console.error(err));
    }
  }, [type, id]);

  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href={texts[type].link}
            title={texts[type].breadcrumb}
          >
            {texts[type].breadcrumb}
          </a>
          &nbsp;/&nbsp;
          <strong>Podgląd</strong>
        </div>

        <div className="mbc-backoffice__header">
          <h2 className="mbc-backoffice__header__title">
            Podgląd {texts[type].preview}
          </h2>
          <hr className="mbc-backoffice__header__hr" />
        </div>

        {rows && <Preview rows={rows} />}

        <div className="mbc-backoffice__bottom">
          <a
            className="mbc-backoffice__goback is-uppercase has-text-weight-bold"
            href={texts[type].link}
          >
            &lt; Wróć do listy {texts[type].goback}
          </a>

          {canEdit &&
          <a
            className="mbc-backoffice__goback is-uppercase has-text-weight-bold"
            href={texts[type].edit}
          >
            Edytuj
          </a>}
        </div>
      </div>
    </section>
  );
};

export default UsersPreview;
