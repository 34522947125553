import "./ObjectImport.scss";

import React, { useState } from "react";
import {
  faBan,
  faCheckCircle,
  faEye,
  faPause,
  faPlay,
  faRedo,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import {
  faMinusSquare,
  faPlusSquare
} from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericDatePicker from "@app/components/GenericDatePicker/GenericDatePicker";
import GenericInput from "@app/components/Input/GenericInput";
import GenericTable from "@app/components/Table/GenericTable";
import Modal from "react-responsive-modal";
import axiosSession from "@app/config/axiosSession";
import { isFuture } from "date-fns";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

const ObjectImport = () => {
  const { handleSubmit, control, errors } = useForm();
  const { notifySuccess, notifyError } = useGenericToastify();
  const [previewModalIsOpen, setPreviewModalIsOpen] = useState(false);
  const [importModalIsOpen, setImportModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [expandedRowData, setExpandedRowData] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  const [shouldTableRefresh, setShouldTableRefresh] = useState(false);

  const urls = {
    get: "/api/archives/processes/?only_root=true",
    getChildren: "/api/archives/processes/",
    import: "/api/archives/import_last/"
  };

  const messages = {
    btnAdd: "Importuj obiekty"
  };

  const maxDate = new Date();
  const toDate = new Date();

  const tableStructure = [
    {
      header: "Od",
      accessor: "from_date",
      className: "w-2"
    },
    {
      header: "Do",
      accessor: "to_date",
      className: "w-2"
    },
    {
      header: "Importowano",
      accessor: "created",
      className: "w-2",
      sort: true
    },
    {
      header: "Status",
      accessor: "tree_status",
      className: "w-2",
      sort: true
    },
    {
      header: "Proces",
      className: "w-4"
    },
    {
      header: "Akcje",
      className: "w-2"
    }
  ];

  const statusIcon = (status, title, tile) => {
    return (
      <div
        className={
          tile ? "object-import__status-tile__icon" : "object-import__icon"
        }
      >
        {status == "COMPLETED" && (
          <div
            title={title}
            className={
              tile
                ? "object-import__status-tile__icon--is-green"
                : "object-import__icon--is-green"
            }
          >
            <FontAwesomeIcon icon={faCheckCircle} />
          </div>
        )}
        {status == "FAILED" && (
          <div
            title={title}
            className={
              tile
                ? "object-import__status-tile__icon--is-red"
                : "object-import__icon--is-red"
            }
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </div>
        )}
        {status == "IN_PROGRESS" && (
          <div
            title={title}
            className={
              tile
                ? "object-import__status-tile__icon--is-grey"
                : "object-import__icon--is-grey"
            }
          >
            <FontAwesomeIcon icon={faRedo} />
          </div>
        )}
        {status == "CANCELLED" && (
          <div
            title={title}
            className={
              tile
                ? "object-import__status-tile__icon--is-red"
                : "object-import__icon--is-red"
            }
          >
            <FontAwesomeIcon icon={faBan} />
          </div>
        )}
      </div>
    );
  };

  const statusTile = item => {
    return (
      <div className="object-import__status-tile">
        {statusIcon(item.status, item.name_display, true)}
        <div className="object-import__status-tile__body">
          <span className="object-import__status-tile__status">
            {"Status: " + item.status_display}
          </span>
          <span className="object-import__status-tile__description">
            {item.name_display}
          </span>
        </div>
      </div>
    );
  };

  const expandRow = (row, index) => {
    if (row.expanded) {
      setExpandedRowData({
        index: index,
        expanded: false,
        rows: []
      });
    } else {
      let ancestors = row.ancestors ? row.ancestors : [];
      row.ancestors = ancestors;

      axiosSession.get(`${urls.getChildren}${row.id}/`).then(res => {
        let ancestor = row.id;
        let data = res.data.children.map(di => ({
          ...di,
          ancestors: ancestors + ancestor
        }));
        setExpandedRowData({
          index: index,
          expanded: true,
          rows: data
        });
      });
    }
  };

  const importButtonOnClick = () => {
    setModalTitle("Importuj obiekty");
    setImportModalIsOpen(true);
  };

  const onImport = data => {
    const datetime = new Date(data.date);
    const datetimeTo = new Date(data.dateTo);
    const time = data.time.split(":");
    const timeTo = data.timeTo.split(":");

    datetime.setHours(time[0]);
    datetime.setMinutes(time[1]);
    datetimeTo.setHours(timeTo[0]);
    datetimeTo.setMinutes(timeTo[1]);

    const convertedDate = new Date(datetime)
    const convertedDateTo = new Date(datetimeTo)

    if (isFuture(datetime) || isFuture(datetimeTo))
      return notifyError("Wybrano nieprawidłową datę");
    if (datetime > datetimeTo)
      return notifyError(
        "Wybrano nieprawidłowy zakres dat. 'Data od' nie może być późniejsza niż 'data do'"
      );

    const url = `${urls.import}?max_date=${
      convertedDate.toISOString().split(".")[0]
    }&to_date=${convertedDateTo.toISOString().split(".")[0]}`;

    axiosSession
      .get(url)
      .then(res => {
        notifySuccess(res.data.message);
        setShouldTableRefresh(true);
      })
      .catch(err => {
        if (err.response.data.detail) {
          notifyError(err.response.data.detail);
        }
        console.error(err);
      })
      .finally(() => setShouldTableRefresh(false));

    setImportModalIsOpen(false);
  };

  const onPreview = item => {
    setModalTitle(item.path);
    setPreviewData(item.children);
    setPreviewModalIsOpen(true);
  };

  const onRestart = item => {
    axiosSession
      .post(`${urls.getChildren}${item.id}/status/`, {
        status: "RESTART"
      })
      .then(() => setShouldTableRefresh(true))
      .catch(err => console.error(err))
      .finally(() => setShouldTableRefresh(false));
  };

  const onPause = item => {
    axiosSession
      .post(`${urls.getChildren}${item.id}/status/`, {
        status: "PAUSE"
      })
      .then(() => setShouldTableRefresh(true))
      .catch(err => console.error(err))
      .finally(() => setShouldTableRefresh(false));
  };

  const actions = item => {
    return (
      <td className="has-text-right">
        {item.level == 1 && (
          <>
            <span
              onClick={() => onPreview(item)}
              onKeyPress={() => onPreview(item)}
              role="button"
              tabIndex="0"
              className="generic-table__table__link"
              title="Zobacz proces"
            >
              <FontAwesomeIcon icon={faEye} />
            </span>

            {["NEW", "FAILED", "CANCELLED"].includes(item.status) && (
              <span
                onClick={() => onRestart(item)}
                onKeyPress={() => onRestart(item)}
                role="button"
                tabIndex="0"
                className="generic-table__table__link"
                title="Wznów"
              >
                <FontAwesomeIcon icon={faPlay} />
              </span>
            )}
            {["NEW", "IN_PROGRESS"].includes(item.status) && (
              <span
                onClick={() => onPause(item)}
                onKeyPress={() => onPause(item)}
                role="button"
                tabIndex="0"
                className="generic-table__table__link"
                title="Zatrzymaj"
              >
                <FontAwesomeIcon icon={faPause} />
              </span>
            )}
          </>
        )}
      </td>
    );
  };

  const addZero = i => {
    if (i < 10) i = "0" + i;
    return i;
  };

  const customRow = (item, index) => {
    const isExpandable = item.has_children && item.level == 0;
    const title = item.level == 0 ? item.import_from : item.path;

    return (
      <tr id={`row-${item.id}`} key={item.id}>
        <td className={isExpandable ? "has-children" : ""}>
          {isExpandable && (
            <span
              className={`generic-table__table__link ${
                item.level ? `indent-with-extend-${item.level}` : ""
              }`}
              onClick={() => expandRow(item, index)}
              onKeyPress={() => expandRow(item, index)}
              title="Rozwiń wiersz"
              role="button"
              tabIndex="0"
            >
              <FontAwesomeIcon
                icon={item.expanded ? faMinusSquare : faPlusSquare}
              />
            </span>
          )}
          <span title={title}>{title}</span>
        </td>
        <td>{item.import_to}</td>
        <td>{item.level == 0 ? item.created : ""}</td>
        <td>{item.tree_status_display}</td>
        <td>
          {item.level == 1 && (
            <div className="object-import__icon__row">
              {item.children.map((elem, idx) => (
                <React.Fragment key={`${item.id}-${idx}`}>
                  {statusIcon(elem.status, elem.name_display, false)}
                </React.Fragment>
              ))}
            </div>
          )}
        </td>
        {actions(item)}
      </tr>
    );
  };

  return (
    <div className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Import z archiwum</strong>
        </div>

        <Modal
          open={importModalIsOpen}
          onClose={() => setImportModalIsOpen(false)}
          classNames={{ modal: "GenericModal" }}
        >
          <div className="GenericModal__Header--with-border">
            <p className="GenericModal__Header__Title">{modalTitle}</p>
          </div>
          <div className="GenericModal__Body">
            <div>
              <form onSubmit={handleSubmit(onImport)}>
                <GenericDatePicker
                  name="date"
                  label="Rozpocznij import od"
                  errors={errors}
                  control={control}
                  required={{ required: "To pole jest wymagane!" }}
                  defaultValue={maxDate}
                  maxDate={maxDate}
                />

                <GenericInput
                  name="time"
                  label="Godzina od"
                  type="time"
                  errors={errors}
                  control={control}
                  required={{ required: "To pole jest wymagane!" }}
                  defaultValue={`${addZero(new Date().getHours())}:${addZero(
                    new Date().getMinutes()
                  )}`}
                />

                <GenericDatePicker
                  name="dateTo"
                  label="Rozpocznij import do"
                  errors={errors}
                  control={control}
                  defaultValue={toDate}
                  toDate={toDate}
                />

                <GenericInput
                  name="timeTo"
                  label="Godzina do"
                  type="time"
                  errors={errors}
                  control={control}
                  defaultValue={`${addZero(new Date().getHours())}:${addZero(
                    new Date().getMinutes()
                  )}`}
                />

                <div className="level-right">
                  <button className="button button--is-orange" type="submit">
                    Importuj
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>

        <Modal
          open={previewModalIsOpen}
          onClose={() => setPreviewModalIsOpen(false)}
          classNames={{ modal: "GenericModal object-import__modal" }}
        >
          <div className="GenericModal__Header--with-border">
            <p className="GenericModal__Header__Title object-import__import-title">
              {modalTitle}
            </p>
          </div>
          <div className="GenericModal__Body">
            <div className="object-import__status-tile__container">
              {previewData.map((elem, index) => (
                <React.Fragment key={index}>{statusTile(elem)}</React.Fragment>
              ))}
            </div>
          </div>
        </Modal>

        <GenericTable
          expandedRowData={expandedRowData}
          customRowWithActions={customRow}
          structure={tableStructure}
          urls={urls}
          messages={messages}
          buttonOnClick={importButtonOnClick}
          hasRefreshAction={true}
          shouldRefresh={shouldTableRefresh}
          itemsPerPage={[5]}
        />
      </div>
    </div>
  );
};

export default ObjectImport;
