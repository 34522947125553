import React, { useCallback } from "react";
import { CheckCircleOutlined, CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import "../../../../scss/GenericToastify.scss";
import {  toast } from "react-toastify";

const CloseButton = () => (
  <CloseOutlined
    className="mbc_toastify__body__close_button"
  ></CloseOutlined>
);

toast.configure({
  className: "mbc_toastify",
  bodyClassName: "mbc_toastify__body",
  progressClassName: "mbc_toastify__progress",
  autoClose: 10000,
  draggable: false,
  position: "top-right",
  hideProgressBar: false,
  closeOnClick: true,
  closeButton: <CloseButton />,
  pauseOnHover: true
});

const notifyError = message => {
  if (message) {
    toast.error( <p><CloseCircleOutlined className="mbc_toastify_icons__error" /> {message}</p>, { toastId: "TOAST-ERROR" });
  }
};
const notifySuccess = message => {
  if (message) {
    toast.success( <p><CheckCircleOutlined className="mbc_toastify_icons__success" /> {message}</p>, { toastId: "TOAST-SUCCESS" });
  }
};

export {notifyError, notifySuccess};
