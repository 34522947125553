import './ObjectImport.scss';

import React, { useState } from 'react';
import {
  faCheckCircle,
  faRedo,
  faTimesCircle,
  faSpinner,
  faAdjust,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GenericTable from '@app/components/Table/GenericTable';
import axiosSession from '@app/config/axiosSession';
import useGenericToastify from '@app/hooks/useGenericToastify';

const ObjectImport_v2 = () => {
  const { notifySuccess, notifyError } = useGenericToastify();
  const [shouldTableRefresh, setShouldTableRefresh] = useState(false);

  const urls = {
    get: '/api/archives/mbc-import-logs/',
    restartImport: '/api/archives/mbc-retry-import/',
    import: '/api/archives/mbc-import/',
  };

  const messages = {
    btnAdd: 'Importuj',
  };

  const tableStructure = [
    {
      header: 'Identyfikator RODA',
      accessor: 'aip_id',
      className: 'w-4',
    },
    {
      header: 'Informacja szczegółowa',
      accessor: 'message',
      className: 'w-4',
    },
    {
      header: 'Status',
      accessor: 'status',
      className: 'w-3',
      sort: true,
    },
    {
      header: 'Akcje',
      className: 'w-1',
    },
  ];

  const statusIcon = (status, title, tile) => {
    return (
      <div className={'object-import__icon'}>
        {status == 'succcess' && (
          <div title={title} className={'object-import__icon--is-green'}>
            <FontAwesomeIcon icon={faCheckCircle} />
          </div>
        )}
        {status == 'error' && (
          <div title={title} className={'object-import__icon--is-red'}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </div>
        )}
        {status == 'in_progres' && (
          <div title={title} className={'object-import__icon--is-green'}>
            <FontAwesomeIcon icon={faSpinner} />
          </div>
        )}
        {status == 'partially_imported' && (
          <div title={title} className={'object-import__icon--is-orange'}>
            <FontAwesomeIcon icon={faAdjust} />
          </div>
        )}
        {status == 'warning' && (
          <div title={title} className={'object-import__icon--is-orange'}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </div>
        )}
        <span className='object-import__status-tile__status'>{title}</span>
      </div>
    );
  };

  const importButtonOnClick = () => {
    axiosSession
      .get(urls.import)
      .then((res) => {
        notifySuccess(res.data.message);
        setShouldTableRefresh(true);
      })
      .catch((err) => {
        if (err.response.data.detail) {
          notifyError(err.response.data.detail);
        }
        console.error(err);
      })
      .finally(() => setShouldTableRefresh(false));
  };

  const onRetry = (item) => {
    axiosSession
      .get(`${urls.restartImport}${item.aip_id}/`)
      .then((res) => {
        notifySuccess(res.data.message);
        setShouldTableRefresh(true);
      })
      .catch((err) => console.error(err))
      .finally(() => setShouldTableRefresh(false));
  };

  const actions = (item) => {
    return (
      <td className='has-text-right'>
        <>
          {['error', 'partially_imported'].includes(item.status) && (
            <span
              onClick={() => onRetry(item)}
              onKeyPress={() => onRetry(item)}
              role='button'
              tabIndex='0'
              className='generic-table__table__link'
              title='Ponów import'
            >
              <FontAwesomeIcon icon={faRedo} />
            </span>
          )}
        </>
      </td>
    );
  };

  const customRow = (item) => {
    return (
      <tr id={`row-${item.id}`} key={item.id}>
        <td>{item.aip_id}</td>
        <td>{item.message}</td>
        <td>{statusIcon(item.status, item.status_display, false)}</td>
        {actions(item)}
      </tr>
    );
  };

  return (
    <div className='mbc-backoffice'>
      <div className='container'>
        <div className='breadcrumbs'>
          <a
            href='/admin-panel/home/'
            className='breadcrumbs__link'
            title='Strona główna'
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Import z archiwum</strong>
        </div>

        <GenericTable
          customRowWithActions={customRow}
          structure={tableStructure}
          urls={urls}
          messages={messages}
          buttonOnClick={importButtonOnClick}
          hasRefreshAction={true}
          shouldRefresh={shouldTableRefresh}
          itemsPerPage={[5]}
          hasSearch={false}
          headerActionsAlignRight={true}
        />
      </div>
    </div>
  );
};

export default ObjectImport_v2;
