import "./Directories.scss";
import "antd/dist/antd.css";

import { CopyOutlined, FolderOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import {
  faCopy,
  faDownload,
  faEye,
  faFile,
  faFolder,
  faLock,
  faPen,
  faPlus,
  faShareSquare,
  faTrash
} from "@fortawesome/free-solid-svg-icons";

import ClipLoader from "react-spinners/ClipLoader";
import Cookie from "js-cookie";
import DropdownButton from "../../components/DropdownButton/DropdownButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericModal from "../../components/Modal/GenericModal";
import Modal from "react-responsive-modal";
import { Tree } from "antd";
import TruncateMarkup from "react-truncate-markup";
import axiosSession from "../../config/axiosSession";
import isEmpty from "lodash/isEmpty";
import useGenericToastify from "../../hooks/useGenericToastify";

const { DirectoryTree } = Tree;

const Directories = ({ message, hasCreatePermission, hasSipPermission }) => {
  const [messages, setMessages] = useState({});

  const deleteMessages = {
    headerTitle: "Potwierdzenie usunięcia elementu",
    bodyContent: "Czy na pewno chcesz usunąć element?",
    deleteSuccess: "Element został poprawnie usunięty",
    deleteError: "Wystąpił problem podczas usuwania elementu",
    btnAdd: "Dodaj",
    btnDeleteNo: "Anuluj",
    btnDeleteYes: "Usuń"
  };

  const archiveMessages = {
    headerTitle: "Potwierdzenie zmiany statusu",
    bodyContent:
      "Uwaga, spowoduje to zmianę statusu na archiwalny, czy na pewno chcesz kontynuować?",
    deleteSuccess: "Element został poprawnie zarchiwizowany",
    deleteError: "Wystąpił problem podczas archiwizowania elementu",
    btnAdd: "Dodaj",
    btnDeleteNo: "Nie, nie archiwizuj",
    btnDeleteYes: "Tak, archiwizuj"
  };

  const publishMessages = {
    headerTitle: "Potwierdzenie zmiany statusu",
    bodyContent:
      "Uwaga, spowoduje to zmianę statusu na opublikowany, czy na pewno chcesz kontynuować?",
    deleteSuccess: "Element został poprawnie opublikowany",
    deleteError: "Wystąpił problem podczas publikowania elementu",
    btnAdd: "Dodaj",
    btnDeleteNo: "Nie, nie publikuj",
    btnDeleteYes: "Tak, opublikuj"
  };

  const urls = {
    get: "/api/directories/",
    add: "/directories/", //appends /${type}/create/
    edit: "/directories/", //appends /${id}/edit
    delete: "/api/directories/directory/",
    deleteItem: "/api/digital_items/", //appends /${id}/
    downloadSip: "/api/archives/request_sip/"
  };

  const statuses = {
    published: "published",
    draft: "draft",
    archived: "archived"
  };

  const directoryCreate = "directory-create";
  const groupObjectCreate = "group-object-create";
  const digitalItemCreate = "digital-item-create";

  const dropdownOptions = {
    mainOption: {
      title: "Dodaj obiekt",
      href: `${urls.add}${digitalItemCreate}/`
    },
    options: [
      {
        id: 1,
        title: "Dodaj obiekt grupowy",
        href: `${urls.add}${groupObjectCreate}/`
      },
      {
        id: 2,
        title: "Dodaj katalog",
        href: `${urls.add}${directoryCreate}/`
      }
    ]
  };

  const getTreeUrl = "/api/directories/tree/";

  const [empty, setEmpty] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalAction, setModalAction] = useState();
  const { notifySuccess, notifyError } = useGenericToastify();
  const [parent, setParent] = useState([]);
  const [data, setData] = useState([]);
  const [details, setDetails] = useState([]);
  // TODO: czy my używamy tego update'a? dodatkowo zmiana, aby naprawić deploya
  const [update, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [deleteItemData, setDeleteItemData] = useState({});
  const [menuVisible, setMenuVisible] = useState(null);
  const [shouldTableRefresh, setShouldTableRefresh] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [modalPublish, setModalPublish] = useState(false);

  const onDelete = item => {
    setDeleteItemData({ id: item.id, name: item.name });
    setMessages({
      ...deleteMessages,
      bodyContent: item.has_children
        ? "Uwaga, ten katalog ma przypisane obiekty, czy na pewno chcesz go usunąć?"
        : "Czy na pewno chcesz usunąć element?"
    });
    setModalAction({
      type: "delete",
      id: item.id,
      is_group: item.is_group,
      is_directory: item.is_directory
    });
    openModal();
  };

  const onPublish = item => {
    setDeleteItemData({ id: item.id, name: item.name });
    setMessages(publishMessages);
    setModalAction({ type: "publish", id: item.id, is_group: item.is_group });
    openModal();
  };

  const onArchive = item => {
    setDeleteItemData({ id: item.id, name: item.name });
    setMessages(archiveMessages);
    setModalAction({ type: "archive", id: item.id, is_group: item.is_group });
    openModal();
  };

  const onConfirmPublish = (id, is_group) =>
    onSetStatus(statuses.published, id, is_group);

  const onConfirmArchive = (id, is_group) =>
    onSetStatus(statuses.archived, id, is_group);

  const onConfirmDelete = (id, is_directory) => {
    axiosSession
      .delete(`${is_directory ? urls.delete : urls.deleteItem}${id}/`)
      .then(() => {
        notifySuccess(messages.deleteSuccess);
        setUpdate(!update);
        setShouldTableRefresh(true);
        Cookie.set("directory-tree", []);
        downloadData();
      })
      .catch(error => {
        if (error.response.data.detail) {
          notifyError(error.response.data.detail);
        } else {
          notifyError(messages.deleteError);
        }
        console.error(error);
      })
      .finally(() => {
        closeModal();
        setShouldTableRefresh(false);
      });
  };

  const onSetStatus = (status, id, is_group) => {
    let patch_url = is_group
      ? `${urls.get}${id}/status/`
      : `/api/digital_items/${id}/status/`;
    axiosSession
      .patch(patch_url, {
        status: status
      })
      .then(() => {
        notifySuccess(messages.deleteSuccess);
        setUpdate(!update);
        fetchDirectories();
      })
      .catch(error => {
        if (error.response.data.detail) {
          notifyError(error.response.data.detail);
        } else {
          notifyError(messages.deleteError);
        }
        console.error(error);
      })
      .finally(() => closeModal());
  };

  const downloadData = () => {
    axiosSession
      .get(getTreeUrl)
      .then(({ data }) => {
        if (data.length === 0) {
          setEmpty("Brak danych.");
        }
        const parsedData = data.map((field, index) => ({
          id: field.id,
          key: `${index}`,
          title: field.title,
          isLeaf: field.is_leaf,
          icon: field.is_group ? <CopyOutlined /> : <FolderOutlined />
        }));
        setData(parsedData);
        setExpandedKeys(JSON.parse(Cookie?.get("directory-tree") ?? "[]"));
      })
      .catch(err => {
        setEmpty("Błąd podczas pobierania danych.");
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  const onModalAction = () => {
    switch (modalAction.type) {
      case "delete":
        onConfirmDelete(modalAction.id, modalAction.is_directory);
        setExpandedKeys([]);
        break;
      case "archive":
        onConfirmArchive(modalAction.id, modalAction.is_group);
        break;
      case "publish":
        onConfirmPublish(modalAction.id, modalAction.is_group);
        break;
    }
    downloadData();
  };

  const onDownloadSip = item => {
    axiosSession
      .post(urls.downloadSip, { digital_items: [item.id] })
      .then(() =>
        notifySuccess("Paczka SIP z obiektem zostanie wysłana na e-mail")
      )
      .catch(err => console.error(err));
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const showMenu = item => {
    if (item.id != menuVisible) {
      setMenuVisible(item.id);
    } else {
      setMenuVisible(null);
    }
  };

  const actions = item => {
    const itemType = item.is_group
      ? "group-object"
      : item.is_directory
      ? "directory"
      : "digital-item";
    return item.actions ? (
      <div className="has-text-right" onMouseLeave={() => setMenuVisible(null)}>
        {itemType === "digital-item" &&
          hasSipPermission &&
          item.actions.to_sip && (
            <button
              onClick={() => onDownloadSip(item)}
              className="generic-table__table__link"
              title="Pobierz paczkę SIP"
            >
              <FontAwesomeIcon icon={faDownload} />
            </button>
          )}
        {item.actions.can_publish && (
          <button
            onClick={() => onPublish(item)}
            className="generic-table__table__link"
            title="Opublikuj"
          >
            <FontAwesomeIcon icon={faShareSquare} />
          </button>
        )}
        {item.actions.can_move_to_archive && (
          <button
            onClick={() => onArchive(item)}
            className="generic-table__table__link"
            title="Archiwizuj"
          >
            <FontAwesomeIcon icon={faLock} />
          </button>
        )}
        {item.is_directory && !item.is_group && item.actions.can_view_item && (
          <a
            href={`${urls.edit}${item.id}/directory-preview/`}
            className="generic-table__table__link"
            title="Podgląd"
          >
            <FontAwesomeIcon icon={faEye} />
          </a>
        )}
        {item.is_group && item.actions.can_view_item && (
          <a
            href={`${urls.edit}${item.id}/group-object-preview/`}
            className="generic-table__table__link"
            title="Podgląd"
          >
            <FontAwesomeIcon icon={faEye} />
          </a>
        )}
        {!item.is_directory && (
          <a
            href={`/digital_items/${item.id}/digital-item-preview/`}
            className="generic-table__table__link"
            title="Podgląd"
          >
            <FontAwesomeIcon icon={faEye} />
          </a>
        )}
        {item.actions.editable && (
          <a
            href={`${urls.edit}${item.id}/${itemType}-edit/`}
            className="generic-table__table__link"
            title="Edycja"
          >
            <FontAwesomeIcon icon={faPen} />
          </a>
        )}
        {item.actions.removable && (
          <button
            onClick={() => onDelete(item)}
            className="generic-table__table__link"
            title="Usuń"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        )}
        {hasCreatePermission && item.is_directory && (
          <button
            onMouseUp={() => showMenu(item)}
            onKeyPress={() => showMenu(item)}
            className="generic-table__table__link menu"
            title="Dodaj"
          >
            <FontAwesomeIcon className="menu-icon" icon={faPlus} />
          </button>
        )}
        {item.id == menuVisible && (
          <div>
            <div className="menu-content">
              {item.is_directory && !item.is_group && (
                <div className="menu-item">
                  <a
                    href={`${urls.add}${directoryCreate}/?directory_id=${item.id}`}
                    className="generic-table__table__link"
                    title="Dodaj katalog"
                  >
                    <FontAwesomeIcon className="icon" icon={faFolder} />
                  </a>
                </div>
              )}
              {item.is_directory && (
                <div className="menu-item">
                  <a
                    href={`${urls.add}${groupObjectCreate}/?directory_id=${item.id}`}
                    className="generic-table__table__link"
                    title="Dodaj obiekt grupowy"
                  >
                    <FontAwesomeIcon className="icon" icon={faCopy} />
                  </a>
                </div>
              )}
              {item.is_directory && (
                <div className="menu-item">
                  <a
                    href={`${urls.add}${digitalItemCreate}/?directory_id=${item.id}`}
                    className="generic-table__table__link"
                    title="Dodaj obiekt"
                  >
                    <FontAwesomeIcon className="icon" icon={faFile} />
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    ) : (
      ""
    );
  };

  useEffect(() => {
    if (!isEmpty(message.messages)) {
      const msg = message.messages[message.messages.length - 1];
      if (msg.includes("publikowan")) {
        setModalPublish(msg);
      } else {
        notifySuccess(msg);
      }
    }
  }, [message, notifySuccess]);

  useEffect(() => {
    Cookie.set("directory-tree", []);
    downloadData();
  }, []);

  const getSelectedData = parent => {
    return axiosSession.get(urls.get, { params: { parent } });
  };

  const fetchDirectories = async () => {
    setIsTableLoading(origin => !origin);
    const directories = await axiosSession.all(
      parent.map(id => getSelectedData(id))
    );
    if (directories.length === 0) {
      setEmpty("Brak danych.");
    }
    let detailsList = [];
    for (var directory of directories) {
      detailsList.push(...directory.data);
    }
    setDetails(detailsList);
    setIsTableLoading(origin => !origin);
  };

  useEffect(() => {
    if (parent) {
      setDetails([]);
      fetchDirectories();
    }
  }, [parent]);

  useEffect(() => {
    if (parent && shouldTableRefresh) {
      fetchDirectories();
    }
  }, [parent, shouldTableRefresh]);

  const updateTreeData = (list, key, children) => {
    return list.map(node => {
      if (node.key === key) {
        return { ...node, children: children };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children)
        };
      }
      return node;
    });
  };

  const onLoadData = async ({ id, key }) => {
    await axiosSession
      .get(getTreeUrl, { params: { parent: id } })
      .then(response => {
        const children = response.data.map((field, index) => ({
          id: field.id,
          key: `${key}-${index}`,
          title: field.title,
          isLeaf: field.is_leaf,
          icon: field.is_group ? <CopyOutlined /> : <FolderOutlined />
        }));
        setTimeout(() => {
          setData(origin => updateTreeData(origin, key, children));
        }, 500);
      });
  };

  const onSelect = (keys, event) => {
    setParent(event.selectedNodes.map(selected => selected.id));
  };

  const onCollapse = (_, node) => {
    if (!node.expanded) {
      setExpandedKeys(origin => {
        const filtered = origin.filter(
          x =>
            node.node.key.length > x.key.length ||
            (node.node.key.length <= x.key.length &&
              node.node.key !== x.key.slice(0, node.node.key.length))
        );
        Cookie.set("directory-tree", JSON?.stringify(filtered) ?? "[]");
        return filtered;
      });
    } else {
      setExpandedKeys(origin => {
        const newList = !origin.find(x => x.key === node.node.key)
          ? origin.concat({ id: node.node.id, key: node.node.key })
          : origin;
        Cookie.set("directory-tree", JSON.stringify(newList));
        return newList;
      });
      onLoadData(node.node);
    }
  };

  return (
    <section className="directories">
      <GenericModal
        itemData={deleteItemData}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        labels={messages}
        actionSubmit={onModalAction}
      />
      <Modal
        open={!!modalPublish}
        onClose={() => setModalPublish(false)}
        center
        showCloseIcon
        classNames={{ modal: "GenericModal" }}
      >
        <div className="GenericModal__Body">
          <div className="column">{modalPublish}</div>
        </div>
        <div className="GenericModal__Footer">
          <p className="GenericModal__Footer__Buttons buttons is-right is-fullwidth">
            <button
              className="button button--is-orange GenericModal__Footer__Button"
              onClick={() => setModalPublish(false)}
            >
              Zamknij
            </button>
          </p>
        </div>
      </Modal>
      <div className={"container"}>
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Drzewo zasobów</strong>
        </div>

        <div className="directories__controls">
          <a href="/directories/search/" className="button button--is-black">
            Wyszukiwanie zasobów
          </a>

          {hasCreatePermission && (
            <DropdownButton
              mainOption={dropdownOptions.mainOption}
              isLoading={isLoading}
              options={dropdownOptions.options}
            />
          )}
        </div>

        <div className="columns">
          <div className="directories__global_list column">
            <DirectoryTree
              treeData={data}
              onSelect={onSelect}
              expandedKeys={expandedKeys.map(x => x.key)}
              multiple
              expandAction={false}
              showLine
              autoExpandParent={false}
              onExpand={onCollapse}
            />
          </div>

          <div className="directories__details_list column is-7">
            <table className="table is-fullwidth">
              <thead>
                <tr>
                  <th colSpan={2} className="w-6">
                    Nazwa
                  </th>
                  <th className="w-3">Status</th>
                  <th className="w-3 has-text-right">Akcje</th>
                </tr>
              </thead>
              {isTableLoading ? (
                <tbody>
                  <tr>
                    <td colSpan="4" className="has-text-centered">
                      Pobieranie danych w toku...{" "}
                      <ClipLoader
                        size={15}
                        color={"black"}
                        loading={isTableLoading}
                      />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {details.length > 0 ? (
                    details.map((item, index) => {
                      return (
                        <tr key={`${index}-${details.length}`}>
                          <td className="w-1">
                            {item.is_directory ? (
                              item.is_group ? (
                                <FontAwesomeIcon icon={faCopy} />
                              ) : (
                                <FontAwesomeIcon icon={faFolder} />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faFile} />
                            )}
                          </td>
                          <td>
                            <TruncateMarkup key={index}>
                              <div title={item.name}>{item.name}</div>
                            </TruncateMarkup>
                          </td>
                          <td>
                            {item.is_directory && !item.is_group
                              ? "-"
                              : item.status}
                          </td>
                          <td>{actions(item)}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4" className="has-text-centered">
                        {empty ? empty : "Wybierz katalog"}
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Directories;
