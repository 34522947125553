import "./Sidebar.scss";
import "antd/dist/antd.css";

import { Button, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { faIndent, faOutdent } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "@ant-design/icons";
import useWindowWidth from "@app/hooks/useWindowResize";

const { SubMenu } = Menu;

const Sidebar = ({ location, userMenuPermissions, adminPage }) => {
  const urls = {
    agreements: "/rules/agreements/",
    archives: "/archives/",
    attributes: "/attributes/",
    cms: `/${adminPage}/cms/page/`,
    collections: "/collections/",
    dictionaries: "/dicts/",
    digital_item_types: "/digital_items/digital-item-types/",
    directories: "/directories/",
    directories_search: "/directories/search/",
    external_users: "/users/external-users/",
    global_admins: "/users/global_admins/",
    groups: "/users/groups/",
    libraries: "/library/",
    local_admins: "/users/admins/",
    logs: "/logs/",
    messages: "/messages/",
    newsletter: "/users/newsletter_setup/",
    ocr: "/attributes/ocr-panel/",
    redactors: "/users/workers/",
    reports: "/reports/",
    reported_errors: "/digital_items/reported-errors/",
    schemas: "/attributes/schemas/",
    search_statistics: "/digital_items/search-statistics/",
    statistics: "",
    synonyms: "/dicts/synonyms/",
    terms: "/rules/terms/"
  };

  const submenus = {
    agreements: "regulations",
    archives: "administration",
    attributes: "schemas_management",
    configuration: "administration",
    digital_item_types: "schemas_management",
    directories: "resources_management",
    directories_search: "resources_management",
    external_users: "users",
    global_admins: "users",
    groups: "users",
    integrations: "administration",
    libraries: "administration",
    local_admins: "users",
    logs: "administration",
    messages: "administration",
    reports: "statistics",
    newsletter: "administration",
    redactors: "users",
    reported_errors: "resources_management",
    schemas: "schemas_management",
    search_statistics: "statistics",
    terms: "regulations",
    dictionaries: "dictionaries",
    synonyms: "dictionaries"
  };

  const isMobile = useWindowWidth() < 768;

  const [collapsed, setCollapsed] = useState(window.innerWidth <= 768);
  const [selectedKey] = useState(selectKey(location));
  const [openKeys, setOpenKeys] = useState(
    window.innerWidth > 768 ? selectSubmenu(selectedKey) : []
  );
  const [generatedMenu, setGeneratedMenu] = useState([]);

  const rootSubmenuKeys = [
    "resources_management",
    "schemas_management",
    "users",
    "regulations",
    "administration",
    "statistics",
    "dictionaries"
  ];

  function selectKey(value) {
    return [Object.keys(urls).find(key => urls[key] === value)];
  }

  function selectSubmenu(key) {
    return [submenus[key]];
  }

  const toggleCollapsed = () => {
    if (!collapsed) {
      setOpenKeys([]);
    } else {
      setOpenKeys(selectedKey);
    }
    setCollapsed(!collapsed);
  };

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const getIcon = (icon = "question") => {
    return <Icon component={() => <FontAwesomeIcon icon={icon} />} />;
  };

  const onClick = item => {
    window.location = urls[item.key];
  };

  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const toggleMobileMenu = () => setIsMobileMenuVisible(!isMobileMenuVisible);

  const localStorageLocation = location => {
    if (location === "search") {
      !localStorage.backToDirectoriesSearch &&
        localStorage.setItem("backToDirectoriesSearch", "/directories/search/");
    } else {
      localStorage.backToDirectoriesSearch &&
        localStorage.removeItem("backToDirectoriesSearch");
    }
  };

  useEffect(() => {
    const menu = [];

    userMenuPermissions.forEach(item => {
      if (item.children.length === 0) {
        menu.push(
          <Menu.Item
            className="has-text-white"
            key={item.key}
            icon={getIcon(item.icon)}
          >
            <a href={item.url} className="has-text-white" title={item.label}>
              {item.label}
            </a>
          </Menu.Item>
        );
      } else {
        menu.push(
          <SubMenu
            className="has-text-white"
            key={item.key}
            icon={getIcon(item.icon)}
            title={item.label}
          >
            <Menu.Divider />
            {item.children.map(child_item => (
              <React.Fragment key={child_item}>
                <Menu.Item
                  className="has-text-white"
                  key={child_item.key}
                  onClick={() => localStorageLocation()}
                >
                  <a
                    href={child_item.url}
                    className="has-text-white"
                    title={child_item.label}
                  >
                    &middot; {child_item.label}
                  </a>
                </Menu.Item>
                <Menu.Divider />
              </React.Fragment>
            ))}
          </SubMenu>
        );
      }
      menu.push(<Menu.Divider key={`divider-${item.key}`} />);
    });

    setGeneratedMenu(menu);
  }, []);

  return (
    <div
      className={
        isMobile
          ? `sidebar-mobile ${isMobileMenuVisible ? "sidebar-mobile-dark" : ""}`
          : "sidebar__container"
      }
    >
      {isMobile && (
        <div
          className="sidebar-mobile__btn"
          onClick={toggleMobileMenu}
          onKeyPress={toggleMobileMenu}
          role="button"
          tabIndex="0"
        >
          <FontAwesomeIcon icon={isMobileMenuVisible ? faIndent : faOutdent} />
        </div>
      )}
      {(!isMobile || (isMobile && isMobileMenuVisible)) && (
        <Menu
          mode="inline"
          inlineCollapsed={isMobile ? false : collapsed}
          theme="dark"
          className={`sidebar ${
            isMobile ? "sidebar-mobile" : ""
          } has-text-white`}
          onClick={onClick}
          defaultSelectedKeys={selectedKey}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        >
          <Menu.Divider />
          <Menu.ItemGroup
            style={isMobile ? { padding: 0 } : undefined}
            key={isMobile ? undefined : "g1"}
            title={
              isMobile ? (
                undefined
              ) : (
                <Button
                  type="primary"
                  onClick={toggleCollapsed}
                  style={{ marginBottom: 16 }}
                >
                  {collapsed ? (
                    <FontAwesomeIcon icon={faIndent} />
                  ) : (
                    <FontAwesomeIcon icon={faOutdent} />
                  )}
                </Button>
              )
            }
          >
            <Menu.Divider />
            {generatedMenu}
          </Menu.ItemGroup>
        </Menu>
      )}
    </div>
  );
};

export default Sidebar;
