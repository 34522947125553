import "./LibraryForm.scss";

import React, { useEffect, useState } from "react";

import CancelButton from "@components/CancelButton/CancelButton";
import CheckboxColumn from "@app/components/CheckboxColumn/CheckboxColumn";
import GenericDoubleInputFieldArray from "@app/components/GenericFieldArray/GenericDoubleInputFieldArray";
import GenericSelect from "@app/components/Select/GenericSelect";
import Input from "@app/components/Input/Input";
import axiosSession from "@app/config/axiosSession";
import find from "lodash/find";
import handleErrors from "@app/helpers/handleErrors";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import useGenericToastify from "@app/hooks/useGenericToastify";

const LibraryForm = ({ id }) => {
  const { handleSubmit, register, errors, control, setError, reset } = useForm({
    mode: "onChange"
  });
  const { notifyError } = useGenericToastify();
  const [isLoading, setIsLoading] = useState(false);
  const [removeAllArray, setRemoveAllArray] = useState(false);
  const [statusItems] = useState([
    { value: false, name: "aktywny" },
    { value: true, name: "zablokowany" }
  ]);
  const [colorSchema, setColorSchema] = useState([]);
  const [selectedColorSchema, setSelectedColorSchema] = useState({});
  const [languages, setLanguages] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [showMap, setShowMap] = useState(false)

  const [showMapOptions] = useState([
    { id: true, name: "Tak" },
    { id: false, name: "Nie" },
  ])

  const urls = {
    library: "/api/library/",
    libraryId: `/api/library/${id}/`,
    cancel: "/cancel?url=/library/",
    languages: "/api/dicts/languages/select-data/",
    colorSchema: "/api/library/color-schema/select-data/"
  };

  // regex for ip v4 xor ip v6
  const ipRegex = /(^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$)|(^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$)/;
  const maskRegex = /^\/([1-9]|[1-9][0-9]|1[01][0-9]|12[0-8])$/;

  useEffect(() => {
    axiosSession
      .get(urls.colorSchema)
      .then(({ data }) => {
        setColorSchema(data);
      })
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    if (id && !isEmpty(languages)) {
      axiosSession
        .get(urls.libraryId)
        .then(({ data }) => {
          const color_schema_value = find(colorSchema, [
            "id",
            data.color_schema
          ]);

          setSelectedColorSchema(color_schema_value);

          const is_blocked_value = find(statusItems, [
            "value",
            data.is_blocked
          ]);

          const show_map_value = find(showMapOptions, [
            "id",
            data.show_map
          ]);

          reset({
            ...data,
            is_blocked: is_blocked_value,
            color_schema: color_schema_value,
            show_map: show_map_value
          });

          setLanguages(
            languages.map(elem => {
              const lang = data.languages.find(x => x.id === elem.permId);
              if (lang != undefined && elem.permId == lang.id) {
                return { ...elem, isChecked: true };
              } else {
                return elem;
              }
            })
          );
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [id, reset, statusItems, shouldFetch]);

  useEffect(() => {
    if (id) {
      axiosSession
        .get(urls.languages)
        .then(({ data }) => {
          setLanguages(
            data.results.map(elem => ({
              permId: elem.id,
              name: elem.name,
              isChecked: false
            }))
          );
          setShouldFetch(true);
        })
        .catch(err => console.error(err))
        .finally(() => setShouldFetch(false));
    }
  }, [id]);

  useEffect(() => {
    if (!isEmpty(errors)) {
      notifyError("Nie udało się zapisać biblioteki.");
    }
  }, [errors, notifyError]);

  const handleSchemaChange = ([selectedOption]) => {
    setSelectedColorSchema(selectedOption);
    return selectedOption;
  };

  const handleMapChange = ([selectedOption]) => {
    setShowMap(selectedOption.id)
    return selectedOption
  }

  const onSubmit = data => {
    setIsLoading(true);
    let method;
    let url;

    if (id) {
      method = "PUT";
      url = urls.libraryId;

      data.is_blocked = data.is_blocked.value;
      data.languages = languages
        .filter(elem => elem.isChecked == true)
        .map(elem => elem.permId);

      if (isEmpty(data.languages)) {
        notifyError("Zaznacz przynajmniej jeden język");
        setIsLoading(false);
        return;
      }

      data.login_ip_addresses = data.login_ip_addresses || [];
      data.objects_ip_addresses = data.objects_ip_addresses || [];
    } else {
      method = "POST";
      url = urls.library;
    }

    data.color_schema = data.color_schema.id;
    data.show_map = showMap

    axiosSession({ method: method, url: url, data: data })
      .then(response => {
        location.href = response.data.url;
      })
      .catch(error => {
        notifyError("Nie udało się zapisać biblioteki.");
        let data = error.response.data;

        if ("login_ip_addresses" in data) {
          data.login_ip_addresses.forEach((elem, idx) => {
            if (!isEmpty(elem)) {
              let property = "address" in elem ? "address" : "mask";
              setError(
                `login_ip_addresses[${idx}].${property}`,
                "",
                elem[property]
              );
            }
          });

          delete data.login_ip_addresses;
        }

        if ("objects_ip_addresses" in data) {
          data.objects_ip_addresses.forEach((elem, idx) => {
            if (!isEmpty(elem)) {
              let property = "address" in elem ? "address" : "mask";
              setError(
                `objects_ip_addresses[${idx}].${property}`,
                "",
                elem[property]
              );
            }
          });

          delete data.objects_ip_addresses;
        }

        handleErrors(data, setError);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section className="library-form">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <a
            className="breadcrumbs__link"
            href="/library/"
            title="Lista bibliotek"
          >
            Biblioteki
          </a>
          &nbsp;/&nbsp;
          <strong>{id ? "Edytuj" : "Dodaj"} bibliotekę</strong>
        </div>

        <div className="library-form__header">
          <h2 className="library-form__header__title">
            {id ? "Edycja biblioteki" : "Dodawanie biblioteki"}
          </h2>
          <hr className="library-form__header__hr" />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="columns is-variable">
            <div className="column is-5">
              <Input
                name="full_name"
                label="Pełna nazwa"
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
              />
              <Input
                name="name"
                label="Nazwa (skrót)"
                disabled={id ? true : false}
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
              />
              <Input
                name="domain"
                label="Domena"
                register={register({
                  required: "To pole jest wymagane!"
                })}
                errors={errors}
              />
              <GenericSelect
                options={colorSchema}
                placeholder="Wybierz kolorystykę biblioteki"
                control={control}
                required={{ required: "To pole jest wymagane!" }}
                name="color_schema"
                errors={errors}
                label="Schemat kolorów"
                handleSelectChange={handleSchemaChange}
              />

              {!isEmpty(selectedColorSchema) ? (
                <div>
                  <div
                    style={{
                      width: "40%",
                      marginBottom: "10px"
                    }}
                  >
                    <p className="label">Kolor podstawowy</p>
                    <div
                      style={{
                        backgroundColor: selectedColorSchema.prime_color,
                        height: "40px"
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      width: "40%"
                    }}
                  >
                    <p className="label">Kolor uzupełniający</p>
                    <div
                      style={{
                        backgroundColor: selectedColorSchema.secondary_color,
                        height: "40px"
                      }}
                    ></div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <GenericSelect
                options={showMapOptions}
                placeholder="Wybierz opcję ustawienia geolokalizacji zbiorów"
                control={control}
                required={{ required: "To pole jest wymagane!" }}
                name="show_map"
                errors={errors}
                defaultValue={showMapOptions[1]}
                label="Możliwość ustawienia geolokalizacji zbiorów"
                handleSelectChange={handleMapChange}
              />
            </div>
          </div>

          {id && (
            <>
              <div className="columns">
                <div className="column is-5">
                  <Input
                    name="url"
                    label="Adres URL (bip)"
                    type="url"
                    register={register()}
                    errors={errors}
                  />

                  <GenericSelect
                    options={statusItems}
                    placeholder="Wybierz status"
                    control={control}
                    required={{ required: "To pole jest wymagane!" }}
                    name="is_blocked"
                    errors={errors}
                    getOptionValue={option => `${option.value}`}
                    label="Status"
                  />
                </div>
              </div>

              <hr className="library-form__header__hr" />
              <h3 className="library-form__section-title">
                Adresy IP uprawnione do logowania do panelu administracyjnego
              </h3>

              <div className="columns library-form--padding-top">
                <div className="column is-full">
                  <GenericDoubleInputFieldArray
                    name="login_ip_addresses"
                    control={control}
                    errors={errors}
                    addButtonText="Dodaj adres IP"
                    deleteButtonTitle="Usuń adres IP"
                    inputName="address"
                    inputLabel="Adres IP"
                    pattern={{
                      pattern: {
                        value: ipRegex,
                        message: "Niepoprawny adres IP"
                      }
                    }}
                    secondInputName="mask"
                    secondInputLabel="Maska"
                    secondPattern={{
                      pattern: {
                        value: maskRegex,
                        message:
                          'Niepoprawny format maski. Przykładowa maska "/24".'
                      }
                    }}
                    appendOption={{ address: "", mask: "" }}
                    removeAllArray={removeAllArray}
                    setRemoveAllArray={setRemoveAllArray}
                    secondIsRequired={false}
                    message="Zezwalaj na dostęp ze wszystkich adresów"
                  />
                </div>
              </div>

              <h3 className="library-form__section-title library-form--padding-top">
                Adresy IP uprawnione do odczytu obiektów chronionych prawem
                autorskim
              </h3>

              <div className="columns library-form--padding-top">
                <div className="column is-full">
                  <GenericDoubleInputFieldArray
                    name="objects_ip_addresses"
                    control={control}
                    errors={errors}
                    addButtonText="Dodaj adres IP"
                    deleteButtonTitle="Usuń adres IP"
                    inputName="address"
                    inputLabel="Adres IP"
                    pattern={{
                      pattern: {
                        value: ipRegex,
                        message: "Niepoprawny adres IP"
                      }
                    }}
                    secondInputName="mask"
                    secondInputLabel="Maska"
                    secondPattern={{
                      pattern: {
                        value: maskRegex,
                        message:
                          'Niepoprawny format maski. Przykładowa maska "/24".'
                      }
                    }}
                    appendOption={{ address: "", mask: "" }}
                    removeAllArray={removeAllArray}
                    setRemoveAllArray={setRemoveAllArray}
                    secondIsRequired={false}
                    message="Zezwalaj na dostęp ze wszystkich adresów"
                  />
                </div>
              </div>

              <hr className="library-form__header__hr" />
              <h3 className="library-form__section-title">Wybór języków</h3>

              <CheckboxColumn
                items={languages}
                name="languages"
                permissionFields={languages}
                setPermissionFields={setLanguages}
              />
            </>
          )}

          <div className="buttons">
            <CancelButton
              returnLocation={urls.cancel}
              bodyContentSufix={`${id ? "edycję" : "dodanie nowej"
                } biblioteki?`}
            />
            <button
              className={`button button--is-wide library-form__btn ${isLoading ? "is-loading" : ""
                }`}
              type="submit"
            >
              Zapisz
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default LibraryForm;
