import "./Synonyms.scss";
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import * as Polish from "@uppy/locales/lib/pl_PL";

import React, {useEffect, useRef, useState} from "react";

import { Dashboard } from '@uppy/react';
import Tus from "@uppy/tus";
import Uppy from "@uppy/core";
import axiosSession from "@app/config/axiosSession";
import useGenericToastify from "@app/hooks/useGenericToastify";


const Synonyms = ({ fileServer }) => {

  const { notifySuccess, notifyError } = useGenericToastify();
  const [fileInfo, setFileInfo] = useState({});
  const [fileIsUploaded, setFileIsUploaded] = useState(false);

  const urls = {
    downloadFile: "/api/search/backoffice/synonyms-download",
    uploadFile: "/api/search/backoffice/synonyms-upload/"
  };

  const messages= {
    noFile: "Słownik synonimów nie został jeszcze wgrany. Stwórz nowy plik zawierający słownik synonimów i wgraj go do systemu."
  };

  const uppy = useRef(
    Uppy({
      id: "uppy1",
      locale: Polish,
      debug: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: 1000000,
        maxNumberOfFiles: 1,
        allowedFileTypes: ['text/*']
      }
    })
      .use(Tus, {
        endpoint: fileServer,
        limit: 0
      })
      .on('file-added', (file) => {
        if (file.name !== "synonyms_dict.txt") {
          uppy.current.reset();
          notifyError("Plik musi nazywać się 'synonyms_dict.txt'");
        }
      })
      .on("complete", result => {
        const {successful} = result;
        setFileInfo(successful[0].data);
        setFileIsUploaded(true);
      }))

  const uploadFile = () => {
    const fd = new FormData();
    fd.append('file', fileInfo);
    axiosSession
      .put(urls.uploadFile, fd, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(() => {
        notifySuccess(
          "Plik został załadowany poprawnie."
        );
        setFileIsUploaded(false);
        setFileInfo({});
        uppy.current.reset();
      })
      .catch(error => {
        notifyError(
          "Nie udało się załadować pliku."
        );
        console.error(error);
      });
  };

  useEffect(() => {
    const currentUppyInstance = uppy.current;
    return () => currentUppyInstance.close();
  }, [uppy]);

  const downloadFile = () => {
    axiosSession({
      url: urls.downloadFile,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'synonyms_dict.txt');
      document.body.appendChild(link);
      link.click();
    }).catch(() => notifyError(messages.noFile));
  };

  return (
    <section className="mbc-backoffice">
      <div className="container">
        <div className="breadcrumbs">
          <a
            href="/admin-panel/home/"
            className="breadcrumbs__link"
            title="Strona główna"
          >
            Strona główna
          </a>
          &nbsp;/&nbsp;
          <strong>Synonimy</strong>
        </div>
        <ul className="synonyms__list">
          <li>1. Pobierz plik zawierający listę synonimów</li>
          <li>2. Wyedytuj plik tekstowy z synonimami np. w Notatnik</li>
          <li>3. Pamiętaj, aby kolejne synonimy były oddzielone przecinkiem</li>
          <li>4. Nowe wartości słownikowe należy wprowadzać w nowej linii</li>
          <li>5. Wgraj zapisany plik</li>
        </ul>
        <button
          className="button button--is-orange synonyms__download-btn"
          onClick={downloadFile}>
          Pobierz plik
        </button>
        <Dashboard
          uppy={uppy.current}
          height={300}
          showProgressDetails={true}
        />
        <button
          className="button button--is-black"
          type="button"
          disabled={!fileIsUploaded}
          onClick={uploadFile}
        >
          Zapisz w systemie
        </button>
      </div>
    </section>
  )
}

export default Synonyms;
